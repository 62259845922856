.social-modal {
	.modal-header {
		padding: 10px;
		h2 {
			color: #252525;
			font-size: 20px;
			margin-bottom: 0;
			padding: 0;
		}
		.close {
			margin: 0;
			position: absolute;
			right: 5px;
			top: 0;
			z-index: 9;
			font-size: 2rem;
			padding: 5px;
			opacity: 1;
		}
	}
	.modal-body {
		.ant-row {
			.ant-col {
				.ant-checkbox-wrapper {
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
				}
			}
		}
		.post-btns {
			justify-content: space-between;
			.ant-btn-primary {
				background-color: #000080;
				border: 1px solid #000080;
				font-size: 14px;
				min-width: 120px;
				color: white;
				cursor: pointer;
				border-radius: 4px;
				flex: 0 0 48%;
				max-width: 48%;
				&:hover {
					background-color: #000;
				}
			}
		}
	}
}
.post-modal {
	.upload-file-box {
		align-items: center;
		background: #212b5c;
		background: -webkit-gradient(left top, left bottom, color-stop(0, #212b5c), color-stop(100%, #000c40));
		background: linear-gradient(180deg, #212b5c, #000c40);
		border: 1px solid #c1c1c1;
		display: flex;
		// height: 150px;
		justify-content: center;
		width: 100%;
		.sku-image {
			max-width: 100%;
		}
	}
	.hashtag-box {
		.input-group-text {
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			border-right: none;
		}
		.form-control {
			border-bottom-left-radius: 0;
			border-left: 0;
			border-top-left-radius: 0;
		}
	}
	max-width: 50% !important;
	.modal-header {
		padding: 1rem 1rem 0.5rem;
		h4 {
			margin: 0;
			font-size: 20px;
		}
		.close {
			font-size: 30px;
			opacity: 1;
			padding: 5px 8px 0 0;
		}
	}
	.web-link-form {
		.sku-image-box {
			justify-content: center;
			text-align: center;
			display: flex;
		}
		.ant-input-lg,
		.ant-input-number-lg {
			font-size: 14px;
			border: 1px solid #d9d9d9;
			border-radius: 0.25rem;
			height: 40px;
			width: 100%;
		}
		.ant-form-item {
			margin-bottom: 0;
		}
		.dollar-radius {
			border-radius: 0 !important;
			width: 100%;
		}
		.ant-select-lg {
			font-size: 14px;
		}
		.ant-btn-lg {
			background-color: #000080;
			border: 1px solid #000080;
			font-size: 14px;
			cursor: pointer;
			border-radius: 4px;
			&:hover {
				background-color: #000;
			}
		}
	}
}
.bioshop-antd-drawer {
	&.rs-drawer-right.rs-drawer-sm {
		width: 531px;
	}
	.rs-drawer-dialog {
		.rs-drawer-content {
			.rs-drawer-body {
				padding: 0;
				position: static;
				.rs-drawer-body-close {
					display: none;
				}
			}
		}
	}
	.ant-drawer-header {
		display: none;
	}
	.ant-drawer-body {
		padding: 0;
	}
	.slide-pannel-new {
		width: 100%;
		display: flex;
		flex-direction: column;
		height: 100%;
		.checkout-container {
			height: calc(100vh - 95px);
		}
		.close-slide-panel {
			position: absolute;
			top: 42px;
			left: -45px;
			z-index: 2;
			margin: 0;
			background-color: #fff;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			border-top-left-radius: 50%;
			border-bottom-left-radius: 50%;
			transition: all 0.5s ease 0s;
			height: 50px;
			padding-right: 0;
			font-size: 20px;
			width: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-right: none;
			i {
				position: relative;
				left: 5px;
				font-size: 40px;
			}
		}
		.ant-tabs-top {
			.ant-tabs-nav {
				margin-bottom: 0;
				&:before {
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);
				}
				.ant-tabs-nav-operations {
					display: none;
				}
			}
		}
		.ant-tabs-nav-wrap {
			width: 100%;
			.ant-tabs-nav-list {
				width: 100%;
				justify-content: space-between;
				.ant-tabs-tab {
					padding: 10px 10px;
					width: 50%;
					justify-content: center;
					box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
					border-top: solid 1px rgb(0 0 0 / 10%);
					margin: 0;
					&:hover {
						color: #000080;
					}
					&.ant-tabs-tab-active {
						.ant-tabs-tab-btn {
							color: #000080;
						}
					}
					.basket-icon {
						display: flex;
						width: 100%;
						height: 100%;
						justify-content: center;
						align-items: center;
						position: relative;
						img {
							width: 30px;
							position: absolute;
						}
						.cart-count {
							background: #000;
							width: 15px;
							height: 15px;
							border-radius: 50%;
							position: absolute;
							top: -15px;
							color: #fff;
							display: flex;
							justify-content: center;
							align-items: center;
							font-size: 10px;
							z-index: 2;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 600;
							left: 8px;
						}
					}
				}
				.ant-tabs-ink-bar {
					background-color: #000080;
				}
			}
		}
		.prod-for-purchase {
			background-color: #fff;
			height: 49px;
			border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			padding: 10px;
			display: flex;
			width: 100%;
			flex-direction: column;
			position: relative;
			h4 {
				font-size: 1.15rem;
				font-weight: 800 !important;
				padding: 0;
				margin: 0;
			}
			.available-for-purchase {
				font-size: 0.75rem;
				margin-top: 1px;
				color: #757575;
				line-height: 1;
			}
			.go-back {
				position: absolute;
				right: 30px;
				top: 14px;
				display: none !important;
				cursor: pointer;
				&:hover {
					border-bottom: solid 1px #000;
				}
			}
		}
		.pro-list-main {
			height: calc(100vh - 133px);
			position: relative;
			padding-top: 10px;
			h2 {
				font-size: 1.15rem;
				font-weight: 800 !important;
				padding: 0 20px;
			}
			.pro-list-inner {
				width: 100%;
				display: flex;
				flex-direction: column;
				padding-top: 20px;
				list-style-type: none;
				overflow-y: auto;
				max-height: calc(100% - 0px);
				height: 100%;
				padding: 0 10px;
				.cart-empty {
					padding-left: 10px;
				}

				&::-webkit-scrollbar {
					width: 6px;
					height: 6px;
				}
				&::-webkit-scrollbar-track {
					transition-duration: 0.3s;
					box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
					border-radius: 10px;
				}
				&::-webkit-scrollbar-thumb {
					transition-duration: 0.3s;
					box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
					border-radius: 10px;
				}
				&:hover::-webkit-scrollbar-track {
					box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
				}
				&:hover::-webkit-scrollbar-thumb {
					box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
				}

				.list-col {
					width: 100%;
					display: inline-flex;
					flex-direction: column;
					margin-bottom: 10px;
					&:last-child {
						padding-bottom: 0;
						border-bottom: none;
					}

					.prod-box {
						background-color: #fff;
						display: inline-flex;
						min-width: 100%;
						position: relative;
						border: solid 1px #d9d9d9;
						border-radius: 8px;
						padding: 10px;
						.prod-image {
							width: 65px;
							flex-basis: 65px;
							position: relative;
							overflow: hidden;
							transition: box-shadow 0.25s;
							flex-grow: 0;
							flex-shrink: 0;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}
						.prod-name {
							display: flex;
							flex-grow: 1;
							margin-left: 25px;
							min-width: 0px;
							flex-direction: column;
							width: 100%;
							padding-right: 15px;
							.prod-title {
								display: flex;
								flex-direction: column;
								.prod-name-inr {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
									font-size: 0.85rem;
									line-height: 18px;
									display: -webkit-box;
									max-width: 100%;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
									overflow-wrap: normal;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
								}
								.prod-veriants-inr {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
									font-size: 12px;
									line-height: 18px;
								}
							}
							.prod-price {
								display: flex;
								margin-top: 5px;
								span {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 800;
									color: #000;
									&.original-price {
										color: #f00;
										margin-left: 10px;
										text-decoration-line: line-through;
									}
								}
							}
							.qty-rmv-btn {
								display: flex;
								width: 100%;
								justify-content: space-between;
								align-items: center;
								margin-top: 5px;
								.remove-btn {
									color: #000;
									font-size: 12px;
									text-decoration: underline;
								}
							}
						}
						.prod-cart-btn {
							display: flex;
							flex-direction: column;
							margin-right: 0;
							gap: 10px;
							a {
								background-color: #000080;
								border-radius: 37px;
								color: #fff;
								cursor: pointer;
								font-family: Nunito Sans, sans-serif !important;
								font-size: 12px;
								font-weight: 700;
								padding: 6px 0;
								min-width: 80px;
								text-align: center;
								&:hover {
									background-color: #000;
								}
								&.share-product-oncart {
									background-color: #fff;
									color: #000;
									border: solid 1px #ccc;
									&:hover {
										background-color: #000;
										color: #fff;
									}
								}
							}
						}
					}
				}
				&.list-gap {
					// max-height: calc(100% - 90px);
					max-height: calc(100% - 340px);
				}
			}
			.checkout-buttons {
				display: flex;
				width: 100%;
				background-color: #fff;
				position: absolute;
				bottom: 0;
				left: 0;
				padding: 10px 20px;
				border-radius: 0 0 8px 8px;
				flex-direction: column;
				.grand-total {
					display: flex;
					width: 100%;
					justify-content: space-between;
					margin-bottom: 10px;
					.total-price {
						font-family: Nunito Sans, sans-serif !important;
						font-weight: 700;
					}
				}
				button {
					width: 100%;
					padding: 0.7rem 1rem;
					background-color: #000080;
					border-color: #000080;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
					&:hover {
						background-color: #000;
						border-color: #000;
					}
				}

				.checkout-discount-section {
					// display: flex;
					width: 100%;
					gap: 15px;
					margin-top: 10px;
					padding-bottom: 20px;
					border-bottom: solid 1px #d9d9d9;
					margin-bottom: 20px;
					.ant-form {
						width: 100%;
						display: flex;
						gap: 10px;
						.ant-form-item {
							margin-bottom: 0;
						}
						.float-label {
							width: 100%;
							.ant-input {
								height: 45px;
								border-radius: 4px;
							}
						}
						.btn-primary {
							background-color: #000080;
							border-color: #000080;
							width: initial;
						}
					}
				}
			}
			&.pro-list-main-cart {
				height: calc(100vh - 45px);
			}

			.checkout-order-total {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 5px;
				.subtotal,
				.shipping {
					display: flex;
					width: 100%;
					justify-content: space-between;
					span {
						&:last-child {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
						}
					}
				}
				.grand-total {
					display: flex;
					width: 100%;
					justify-content: space-between;
					border-top: solid 1px #d9d9d9;
					padding-top: 10px;
					margin-top: 15px;
					.gr-total {
						font-size: 16px;
					}
					.gr-t-price {
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						color: #000;
						font-size: 22px;
					}
				}
			}
		}
		.checkout-frame {
			display: flex;
			width: 100%;
			background-color: #fff;
			position: absolute;
			height: calc(100vh - 45px);
			overflow-y: auto;
			// margin-top: 43px;

			&::-webkit-scrollbar {
				width: 6px;
				height: 6px;
			}
			&::-webkit-scrollbar-track {
				transition-duration: 0.3s;
				box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				transition-duration: 0.3s;
				box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
				border-radius: 10px;
			}
			&:hover::-webkit-scrollbar-track {
				box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
			}
			&:hover::-webkit-scrollbar-thumb {
				box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
			}
			iframe {
				width: 100%;
				border: none;
			}
			.order-summery {
				width: 100%;
				.ant-collapse {
					border: none;
				}
				.ant-collapse-content {
					background-color: #fafafa;
				}
				.ant-collapse-header {
					padding: 13px 10px 13px 10px;
					border-radius: 0;
				}
				.ant-collapse-expand-icon {
					span {
						vertical-align: 0;
					}
				}
				.total-price-top {
					position: absolute;
					z-index: 2;
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
					color: #000;
					font-size: 20px;
					right: 0;
					top: 0;
					padding: 8px 16px;
				}
				.ordered-products {
					width: 100%;
					.list-col {
						width: 100%;
						display: inline-flex;
						flex-direction: column;
						margin-bottom: 10px;
						&:last-child {
							padding-bottom: 0;
							border-bottom: none;
						}

						.prod-box {
							background-color: #fff;
							display: inline-flex;
							min-width: 100%;
							position: relative;
							border: solid 1px #d9d9d9;
							border-radius: 8px;
							padding: 10px;
							.prod-image {
								width: 65px;
								flex-basis: 65px;
								position: relative;
								transition: box-shadow 0.25s;
								flex-grow: 0;
								flex-shrink: 0;
								img {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
								}
								.pro-qty {
									position: absolute;
									background: rgba(114, 114, 114, 0.9);
									color: #fff;
									height: 1.75em;
									min-width: 1.75em;
									border-radius: 1.75em;
									display: flex;
									font-size: 0.85rem;
									justify-content: center;
									align-items: center;
									right: -5px;
									top: -6px;
									font-family: "Nunito Sans", sans-serif !important;
								}
							}
							.prod-name {
								display: flex;
								flex-grow: 1;
								margin-left: 25px;
								min-width: 0px;
								flex-direction: column;
								width: 100%;
								padding-right: 15px;
								.prod-title {
									display: flex;
									flex-direction: column;
									.prod-name-inr {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
										color: #000;
										font-size: 0.85rem;
										line-height: 18px;
										display: -webkit-box;
										max-width: 100%;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: normal;
										overflow-wrap: normal;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 2;
									}
									.prod-veriants-inr {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
										color: #000;
										font-size: 0.85rem;
										line-height: 18px;
									}
								}
								.prod-price {
									display: flex;
									margin-top: 5px;
									span {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 800;
										color: #000;
										&.original-price {
											color: #f00;
											margin-left: 10px;
											text-decoration-line: line-through;
										}
									}
								}
								.qty-rmv-btn {
									display: flex;
									width: 100%;
									justify-content: space-between;
									align-items: center;
									margin-top: 5px;
									.remove-btn {
										color: #000;
										font-size: 12px;
										text-decoration: underline;
									}
								}
							}
							.prod-cart-btn {
								display: flex;
								flex-direction: column;
								margin-right: 0;
								a {
									background-color: #000080;
									border-radius: 37px;
									color: #fff;
									cursor: pointer;
									font-family: Nunito Sans, sans-serif !important;
									font-size: 12px;
									font-weight: 700;
									padding: 6px 0;
									min-width: 80px;
									text-align: center;
									&:hover {
										background-color: #000;
									}
								}
							}
						}
					}
				}
				.checkout-discount-section {
					// display: flex;
					width: 100%;
					gap: 15px;
					margin-top: 10px;
					padding-bottom: 20px;
					border-bottom: solid 1px #d9d9d9;
					margin-bottom: 20px;
					.ant-form {
						width: 100%;
						display: flex;
						gap: 10px;
						.ant-form-item {
							margin-bottom: 0;
						}
						.float-label {
							width: 100%;
							.ant-input {
								height: 45px;
								border-radius: 4px;
							}
						}
						.btn-primary {
							background-color: #000080;
							border-color: #000080;
						}
					}
				}
				.checkout-order-total {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 5px;
					.subtotal,
					.shipping {
						display: flex;
						width: 100%;
						justify-content: space-between;
						span {
							&:last-child {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
							}
						}
					}
					.grand-total {
						display: flex;
						width: 100%;
						justify-content: space-between;
						border-top: solid 1px #d9d9d9;
						padding-top: 10px;
						margin-top: 15px;
						.gr-total {
							font-size: 16px;
						}
						.gr-t-price {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
							font-size: 22px;
						}
					}
				}
				.customer-information {
					width: 100%;
					margin-top: 10px;
					padding: 0 10px;
					.ant-tabs-top {
						.ant-tabs-nav {
							&:before {
								display: none;
							}
						}
					}
					.ant-tabs-nav-wrap {
						.ant-tabs-nav-list {
							width: auto;
							.ant-tabs-tab {
								box-shadow: none;
								width: auto;
								border: none;
								position: relative;
								padding-right: 15px;
								&:after {
									content: "\f105";
									font-family: "FontAwesome";
									position: absolute;
									right: 0;
									font-size: 15px;
								}
								&:nth-child(2) {
									&:after {
										display: none;
									}
								}
								&:hover {
									text-decoration: underline;
								}
							}
							.ant-tabs-ink-bar {
								display: none;
							}
						}
					}
					.checkout-stage-one {
						width: 100%;
						margin-top: 20px;
						h2 {
							font-size: 1.15rem;
							font-weight: 700 !important;
							padding: 0;
						}
						.ant-form {
							margin-top: 15px;
						}
						.inf-column {
							display: flex;
							width: 100%;
							padding: 8px 0;
							.float-label {
								width: 100%;
								.ant-input-lg,
								.ant-select-selector {
									height: 45px;
									border-radius: 4px;
								}
							}
						}
						.inf-button {
							width: 100%;
							margin-top: 10px;
							margin-bottom: 15px;
							button {
								width: 100%;
								padding: 0.7rem 1rem;
								background-color: #000080;
								border-color: #000080;
								font-family: Nunito Sans, sans-serif !important;
								font-weight: 700;
								&:hover {
									background-color: #000;
								}
							}
						}
					}
					.checkout-stage-two {
						width: 100%;
						margin-top: 20px;
						h2 {
							font-size: 1.15rem;
							font-weight: 700 !important;
							padding: 0;
						}
						.filed-detail {
							display: flex;
							flex-direction: column;
							border: solid 1px #d9d9d9;
							padding: 10px;
							border-radius: 4px;
							.contact-email {
								display: flex;
								flex-direction: column;
								position: relative;
								.contc-1 {
									color: #737373;
								}
								.contact-2 {
									strong {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
									}
								}
								a {
									position: absolute;
									top: 0;
									right: 10px;
									&:hover {
										text-decoration: underline;
									}
								}
							}
							.shipp-email {
								border-top: solid 1px #d9d9d9;
								padding-top: 10px;
								margin-top: 10px;
								a {
									top: 6px;
								}
							}
						}
						.more-addresses {
							width: 100%;

							h2 {
								font-size: 1.15rem;
								font-weight: 700 !important;
								padding: 0;
							}
							.slick-slider {
								margin-left: -5px;
								margin-right: -5px;
								.slick-list {
									.slick-track {
										margin: 0;
										.slick-slide {
											padding: 0 5px;
											// min-width: 256px;
											min-width: 248px;
										}
									}
								}
							}
							.address-box {
								border: solid 1px #d9d9d9;
								min-height: 135px;
								border-radius: 4px;
								padding: 16px;
								position: relative;
								cursor: pointer;
								&.dflt {
									border-color: #000080;
								}
								.address-inner-detail {
									display: flex;
									width: 100%;
									flex-direction: column;
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									font-size: 0.854rem;
									h4 {
										font-size: 1rem;
										font-weight: 700 !important;
										color: #737373;
									}
								}
								.edit-address {
									position: absolute;
									bottom: 16px;
									right: 16px;
									font-size: 16px;
									display: flex;
									align-items: center;
									i {
										cursor: pointer;
										margin-right: 15px;
									}
								}
							}
						}
						.select-shipping-rate {
							width: 100%;
							margin-top: 30px;
							.shipping-box {
								display: flex;
								flex-direction: column;
								border: solid 1px #d9d9d9;
								border-radius: 4px;
								.ant-radio-wrapper {
									border-bottom: solid 1px #d9d9d9;
									width: 100%;
									padding: 12px 10px;
									&:last-child {
										border: none;
									}
									.ship-rates {
										display: flex;
										flex-direction: column;
										width: 250px;
										.small-text {
											color: #737373;
											font-size: 0.854rem;
										}
									}
									.ship-rates-right {
										position: absolute;
										right: 10px;
										top: 12px;
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
									}
								}
								&.style2 {
									background-color: #ffffff;
								}
							}
						}
						.checkot-continue-btn {
							width: 100%;
							margin-top: 30px;
							margin-bottom: 20px;
							button {
								width: 100%;
								padding: 1rem 1rem;
								background-color: #000080;
								border-color: #000080;
								font-family: Nunito Sans, sans-serif !important;
								font-weight: 700;
								&:hover {
									background-color: #000;
								}
							}
						}
						.gift-card-checkout {
							width: 100%;
							margin-top: 30px;
							h2 {
								font-size: 1.15rem;
								font-weight: 700 !important;
								padding: 0;
							}
							.checkout-discount-section {
								padding-bottom: 0;
								margin-bottom: 0;
								border: none;
							}
						}
						.select-cradit-card {
							width: 100%;
							margin-top: 30px;
							h2 {
								font-size: 1.15rem;
								font-weight: 700 !important;
								padding: 0;
								margin-bottom: 0;
							}
							p {
								color: #737373;
							}
							.filed-detail {
								padding-top: 15px;
								padding-bottom: 15px;
								.contact-email {
									.contact-2 {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700 !important;
										font-size: 16px;
										i {
											font-size: 18px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
// Bioshop Checkout

.bio_shop_new_ift {
	width: 100%;
	margin-top: 70px;

	.bio_container_main {
		width: 100%;

		.bio_shop_header {
			display: flex;
			width: 100%;

			.bio_shop_header_left {
				display: inline-flex;
				flex-direction: column;

				.brand_logo {
					width: 100px;
					overflow: hidden;
					position: relative;
					border: solid 1px #ccc;
					height: 100px;
					border-radius: 50%;

					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
				}
				.brand_name {
					text-transform: uppercase;
					font-family: Nunito Sans, sans-serif !important;
					font-size: 1rem;
					font-weight: 700;
					color: #f00;
					margin-top: 15px;
				}
			}
		}
	}
}

.bio_shop_main_ift {
	width: 100%;
	margin-top: 0;

	.bio_shop_inr {
		max-width: 1440px;
		margin: 0 auto;
		display: flex;
	}

	.bio_container_main {
		width: 100%;
		min-height: 550px;
		&.bio_container_main_infl {
			display: flex;
			.bio_shop_body {
				.bioshop_filters_new {
					grid-template-columns: repeat(3, minmax(0, 1fr));
				}
				.bio_cat_images {
					.post-grid {
						grid-template-columns: repeat(3, minmax(0, 1fr));
					}
				}
			}

			.is-sticky {
				position: fixed;
				top: 30px;
				z-index: 999;
				animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
			}
			.left-column {
				flex: 0 0 23% !important;
				max-width: 23% !important;
				@media only screen and (max-width: 1600px) {
					flex: 0 0 28% !important;
					max-width: 28% !important;
				}
				@media only screen and (max-width: 1400px) {
					flex: 0 0 35% !important;
					max-width: 35% !important;
				}
				@media only screen and (max-width: 990px) {
					flex: 0 0 37% !important;
					max-width: 37% !important;
				}
				@media only screen and (max-width: 760px) {
					flex: 0 0 100% !important;
					max-width: 100% !important;
				}
				.mobile-preview {
					background-color: #c8c8c8;
					border-radius: 25px;
					margin: 0 0 10px 0;
					max-width: 320px;
					padding: 14px 12px;
					.preview-mobile-box {
						background-color: #170a1c;
						background-image: url(../images/mobile-speaker.png);
						background-position: center top 6px;
						background-repeat: no-repeat;
						border-radius: 25px;
						padding: 22px 5px 30px;
						.mobile-header {
							color: #fff;
							display: flex;
							padding: 5px 20px 5px 7px;
							.place-holder-name {
								font-family: "SegoeUIBold";
								padding-left: 0;
								padding-top: 0;
							}
						}
						.mobile-gallery {
							height: 490px;
							margin: 0 auto;
							overflow: scroll;
							overflow-x: hidden;
							width: 100%;
							.row {
								justify-content: flex-start;
								margin: 0;
								width: 100%;
								.col-4 {
									display: inline-block;
									// float: left;
									padding: 0;
									position: relative;
									width: 33.333%;
									&::after {
										content: "";
										display: block;
										padding-top: 100%;
									}
								}
							}
							.mobile-image-box {
								border-color: #f6f6f6 #f3f3f3 #eee;
								border-style: solid;
								border-width: 1px;
								bottom: 1px;
								left: 1px;
								position: absolute;
								right: 1px;
								top: 1px;
								.mobile_box_inr {
									background-color: #fff;
									bottom: 0;
									display: block;
									left: 0;
									margin: 0;
									overflow: hidden;
									position: absolute;
									right: 0;
									top: 0;
									img {
										border-radius: 0 !important;
										cursor: pointer;
										display: block;
										height: 100%;
										object-fit: cover;
										object-position: 50% 0;
										width: 100%;
									}
									video {
										border-radius: 0 !important;
										cursor: pointer;
										display: block;
										height: 100%;
										object-fit: cover;
										object-position: 50% 0;
										width: 100%;
									}
								}
								.video-label {
									background-color: #222;
									border-radius: 6px;
									color: #fff;
									cursor: pointer;
									font-size: 10px;
									height: 18px;
									position: absolute;
									right: 0;
									text-align: center;
									top: 0;
									width: 18px;
									z-index: 0;
									padding: 0;
								}
							}
							.linked-label {
								background: rgba(0, 0, 0, 0.5);
								border-radius: 2px;
								bottom: 0.5rem;
								color: #fff;
								cursor: pointer;
								font-size: 0.8rem;
								left: 5px;
								padding: 0.16667rem 0.4rem;
								position: absolute;
							}
						}
						.mobile-gallery::-webkit-scrollbar {
							width: 6px;
							height: 6px;
						}

						.mobile-gallery::-webkit-scrollbar-track {
							box-shadow: inset 0 0 5px #ffffff !important;
							border-radius: 10px;
						}

						.mobile-gallery::-webkit-scrollbar-thumb,
						.mobile-gallery::-webkit-scrollbar-thumb:hover {
							background: #faad14 !important;
							// background: #134a7c !important;
							// background: #6270bd !important;

							border-radius: 10px;
						}
					}
				}
				.select-source-container {
					display: flex;
					max-width: 320px;
					margin-bottom: 20px;
					flex-flow: row wrap;
					.select-source {
						max-width: 75%;
						flex: 0 0 75%;
						.ant-select-lg {
							font-size: 14px;
						}
						.ant-select-selector {
							border-color: #000;
							border-right: none;
						}
					}
					.connected-button {
						max-width: 25%;
						flex: 0 0 25%;
						font-size: 13px;
						background-color: green;
						color: #fff;
						font-family: Nunito Sans, sans-serif !important;
						font-weight: 700;
						display: flex;
						justify-content: center;
						align-items: center;
						border-radius: 0 2px 2px 0;
					}
				}
			}
			.right-column {
				flex: 0 0 77% !important;
				max-width: 77% !important;
				padding: 0;
				margin-top: 0;
				@media only screen and (max-width: 1600px) {
					flex: 0 0 72% !important;
					max-width: 72% !important;
				}
				@media only screen and (max-width: 1400px) {
					flex: 0 0 65% !important;
					max-width: 65% !important;
				}
				@media only screen and (max-width: 990px) {
					flex: 0 0 63% !important;
					max-width: 63% !important;
				}
				@media only screen and (max-width: 760px) {
					display: none;
				}
				.bio_shop_body {
					margin-top: -4px;
					.bio_shop_header {
						margin-top: 0;
						.influencer-bioshop-header-inner {
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
						}
						.bio_shop_header_topmenu {
							margin-top: 10px;
						}
					}
				}
			}
			.add-post-container {
				height: calc(100vh - 304px);
				position: absolute;
				right: 2%;
				max-width: 75% !important;
			}
			.image-edit-box {
				box-shadow: rgb(228, 231, 237) 0px 0px 2px 1px;
				position: relative;
				background: rgb(255, 255, 255);
				border-radius: 10px;
				padding: 30px 20px 20px;
				.close-box {
					cursor: pointer;
					font-size: 20px;
					position: absolute;
					right: 7px;
					top: 0;
				}
				.image-wrapper {
					display: flex;
					.image-box {
						align-items: center;
						justify-content: center;
						max-width: 26%;
						flex: 0 0 26%;
						img,
						video {
							height: auto;
							width: 100%;
							border-width: 1px;
							border-style: solid;
							border-color: rgb(204, 204, 204);
							border-image: initial;
							border-radius: 4px;
							padding: 4px;
							object-fit: cover;
						}
					}
					.image-edit-links {
						flex: 0 0 74%;
						max-width: 74%;
						padding: 0 10px;
						.ant-select-selector,
						.ant-picker {
							border-color: #000;
						}
						.edit_button_main {
							display: flex;
							margin-top: 15px;
							gap: 10px;
							button {
								background-color: #000080;
								border: 1px solid #000080;
								font-size: 14px;
								min-width: 120px;
								cursor: pointer;
								border-radius: 4px;
								&:hover {
									background-color: #000;
								}
							}
						}
					}
				}
			}
		}

		.bio_shop_header {
			min-width: 100%;
			display: flex;
			// flex-direction: column;
			align-items: center;
			// justify-content: space-between;
			position: relative;
			margin: 20px 0;
			gap: 30px;

			.bio_shop_header_left {
				display: flex;
				flex-direction: column;
				// width: 17%;
				flex-grow: 0;
				flex-shrink: 0;
				// flex-basis: 17%;

				.brand-descrp {
					display: flex;
					align-items: center;
					cursor: pointer;

					.brand_logo {
						width: 70px;
						overflow: hidden;
						position: relative;
						border: solid 1px #ccc;
						height: 70px;
						border-radius: 50%;

						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					.logo-right {
						display: flex;
						flex-direction: column;
						margin-left: 15px;
					}
					.current-brand-name {
						text-transform: uppercase;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 1.15rem;
						font-weight: 800;
						color: #000;
						.view-profile-btn {
							color: #7b8994;
							text-transform: capitalize;
							font-size: 12px;
							font-weight: 700;
							&:hover {
								color: #000;
								text-decoration: underline;
							}
						}
					}
					.brand-followers {
						display: flex;
						gap: 10px;
						span {
							font-family: Nunito Sans, sans-serif !important;
							font-weight: 600;
							strong {
								font-weight: 800;
								text-transform: uppercase;
							}
						}
					}
				}
				.brand_name {
					text-transform: uppercase;
					font-family: Nunito Sans, sans-serif !important;
					font-size: 1rem;
					font-weight: 700;
					color: #f00;
					margin-top: 15px;
				}
			}
			.bio_shop_header_topmenu {
				display: flex;
				gap: 10px;
				// margin-top: 20px;
				a {
					height: 37px;
					border: solid 1px #ccc;
					color: #000;
					font-family: Nunito Sans, sans-serif !important;
					font-size: 14px;
					font-weight: 700;
					display: flex;
					align-items: center;
					justify-content: center;
					border-radius: 22px;
					padding-left: 20px;
					padding-right: 20px;
					transition: none;
					&:hover {
						background-color: #000080 !important;
						border-color: #000080 !important;
						color: #fff;
					}
					&.active {
						background-color: #000080;
						border-color: #000080;
						color: #fff;
					}
				}
				button {
					background-color: #fff;
					color: #000;
					border-color: #ccc;
					font-family: Nunito Sans, sans-serif !important;
					font-size: 14px;
					font-weight: 700;
					border-radius: 22px;
					padding-left: 20px;
					padding-right: 20px;
					&:focus {
						box-shadow: none;
					}
					&:focus-visible {
						outline: none;
					}
					&:hover {
						background-color: #000080;
						color: #fff;
					}
					&.active {
						background-color: #000080;
						color: #fff;
						border-color: #000080;
						&:focus {
							box-shadow: none;
						}
					}
				}
			}
			.bio_shop_copy_link {
				width: 32%;
			}

			.bio_shop_header_center {
				display: flex;
				flex-grow: 1;
				width: 100%;
				min-width: 0;

				.bio_shop_cat_slider {
					width: 100%;
					background-color: #fff;
					z-index: 1;
					margin-top: 15px;

					.react-multi-carousel-list {
						margin-left: -12px;
						margin-right: -12px;

						.react-multi-carousel-track {
							.react-multi-carousel-item {
								padding-left: 12px;
								padding-right: 12px;
							}
						}
					}
					.react-multiple-carousel__arrow {
						&:before {
							background: #fff;
							width: 32px;
							height: 32px;
							border-radius: 50%;
							display: flex;
							justify-content: center;
							align-items: center;
							line-height: normal;
							box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
							font-size: 16px;
							color: #000 !important;
							margin-top: -20px;
						}
					}

					.react-multiple-carousel__arrow--right {
						right: calc(0% - 3px) !important;
					}
					.react-multiple-carousel__arrow--left {
						left: calc(0% - 3px) !important;
					}

					.carousel-items {
						display: inline-flex;
						flex-direction: column;
						width: 100%;

						a {
							.cat_height {
								height: 88px;
								position: relative;
								overflow: hidden;
								width: 100%;
								transition: box-shadow 0.25s;
								// box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
								border: solid 1px #ccc;
								border-radius: 8px;
								display: none;

								img {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
								}
							}

							.circles {
								border-radius: 50% !important;
								border: 1px solid #ccc;
								padding: 2px;
								width: 65px;
								height: 65px;
							}

							span {
								font-weight: 800 !important;
								font-size: 14px;
								margin-bottom: 0;
								margin-top: 0;
								color: #000;
								height: 88px;
								position: relative;
								overflow: hidden;
								width: 100%;
								transition: box-shadow 0.25s;
								border: solid 1px #ccc;
								border-radius: 8px;
								display: flex;
								align-items: center;
								justify-content: center;
								text-transform: uppercase;
								font-family: "Nunito Sans", sans-serif !important;
								text-align: center;
							}

							&.active span {
								color: #e80c5b;
							}
						}
					}
					&.sub-cat-slider {
						.react-multiple-carousel__arrow {
							&:before {
								margin-top: -40px;
							}
						}
						// height:0px;
						// overflow: hidden;
						-webkit-transition: height, 0.3s linear;
						-moz-transition: height, 0.3s linear;
						-ms-transition: height, 0.3s linear;
						-o-transition: height, 0.3s linear;
						transition: height, 0.3s linear;

						&.sub-cat-slider.open {
							height: 115px;
							-webkit-transition: height, 0.3s linear;
							-moz-transition: height, 0.3s linear;
							-ms-transition: height, 0.3s linear;
							-o-transition: height, 0.3s linear;
							transition: height, 0.3s linear;
						}

						.carousel-items {
							a {
								.cat_height {
									height: 68px !important;
								}
								span {
									font-weight: 700 !important;
									line-height: 1.2rem;
								}
							}
						}
					}
				}
			}
		}

		.bio_shop_body {
			width: 100%;
			margin-top: 0;
			margin-bottom: 50px;
			.bioshop_filters_new {
				display: grid;
				width: 100%;
				grid-template-columns: repeat(4, minmax(0, 1fr));
				grid-column-gap: 24px;
				.post-type {
					display: flex;
					flex-direction: column;
					position: relative;
					.ant-select-lg {
						font-size: 14px;
						.ant-select-selector {
							border-color: #000;
						}
					}
					&.category-filter {
						.category-box {
							width: 100%;
							height: 40px;
							padding: 0 11px;
							position: relative;
							background-color: #fff;
							border: 1px solid #000;
							border-radius: 2px;
							display: flex;
							align-items: center;
							cursor: pointer;
							transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
							// &:hover{
							//   border-color: #40a9ff;
							//   border-right-width: 1px;
							// }
							.anticon-down {
								height: 12px;
								color: rgba(0, 0, 0, 0.25);
								position: absolute;
								right: 11px;
								font-size: 12px;
							}
						}
						.category-box-all {
							position: absolute;
							background-color: #fff;
							border-radius: 3px;
							box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
							margin-top: 3px;
							z-index: 99;
							left: 0;
							top: 70px;
							.all-inner {
								height: 350px;
								position: relative;
								padding: 10px;
								width: 500px;
								display: flex;
								.main-cat-left {
									width: 40%;
									display: flex;
									flex-direction: column;
									list-style-type: none;
									overflow-y: auto;
									padding: 0 10px;
									border-right: solid 1px #ccc;
									h3 {
										font-weight: 800 !important;
										font-size: 16px;
										padding: 8px 0 8px 0;
										margin: 0;
										line-height: 1;
									}
									.cat-filter-box {
										padding: 0;
										display: flex;
										flex-direction: column;
										margin-top: 5px;
										gap: 10px;
										button {
											color: rgb(64, 64, 64);
											font-size: 0.875rem;
											display: flex;
											justify-content: center;
											align-items: center;
											text-align: center;
											border: solid 1px #dce4ec;
											background-color: #fff;
											padding: 8px 10px;
											border-radius: 0;
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											text-transform: uppercase;
											line-height: 1.25rem;
											position: relative;
											height: auto;
											&:hover {
												background-color: #e1e2e4;
											}
											&.active {
												&:before {
													position: absolute;
													right: -4px;
													top: -7px;
													content: "\f00c";
													font-family: Fontawesome;
													z-index: 1;
													font-size: 10px;
													background: #e80c5b;
													width: 16px;
													height: 16px;
													border-radius: 50%;
													color: #fff;
													font-weight: normal;
												}
											}
										}
									}
									&::-webkit-scrollbar {
										width: 6px;
										height: 6px;
									}
									&::-webkit-scrollbar-track {
										transition-duration: 0.3s;
										box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
										border-radius: 10px;
									}
									&::-webkit-scrollbar-thumb {
										transition-duration: 0.3s;
										box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
										border-radius: 10px;
									}
									&:hover::-webkit-scrollbar-track {
										box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
									}
									&:hover::-webkit-scrollbar-thumb {
										box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
									}
								}
								.main-cat-right {
									width: 60%;
									display: flex;
									flex-direction: column;
									list-style-type: none;
									overflow-y: auto;
									padding: 0 10px;
									h3 {
										font-weight: 800 !important;
										font-size: 16px;
										padding: 8px 0 8px 0;
										margin: 0;
										line-height: 1;
									}
									.cat-filter-box {
										padding: 0;
										display: flex;
										flex-wrap: wrap;
										margin-top: 5px;
										gap: 10px;
										button {
											color: rgb(64, 64, 64);
											font-size: 0.875rem;
											display: flex;
											justify-content: center;
											align-items: center;
											text-align: center;
											border: solid 1px #dce4ec;
											background-color: #fff;
											padding: 8px 10px;
											border-radius: 0;
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											text-transform: uppercase;
											line-height: 1.25rem;
											position: relative;
											height: auto;
											&:hover {
												background-color: #e1e2e4;
											}
											&.active {
												&:before {
													position: absolute;
													right: -4px;
													top: -7px;
													content: "\f00c";
													font-family: Fontawesome;
													z-index: 1;
													font-size: 10px;
													background: #e80c5b;
													width: 16px;
													height: 16px;
													border-radius: 50%;
													color: #fff;
													font-weight: normal;
												}
											}
										}
									}

									&::-webkit-scrollbar {
										width: 6px;
										height: 6px;
									}

									&::-webkit-scrollbar-track {
										transition-duration: 0.3s;
										box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
										border-radius: 10px;
									}
									&::-webkit-scrollbar-thumb {
										transition-duration: 0.3s;
										box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
										border-radius: 10px;
									}
									&:hover::-webkit-scrollbar-track {
										box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
									}
									&:hover::-webkit-scrollbar-thumb {
										box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
									}
								}
							}
						}
					}
					&.show-live-events {
						align-items: flex-end;
						justify-content: flex-end;
						a {
							height: 40px;
							background-color: #000080;
							border-color: #000080;
							color: #fff;
							border-radius: 2px;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 14px;
							font-weight: 700;
							padding-left: 25px;
							padding-right: 25px;
							display: flex;
							align-items: center;
							justify-content: center;
							i {
								margin-right: 5px;
							}
							svg {
								margin-right: 5px;
							}
							&:hover {
								background-color: #000;
								border-color: #000;
							}
						}
					}
				}
			}

			.bio_shop_cat_slider {
				// &.sub-cat-slide{
				//   .react-multi-carousel-list{
				//     -webkit-transition: max-height 1s;
				//     -moz-transition: max-height 1s;
				//     -ms-transition: max-height 1s;
				//     -o-transition: max-height 1s;
				//     transition: max-height 1s;
				//     background: #e5feff;
				//     overflow: hidden;
				//     // "height: 0" not work with css transitions
				//     max-height: 0;
				//   }
				//   &.sub-category-class{
				//     .react-multi-carousel-list{
				//       max-height: 150px;
				//     }
				//   }
				// }

				// &.sub-cat-slide{
				//   .react-multi-carousel-list{
				//     height:0px;
				//     overflow: hidden;
				//     -webkit-transition:height, 0.3s linear;
				//     -moz-transition: height, 0.3s linear;
				//     -ms-transition: height, 0.3s linear;
				//     -o-transition: height, 0.3s linear;
				//     transition: height, 0.3s linear;
				//   }
				//   &.sub-category-class{
				//     .react-multi-carousel-list{
				//       height: 150px;
				//       -webkit-transition:height, 0.3s linear;
				//       -moz-transition: height, 0.3s linear;
				//       -ms-transition: height, 0.3s linear;
				//       -o-transition: height, 0.3s linear;
				//       transition: height, 0.3s linear;
				//     }
				//   }
				// }

				// position: -webkit-sticky;
				// position: -moz-sticky;
				// position: -o-sticky;
				// position: -ms-sticky;
				// position: sticky;
				// top: 0;
				background-color: #fff;
				z-index: 1;
				margin-top: 15px;

				.react-multi-carousel-list {
					margin-left: -12px;
					margin-right: -12px;

					.react-multi-carousel-track {
						.react-multi-carousel-item {
							padding-left: 12px;
							padding-right: 12px;
						}
					}
				}
				.react-multiple-carousel__arrow {
					&:before {
						background: #fff;
						width: 32px;
						height: 32px;
						border-radius: 50%;
						display: flex;
						justify-content: center;
						align-items: center;
						line-height: normal;
						box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
						font-size: 16px;
						color: #000 !important;
						// margin-top: -20px;
					}
				}

				.react-multiple-carousel__arrow--right {
					right: calc(0% - 3px) !important;
				}
				.react-multiple-carousel__arrow--left {
					left: calc(0% - 3px) !important;
				}

				.carousel-items {
					display: inline-flex;
					flex-direction: column;
					width: 100%;

					a {
						.cat_height {
							height: 88px;
							position: relative;
							overflow: hidden;
							width: 100%;
							transition: box-shadow 0.25s;
							// box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
							border: solid 1px #ccc;
							border-radius: 8px;
							display: none;

							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
						}

						.circles {
							border-radius: 50% !important;
							border: 1px solid #ccc;
							padding: 2px;
							width: 65px;
							height: 65px;
						}

						// span {
						//   font-family: "Nunito Sans", sans-serif !important;
						//   display: block;
						//   font-weight: 800 !important;
						//   font-size: 14px;
						//   margin-bottom: 0;
						//   margin-top: 10px;
						//   color: #000;
						//   display: none;
						// }
						span {
							font-weight: 800 !important;
							font-size: 14px;
							margin-bottom: 0;
							margin-top: 0;
							color: #000;
							height: 88px;
							position: relative;
							overflow: hidden;
							width: 100%;
							transition: box-shadow 0.25s;
							border: solid 1px #ccc;
							border-radius: 8px;
							display: flex;
							align-items: center;
							justify-content: center;
							text-transform: uppercase;
							font-family: "Nunito Sans", sans-serif !important;
							text-align: center;
						}

						&.active span {
							color: #e80c5b;
							border-color: #333;
						}
					}
				}
				&.sub-cat-slider {
					.react-multiple-carousel__arrow {
						&:before {
							margin-top: -40px;
						}
					}
					// height:0px;
					// overflow: hidden;
					-webkit-transition: height, 0.3s linear;
					-moz-transition: height, 0.3s linear;
					-ms-transition: height, 0.3s linear;
					-o-transition: height, 0.3s linear;
					transition: height, 0.3s linear;

					&.sub-cat-slider.open {
						height: 115px;
						-webkit-transition: height, 0.3s linear;
						-moz-transition: height, 0.3s linear;
						-ms-transition: height, 0.3s linear;
						-o-transition: height, 0.3s linear;
						transition: height, 0.3s linear;
					}

					.carousel-items {
						a {
							.cat_height {
								height: 68px !important;
							}
							span {
								font-weight: 700 !important;
								line-height: 1.2rem;
							}
						}
					}
				}
			}

			.bio_cat_images {
				width: 100%;
				.no-data-found {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-height: 400px;
				}

				.post-grid {
					margin-top: 20px;
					display: grid;
					grid-row-gap: 32px;
					grid-column-gap: 24px;
					grid-template-columns: repeat(4, minmax(0, 1fr));
					overflow: hidden !important;

					.post-image {
						position: relative;

						.image-box {
							position: relative;
							left: 1px;
							right: 1px;
							top: 1px;
							bottom: 1px;
							padding-bottom: 100%;

							span {
								position: absolute;
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								overflow: hidden;
								margin: 0;
								display: block;
								cursor: pointer;
								border: 1px solid;
								border-color: #f6f6f6 #f3f3f3 #eee;
								border-radius: 10px;

								img {
									display: block;
									height: 100%;
									width: 100%;
									border-radius: 0px !important;
									-o-object-fit: cover;
									object-fit: cover;
									-o-object-position: 50% 0;
									object-position: 50% 0;
								}
								video {
									display: block;
									height: 100%;
									width: 100%;
									border-radius: 0px !important;
									-o-object-fit: cover;
									object-fit: cover;
									-o-object-position: 50% 0;
									object-position: 50% 0;
								}
								&.default-image {
									display: flex;
									align-items: center;
									justify-content: center;
									background: rgba(33, 43, 92, 1);
									background: -moz-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
									background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33, 43, 92, 1)), color-stop(100%, rgba(0, 12, 64, 1)));
									background: -webkit-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
									background: -o-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
									background: -ms-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
									background: linear-gradient(to bottom, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
									filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
									img {
										width: initial;
										height: initial;
										max-width: 100%;
										max-height: 100%;
									}
								}
							}
							&.play-button {
								i {
									position: absolute;
									right: 0;
									margin: 0 auto;
									z-index: 9;
									text-align: center;
									font-size: 70px;
									cursor: pointer;
									color: #fff;
									opacity: 0.8;
									width: auto;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								}
							}
						}
						.cat-pro-detail {
							display: flex;
							flex-direction: column;
							width: 100%;
							margin-top: 10px;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 800;

							.cat-pro-title {
								display: flex;
								gap: 20px;
								position: relative;
								justify-content: space-between;
								span {
									font-size: 14px;
									&.bio-item-title {
										flex: 1;
									}
								}
								.inf-ref-fee-main {
									display: flex;
									flex-direction: column;
									.bio-item-fee {
										color: #f00;
									}
									.inf-item-fee {
										color: #fe8300;
									}
								}
							}
							.cat-pro-price {
								margin-top: 5px;
								.original-price {
									padding-right: 15px;
									color: red;
									text-decoration-line: line-through;
								}
								.discounted-price {
									color: green;
								}
							}
						}

						&:after {
							content: "";
							padding-top: 100%;
							display: none;
						}
						&.post-event-box {
							.inf-item-detail {
								position: absolute;
								display: flex;
								background-color: rgba(0, 0, 0, 0.5);
								width: 100%;
								height: 100%;
								top: 0;
								left: 0;
								padding: 8px;
								flex-direction: column;
								justify-content: space-between;
								border-radius: 10px;
								z-index: 1;
								cursor: pointer;
								.vid-time {
									display: flex;
									justify-content: space-between;
									.vid-date {
										font-size: 0.85rem;
										line-height: 1.25rem;
										font-weight: 800;
										display: inline-flex;
										color: rgb(255, 255, 255);
										padding: 4px 16px;
										border-radius: 44px;
										background: rgba(0, 0, 0, 0.6);
										z-index: 3;
										align-items: center;
										font-family: Nunito Sans, sans-serif !important;
										letter-spacing: 0.02rem;
										&.off-percentage {
											color: #f85c5b;
										}

										&.live-now {
											animation: blinkingText 1.2s infinite;

											@keyframes blinkingText {
												0% {
													color: #f85c5b;
												}
												49% {
													color: #f85c5b;
												}
												60% {
													color: transparent;
												}
												99% {
													color: transparent;
												}
												100% {
													color: #f85c5b;
												}
											}
										}
									}
									.vid-views {
										padding: 4px 6px;
										border-radius: 4px;
										justify-content: center;
										align-items: center;
										i {
											margin-right: 6px;
											font-size: 0.775rem;
										}
									}
								}
								.author-name {
									display: flex;
									align-items: center;
									margin-top: 15px;
									.auth-left {
										display: flex;
										align-items: center;
									}
									.auth-image {
										width: 32px;
										height: 32px;
										border: solid 1px #fff;
										border-radius: 50%;
										position: relative;
										overflow: hidden;
										img {
											display: block;
											width: 100%;
											height: 100%;
											object-fit: contain;
											object-position: center;
										}
									}
									.auth-right {
										display: flex;
										flex-direction: column;
										font-family: "Nunito Sans", sans-serif !important;
										font-size: 0.85rem;
										color: #fff;
										font-weight: 800;
										color: #fff;
										.ref-fee {
											color: #fff;
										}
										.inf-fee {
											color: #fff;
										}
									}
									.auth-title {
										color: #fff;
										font-size: 0.875rem;
										margin-left: 8px;
										font-family: "SegoeUI_Medium";
									}
								}
							}
						}
					}
					.content-loader {
						border: 4px solid #f3f3f3;
						border-radius: 50%;
						border-top: 4px solid #252627;
						width: 50px;
						height: 50px;
						margin: 20px 0;
						-webkit-animation: postSpin 2s linear infinite;
						animation: postSpin 2s linear infinite;
					}
					@keyframes postSpin {
						0% {
							transform: rotate(0deg);
						}
						100% {
							transform: rotate(360deg);
						}
					}
				}
			}
			.bioshop-profile-page {
				max-width: 100%;
				margin-top: 20px;
				min-height: 500px;

				.brand-logo {
					max-width: 100%;
					display: flex;
					justify-content: center;

					.profile-logo-brnd {
						width: 150px;
						overflow: hidden;
						position: relative;
						border: solid 1px #ccc;
						height: 150px;
						border-radius: 50%;
						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
				}
				.brand-detail {
					max-width: 100%;
					display: flex;
					justify-content: center;
					flex-direction: column;
					align-items: center;
					margin-top: 20px;

					h2 {
						font-weight: 700 !important;
						text-align: center;
					}
					p {
						text-align: center;
						font-size: 16px;
						font-family: SegoeUI_Medium;
					}
				}
			}

			.bioshop_links {
				max-width: 100%;
				margin-top: 20px;
				min-height: 500px;

				.all-links {
					display: flex;
					max-width: 600px;
					margin: 0 auto;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					padding: 0 20px;
					display: flex;

					a {
						display: block;
						background: #000080;
						border: solid 1px #000080;
						color: #fff;
						text-align: center;
						padding: 12px 0;
						margin-bottom: 15px;
						border-radius: 8px;
						font-size: 16px;
						width: 100%;
						&:hover {
							background-color: #fff;
							color: #000;
						}
					}
				}
			}
		}
	}
}

.bio-image-modal {
	max-width: 960px !important;

	.bio-modal-left-area {
		max-width: 100%;

		.react-multi-carousel-list {
			position: static;
			.react-multiple-carousel__arrow {
				min-height: auto !important;

				&:before {
					color: #b9bdbb !important;
					font-size: 65px !important;
					line-height: 1;
				}
			}
			.react-multiple-carousel__arrow--right {
				right: -60px !important;
			}
			.react-multiple-carousel__arrow--left {
				left: -60px !important;
			}

			.react-multi-carousel-track {
				.react-multi-carousel-item {
					height: 620px;
				}
			}
		}

		.bio-modal-main-content-area {
			display: flex;
			width: 100%;
			justify-content: space-between;

			.image-inner-box {
				position: relative;
				width: 45%;
				height: 100%;

				.img-tag-area {
					position: relative;
					height: 500px;
					overflow: hidden;
					&.default-image {
						display: flex;
						align-items: center;
						justify-content: center;
						background: rgba(33, 43, 92, 1);
						background: -moz-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33, 43, 92, 1)), color-stop(100%, rgba(0, 12, 64, 1)));
						background: -webkit-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						background: -o-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						background: -ms-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						background: linear-gradient(to bottom, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
						img {
							width: initial;
							height: initial;
							max-width: 100%;
							max-height: 100%;
							border: none;
							padding: 0;
						}
						.tag-div-main {
							display: none;
						}
					}
				}

				.tag-div-main {
					background-color: white;
					border-radius: 50%;
					color: #000;
					font-size: 11px;
					height: 20px;
					left: 45%;
					line-height: 20px;
					position: absolute;
					width: 20px;
					cursor: pointer;
					text-align: center;
					font-weight: bold;

					&.active-circle {
						background-color: red !important;
						color: #fff !important;
					}
				}

				.image-inner-media {
					width: 100%;
					height: 100%;
					object-fit: cover;
					// object-position: 50% 0;
					border: solid 1px #ccc;
					padding: 5px;
				}
				video {
					width: 100%;
					height: 100%;
					// object-fit: cover;
					// object-position: 50% 0;
					border: solid 1px #ccc;
					padding: 5px;
				}
				.rounded {
					border-radius: 0.25rem !important;
				}
			}
			.event-right-area {
				width: 54%;
				display: flex;
				.countdown {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 80px 24px 24px;

					.timer-title {
						color: #000;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 16px;
						margin-bottom: 15px;
					}
					.ant-statistic-content-value {
						width: 320px;
						display: flex;
						justify-content: space-evenly;
						max-width: 320px;
						.dhms {
							position: relative;
							display: flex;
							flex-direction: column;
							-webkit-box-align: center;
							align-items: center;
							width: 100%;
							color: #000;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 40px;
							font-weight: 800;
							letter-spacing: 2px;
							span {
								font-size: 0.725rem;
								font-weight: 400;
							}
							&:after {
								position: absolute;
								width: 0px;
								height: 24px;
								right: 0px;
								top: 25%;
								content: "";
								border: 1px solid rgb(64, 64, 64);
							}
							&:last-child {
								&:after {
									display: none;
								}
							}
						}
					}
					.rsvp-btns {
						display: flex;
						width: 100%;
						justify-content: center;
						gap: 10px;
						a {
							margin-top: 30px;
							background-color: #000080;
							border: none;
							border-radius: 4px;
							color: #fff;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 14px;
							font-weight: 700;
							height: auto;
							padding: 4px 15px;
							text-decoration: none;
							min-width: 80px;
							text-align: center;
							&:hover {
								background-color: #000;
							}
						}
					}
					.fee-row {
						color: #000;
						font-family: Nunito Sans, sans-serif !important;
						display: flex;
						gap: 10px;
						justify-content: center;
						span {
							border: solid 1px #000;
							padding: 4px 16px;
							border-radius: 44px;
							font-weight: 700;
							min-width: 80px;
							margin-top: 30px;
							text-align: center;
						}
					}
					.notify-button {
						width: 100%;
						display: flex;
						justify-content: center;
						margin-top: 30px;
						button {
							background-color: transparent;
							color: #fff;
							border-color: #ccc;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 16px;
							font-weight: 700;
							border-radius: 22px;
							padding-left: 50px;
							padding-right: 50px;
							padding-top: 8px;
							padding-bottom: 8px;
							&:hover {
								background-color: #fff;
								color: #000;
							}
						}
					}
					.broadcaster-name {
						width: 100%;
						text-align: center;
						display: block;
						color: #000;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 18px;
						margin-top: 30px;
					}
					.tim-author-name {
						display: flex;
						align-items: center;
						margin-top: 8px;
						flex-direction: column;
						margin-top: 30px;
						.tim-auth-image {
							width: 32px;
							height: 32px;
							border: solid 1px #000;
							border-radius: 50%;
							position: relative;
							overflow: hidden;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}
						}
						.tim-auth-title {
							color: #000;
							font-size: 0.875rem;
							margin-left: 0;
							font-family: "SegoeUI_Medium";
							margin-top: 5px;
						}
					}
				}
			}
			.modal-content-right-area {
				width: 54%;

				.cat-pro-detail {
					display: flex;
					flex-direction: column;
					width: 100%;
					margin-top: 10px;
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 800;

					.cat-pro-title {
						span {
							font-size: 14px;
						}
					}
					.cat-pro-price {
						margin-top: 5px;
						.original-price {
							padding-right: 15px;
							color: red;
							text-decoration-line: line-through;
						}
						.discounted-price {
							color: green;
						}
					}
				}
				.pro-cat-description {
					width: 100%;
					margin-top: 20px;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
					margin-bottom: 20px;
					p {
						margin-bottom: 0;
					}
				}
				.cat-pro-buttons {
					display: flex;
					width: 100%;
					gap: 6px;
					margin-bottom: 10px;
					span {
						flex-basis: 100%;
						// background-color: #fff;
						// border-color: #5e5e5e;
						padding: 10px 0;
						font-family: "Nunito Sans", sans-serif !important;
						border-radius: 2px;
						position: relative;
						// padding-left: 15px;
						text-transform: uppercase;
						font-weight: 700;
						// color: #5e5e5e;
						font-size: 0.775rem;
						background-color: #000080;
						border-color: #000080;
						color: #fff;

						i {
							position: relative;
							font-size: 14px;
							margin-left: 2px;
							display: none;
						}
						&:hover {
							background-color: #000;
							color: #fff;
							border-color: #000;
						}
					}
				}
			}
			&.post-event-modal {
				.image-inner-box {
					.img-tag-area {
						height: 620px;
					}
				}
			}
		}
		.modal-content-right-area span.bio-item-fee {
			font-size: 14px;
			color: #f00;
			font-family: "Nunito Sans", sans-serif !important;
			font-weight: 800;
			display: block;
		}
		.modal-content-right-area span.inf-item-fee {
			font-size: 14px;
			color: #fe8300;
			font-family: "Nunito Sans", sans-serif !important;
			font-weight: 800;
			display: block;
		}

		.bio-image-thumbnail {
			display: flex;
			width: 100%;
			gap: 15px;
			margin-top: 15px;
			justify-content: center;

			.bio-t-img {
				width: 70px;
				height: 100%;
				border: solid 1px #ccc;
				cursor: pointer;
				img {
					width: 100%;
					height: 100%;
					object-fit: fill;
					object-position: 50% 0;
				}
			}
		}
	}

	.modal-content {
		padding: 1rem 1rem;

		.modal-header {
			padding: 0;
			padding-bottom: 5px;
			margin-bottom: 5px;
			border: none;

			.close {
				padding: 4px 0.5rem;
				font-size: 2rem;
			}

			.modal-title {
				line-height: 14px;
				font-size: 14px;
				text-transform: uppercase;
				font-weight: 700 !important;
				margin-bottom: 0;
			}
		}
		.modal-body {
			padding: 0;
		}

		.inner-image-box {
			position: relative;
			margin-top: 32px;

			.space-grid-right {
				padding-right: 7px;

				img {
					border: solid 1px #ccc;
					padding: 2px;
					border-radius: 2px;
					width: 100%;
				}
			}

			.space-grid-left {
				padding-left: 5px;
				gap: 10px;

				.prod-name {
					font-size: 14px;
					line-height: 18px;
					font-weight: 500;
					text-align: left;
					font-family: "SegoeUIBold";
				}
				.prod-sku {
					display: flex;
					justify-content: space-between;
					width: 100%;
					font-weight: 500;
					font-size: 14px;
					font-family: "SegoeUIBold";

					.prod-amount {
						color: green;
						display: flex;
						padding-right: 5px;

						.prod-discount {
							padding-left: 10px;
						}
					}
				}

				.prod-footer {
					display: flex;
					justify-content: space-between;
					width: 102%;
					font-weight: 500;
					font-size: 14px;
					font-family: "SegoeUIBold";
					align-items: end;
					margin-left: -4px;
					//   margin-right: -4px;

					.shop-button {
						background: #000080;
						border-color: #000080;
						font-size: 10px;
						padding: 10px 0px;
						text-transform: uppercase;

						.fa {
							display: flex !important;
							justify-content: center;
						}
					}

					.col-4 {
						padding: 0 4px;
					}
					.col-4:last-child {
						padding-right: 0;
					}
				}
			}
		}
	}
	&.video-modal-box {
		.bio-modal-left-area {
			.bio-modal-main-content-area {
				height: 100%;
				.image-inner-box {
					display: flex;
					.img-tag-area {
						width: 100%;
						height: 100%;
					}
				}
			}
			.bio-image-thumbnail {
				display: none;
			}
		}
	}
}
.copy-popup-text {
	font-family: "Nunito Sans", sans-serif !important;
	font-weight: 600;
}
.your-copy-link {
	display: flex;
	justify-content: space-between;
	// padding: 5px;
	border: 1px solid black;
	// border-radius: 1em;

	.item-a {
		display: inline-block;
		padding: 10px;
		cursor: default;
		overflow: hidden;
		word-break: break-all;
		height: 36px;
		span {
			font-family: "SegoeUIBold";
		}
		a {
			color: #000;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.item-b {
		padding: 10px;
		border-left: 1px solid black;
		cursor: pointer;
	}
}

.ant-notification {
	z-index: 999999999;
}

.bio_shop_header_right {
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    flex-shrink: 0;
    position: absolute;
    top: 20px;
    right: 11px;
}

.bio_shop_header_right {
	button {
		background: #000080;
		border: none;
		color: white;
		background-color: #000080;
		color: #fff;
		font-family: Nunito Sans, sans-serif !important;
		padding: 5px 20px;
		font-weight: 700;
		border-radius: 37px;
		font-size: 14px;
		align-self: flex-end;
		.fa {
			margin-right: 7px;
			font-size: 13px;
			&.active {
				color: red;
			}
		}
		&:hover {
			background-color: #000;
			color: #fff;
		}
		&:focus {
			background-color: #000;
			color: #fff;
		}
	}
}

.profile-modal {
	.cst-close {
		position: absolute;
		right: 4px;
		top: -2px;
		font-size: 20px;
		line-height: 1;
		cursor: pointer;
		z-index: 1;
		padding: 5px;
	}
	.ant-tabs-nav-list {
		width: 100%;
		.ant-tabs-tab {
			width: 50%;
			justify-content: center;
			.ant-tabs-tab-btn {
				font-size: 1.1rem;
				font-family: "SegoeUI_Medium";
				letter-spacing: 1px;
				&:hover {
					color: #000;
				}
			}
			&.ant-tabs-tab-active {
				.ant-tabs-tab-btn {
					color: #000;
				}
			}
			&:hover {
				color: #000;
			}
		}
		.ant-tabs-ink-bar {
			background-color: #000;
		}
	}

	.bioshop-profile-page {
		max-width: 100%;
		margin-top: 20px;
		min-height: 250px;

		.brand-logo {
			max-width: 100%;
			display: flex;
			justify-content: center;

			.profile-logo-brnd {
				width: 100px;
				overflow: hidden;
				position: relative;
				border: solid 1px #ccc;
				height: 100px;
				border-radius: 50%;
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
		}
		.brand-detail {
			max-width: 100%;
			display: flex;
			justify-content: center;
			flex-direction: column;
			align-items: center;
			margin-top: 20px;

			h2 {
				font-weight: 700 !important;
				text-align: center;
			}
			p {
				text-align: center;
				font-size: 16px;
				font-family: SegoeUI_Medium;
			}
		}
	}
	.bioshop_links {
		max-width: 100%;
		margin-top: 20px;
		min-height: 250px;

		.all-links {
			display: flex;
			max-width: 100%;
			margin: 0 auto;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			padding: 0 20px;
			display: flex;
			.no-data-found {
				text-align: center;
			}

			a {
				display: block;
				background: #000080;
				border: solid 1px #000080;
				color: #fff;
				text-align: center;
				padding: 12px 0;
				margin-bottom: 15px;
				border-radius: 8px;
				font-size: 16px;
				width: 100%;
				&:hover {
					background-color: #fff;
					color: #000;
				}
			}
		}
	}
}

.tag-div-main {
	background-color: white;
	border-radius: 50%;
	color: #000;
	font-size: 11px;
	height: 20px;
	left: 45%;
	line-height: 20px;
	position: absolute;
	width: 20px;
	cursor: pointer;
	text-align: center;
	font-family: "SegoeUIBold";
}
.inner-image-box {
	position: relative;
}

.inner-image-box .close {
	position: absolute;
	// background: #000;
	border-radius: 50%;
	width: 16px;
	height: 16px;
	right: 4px;
	top: 4px;
	text-align: center;
	cursor: pointer;
	opacity: 0.6;
}

.inner-image-box .close span {
	color: #000;
	font-size: 18px;
	position: relative;
	font-family: "SegoeUIBold";
	top: -6px;
	font-weight: 600;
	text-shadow: none !important;
}

.inner-image-box .image_num {
	position: absolute;
	background: #fff;
	width: 16px;
	height: 16px;
	text-align: center;
	bottom: 8px;
	color: #000;
	line-height: 15px;
	left: 8px;
	border-radius: 50%;
	font-size: 10px;
	font-weight: bold;
	font-family: "SegoeUIBold";
	cursor: pointer;
}

.inner-image-box .img1 {
	cursor: pointer;
}

.dash_icon-top {
	color: #e4e7ed;
	float: left;
	margin-top: 0;
}
.imp-tx {
	font-size: 0.928571429rem;
}
.text-muted {
	color: #6c757d !important;
}
.image-edit-box .image-box-info span {
	cursor: pointer;
	float: left;
	font-size: 20px;
	margin-right: 10px;
}
.related-images {
	margin-top: 10px;
}
.related-images img {
	height: 94px !important;
}
.add-image-modal .modal-dialog {
	max-width: 50%;
}

.add-image-modal .sku-image {
	max-width: 100%;
}

.add-image-modal .sku-image-box {
	display: flex;
	justify-content: center;
	// align-items: center;
	text-align: center;
}

.add-image-modal .image-space {
	position: relative;
	top: 26px;
	label {
		cursor: pointer;
	}
}

.analytic-box {
	margin-bottom: 20px;
	padding: 15px;
}
.analytic-box .count-box {
	display: flex;
	justify-content: space-between;
	border-bottom: solid 1px #ede9e9;
	padding-left: 0;
	padding-right: 0;
	margin-bottom: 14px;
}
.analytic-box .count-box:last-child {
	margin-bottom: 0;
	border-bottom: none;
}
.analytic-box .count-box .count {
	font-size: 1rem;
}
.analytic-box .count-box .count-title {
	color: #798892;
	font-size: 1rem;
}
.prod-desc {
	line-height: 18px;
	max-width: 70%;
	text-align: right;
}
.modal-header-style2 {
	padding: 1rem 1rem 0.5rem;
}
.white-loader.ant-spin-lg .ant-spin-dot i,
.white-loader.ant-spin-sm .ant-spin-dot i,
.white-loader .ant-spin-dot-item {
	background-color: #ffffff;
}
.loader-center {
	text-align: center;
	margin: auto;
	padding: 2rem 1rem;
	justify-content: center;
	display: flex;
	align-items: center;
	flex-direction: column;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
}
.ind-post-anlytics {
	display: flex;

	.edit-left {
		flex: 0 0 26%;
		max-width: 26%;
	}

	.edit-right {
		flex: 0 0 74%;
		max-width: 74%;
		display: flex;

		.an-col.col-md-3 {
			padding-left: 10px;
			padding-right: 10px;
		}

		.an-col-inr {
			margin-bottom: 24px;
			background-color: #fff;
			box-shadow: 0px 0px 2px 1px #e4e7ed;
		}

		.an-content {
			transition: opacity 0.2s ease-out;
			margin: 0 auto;
			padding: 8px;
			width: 100%;
			overflow-x: visible;
		}

		.an-icon-top {
			color: #e4e7ed;
			float: left;
			margin-top: 0;
		}

		.dash_icon-top .fa-2x {
			font-size: 1.3em;
		}
	}
}
.imp-t {
	color: #575757;
	font-family: Nunito Sans, sans-serif;
	font-size: 1.57142857rem;
	font-weight: 600;
	line-height: 1.5;
}
@media only screen and (max-width: 1440px) {
	.bio_shop_main_ift {
		.bio_container_main {
			&.bio_container_main_infl {
				.bio_shop_body {
					.bio_cat_images {
						.post-grid {
							grid-template-columns: repeat(2, minmax(0, 1fr));
						}
					}
				}
				.left-column {
					.select-source-container {
						.connected-button {
							font-size: 11px;
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 1240px) {
	.bio_shop_main_ift {
		.bio_container_main {
			.bio_shop_header {
				gap: 20px;
				.bio_shop_header_topmenu {
					flex-wrap: wrap;
					gap: 5px;
					button {
						padding-left: 10px;
						padding-right: 10px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 990px) {
	.bio_shop_main_ift {
		.bio_container_main {
			.bio_shop_header {
				.bio_shop_header_topmenu {
					gap: 10px;
				}
			}
		}
	}
	.bio_shop_main_ift {
		.bio_container_main.bio_container_main_infl {
			.left-column .mobile-preview {
				.preview-mobile-box {
					.mobile-gallery {
						height: 335px;
					}
				}
			}
			.right-column {
				.bio_shop_body {
					.bio_shop_header {
						flex-direction: column;
						.bio_shop_copy_link {
							width: 100%;
							margin-top: 20px;
						}
					}
				}
			}
			.bio_shop_body {
				.bioshop_filters_new {
					gap: 10px;
				}
			}
			.left-column {
				.is-sticky {
					position: relative;
					z-index: 1;
				}
			}
		}
		.bio_container_main {
			.bio_shop_body {
				.bio_cat_images {
					.post-grid .post-image {
						.cat-pro-detail {
							.cat-pro-title {
								flex-direction: column;
								gap: 0;
							}
						}
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 760px) {
	.bio_shop_main_ift {
		.bio_container_main {
			.bio_shop_header {
				flex-direction: column;
				align-items: flex-start;
				gap: 10px;
			}
		}
	}
	.influencer-bioshop {
		.account-container-main .ant-layout-has-sider .ant-layout .ant-layout-content .site-layout-background .account-inr-container {
			padding-left: 0;
			padding-right: 0;
		}
	}
	.bio_shop_main_ift {
		.bio_container_main.bio_container_main_infl {
			margin: 0;
			.left-column {
				padding: 0;
				.select-source-container {
					margin: 0 auto;
				}
				.mobile-preview {
					width: 100%;
					max-width: 100%;
					height: calc(100vh - 250px);
					margin: 0;
					padding: 0;
					background-color: transparent;
					.preview-mobile-box {
						background: none;
						padding: 0;
						.mobile-header {
							color: #000;
						}
						.mobile-gallery {
							height: 92%;
							left: 0;
							overflow: auto;
							position: absolute;
							width: 100%;
						}
					}
				}
			}
			.add-post-container {
				max-width: 100% !important;
				right: 0;
				height: auto;
				padding: 20px !important;
				.image-edit-box {
					padding: 10px;
					border-radius: 0;
					box-shadow: none;
					z-index: 999;
					.ind-post-anlytics {
						flex-direction: column;
						.edit-left {
							flex: 0 0 100%;
							max-width: 100%;
						}
						.edit-right {
							flex: 0 0 100%;
							max-width: 100%;
						}
						.edit-right {
							flex-flow: row wrap;
							gap: 2%;
							.an-col.col-md-3 {
								max-width: 48%;
								flex: 0 0 48%;
								padding: 0;
								.imp-t {
									font-size: 14px;
								}
							}
						}
					}
					.image-wrapper {
						flex-direction: column;
						.image-box {
							max-width: 100%;
							flex: 0 0 100%;
						}
						.image-edit-links {
							max-width: 100%;
							flex: 0 0 100%;
							padding: 0;
							margin-top: 20px;
							.edit_button_main {
								button {
									flex-basis: 100%;
									min-width: auto;
								}
							}
						}
					}
				}
			}
		}
	}
}
.followers-modal {
	min-height: 200px;
	max-height: 315px;
	overflow-y: auto;
}

.followers-modal h2 {
	text-align: center;
	font-weight: 800 !important;
	font-size: 24px;
	margin-bottom: 20px;
}

.modal-followers-list-main {
	margin-bottom: 10px;

	.modal-followers-list {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0;
		padding: 8px 0 9px 0;
		margin-inline-start: 10px;
		margin-inline-end: 10px;
		cursor: pointer;
		border-bottom: 1px solid #ededed;
		gap: 15px;

		.followers-thumb-area {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 10px;
			.followers-thumb {
				width: 45px;
				overflow: hidden;
				position: relative;
				border: solid 1px #ccc;
				height: 45px;
				border-radius: 50%;
				img {
					display: block;
					width: 100%;
					height: 100%;
					object-fit: cover;
					object-position: center;
				}
			}
			.user-info-text {
				h3 {
					font-size: 13px;
					color: black;
					font-weight: 700 !important;
					margin: 0;
					padding: 0 0 2px 0;
				}
				h4 {
					font-size: 14px;
					color: #6e6e6e;
					font-weight: 400;
					margin: 0;
					padding: 0 0 3px 0;
				}

				h5 {
					font-size: 12px;
					color: #373737;
					font-weight: 400;
					margin: 0;
					padding: 0;
				}
			}
		}

		.follow-btn-right {
			.follow-btn {
				background-color: #000080;
				border: 1px solid #000080;
				color: #fff;
				font-family: Nunito Sans, sans-serif !important;
				padding: 7px 20px 5px 20px;
				font-weight: 700;
				border-radius: 37px;
				display: inline-block;
				font-size: 12px;
				cursor: pointer;
				&:hover {
					background-color: #000080;
					border: 1px solid #000080;
					box-shadow: none;
					color: #fff;
				}
				&:focus {
					background-color: #000080;
					border: 1px solid #000080;
					color: #fff;
					box-shadow: none;
				}

				&:active {
					background-color: #000080;
					border: 1px solid #000080;
					box-shadow: none !important;
				}
			}
		}
	}
}

.modal-simple {
}

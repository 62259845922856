.scrollbar-style-product::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

.scrollbar-style-product::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #d3d3d3 !important;
  border-radius: 10px;
}

.scrollbar-style-product::-webkit-scrollbar-thumb,
.scrollbar-style-product::-webkit-scrollbar-thumb:hover {
  background: #000080 !important;
  border-radius: 10px;
}

.search-dropdown-main {
  position: relative;
  z-index: 101;
  .form-control.search-field {
    border-radius: 100px 100px 100px 100px !important;
    border-color: #cdcdcd !important;
    padding-left: 3rem;
    font-size: 13px;
  }
  .input-group-append {
    position: absolute;
    right: 5px;
    top: 5px;
    z-index: 100;
    .btn-outline-secondary {
      border-radius: 100px !important;
      padding: 0.35rem 1.2rem 0.45rem 1.2rem;
      height: 100%;
    }
  }
  .search-zoom-icon {
    position: absolute;
    left: 14px;
    top: 13px;
    z-index: 100;
    font-size: 20px;
    color: #5f5f5f;
  }
  .multi-line-search-text {
    position: absolute;
    left: 44px;
    top: 7px;
    z-index: 110;
    display: flex;
    flex-direction: column;
    gap: 0;
    .placeholder-text1 {
      font-size: 12px;
      color: #000000;
      font-weight: 600;
      line-height: initial;
    }
    .placeholder-text2 {
      font-size: 12px;
      color: #5f5f5f;
      line-height: initial;
    }
  }

  .search-cross-button {
    position: absolute;
    right: 100px;
    top: 9px;
    z-index: 100;
    color: #000000;
    background-color: transparent;
    border: 0px;
    padding: 0;
    margin: 0;
    border-radius: 100px;
    height: 26px;
    width: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    text-align: center;
    transition: box-shadow 0.2s ease-in 0s;
    &:hover {
      background-color: transparent;
      box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 15px;
    }
  }
  .search-dropdown-wrapper {
    position: absolute;
    left: 0;
    top: 100%;
    background-color: #ffffff;
    border-radius: 4px;
    padding: 15px 0px;
    width: 100%;
    filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 15px 15px);
    z-index: 999;
    height: 560px;
    overflow: hidden;
    .search-tabs {
      .ant-tabs-nav {
        .ant-tabs-nav-wrap {
          border-bottom: solid 1px #ccc;
          padding: 0 24px 0px 24px;

          .ant-tabs-nav-list {
            .ant-tabs-tab {
              font-family: Nunito Sans, sans-serif !important;
              font-size: 15px;
              font-weight: 700 !important;
              justify-content: center;
              margin: 0 40px 0 0;
              // min-width: 80px;
              padding: 1rem 0rem 1.2rem 0rem;

              &:hover {
                color: #000080;
              }

              &.ant-tabs-tab-active {
                //   background-color: #000080;
                //   border-radius: 2rem;
                font-weight: 700 !important;

                .ant-tabs-tab-btn {
                  color: #000080;
                  text-shadow: none;
                }
              }
              .tab-box {
                display: flex;
                align-items: center;
                gap: 7px;
                i.fa-tags {
                  font-size: 15px;
                }
                i.fa-shopping-basket {
                  font-size: 14px;
                  position: relative;
                  top: -2px;
                }
                i.fa-tv {
                  font-size: 15px;
                  font-weight: 700;
                }
              }
            }

            .ant-tabs-ink-bar {
              // display: none;
              background-color: #000080;
            }
          }
        }
      }
      .ant-tabs-content-holder {
        padding: 15px 10px 15px 24px;
      }
    }
  }
}

.search-hd-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  padding-right: 24px;
  .tab-hd {
    font-weight: 700 !important;
    margin-bottom: 0px;
    font-size: 16px;
  }
  .tab-view-more-link {
    font-size: 14px;
    color: black;
    text-decoration: underline;
    &:hover {
      color: #000080;
      text-decoration: none;
    }
    &:focus {
      color: #000080;
      text-decoration: none;
    }
  }
}

.tab-product-list-main {
  height: 400px;
  overflow-y: auto;
  .tab-product-list-item {
    width: 100%;
    display: flex;
    align-items: center;
    // cursor: pointer;
    padding: 8px 20px 8px 8px;
    .tab-product-img {
      display: block;
      width: 64px;
      flex: 1 0 56px;

      height: 100%;
      position: relative;
      display: inline-block;
      z-index: 0;
      overflow: hidden;
      background-color: rgb(245, 246, 247);
      margin: 0;
      img {
        display: block;
        object-fit: cover;
        object-position: center center;
        height: 64px;
      }
    }
    .tab-product-info {
      width: 100%;
      margin: 0px 8px 0px 16px;
      flex: 1 1 auto;
      min-width: 0;
      .tab-product-name {
        color: rgb(0, 0, 0);
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 700 !important;
        font-size: 1.1rem;
        // line-height: 1.5rem;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-wrap: normal;
        display: block;
      }
      .info-1 {
        color: rgb(114, 118, 130);
        font-family: "Nunito Sans", sans-serif !important;
        font-weight: 600 !important;
        font-size: 0.875rem;
        // line-height: 1rem;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow-wrap: normal;
        display: block;
      }
    }

    .tab-add-product-right {
      display: flex;
      gap: 16px;
      flex: 0 0 auto;
      align-items: center;
      .tab-add-product-price {
        color: rgb(0, 0, 0);
        line-height: 1.5rem;
        font-size: 1.2rem;
        font-weight: 700 !important;
        margin-bottom: 0;
      }
      .tab-add-product-button {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border: 1px solid #000080;
        border-radius: 100px;
        font-weight: 700 !important;
        font-family: Nunito Sans, sans-serif !important;
        background: #000080;
        width: 45px;
        height: 45px;
        padding: 0px 12px;
        box-shadow: none;
        color: rgb(255, 255, 255);
        font-size: 0.75rem;
        text-transform: uppercase;
		&.large-size {
			font-size: 1rem;
		}
      }
    }
    &:hover {
      background-color: rgb(247, 247, 247);
    }

    &.reviews {
      .review-image {
        width: 56px;
        height: 56px;
        overflow: hidden;
        display: inline-block;
        border: 1px solid rgb(230, 232, 241);
        border-radius: 50%;
        flex: 0 0 auto;
		&.brand-style {
			border-radius: 0%;
			border: 0;
			box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);

		}
        .review-user-img {
          width: 100%;
          height: 100%;
          position: relative;
          display: inline-block;
          z-index: 0;
          overflow: hidden;
          background-color: #000080;
          &.avatar-bg {
            background-color: #cacfe0;
          }
		  img {
			width: 100%;
			height: 100%!important;
      min-height: 100%!important;
			object-fit: contain;
			display: block!important;
      visibility: visible!important;
		  }
        }
      }
	 
    }
  }
}


.search-backdrop {
  background-color: rgba(0,0,0,0.7);
  width: 100%;
  height:calc(100vh - 77px);
  position: fixed;
  left: 0;
  bottom: 0px;
  z-index: 99;
  // z-index: 1000;
}

@media only screen and (max-width: 990px) {
  .search-backdrop {
    display: none;
  }
}


 .get-the-app-btn {
  font-family: Nunito Sans, sans-serif !important;
  padding: 7px 15px;
  background-color: #000080;
  font-weight: 700;
  color: #fff;
  border-radius: 37px;
  display: inline-block;
  margin-top: 0;
  font-size: 13px;
  box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
  transition:all 0.3s ease-in-out;
  opacity: 0;

  &:hover {
      background-color: #000;
      color: #FFF;
  }
  &:focus {
    background-color: #000080;
    color: #FFF;
}
  &.get-app-btn-mob {
    opacity: 1;
  }
}
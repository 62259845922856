.reviews-box-list {
    .col-xl-3 {
        padding-left: 10px;
        padding-right: 10px;
    }
.reviews-box {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  position: relative;
  
.review-delete-btn {
  position: relative;
  z-index: 2;
}
  .block-review-link {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     display: block;
     text-indent: -9999px;
     z-index: 1;
    }
  margin-bottom: 20px;
  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  .prod-box {
    background-color: #fff;
    display: inline-flex;
    min-width: 100%;
    position: relative;
    border: solid 1px #d9d9d9;
    border-radius: 8px;
    padding: 10px;
    .prod-image {
      width: 70px;
      flex-basis: 70px;
      border: 1px solid #e3e3e3;
      position: relative;
      overflow: hidden;
      transition: box-shadow 0.25s;
      flex-grow: 0;
      flex-shrink: 0;
      height: 120px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    .prod-name {
      display: flex;
      flex-grow: 1;
      margin-left: 15px;
      min-width: 0px;
      flex-direction: column;
      width: 100%;
      padding-right: 0px;
      justify-content: space-between;
      gap: 15px;
      .prod-title {
        display: flex;
        flex-direction: column;
        .prod-name-inr {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 700;
          color: #000;
          font-size: 1.2rem;
          line-height: 20px;
          display: -webkit-box;
          max-width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          overflow-wrap: normal;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          margin-bottom: 5px;
        }
        .prod-veriants-inr {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 700;
          color: #858585;
          font-size: 12px;
          line-height: 18px;
        }
      }
      .prod-price {
        display: flex;
        margin-top: 5px;
        span {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;
          color: #000;
          &.original-price {
            color: #f00;
            margin-left: 0px;
            margin-right: 4px;
            text-decoration-line: line-through;
          }
        }
      }
      .prod-inf-ref-fee {
        display: flex;
        width: 100%;
        span {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;
          color: #f00;
          font-size: 13px;
        }
      }
      .prod-cash-back-fee {
        display: flex;
        width: 100%;
        span {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;
          color: rgb(50, 102, 20);
          font-size: 13px;
        }
      }
      .prod-creator-fee {
        display: flex;
        width: 100%;
        span {
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;
          color: #000080;
          font-size: 13px;
        }
      }
      .qty-rmv-btn {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        .remove-btn {
          color: #000;
          font-size: 12px;
          text-decoration: underline;
        }
      }
    }
    .prod-cart-btn {
      display: flex;
      flex-direction: column;
      margin-right: 0;
      gap: 10px;
      a {
        background-color: #000080;
        border-radius: 37px;
        color: #fff;
        cursor: pointer;
        font-family: Nunito Sans, sans-serif !important;
        font-size: 12px;
        font-weight: 700;
        padding: 6px 0;
        min-width: 80px;
        text-align: center;
        &:hover {
          background-color: #000000;
        }
        &.share-product-oncart {
          background-color: #fff;
          color: #000;
          border: solid 1px #ccc;
          transition: none;
          &:hover {
            background-color: #000000;
            color: #fff;
          }
        }
      }
    }
  }

  .review-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .date-left {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
      .calendar-icon {
        color: #898989;
      }
      .date-text {
        font-size: 12px;
      }
    }


    .button-right button {
        background-color: #e71a1a;
        border: 1px solid #e71a1a;
        color: #fff;
        font-family: Nunito Sans, sans-serif !important;
        padding: 2px 15px;
        font-weight: 600;
        border-radius: 22px;
        display: inline-block;
        font-size: 13px;
        height: initial;
        cursor: pointer;
    
        &:hover {
          background-color: #b41010;
          border: 1px solid #b41010;
          color: #fff;
        }
      }


  }
}
}
.heading-top-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c5c5c5;
    margin-bottom: 25px;
    padding: 0 0 5px 0;
    h2 {
        margin: 0;
        padding: 0;
        font-size: 22px;
        font-weight: 700;
    }
    .add-button {
        background-color: #000080;
        border: 1px solid #000080;
        color: #fff;
        font-family: Nunito Sans, sans-serif !important;
        padding: 0;
        font-weight: 600;
        border-radius: 22px;
        display: inline-block;
        font-size: 13px;
        height: initial;
        cursor: pointer;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        z-index: 1;
        &:hover {
          background-color: #000000;
          border: 1px solid #000000;
          color: #fff;
        }
      }
}



@media only screen and (max-width: 1440px) and (min-width: 1200px)  {
    .reviews-box-list {
        .col-xl-3 {
            flex: 0 0 33%;
            max-width: 33%;
        }
    }
}

.popular_cat_main{
    width: 100%;
    margin-top:70px;

    .popular_cat{
        width: 100%;

        .popular_cat_carousal{

            .react-multi-carousel-list{
                margin-left: -12px;
                margin-right: -12px;

                .react-multi-carousel-track {
                    .react-multi-carousel-item{
                        padding-left: 12px;
                        padding-right: 12px;

                        a{
                            &.active{
                                .cash-item {
                                    h4{
                                        color: #e80c5b;
                                        border-color: #333;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .react-multiple-carousel__arrow--left{
                left: calc(0% + -5px) !important;
            }
            .react-multiple-carousel__arrow--right{
                right: calc(0% + -5px) !important;
            }
        }

        .cash-item{
            display: inline-flex;
            flex-direction: column;
            width: 100%;

            .cat_height{
                height: 88px;
                position: relative;
                overflow: hidden;
                width: 100%;
                transition: box-shadow .25s;
                // box-shadow: 0px 4px 8px #0a164626;
                border: solid 1px #ccc;
                border-radius: 8px;
                display: none;

                img{
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }
            // h4{
            //     font-weight: 800 !important;
            //     font-size: 14px;
            //     margin-bottom: 0;
            //     margin-top: 10px;
            //     color: #000;
            //     display: none;
            // }
            h4{
                font-weight: 800 !important;
                font-size: 14px;
                margin-bottom: 0;
                margin-top: 0;
                color: #000;
                height: 88px;
                position: relative;
                overflow: hidden;
                width: 100%;
                transition: box-shadow 0.25s;
                border: solid 1px #ccc;
                border-radius: 8px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-transform: uppercase;
                text-align: center;
            }
        }
        .react-multiple-carousel__arrow::before{
            background: #FFF;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: normal;
            box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
            font-size: 16px;
            color: #000 !important;
            // margin-top: -20px;
        }
    }
}

.sub-category-banner-main{
    width: 100%;
    margin-top: 70px;

    .sub-category-banner{
        width: 100%;
        position: relative;

        .slick-list{
            margin-left: -10px;
            margin-right: -10px;

            .slick-slide{
                padding-left: 10px;
                padding-right: 10px;
                

                .bnr-height{
                    display: flex;
                    width: 100%;
                    height: 300px;
                    position: relative;
                    align-items: center;
                    overflow: visible;
        
                    .top-bnr-gap{
                        width: 100%;
                        padding: 0;
                        position: absolute;
                        height: 100%;
                        object-fit: cover;
                        top: 0px;
                        left: 0px;
                        right: 0px;
                        bottom: 0px;
                        border-radius: 8px;
                    }
                    .dsk-bnr, .iph-bnr{
                        display: none;
                    }
                }
            }
            .slick-slide > div{
                display: flex;
            }

            &:after{
                content: "";
                background: linear-gradient(270deg, #ffffff 0%, rgba(255, 255, 255, 0) 93.82%);
                width: 25%;
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
            }
        }

        &.remove-shadow{
            .slick-slider{
                .slick-list{
                    &:after{
                        display: none;
                    }
                }
            }
        }
        .slick-next{
            &.slick-disabled{
                display: none !important;
            }
        }

        .slick-next{
            background: #FFF;
            box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            z-index: 2;
            right: -15px;
            &:before{
                font-family: "FontAwesome";
                color: #000;
                font-size: 25px;
                content: "\f105";
            }
        }
        .slick-prev{
            background: #FFF;
            box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
            border-radius: 50%;
            width: 32px;
            height: 32px;
            z-index: 9;
            left: -15px;
            &:before{
                font-family: "FontAwesome";
                color: #000;
                font-size: 25px;
                content: "\f104";
            }
        }

        .react-multiple-carousel__arrow::before{
            background: #FFF;
            width: 32px;
            height: 32px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: normal;
            box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
            font-size: 16px;
            color: #000 !important;
        }
    }
    .sub-sub-category-banner{
        background-color: #324251;
        display: flex;
        width: 100%;
        height: 300px;
        position: relative;
        align-items: center;
        overflow: visible;
        justify-content: center;
        border-radius: 8px;
        span{
            color: #FFF;
            font-family: "Nunito Sans", sans-serif !important;
            font-size: 40px;
            font-weight: 800;
        }
        .top-bnr-gap{
            width: 100%;
            padding: 0;
            position: absolute;
            height: 100%;
            object-fit: cover;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            border-radius: 8px;
        }
        .dsk-bnr, .iph-bnr{
            display: none;
        }
    }
}

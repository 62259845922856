.all_influencers_reviews{
    width: 100%;
    margin-top: 20px;
    min-height: 550px;
    padding-bottom: 30px;
    .event-container{
        margin-top: 0;
        padding-bottom: 0;
        border: none;
    }
    .influencers-col-box{
        display: grid;
        grid-row-gap: 10px;
        grid-column-gap: 10px;
        grid-template-columns: repeat(6, 1fr);
        &.infl-box-gap{
            margin-top: 10px;
        }

        @media only screen and (max-width: 1599px) {
            grid-template-columns: repeat(5, 1fr);
        }

        @media only screen and (max-width: 1349px) {
            grid-template-columns: repeat(4, 1fr);
        }

        @media only screen and (max-width: 990px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media only screen and (max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}
.account-left-content {
    .card-information-store{
      .my-interest-filter {
        width: 100%;
        padding: 0px 10px;
        .filter-hd {
          font-weight: 800 !important;
          font-size: 16px;
          padding: 0 0 25px 0;
          margin: 0;
          line-height: 1;
          font-family: Nunito Sans, sans-serif !important;
        }
        .radio-box {
          padding: 8px;
          padding-bottom: 14px;
          display: flex;
          border-bottom: 1px solid rgb(229, 229, 229);
          .ant-radio-group {
            width: 100%;
            margin-top: -10px;
            .ant-space-vertical {
              width: 100%;
              .ant-space-item {
                label {
                  width: 100%;
                  width: 100%;
                  margin-right: 0;
                  flex-direction: row-reverse;
                  justify-content: space-between;
                  span {
                    padding: 0;
                    font-family: Nunito Sans, sans-serif !important;
                    font-weight: 600;
                  }
                  .ant-radio-checked {
                    .ant-radio-inner {
                      border-color: #000080;
                    }
                  }
                  .ant-radio-inner {
                    width: 24px;
                    height: 24px;
                    &::after {
                      width: 24px;
                      height: 24px;
                      top: 32%;
                      left: 32%;
                      background-color: #000080;
                    }
                  }
                  &::after {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .filter-box {
          padding: 0px;
          padding-bottom: 14px;
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          gap: 15px;
          border-bottom: 1px solid rgb(229, 229, 229);
          button {
            color: rgb(64, 64, 64);
            font-size: 0.875rem;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            border-color: #dce4ec;
            background-color: #fff;
            padding: 15px;
            border-radius: 0;
            font-family: "Nunito Sans", sans-serif !important;
            font-weight: 700;
            text-transform: uppercase;
            line-height: 1.25rem;
            position: relative;
            &:hover {
              background-color: #e1e2e4;
            }
            &:focus {
              box-shadow: none;
            }
            &:focus-visible {
              outline: none;
            }
            &.active {
              border-color: #000;
              &:before {
                position: absolute;
                right: -4px;
                top: -7px;
                content: "\f00c";
                font-family: Fontawesome;
                z-index: 1;
                font-size: 10px;
                background: #e80c5b;
                width: 16px;
                height: 16px;
                border-radius: 50%;
                color: #fff;
                font-weight: normal;
              }
            }
          }
          @media only screen and (max-width: 1270px) {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        &:last-child {
          .filter-box {
            padding-bottom: 15px;
            border: none;
          }
        }

        .filter-buttons {
            padding-top: 15px;
            button {
                background-color: #fff;
                color: #000;
                border-color: #ccc;
                font-family: Nunito Sans, sans-serif !important;
                font-size: 16px;
                font-weight: 700;
                border-radius: 22px;
                padding-left: 20px;
                padding-right: 20px;
                padding-top: 8px;
                padding-bottom: 8px;
                margin-right: 0.5rem;
                &:last-child {
                  background-color: #000080;
                  color: #fff;
                  border-color: #000080;
                }
                &:hover {
                  background-color: #000000;
                  color: #fff;
                  border-color: #000000;
                }
              }
        }

      }
    }
  }
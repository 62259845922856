.ant-btn {
&.back-button {
  background-color: #000080;
  border: 1px solid #000080;
  color: white;
  border-radius: 3px;
  font-size: 14px;
  font-weight: 400 !important;
  height: 35px;
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0px 15px 2px 12px;

  &:hover {
    background-color: #000000;
    border-color: #000000;
    color: white;
  }
  &:focus {
    background-color: #000080;
    border-color: #000080;
    color: white;
  }
}
}

.ant-btn {
  &.red-button {
    background-color: red;
    border: 1px solid red;
    color: white;
    border-radius: 3px;
    font-size: 14px;
    font-weight: 400 !important;
    height: 35px;
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0px 15px 2px 12px;
  
    &:hover {
      background-color: #cf0000;
      border-color: #cf0000;
      color: white;
    }
    &:focus {
      background-color: red;
      border-color: red;
      color: white;
    }
  }
  }

.user-info-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 15px;
  font-family: Nunito Sans, sans-serif !important;
  .user-left {
    line-height: normal;
    .user-id {
      font-size: 14px;
    }
    .user-name {
      font-size: 18px;
      color: #000;
      font-weight: 800;
    }
    .user-link {
      font-size: 14px;
    }
  }
  .user-right {
    .copy-btn {
      background-color: green;
      border-color: green;
      color: #fff;
      font-family: Nunito Sans, sans-serif !important;
      font-weight: 700;
      border-radius: 37px;
      height: auto;
      padding: 3px 11px;
      border-radius: 4px;
      font-size: 14px;
      &:hover {
        background-color: #037203;
        border-color: #037203;
      }
      &:focus {
        background-color: green;
        border-color: green;
      }
    }
  }
}

.user-menu-main {
  margin: 25px 0;
  display: flex;
  flex-direction: column;
  gap: 0px;
  .user-menu-item {
    display: flex;
    border-top: 1px solid #dedede;
    a {
      display: flex;
      gap: 10px;
      width: 100%;
      align-items: center;
      padding: 8px 10px 8px 0px;
      color: #000080;
      &:hover {
        color: #000000;
        color: #000000;
      }
      &:focus {
        color: #000080;
        color: #000080;
      }
     
    }
    &:last-child {
      a {
      border-bottom: 1px solid #dedede;
      }
    }
  }
}

// ali css styling
.listing-image {
  width: 50px;
  height: 50px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #efefef;
}

.listing-image.default-listing-image {
  background-color: #000080;
}

.listing-image img {
  width: 100%;
  object-position: top;
}

.listing-image img.full-cover {
  height: 100%;
  object-fit: cover;
}

.custom-paginate {
  float: right;
}

// image upload
.dp_cont {
  display: flex;
  align-items: center;
  margin-bottom: 2.9rem;
}

.dp_cont.mb-5 {
  margin-bottom: 4rem !important;
}

.dp_cont span {
  width: 76px;
  height: 76px;
  position: relative;
  text-align: center;
}

.dp_cont span strong {
  font-family: "SegoeUIBold";
  display: block;
  padding-top: 5px;
  position: absolute;
  width: 100%;
}

.dp_cont span img {
  width: 76px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px rgb(126 125 125 / 40%);
}

.dp_buttons {
  margin-left: 20px;
  width: 100%;
  display: flex;
}

.dp_buttons button {
  margin-bottom: 0;
  width: 100%;
}

.dp_buttons button:last-child {
  margin-right: 0 !important;
}

.paypal-confirm-modal {
  border: 0px !important;
}
.profile-btn {
  font-family: Nunito Sans, sans-serif !important;
  margin: 0 5px 0;
  border-radius: 3px;
  color: #ffffff;
  background-color: #000080;
  border: 1px solid #000080;
  width: 100%;
  height: 35px;
  line-height: 30px;
  padding: 2px 0px 0px 0px;
  align-items: center;
  filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
  font-size: 13px;

  &:hover {
    color: #ffffff;
    background-color: #000;
    border: 1px solid #000;
  }

  &:focus {
    color: #ffffff;
    background-color: #000;
    border: 1px solid #000;
  }

  &.color-secondary:disabled {
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    opacity: 0.65;

    &:hover {
      opacity: 0.65;
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }

    &:focus {
      color: #ffffff;
      background-color: #000;
      border: 1px solid #000;
    }
  }

  label {
    cursor: pointer;
  }
}

.mt-98 {
  margin-top: 98px;
}

.btn-style2 {
  .primary-btn {
    background-color: #000080;
    color: #fff;
    border: 0px !important;
    font-family: Nunito Sans, sans-serif !important;
    padding: 6px 15px 8px 15px;
    font-weight: 700;
    border-radius: 5px;
    display: inline-block;
    font-size: 12px;
    cursor: pointer;

    &:hover {
      background-color: #000000;
      border: 0px !important;
      color: #fff;
      box-shadow: none;
    }
  }
}

.modal-form-style .ant-input {
  border-color: #000000 !important;
  border: 1px solid #d9d9d9;
}

.submit-btn-style {
  background-color: #000080;
  color: #fff;
  border: 0px !important;
  font-family: Nunito Sans, sans-serif !important;
  padding: 6px 15px 8px 15px;
  font-weight: 400;
  border-radius: 0 3px 3px 0;
  font-size: 13px;
  min-height: 40px;

  &:hover {
    background-color: #000000;
    border: 0px !important;
    color: #fff;
    box-shadow: none;
  }

  &:focus {
    background-color: #000000;
    border: 0px !important;
    color: #fff;
    box-shadow: none;
  }
}
.verify-email-area {
  gap: 7px;
  margin: 0px 0 15px 0;
  .verify-icon {
    color: green;
    position: relative;
    &.red {
      color: red;
    }
  }
  .verify-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 1250px) {
  .mt-98 {
    margin-top: 0px;
  }

  .profile_box_main.col-md-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

// ali css styling

.mb-20 {
  margin-bottom: 20px !important;
}

.bioshop-body {
  .account-container-main {
    .ant-layout-has-sider {
      .sidebar-main {
        position: absolute;
      }
    }

    .layout-content {
      margin-left: 250px;
    }
  }

  .brd_footer_main {
    display: none;
  }

  &.shopper-bioshop-body {
    .account-container-main {
      height: calc(100vh - 134px);
    }
  }
}

.antd-loading-ift {
  width: 100%;
  min-height: 100%;
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
}

.code-activated-popup {
  .modal-hd1 {
    border-bottom: 1px solid #ccc;
    color: #252525;
    font-size: 20px;
    margin-bottom: 15px;
    padding: 0 0 8px;
  }

  .modal-footer {
    display: flex;
    justify-content: center;

    button {
      align-items: center;
      background-color: #000080;
      border: 1px solid #000080;
      border-radius: 3px;
      color: #fff;
      -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
      font-family: Nunito Sans, sans-serif !important;
      font-size: 15px;
      font-weight: 700;
      height: 45px;
      margin: 0 5px;
      min-width: 120px;
      padding: 6.4px 25px 5.4px;

      &:hover {
        background-color: #000000;
      }
    }
  }

  .order-detail-title-area {
    margin-bottom: 10px;
    padding: 4px 0 0 0;
    position: relative;
    display: flex;
    justify-content: space-between;

    .order-num {
      font-family: Nunito Sans, sans-serif;
      font-size: 17px;
      font-weight: 700;
    }

    .order-date {
      font-size: 13px;
    }
  }

  .order-sum {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid #e1e3e5;
    border-radius: 5px;
    box-shadow: 0 0 0.2125rem rgb(23 24 24 / 5%),
      0 0.0625rem 0.125rem rgb(0 0 0 / 15%);
    padding: 15px;

    .paid-mid-row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px 0;

      .multi-content {
        display: flex;
        flex-direction: row;
        gap: 15px;
        justify-content: space-between;

        .left-hd {
          min-width: 120px;
        }
      }

      .fw-bold {
        font-family: Nunito Sans, sans-serif !important;
        font-weight: 700;
      }
    }

    .paid-bottom-row {
      border-top: 1px solid #e1e3e5;
      display: flex;
      flex-direction: row;
      gap: 15px;
      justify-content: space-between;
      margin-top: 5px;
      padding-top: 5px;
    }
  }

  .transactions-box {
    background: #fff;
    border: 1px solid #d6dee5;

    thead {
      background: #000080;
      color: #fff;

      tr {
        th {
          font-family: Nunito Sans, sans-serif;
          font-weight: 700;
          padding: 0.3rem 0.75rem;
          text-transform: none;
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 12px;
          padding: 0.1rem 0.75rem;
          vertical-align: middle;
        }
      }
    }
  }
}

.swal2-image {
  border-radius: 50%;
  margin: 1em auto 0em;
  border: solid 1px #ccc;
}

.swal2-actions {
  .swal2-cancel {
    margin-right: 10px;
  }
}

.ant-layout {
  .custom-sidebar {
    width: 250px;
    background-color: #fff;
    height: 100vh;
  }
}

.ant-layout-has-sider {
  .sidebar-main {
    bottom: 0;
    left: 0;
    top: 0;
    z-index: 98;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      padding: 0;
    }
  }
}

.account-container-main {
  width: 100%;
  display: inline-block;
  // margin-bottom: 40px;

  .ant-layout {
    background-color: #f9f9f9;
    height: 100%;
    position: relative;
  }

  .ant-layout-has-sider {
    .sidebar-main {
      background-color: #fff;
      border-right: 1px solid #e1e1e1;

      // width: 250px !important;
      // max-width: 250px !important;
      // min-width: 250px !important;
      // flex: 0 0 250px !important;
      &.is-sticky {
        position: fixed;
        z-index: 999;
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
      }

      .wordspace-hd {
        color: gray;
        font-size: 13px;
        margin-left: 15px;
        margin-top: 24px;
      }

      .workspace-menu {
        position: relative;
        margin-bottom: 25px;
        background-color: #ffffff;
        border-radius: 7px;
        padding: 7px 7px 7px 15px;

        &:hover {
          background-color: #f5f5f5;
          cursor: pointer;
        }

        &:focus {
          background-color: #f5f5f5;
        }

        .store-menu {
          display: flex;
          align-items: center;
          gap: 15px;
        }

        .workspace-icon {
          width: 32px;
          height: 32px;
          background-color: #000080;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            color: white;
            font-size: 20px;
          }
        }

        .store-name-txt {
          font-size: 1rem;
          color: #000080;
        }

        .store-owner-txt {
          font-size: 12px;
          color: grey;
        }

        .workspace-drop-box {
          position: absolute;
          z-index: 9;
          background: #fff;
          // left: calc(100% + 15px);
          left: calc(100% - 10px);
          top: calc(100% - 150%);
          border-radius: 7px;
          -webkit-box-shadow: 0 0 2px rgb(0 0 0 / 26%);
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
          width: 220px;
          padding: 6px 0;

          .drop-item {
            padding: 10px 16px;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 19px;
            letter-spacing: 0;
            text-align: left;
            color: #162b4c;
            display: block;

            &:hover {
              background: #f5f5f5;
            }

            .drop-icon {
              margin-right: 15px;
            }

            .drop-txt {
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 19px;
              letter-spacing: 0;
              text-align: left;
            }
          }
        }
      }

      .ant-menu-inline {
        border-right: 0;

        .ant-menu-item {
          background-color: #fff;
          margin: 0;
          padding-left: 0 !important;
          width: 100%;

          &.ant-menu-item-selected {
            background-color: #f5f5f5;

            a {
              font-weight: 700 !important;
            }
          }

          &::after {
            display: none;
          }

          a {
            align-items: center;
            color: #000080;
            display: flex;
            font-family: Nunito Sans, sans-serif !important;
            font-size: 14px;
            padding-left: 15px !important;

            // &:hover{
            //   background-color: #f5f5f5;
            // }
            &.active {
              background-color: #f5f5f5;
              font-weight: 700 !important;
              color: #000000;
            }

            svg {
              width: 20px;
            }

            .nav-text {
              margin-left: 15px;
            }
          }
        }

        .ant-menu-submenu {
          .ant-menu-submenu-title {
            padding: 0;
            padding-left: 0 !important;

            &:hover {
              color: #000080;
            }
          }

          .ant-menu-title-content {
            padding-left: 15px;

            &:hover {
              background-color: #f5f5f5;
            }

            svg {
              width: 20px;
            }

            .nav-text {
              margin-left: 15px;
            }
          }

          &.ant-menu-submenu-selected {
            color: #000080;
          }

          &:hover {
            .ant-menu-submenu-title > .ant-menu-submenu-arrow {
              color: #000080;
            }
          }
        }
      }
    }

    .ant-layout {
      .ant-layout-header {
        align-items: center;
        background-color: #000080;
        color: #fff;
        display: flex;
        height: 55px;
        justify-content: space-between;
        line-height: 55px;
        padding: 0 1rem 0 2rem !important;

        .header-title {
          font-size: 1.5rem;
        }
      }

      .ant-layout-content {
        margin: 0 !important;

        .site-layout-background {
          padding: 0 !important;
          background-color: #f9f9f9;

          .account-inr-container {
            margin: 0;
            padding: 2rem;
            min-height: fit-content;
            display: flex;

            .account-left-content {
              background-color: transparent;
              box-shadow: none;
              border-radius: 0;
              width: 100%;
              min-height: fit-content;
              height: calc(100vh - 245px);

              .purchases-filter {
                max-width: 50%;
              }

              .account-detail {
                padding: 0;

                // max-width: 50%;
                .category-setup {
                  background-color: #fff;
                  padding: 15px;
                  max-width: 100%;
                  border: 1px solid #d6dee5;
                  border-radius: 4px;

                  .dash_block_profile {
                    min-height: 300px;

                    .dash_content_profile {
                      margin: 0 auto;
                      overflow-x: visible;
                      transition: opacity 0.2s ease-out;
                      width: 100%;

                      .cat-box {
                        margin-bottom: 10px;

                        // .cat-image{
                        //   display: none;
                        // }
                        .cat-lable {
                          font-family: SegoeUIBold;
                          font-size: 0.8rem;
                          font-weight: 700;
                          padding-bottom: 15px;
                          padding-top: 5px;
                          // padding-top: 0;
                          // padding-bottom: 0;
                          // align-items: center;
                          // border: 1px solid #ccc;
                          // border-radius: 8px;
                          // color: #000;
                          // display: flex;
                          // font-size: 13px;
                          // font-weight: 700!important;
                          // height: 65px;
                          // justify-content: center;
                          // margin-bottom: 0;
                          // margin-top: 0;
                          // overflow: hidden;
                          // position: relative;
                          // text-transform: uppercase;
                          // transition: box-shadow .25s;
                          // width: 100%;
                        }

                        .action {
                          top: 33%;
                        }
                      }

                      .category-save-button {
                        button {
                          background-color: #000080;
                          border-color: #000080;
                          color: #fff;
                          font-family: Nunito Sans, sans-serif !important;
                          font-weight: 700;
                          padding: 6px 15px;
                          border-radius: 4px;
                          font-size: 14px;
                          max-width: 120px;

                          &:hover {
                            background-color: #000000;
                          }
                        }
                      }
                    }
                  }
                }

                .user-profile {
                  background-color: #fff;
                  padding: 15px;
                  max-width: 100%;
                  border: 1px solid #d6dee5;
                  border-radius: 4px;
                  margin-top: 35px;

                  .edit-button {
                    .ant-btn {
                      padding: 6px 15px;
                      border-radius: 4px;
                      font-size: 14px;
                      min-width: 100px;
                    }
                  }

                  .user-profile-image {
                    display: flex;
                    width: 100%;
                    margin-bottom: 20px;
                    margin-top: -50px;
                    justify-content: center;

                    .prof-img {
                      display: flex;
                      flex-direction: column;
                      align-items: center;

                      .profile-pic {
                        width: 76px;
                        height: 76px;
                        position: relative;

                        img {
                          border-radius: 50%;
                          box-shadow: 0 0 0 4px rgb(126 125 125 / 40%);
                        }
                      }

                      .profile-name {
                        font-family: "SegoeUIBold";
                        padding-top: 5px;
                      }
                    }
                  }
                }

                .card-information-store {
                  background-color: #fff;
                  padding: 15px;
                  max-width: 100%;
                  border: 1px solid #d6dee5;
                  border-radius: 4px;

                  .cd-detail-box {
                    button {
                      padding: 6px 15px;
                      border-radius: 4px;
                      font-size: 14px;
                      min-width: 100px;
                    }
                  }
                }

                .ant-input-lg::-webkit-outer-spin-button,
                .ant-input-lg::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                }
              }

              .account-detail {
                padding: 0;

                &.account-settings {
                  // max-width: 50%;
                  .category-setup {
                    background-color: #fff;
                    padding: 15px;
                    max-width: 100%;
                    border: 1px solid #d6dee5;
                    border-radius: 4px;

                    .dash_block_profile {
                      min-height: 300px;

                      .dash_content_profile {
                        margin: 0 auto;
                        overflow-x: visible;
                        transition: opacity 0.2s ease-out;
                        width: 100%;

                        .cat-box {
                          margin-bottom: 10px;

                          // .cat-image{
                          //   display: none;
                          // }
                          .cat-lable {
                            font-family: SegoeUIBold;
                            font-size: 0.8rem;
                            font-weight: 700;
                            padding-bottom: 15px;
                            padding-top: 5px;
                            // padding-top: 0;
                            // padding-bottom: 0;
                            // align-items: center;
                            // border: 1px solid #ccc;
                            // border-radius: 8px;
                            // color: #000;
                            // display: flex;
                            // font-size: 13px;
                            // font-weight: 700!important;
                            // height: 65px;
                            // justify-content: center;
                            // margin-bottom: 0;
                            // margin-top: 0;
                            // overflow: hidden;
                            // position: relative;
                            // text-transform: uppercase;
                            // transition: box-shadow .25s;
                            // width: 100%;
                          }

                          .action {
                            top: 33%;
                          }
                        }

                        .category-save-button {
                          button {
                            background-color: #000080;
                            border-color: #000080;
                            color: #fff;
                            font-family: Nunito Sans, sans-serif !important;
                            font-weight: 700;
                            padding: 6px 15px;
                            border-radius: 4px;
                            font-size: 14px;
                            max-width: 120px;

                            &:hover {
                              background-color: #000000;
                            }
                          }
                        }
                      }
                    }
                  }

                  .user-profile {
                    background-color: #fff;
                    padding: 15px;
                    max-width: 100%;
                    border: 1px solid #d6dee5;
                    border-radius: 4px;
                    margin-top: 35px;

                    .edit-button {
                      .ant-btn {
                        padding: 6px 15px;
                        border-radius: 4px;
                        font-size: 14px;
                        min-width: 100px;
                      }
                    }

                    .user-profile-image {
                      display: flex;
                      width: 100%;
                      margin-bottom: 20px;
                      margin-top: 0px;
                      justify-content: flex-start;
                      align-items: center;
                      gap: 50px;

                      .prof-img {
                        display: flex;
                        flex-direction: column;
                        align-items: center;

                        .profile-pic {
                          width: 76px;
                          height: 76px;
                          position: relative;

                          img {
                            border-radius: 50%;
                            box-shadow: 0 0 0 4px rgb(126 125 125 / 40%);
                          }
                        }

                        .profile-name {
                          font-family: "SegoeUIBold";
                          padding-top: 5px;
                        }
                      }
                    }
                  }

                  .card-information-store {
                    background-color: #fff;
                    padding: 15px;
                    max-width: 100%;
                    border: 1px solid #d6dee5;
                    border-radius: 4px;

                    .cd-detail-box {
                      button {
                        padding: 6px 15px;
                        border-radius: 4px;
                        font-size: 14px;
                        min-width: 100px;
                      }
                    }
                  }

                  .ant-input-lg::-webkit-outer-spin-button,
                  .ant-input-lg::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                  }

                  .brand-followers {
                    display: flex;
                    gap: 30px;
                    span {
                      font-family: Nunito Sans, sans-serif !important;
                      font-weight: 600;
                      strong {
                        font-weight: 800;
                        text-transform: uppercase;
                      }
                    }
                    .count-detail {
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      align-items: center;
                      strong {
                        font-size: 14px;
                      }
                    }
                  }
                }
              }

              .pr-op-block {
                background-color: #fff;
                box-shadow: none;
                border: 1px solid #d6dee5;
                border-radius: 4px;
                padding: 15px;

                .profile-form-main {
                  .profile-form-button {
                    .ant-form-item {
                      .ant-btn {
                        padding: 6px 15px;
                        border-radius: 4px;
                        font-size: 14px;
                        min-width: 100px;
                      }
                    }
                  }
                }

                .password-box {
                  .ps-btns {
                    display: flex;
                    gap: 10px;
                  }

                  .change-password {
                    padding: 6px 15px;
                    border-radius: 4px;
                    font-size: 14px;
                    min-width: 100px;
                    text-align: center;
                  }
                }

                &.pro-inf-block {
                  padding: 0;
                  border: none;
                  background-color: transparent;
                }

                .user-profile-image {
                  display: flex;
                  width: 100%;
                  margin-bottom: 20px;

                  .prof-img {
                    display: flex;
                    flex-direction: column;

                    .profile-pic {
                      width: 76px;
                      height: 76px;
                      position: relative;

                      img {
                        border-radius: 50%;
                        box-shadow: 0 0 0 4px rgb(126 125 125 / 40%);
                      }
                    }

                    .profile-name {
                      font-family: "SegoeUIBold";
                      padding-top: 5px;
                    }
                  }

                  .porf-btns {
                    display: flex;
                    gap: 10px;
                    margin-left: 10px;
                    height: fit-content;
                    margin-top: 22px;

                    label {
                      border-radius: 4px;
                      font-size: 14px;
                      min-width: 100px;
                      font-weight: 700;
                      background-color: #000080;
                      border-color: #000080;
                      color: #fff;
                      font-family: Nunito Sans, sans-serif !important;
                      height: 35px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      margin: 0;
                      padding: 0 15px;
                      cursor: pointer;

                      &:hover {
                        background-color: #000000;
                        border-color: #000000;
                      }
                    }

                    button {
                      padding: 0 15px;
                      display: flex;
                      justify-content: center;
                      align-items: center;
                      border-radius: 4px;
                      height: 35px;
                      font-size: 14px;
                      min-width: 100px;
                      font-weight: 700;
                      background-color: #000080;
                      border-color: #000080;
                      color: #fff;
                      font-family: Nunito Sans, sans-serif !important;

                      &.ant-btn[disabled],
                      &.ant-btn[disabled]:hover,
                      &.ant-btn[disabled]:focus,
                      &.ant-btn[disabled]:active {
                        color: #ffffff;
                        background-color: #000080;
                        border: 1px solid #000080;
                        opacity: 0.65;
                      }

                      &:hover {
                        background-color: #000;
                        border-color: #000;
                      }
                    }
                  }
                }
              }

              .purchase-content-main {
                padding: 0;

                .mb-20 {
                  margin-bottom: 20px;
                }

                .ant-picker,
                .ant-select-selector {
                  border: solid 1px #000;
                }

                .ant-btn {
                  background-color: #000080;
                  border-color: #000080;
                  color: #fff;
                  font-family: Nunito Sans, sans-serif !important;
                  padding: 0 15px;
                  font-weight: 700;
                  border-radius: 4px;
                  font-size: 14px;
                  height: 40px;
                  min-width: 100px;

                  &:first-child {
                    margin-right: 10px;
                  }

                  &:hover {
                    background-color: #000000;
                  }
                }

                .purchase-data {
                  .table {
                    background: #fff;
                    box-shadow: 0 0 2px 1px #e4e7ed;
                    border: 1px solid #d6dee5;

                    thead {
                      background: #000080;
                      color: #fff;

                      tr {
                        th {
                          font-family: Nunito Sans, sans-serif;
                          font-weight: 700;
                          padding: 0.3rem 0.75rem;
                          text-transform: none;
                        }
                      }
                    }

                    tbody {
                      font-size: 12px;
                      padding: 0.1rem 0.75rem;
                      vertical-align: middle;
                    }
                  }
                }
              }

              .wallet-content-main {
                .wallet-row-1 {
                  padding: 0;
                  max-width: 80%;
                  // max-width: 60%;
                  margin-bottom: 30px;
                  @media only screen and (max-width: 1640px) {
                    max-width: 95%;
                  }
                  @media only screen and (max-width: 1430px) {
                    max-width: 100%;
                  }
                  @media only screen and (max-width: 1320px) {
                    max-width: 100%;
                  }

                  .balance-box {
                    background-color: #fff;
                    position: relative;

                    .pp-connected {
                      position: absolute;
                      right: 7px;
                      top: 7px;

                      background: green;
                      border-radius: 5px;
                      color: #fff;
                      font-family: SegoeUIBold;
                      font-size: 9pt;
                      font-weight: 600;
                      padding: 2px 10px;
                      position: absolute;
                    }
                    .pp-disconnected {
                      position: absolute;
                      right: 7px;
                      top: 7px;

                      background: red;
                      border-radius: 5px;
                      color: #fff;
                      font-family: SegoeUIBold;
                      font-size: 9pt;
                      font-weight: 600;
                      padding: 2px 10px;
                      position: absolute;
                    }
                    .email-text {
                      overflow: hidden;
                      white-space: nowrap;
                      text-overflow: ellipsis;
                      // max-width: 150px;
                    }
                  }
                }

                .wallet-row-2 {
                  padding: 0;
                  margin: 0;

                  .transactions-box {
                    background: #fff;
                    border: 1px solid #d6dee5;
                    box-shadow: 0 0 2px 1px #e4e7ed;

                    thead {
                      background: #000080;
                      color: #fff;

                      tr {
                        th {
                          font-family: Nunito Sans, sans-serif;
                          font-weight: 700;
                          padding: 0.3rem 0.75rem;
                          text-transform: none;
                        }
                      }
                    }

                    tbody {
                      tr {
                        td {
                          font-size: 12px;
                          padding: 0.1rem 0.75rem;
                          vertical-align: middle;
                        }
                      }
                    }
                  }
                }

                .withdraw-request {
                  h3 {
                    padding: 0;
                  }

                  .favourite-brands-container {
                    .ant-tabs {
                      .ant-tabs-nav {
                        .ant-tabs-nav-wrap {
                          padding-left: 0;
                          padding-right: 0;
                        }
                      }
                    }

                    .purchase-data {
                      padding: 0;

                      .transactions-box {
                        background: #fff;
                        border: 1px solid #d6dee5;
                        box-shadow: 0 0 2px 1px #e4e7ed;

                        thead {
                          background: #000080;
                          color: #fff;

                          tr {
                            th {
                              font-family: Nunito Sans, sans-serif;
                              font-weight: 700;
                              padding: 0.3rem 0.75rem;
                              text-transform: none;
                            }
                          }
                        }

                        tbody {
                          tr {
                            td {
                              font-size: 12px;
                              padding: 0.1rem 0.75rem;
                              vertical-align: middle;
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .favourite-brands-container {
                .ant-tabs {
                  .ant-tabs-nav {
                    .ant-tabs-nav-wrap {
                      padding-left: 0;
                      padding-right: 0;
                    }
                  }
                }

                .favourite-brands {
                  padding: 0;
                  grid-template-columns: repeat(3, 1fr);
                }
              }

              .bcm-inf-main {
                background-color: #fff;
                padding: 15px;
                border: 1px solid #d6dee5;
                border-radius: 4px;
                max-width: 100%;
              }

              .plus-btn {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 15px;

                .plus-address {
                  align-items: center;
                  display: flex;
                  font-family: Nunito Sans, sans-serif !important;
                  font-weight: 800;
                  right: 0;
                  text-transform: uppercase;

                  &:hover {
                    background-color: #000000;
                    border-color: #000000;
                    color: #fff;
                  }
                }
              }
            }

            .bio_shop_main_ift {
              margin-top: 0;

              .midd-width-ifti {
                padding: 0;
                display: block;
              }
            }
          }
        }
      }
    }
  }

  .dashboard-content-full {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    position: relative;
    width: 100%;
    margin-top: 18px;

    .dashboard-inner-content {
      align-self: center;
      padding: 0;
      width: 100%;

      .dashboard-box-main {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
        margin-bottom: 30px;
        padding: 15px;

        .box-top-area {
          align-items: center;
          display: flex;
          gap: 15px;
          justify-content: space-between;
          margin-bottom: 30px;

          .box-top-hd-left {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 13px;
            font-weight: 700;
          }

          .box-top-hd-right {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 12px;
            font-weight: 700;
          }

          .total-hd {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 12px;
            font-weight: 700;
            margin-bottom: 2px;
            padding-left: 14px;
            position: relative;

            &::before {
              background-color: #000080;
              border-radius: 50%;
              bottom: 0;
              content: "";
              height: 8px;
              left: 0;
              position: absolute;
              top: 5px;
              width: 8px;
            }

            &.sales {
              &::before {
                background-color: #c1c1c1;
              }
            }
          }
        }

        .box-bottom-area {
          .box-bottom-left {
            .box-bottom-hd1 {
              color: #134a7c;
              font-family: Nunito Sans, sans-serif !important;
              font-size: 28px;
              font-weight: 700;
              line-height: normal;

              &.color-gray {
                color: gray;
              }
            }

            .box-bottom-hd2 {
              align-items: center;
              display: flex;
              font-family: Nunito Sans, sans-serif !important;
              font-size: 14px;
              font-weight: 700;
              gap: 8px;
            }
          }
        }
      }

      .filters-box-main {
        background-color: #fff;
        border-radius: 5px;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
        margin-bottom: 30px;
        padding: 15px;

        .filters-top-area {
          .filters-top-hd-left {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 13px;
            font-weight: 700;
          }

          .filters-top-hd-right {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 12px;
            font-weight: 700;
          }

          .date-range {
            .ant-space-item {
              width: 96%;

              .ant-picker-large {
                border-color: #000;
                height: 40px;

                .ant-picker-input > input {
                  font-size: 14px;
                  height: 40px;

                  &::placeholder {
                    color: #000;
                  }
                }
              }
            }

            .ant-form-item {
              .ant-btn-default {
                background-color: #000080;
                border-color: #000080;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                border-radius: 4px;
                font-size: 16px;
                height: 40px;

                &:hover {
                  background-color: #000;
                }
              }
            }
          }
        }

        .filters-bottom-area {
          .filters-data {
            background: #fff;
            border: 1px solid #d6dee5;
            box-shadow: 0 0 2px 1px #e4e7ed;
            margin-bottom: 0;

            .table_heading {
              background: #000080;
              color: #fff;

              tr {
                th {
                  font-family: Nunito Sans, sans-serif;
                  font-weight: 700;
                  text-transform: none;
                }
              }
            }

            tbody {
              tr {
                td {
                  font-size: 13px;
                  padding: 0.5rem 0.75rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .account-container-outer {
    width: 100%;
  }

  .dashboard-inner-content {
    .account-sidebar {
      width: 100% !important;
    }
  }

  .account-inr-container {
    width: 100%;
    margin: 40px auto;
    min-height: 700px;

    &.reset-page {
      width: 550px;
      min-height: 300px;
    }

    .account-sidebar {
      width: 25.54701%;
      margin-right: 0.85556%;
      float: left;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      border-radius: 8px;
      min-height: 590px;

      .sid-1 {
        padding: 15px 10px;
        border-bottom: solid 1px #ccc;

        span {
          display: block;
          text-align: center;
        }

        .member-welcome {
          font-size: 14px;
          line-height: 18px;
          color: #7b8994;
          padding-bottom: 5px;
        }

        .member-name {
          font-family: "Bitter", serif;
          font-weight: 700;
          font-style: normal;
          color: #252525;
          font-size: 24px;
          line-height: 44px;
          padding-bottom: 0;
        }

        .member-since {
          font-size: 14px;
          line-height: 18px;
          color: #7b8994;
        }

        .member-edit {
          margin-top: 15px;
          display: none;

          a {
            font-family: Nunito Sans, sans-serif !important;
            font-size: 16px;
            font-weight: 800;
            color: #252525;

            &:hover {
              text-decoration: underline;
            }

            i {
              padding-right: 5px;
            }
          }
        }
      }

      .sid-2 {
        padding: 15px 40px 10px 0;

        ul {
          list-style-type: none;
          margin: 0;
          padding: 0;

          li {
            width: 100%;

            a {
              display: block;
              width: 100%;
              padding-left: 38px;
              color: #7b8994;

              span {
                padding: 12px 0 12px 10px;
                line-height: 20px;
                width: 100%;
                border-bottom: solid 1px #ccc;
                color: #7b8994;
                display: block;
              }

              &:hover {
                color: #000;
              }

              &.active {
                border-left: solid 5px #000080;
                padding-left: 33px;

                span {
                  color: #000;
                  font-weight: 800;
                  font-family: "SegoeUIBold";
                }
              }
            }

            &:last-child {
              a {
                span {
                  border-bottom: none;
                }
              }
            }
          }
        }
      }
    }

    .account-left-content {
      width: 73.55556%;
      float: left;
      background-color: #fff;
      box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
      border-radius: 8px;
      min-height: 590px;
      position: relative;

      h1 {
        width: 100%;
        font-size: 28px;
        font-family: "Bitter", serif !important;
        line-height: 36px;
        padding: 18px 30px 6px 30px;
        border-bottom: solid 1px #ccc;
        font-weight: 800;
        position: relative;

        .plus-address {
          position: absolute;
          right: 30px;
          display: flex;
          top: 20px;
          align-items: center;
          text-transform: uppercase;
          font-family: "Nunito Sans", sans-serif !important;
          font-weight: 800;

          &:focus {
            color: #000;
            background-color: transparent;
            border-color: #ccc;
          }

          i {
            padding-right: 10px;
          }

          &:hover {
            background-color: #000000;
            color: #fff;
            border-color: #000000;
          }
        }
      }

      h3 {
        padding: 10px 30px 0 30px;
        font-family: "SegoeUIBold" !important;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 20px;
      }

      .account-detail {
        .social_box_main {
          background-color: #fff;
          border-radius: 5px;
          box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
          padding: 25px;
          position: relative;

          .social_box_header {
            .connection-status-badge-green {
              background: green;
              border-radius: 5px;
              color: #fff;
              font-family: SegoeUIBold;
              font-size: 9pt;
              font-weight: 600;
              padding: 4px 10px;
              position: absolute;
              top: 11px;
              right: 10px;
            }

            .connection-status-badge-red {
              background: red;
              border-radius: 5px;
              color: #fff;
              font-family: SegoeUIBold;
              font-size: 9pt;
              font-weight: 600;
              padding: 4px 10px;
              position: absolute;
              right: 10px;
              top: 11px;
            }
          }

          .dp_fields-setup {
            .connected-text {
              span {
                font-family: "SegoeUIBold";
                color: green;
              }
            }
          }

          .connect-button-ig-fb {
            background-color: #000080;
            border: 1px solid #000080;
            border-radius: 3px;
            color: #fff;
            height: 35px;

            &:hover {
              background-color: #000000;
              border-color: #000000;
            }
          }

          .select-pages {
            display: flex;
            width: 100%;
            flex-direction: column;

            .ant-select-lg {
              font-size: 14px;

              .ant-select-selector {
                border-radius: 4px;

                .ant-select-selection-search-input {
                  cursor: pointer;
                }

                .ant-select-selection-placeholder {
                  color: #000;
                }
              }
            }

            .page-submit-btn {
              display: flex;
              width: 100%;
              gap: 5px;

              button {
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                background-color: #000080;
                border: 1px solid #000080;
                border-radius: 3px;
                color: #fff;

                &:hover {
                  background-color: #000;
                  border-color: #000;
                }
              }
            }
          }
        }

        .infl-connection-screen {
          width: 100%;
          display: flex;
          flex-direction: column;
          margin-top: 20px;

          .conn-list {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: solid 1px #ccc;
            margin-bottom: 25px;
            padding-bottom: 10px;

            .conn-list-left {
              display: flex;

              .ins-icon {
                background-color: #f3f3f5;
                width: 50px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 28px;
                border-radius: 8px;
                margin-right: 10px;
              }

              .ins-detail {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .ins-detail-title {
                  font-family: "SegoeUIBold";
                }

                .ins-detail-status {
                  .ins-username {
                    font-family: "SegoeUIBold";
                    color: green;
                  }
                }
              }
            }

            .connection-status {
              display: flex;

              .ins-connected {
                background-color: green;
                color: #fff;
                padding: 7px 16px;
                border-radius: 4px;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                letter-spacing: 0.035rem;
                min-width: 120px;
                text-align: center;
              }

              .ins-mng {
                padding: 7px 16px;
                border-radius: 4px;
                height: auto;
                color: #fff;
                padding: 7px 16px;
                border-radius: 4px;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                letter-spacing: 0.035rem;
                background-color: #000080;
                min-width: 120px;
                border-color: #000080;
                border: none;
              }

              button,
              a {
                background-color: #000080;
                border-color: #000080;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                height: auto;
                font-size: 14px;
                border: none;
                border-radius: 4px;
                padding: 4px 15px;
                text-decoration: none;

                &:hover {
                  background-color: #000;
                }

                &:disabled {
                  color: rgba(0, 0, 0, 0.25);
                  border-color: #d9d9d9;
                  background: #f5f5f5;
                  text-shadow: none;
                  box-shadow: none;
                  border: solid 1px;
                }
              }
            }
          }
        }

        .user-profile {
          width: 100%;

          ul {
            list-style-type: none;
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-row-gap: 10px;
            grid-column-gap: 30px;

            li {
              width: 100%;
              border-bottom: solid 1px #e9e9e9;
              display: flex;
              padding: 5px 5px;
              justify-content: space-between;
              font-family: Nunito Sans, sans-serif !important;
              flex-direction: column;

              span {
                &:first-child {
                  font-weight: 800;
                }
              }
            }

            &.account-numer {
              grid-template-columns: repeat(1, 1fr);
              margin-bottom: 10px;
            }
          }

          .edit-button {
            width: 100%;
            margin-top: 24px;

            .ant-btn {
              background-color: #000080;
              border-color: #000080;
              color: #fff;
              font-family: Nunito Sans, sans-serif !important;
              padding: 10px 35px;
              font-weight: 700;
              border-radius: 37px;
              height: auto;
              font-size: 16px;

              &:hover {
                background-color: #000000;
              }
            }
          }
        }

        display: inline-block;
        padding: 0 30px 30px 30px;
        // width: 100%;

        .pr-op-block {
          margin-bottom: 10px;
          width: 100%;
          box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
          border-radius: 8px;
          padding: 20px;

          &.pro-inf-block {
            padding-left: 0;
            padding-right: 0;
            box-shadow: none;
          }

          .profile-form-main {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .profile-form-col {
              flex: 0 0 48%;
              max-width: 48%;

              &:first-child {
                flex: 0 0 100%;
                max-width: 100%;
              }

              .ant-form-item {
                margin-bottom: 15px;
              }

              .ant-input-lg {
                font-size: 14px;
                border-color: #d9d9d9;
                height: 40px;
              }

              .ant-select-selector {
                border-color: #d9d9d9;
                font-size: 14px;
              }
            }

            .profile-form-textarea {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 0;

              label {
                width: 100%;
              }

              .ant-input {
                border-color: #000;
              }
            }

            .profile-form-button {
              width: 100%;
              display: flex;
              gap: 15px;

              .ant-form-item {
                margin-bottom: 0;

                .ant-btn {
                  background-color: #000080;
                  border-color: #000080;
                  color: #fff;
                  font-family: Nunito Sans, sans-serif !important;
                  padding: 10px 35px;
                  font-weight: 700;
                  border-radius: 37px;
                  height: auto;
                  font-size: 16px;

                  &:hover {
                    background-color: #000000;
                  }
                }
              }
            }
          }

          .ps-title {
            display: block;
            width: 100%;
            line-height: 20px;
            font-family: "SegoeUIBold" !important;
            font-size: 16px;
            margin-bottom: 10px;
          }

          .password-box {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .hide-pass {
              color: #252525;
              font-size: 16px;
            }

            .change-password {
              background-color: #000080;
              color: #fff;
              font-family: Nunito Sans, sans-serif !important;
              padding: 10px 35px;
              font-weight: 700;
              border-radius: 37px;
              display: inline-block;
              font-size: 16px;
              cursor: pointer;

              &:hover {
                background-color: #000000;
              }
            }
          }

          .inf-cret-main {
            display: inline-flex;
            min-width: 100%;
            align-items: center;

            .inf-cret-left {
              display: flex;
              flex-grow: 1;
              min-width: 0px;
              flex-direction: column;
              width: 100%;

              p {
                strong {
                  font-family: "SegoeUIBold";
                }

                span {
                  display: inline-block;
                  position: relative;
                  left: 5px;
                  top: 2px;

                  i {
                    font-size: 16px;
                    cursor: pointer;
                    color: #000080;
                  }
                }
              }

              a {
                background-color: #000080;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                padding: 8px 15px;
                font-weight: 700;
                border-radius: 4px;
                display: inline-block;
                font-size: 14px;
                cursor: pointer;
                text-align: center;

                &:hover {
                  background-color: #000;
                }
              }

              .become-inf-container {
                display: flex;
                align-items: center;

                p {
                  margin-bottom: 0;
                  margin-right: 20px;
                }
              }
            }

            .inf-cret-right {
              width: 88px;
              height: 88px;
              flex-basis: 88px;
              position: relative;
              overflow: hidden;
              transition: box-shadow 0.25s;
              padding: 4px;
              flex-grow: 0;
              flex-shrink: 0;

              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: center;
                border-radius: 6px;
              }
            }
          }

          .instagram-user-connect-new {
            width: 100%;
            margin-top: 20px;

            .ant-form-item {
              margin-bottom: 15px;
            }

            .ant-input-number-lg {
              width: 100%;
            }

            .bcm-inf-btn {
              display: flex;
              width: 100%;

              .ant-form-item {
                margin-bottom: 0;
              }

              .ant-btn {
                padding: 6px 15px;
                border-radius: 4px;
                font-size: 14px;
                min-width: 100px;
                background-color: #000080;
                border-color: #000080;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                height: auto;

                &:hover {
                  background-color: #000;
                }
              }
            }
          }

          .add-link-bio-infl {
            width: 100%;

            h4 {
              font-weight: 800 !important;
              font-size: 14px;
            }
          }
        }

        .card-information-store {
          width: 100%;

          .add-card-detail {
            height: 45px;
            border: solid 1px #d9d9d9;
            display: flex;
            width: 100%;
            font-family: Nunito Sans, sans-serif !important;
            font-size: 16px;
            font-weight: 800;
            text-transform: uppercase;
            align-items: center;
            padding-left: 12px;
            margin: 10px 0;
            position: relative;
            cursor: pointer;

            i {
              margin-right: 10px;
            }

            .selected-cradit-card {
              position: absolute;
              right: 20px;

              i {
                margin-right: 0;
              }
            }
          }

          .cd-detail-box {
            width: 100%;
            display: flex;
            padding: 10px 0;

            .ant-form-item {
              margin-bottom: 0;
            }

            &.cd-detail-box-gap {
              gap: 10px;
            }

            .float-label {
              width: 100%;

              .ant-form-item {
                margin-bottom: 0;
              }

              .ant-input-lg {
                height: 40px;
                font-size: 14px;
              }

              .ant-select-selector {
                height: 40px;
                font-size: 14px;
              }

              label {
                background-color: #fff;
                width: 90%;

                &.label-float {
                  width: auto;
                }
              }
            }

            .cd-2col {
              width: 50%;
            }

            .ant-checkbox-inner {
              width: 18px;
              height: 18px;
            }

            button {
              background-color: #000080;
              border-color: #000080;
              color: #fff;
              font-family: Nunito Sans, sans-serif !important;
              padding: 10px 35px;
              font-weight: 700;
              border-radius: 37px;
              height: auto;
              font-size: 16px;
              cursor: pointer;

              &:first-child {
                margin-right: 10px;
              }

              &:hover {
                background-color: #000000;
              }
            }
            &.spacing {
              input {
                padding-left: 11px;
                font-size: 14px;
              }
            }
          }

          .billing-address-store {
            width: 100%;
            margin-top: 10px;
          }

          .address-list {
            width: 100%;
            display: grid;
            gap: 15px;
            margin-top: 15px;
            grid-template-columns: repeat(1, 1fr);

            .address-box {
              width: 100%;
              border: solid 1px #d9d9d9;
              border-radius: 4px;
              min-height: 140px;
              padding: 16px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;

              &.dft-box {
                background-color: #fafafa;
                border-color: #000;
              }

              h4 {
                font-size: 1rem;
                font-weight: 700 !important;
                color: #737373;
              }

              .ship-address-iner {
                display: flex;
                width: 100%;
                flex-direction: column;
                font-family: "Nunito Sans", sans-serif !important;
                font-weight: 700;
              }

              .ship-btns {
                display: flex;
                gap: 10px;
                margin-top: 10px;

                button {
                  background-color: #000080;
                  border-color: #000080;
                  color: #fff;
                  border-radius: 4px;
                  min-width: 80px;

                  &:hover {
                    background-color: #000000;
                  }
                }
              }

              .ant-checkbox-wrapper {
                position: absolute;
                top: 16px;
                right: 16px;
              }
            }
          }
        }
      }

      .favourite-brands-container {
        .brand_container_main {
          width: 100%;
          padding: 0;

          .summary_container_main {
            background-color: #fff;
            padding: 15px;
            max-width: 100%;
            border: 1px solid #d6dee5;
            border-radius: 4px;

            .ant-picker-range {
              display: flex !important;
              width: 100%;
              border: 1px solid #000;
            }

            .summary_content_profile {
              .card {
                border-radius: 0;
                box-shadow: none !important;
                margin-bottom: 0;
                margin-top: 1rem;
                padding-bottom: 0;
                padding-left: 0;
                padding-right: 0;
                border: none;

                .count-box {
                  .count {
                    font-size: 1rem;
                    font-family: Nunito Sans, sans-serif !important;
                    font-weight: 600 !important;
                  }
                }
              }
            }
          }

          .upload_box_main {
            background-color: #fff;
            padding: 15px;
            max-width: 100%;
            border: 1px solid #d6dee5;
            border-radius: 4px;

            .dash_content_profile {
              min-height: 300px;

              .default-btn {
                background-color: #000080;
                border: 1px solid #000080;
                border-radius: 3px;
                font-size: 13px;
                font-weight: 400 !important;
                height: 35px;
                min-width: 100px;

                &:hover {
                  background-color: #000000;
                  border-color: #000000;
                }
              }
            }
          }

          .campaign-box {
            border: none;
            box-shadow: 0 0 3px #ccc !important;

            .campaign-header {
              &.col-12 {
                display: flex;
                justify-content: space-between;
              }

              h6 {
                font-family: SegoeUIBold !important;
                margin-bottom: 1rem;
              }

              .cmp-h-right {
                .custom-switch {
                  top: -5px;

                  .custom-control-input {
                    &:checked {
                      -webkit-transform: translateX(1.25rem);
                      transform: translateX(1.25rem);
                      background-color: #fff;
                    }
                  }

                  .custom-control-label {
                    cursor: pointer;
                    padding-bottom: 1.5rem;
                    padding-left: 0.6rem;

                    &:before {
                      border-radius: 3rem;
                      height: 1.5rem;
                      width: 2.75rem;
                      background-color: #000080;
                      border-color: #000080;
                    }

                    &::after {
                      border-radius: 1.25rem;
                      height: calc(1.5rem - 4px);
                      width: calc(1.5rem - 4px);
                      transform: translateX(1.25rem);
                    }
                  }
                }
              }
            }

            .any-post-img-col {
              display: flex;

              .any-post-image {
                flex: 0 0 100%;
                max-width: 100%;
                position: relative;

                .any-image-box {
                  bottom: 1px;
                  left: 1px;
                  position: absolute;
                  right: 1px;
                  top: 1px;

                  .any-image-box-iner {
                    bottom: 0;
                    display: block;
                    left: 0;
                    margin: 0;
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    top: 0;

                    img,
                    video {
                      border: none;
                      border-radius: 0.3rem !important;
                      object-position: center top;
                      padding: 0;
                      width: 100%;
                      display: block;
                      height: 100%;
                      object-fit: cover;
                    }
                  }
                }

                &:after {
                  content: "";
                  display: block;
                  padding-top: 100%;
                }
              }
            }

            .cam-buttons {
              .red-btn2 {
                background-color: #eb335b;
                border: 1px solid #eb335b;
                font-size: 13px;
                font-weight: 400 !important;
                height: 35px;
                min-width: 100px;
                font-family: Nunito Sans, sans-serif !important;
                border-radius: 3px;
              }
            }
          }

          .custom-paginate {
            .page-item {
              margin: 0 5px;

              .page-link {
                border-radius: 0.25rem;
                color: #000080;
                font-weight: 700;
                padding: 0.5rem 1rem;
              }

              &.disabled {
                opacity: 0.8;

                .page-link {
                  color: #6c757d !important;
                }
              }

              &.active {
                a {
                  background-color: #fff !important;
                  border: 1px solid #000080 !important;
                  color: #000080 !important;
                  outline: none;
                }
              }
            }
          }
        }

        .new-dash-content {
          .ant-picker {
            border-color: #000;
          }

          .ant-select-selector {
            border-color: #000;
          }

          .ant-form-item {
            margin-bottom: 0;
          }

          .srh-btn {
            .ant-btn {
              background-color: #000080;
              border: 1px solid #000080;
              border-radius: 3px;
              min-width: 100px;
              color: #fff;
              height: 40px;
              font-size: 15px;

              &:hover {
                background-color: #000000;
              }

              &:first-child {
                margin-right: 10px;
              }
            }
          }

          .dash-boxes {
            .dashboard-info-box {
              background-color: #fff;
              border-radius: 5px;
              box-shadow: 0 0 2px rgba(0, 0, 0, 0.26);
              margin-bottom: 24px;
              padding: 5px 20px 5px 50px;
              position: relative;
              text-align: right;

              .info-icon-main {
                bottom: 0;
                color: #e4e7ed;
                font-size: 2.7em;
                left: 10px;
                margin: auto;
                position: absolute;
                top: 0;
              }

              .value-info-hd {
                color: #575757;
                font-family: Nunito Sans, sans-serif;
                font-size: 18px;
                font-weight: 600;
                line-height: 1.5;
              }

              .total-info-hd {
                color: #798892;
                font-family: Nunito Sans, sans-serif;
                font-size: 12px;
                font-weight: 600;
                text-transform: uppercase;
              }
            }

            .title-hd {
              color: #252525;
              font-size: 1.25rem;
              font-weight: 800 !important;
              letter-spacing: 1px;
              margin-bottom: 0;
              padding: 0;
            }
          }
        }

        .ant-tabs {
          .ant-tabs-nav {
            margin-bottom: 30px;

            &::before {
              display: none;
            }

            .ant-tabs-nav-wrap {
              border-bottom: solid 1px #ccc;
              padding: 0 30px 13px 30px;

              .ant-tabs-nav-list {
                .ant-tabs-tab {
                  font-family: Nunito Sans, sans-serif !important;
                  font-size: 16px;
                  font-weight: 600 !important;
                  justify-content: center;
                  margin: 0 10px 0 0;
                  min-width: 80px;
                  padding: 0.5rem 1.5rem;

                  &:hover {
                    color: #000;
                  }

                  &.ant-tabs-tab-active {
                    background-color: #000080;
                    border-radius: 2rem;

                    .ant-tabs-tab-btn {
                      color: #fff;
                      text-shadow: none;
                    }
                  }
                }

                .ant-tabs-ink-bar {
                  display: none;
                }
              }
            }
          }
        }

        .reorder-gallery-container {
          .reorder-title {
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding-bottom: 15px;

            h4 {
              color: #252525;
              font-size: 25px;
              font-weight: 800 !important;
              letter-spacing: 1px;
              margin-bottom: 0;
              padding: 0;
            }
          }

          .reorder-content {
            .bioshopaction {
              display: flex;
              justify-content: flex-start;
              margin-bottom: 20px;

              .bioshoplimit {
                margin-right: 10px;
                min-width: 40%;
              }

              .syncbutton {
                .ant-btn-primary {
                  background-color: #000080;
                  border: 1px solid #000080;
                  border-radius: 3px;
                  min-width: 100px;
                  color: white;
                  font-size: 13px;
                  font-weight: 400 !important;
                  height: 38px;
                  font-family: Nunito Sans, sans-serif !important;

                  &:hover {
                    background-color: #000000;
                  }
                }
              }
            }

            .bioshop-area {
              grid-row-gap: 15px;
              grid-column-gap: 15px;
              display: grid !important;
              grid-template-columns: repeat(4, minmax(0, 1fr));
              overflow-x: hidden;
              overflow-y: auto;

              .image-post-box-aff {
                cursor: pointer;
                position: relative;
                height: -webkit-fit-content;
                height: -moz-fit-content;
                height: fit-content;

                .image-post-box-aff-inr {
                  border-color: #f6f6f6 #f3f3f3 #eee;
                  border-style: solid;
                  border-width: 1px;
                  bottom: 1px;
                  left: 1px;
                  position: absolute;
                  right: 1px;
                  top: 1px;
                  border-radius: 10px;

                  .image-post-box-aff-inr-inr {
                    border-radius: 10px;
                    bottom: 0;
                    display: block;
                    left: 0;
                    margin: 0;
                    overflow: hidden;
                    position: absolute;
                    right: 0;
                    top: 0;

                    .post-image {
                      border-radius: 0 !important;
                      display: block;
                      height: 100%;
                      object-fit: cover;
                      object-position: 50% 0;
                      width: 100%;
                      cursor: move;
                    }

                    video {
                      height: auto;
                      max-width: 100%;
                    }
                  }
                }

                &:after {
                  content: "";
                  display: block;
                  padding-top: 100%;
                }

                &.default-event-banner {
                  .image-post-box-aff-inr {
                    .image-post-box-aff-inr-inr {
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      background: rgba(33, 43, 92, 1);
                      background: -moz-linear-gradient(
                        top,
                        rgba(33, 43, 92, 1) 0%,
                        rgba(0, 12, 64, 1) 100%
                      );
                      background: -webkit-gradient(
                        left top,
                        left bottom,
                        color-stop(0%, rgba(33, 43, 92, 1)),
                        color-stop(100%, rgba(0, 12, 64, 1))
                      );
                      background: -webkit-linear-gradient(
                        top,
                        rgba(33, 43, 92, 1) 0%,
                        rgba(0, 12, 64, 1) 100%
                      );
                      background: -o-linear-gradient(
                        top,
                        rgba(33, 43, 92, 1) 0%,
                        rgba(0, 12, 64, 1) 100%
                      );
                      background: -ms-linear-gradient(
                        top,
                        rgba(33, 43, 92, 1) 0%,
                        rgba(0, 12, 64, 1) 100%
                      );
                      background: linear-gradient(
                        to bottom,
                        rgba(33, 43, 92, 1) 0%,
                        rgba(0, 12, 64, 1) 100%
                      );
                      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#212b5c', endColorstr='#000c40', GradientType=0);

                      img {
                        width: initial;
                        height: initial;
                        max-width: 100%;
                        max-height: 100%;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .favourite-brands {
        width: 100%;
        display: grid;
        grid-row-gap: 20px;
        grid-column-gap: 20px;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px 30px;

        .ant-empty-normal {
          position: absolute;
          left: 0;
          right: 0;
          height: 100%;
        }

        .indv-brand {
          a {
            display: inline-flex;
            flex-direction: column;
            width: 100%;
            cursor: pointer;

            .brand-boxes {
              background-color: #fff;
              border-radius: 6px;
              display: inline-flex;
              box-shadow: 0px 0px 8px rgba(10, 22, 70, 0.15);
              min-width: 100%;
              align-items: center;
              position: relative;

              .brnd-fav {
                position: absolute;
                right: 20px;
                font-size: 18px;
                padding: 5px;

                .fa-heart {
                  color: #e80c5b;
                }

                .fa-times {
                  display: none;
                  font-size: 20px;
                }

                &:hover {
                  .fa-times {
                    display: block;
                  }

                  .fa-heart {
                    display: none;
                  }
                }
              }

              .brand-boxes-logos {
                width: 88px;
                height: 88px;
                flex-basis: 88px;
                position: relative;
                overflow: hidden;
                transition: box-shadow 0.25s;
                padding: 4px;
                flex-grow: 0;
                flex-shrink: 0;

                img,
                video {
                  display: block;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  object-position: center;
                  border-radius: 6px;
                }
              }

              .brand-user {
                display: flex;
                flex-grow: 1;
                margin-left: 10px;
                min-width: 0px;
                flex-direction: column;
                width: 100%;
                padding-right: 15px;

                .brnd-user-title {
                  display: flex;
                  flex-direction: column;

                  .brnd-name-title {
                    font-family: "Nunito Sans", sans-serif !important;
                    font-weight: 700;
                    color: #000;
                  }
                }

                .ft-2 {
                  display: flex;
                  align-items: center;

                  span {
                    font-size: 14px;
                    font-weight: 800;
                    font-family: Nunito Sans, sans-serif !important;
                    line-height: 20px;
                    color: #e80c5b;
                  }
                }

                .ft-3 {
                  display: flex;

                  .brnd-name-descrp {
                    font-size: 12px;
                    line-height: 16px;
                    color: #7b8994;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                  }
                }
              }

              .add-remove-button {
                display: flex;
                flex-direction: column;
                margin-right: 10px;

                span {
                  text-align: center;
                  font-size: 12px;
                  background-color: #b02a37;
                  color: #fff;
                  margin-bottom: 10px;
                  min-width: 60px;
                  padding: 2px 0;
                  border-radius: 2px;
                  font-family: "SegoeUI_Medium";

                  &:last-child {
                    margin-bottom: 0;
                    background-color: #000080;
                  }

                  &:hover {
                    background-color: #000;
                  }
                }

                &.rmv-2 {
                  span {
                    &:first-child {
                      background-color: #b02a37;
                    }
                  }
                }
              }
            }
          }
        }

        .antd-loading-ift {
          width: 100%;
          min-height: 100%;
          display: flex;
          position: absolute;
          left: 0;
          top: 0;
          justify-content: center;
          align-items: center;
        }
      }

      .purchase-content-main {
        width: 100%;
        padding: 20px 30px 20px 30px;
      }

      .purchases-filter {
        width: 100%;
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        &.referral-filter {
          gap: 10px;
          display: grid;
          grid-template-columns: repeat(4, minmax(0, 1fr));

          .referral-filter-column {
            &.ref-buttons {
              display: flex;
              gap: 10px;
              align-items: flex-end;

              .ant-btn {
                padding: 0;
                width: 50%;
              }
            }
          }
        }

        .ant-picker-large,
        .ant-select-selector {
          border-color: #000;
          height: 40px;

          .ant-picker-input > input {
            font-size: 14px;
            height: 40px;

            &::placeholder {
              color: #000;
            }
          }
        }

        .ant-form-item {
          margin-bottom: 0;
        }

        .ant-btn {
          background-color: #000080;
          border-color: #000080;
          color: #fff;
          font-family: Nunito Sans, sans-serif !important;
          padding: 0 35px;
          font-weight: 700;
          border-radius: 4px;
          font-size: 16px;
          height: 40px;

          &:hover {
            background-color: #000;
          }
        }
      }

      .purchase-data {
        width: 100%;

        .pagination {
          width: 100%;
          justify-content: flex-end;
        }

        .antd-loading-ift {
          width: 100%;
          min-height: 100%;
          display: flex;
          left: 0;
          top: 0;
          justify-content: center;
          align-items: center;
        }

        .sales-summery {
          width: 100%;
          margin-top: 8px;

          .summarry-box {
            background: #fff;
            border: 1px solid #d6dee5;
            font-family: Nunito Sans, sans-serif;
            font-weight: 700;
            padding: 10px;

            .summary-hd {
              border-bottom: 1px solid #e1e1e1;
              font-size: 18px;
              padding-bottom: 5px;
            }

            .summary-detail-box {
              border-bottom: 1px solid #ede9e9;
              display: flex;
              justify-content: space-between;
              margin-bottom: 4px;
              padding-left: 0;
              padding-right: 0;

              &:last-child {
                border-bottom: none;
                margin-bottom: 0;
              }

              .count-title {
                color: #798892;
                font-size: 12px;
                margin-bottom: 3px;
              }

              .count {
                font-size: 12px;
                margin-bottom: 3px;
              }
            }
          }
        }

        .table-responsive {
          .table {
            th {
              font-family: "SegoeUIBold";
            }
          }

          tbody {
            tr {
              td {
                font-size: 12px;
                padding: 0.1rem 0.75rem;
                vertical-align: middle;
              }
            }
          }
        }

        &.referral-data {
          .transactions-box {
            tbody {
              tr {
                td {
                  font-size: 12px;
                  padding: 0.1rem 0.75rem;
                  vertical-align: middle;

                  .open-modal {
                    cursor: pointer;
                    color: #40a9ff;

                    &:hover {
                      text-decoration: underline;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .wallet-content-main {
        width: 100%;
        padding: 0;

        .wallet-row-1 {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding-left: 30px;
          padding-right: 30px;
          flex-wrap: wrap;

          .inr-box-main {
            display: inline-flex;
            min-height: 100%;
            align-items: center;

            .wallet-icon {
              width: 50px;
              flex-basis: 50px;
              position: relative;
              overflow: hidden;
              flex-grow: 0;
              flex-shrink: 0;
              margin-right: 10px;

              .filter-gray {
                filter: invert(76%) sepia(11%) saturate(6%) hue-rotate(315deg)
                  brightness(96%) contrast(81%);
              }

              &.pp-logo {
                width: 55px;
                flex-basis: 55px;

                .pp-img {
                  width: 100%;
                }
              }
            }

            .wallet-data {
              display: flex;
              flex-grow: 1;
              margin-left: 10px;
              min-width: 0px;
              flex-direction: column;
              width: 100%;
              padding-right: 0px;
              // padding-right: 15px;

              .wallet-detail {
                display: flex;
                flex-direction: column;
              }

              .withdraw {
                display: flex;
              }
            }
          }

          .balance-box {
            // flex: 0 0 48%;
            // max-width: 48%;
            flex: 0 0 24%;
            max-width: 24%;
            min-height: 100px;
            box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
            border-radius: 4px;
            padding: 10px 20px;
            display: flex;
            flex-direction: column;

            .box-title {
              font-family: "SegoeUI_Medium";
              font-size: 14px;
              color: #7b8994;
            }

            .box-ammount {
              font-family: Nunito Sans, sans-serif !important;
              color: #252525;
              font-weight: 700;
              font-size: 25px;

              span {
                font-size: 14px;
              }
            }

            .withdraw {
              a {
                font-size: 12px;
                background-color: #000080;
                margin-right: 10px;
                color: #fff;
                padding: 5px 12px;
                border-radius: 5px;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;

                &:last-child {
                  margin-right: 0;
                  background-color: #e9e9e9;
                  color: #000;
                  display: none;

                  &:hover {
                    color: #fff;
                  }
                }

                &:hover {
                  background-color: #000000;
                }
              }
            }
          }

          &.wallet-box-gap {
            .balance-box {
              margin-bottom: 25px;
            }
          }
        }

        .wallet-row-2 {
          width: 100%;
          margin-top: 20px;
          padding-left: 30px;
          padding-right: 30px;

          .wallet-header {
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 20px;

            h3 {
              padding-left: 0;
              padding: 0;
            }

            .wallet-header-right {
              display: flex;

              .ant-picker-range {
                margin-right: 10px;
                border-color: #000;
              }

              .ant-btn-primary {
                background-color: #000080;
                border-color: #000080;
                color: #fff;
                font-family: Nunito Sans, sans-serif !important;
                padding: 0 15px;
                font-weight: 700;
                border-radius: 4px;
                font-size: 14px;
                height: 40px;
                min-width: 100px;

                &:hover {
                  background-color: #000;
                  border-color: #000;
                }
              }
            }
          }

          .purchase-data {
            .table-responsive-lg {
              .table {
                th {
                  font-size: 12px;
                  text-transform: uppercase;
                  font-family: "SegoeUIBold";
                }

                td {
                  font-family: "SegoeUIBold";
                  font-size: 12px;

                  .status-active {
                    color: green;
                  }

                  .status-deleted {
                    color: red;
                  }

                  .pro-name {
                    display: flex;
                    min-width: 100%;
                    position: relative;

                    .pro-boxes-logos {
                      width: 55px;
                      height: 55px;
                      flex-basis: 55px;
                      position: relative;
                      overflow: hidden;
                      transition: box-shadow 0.25s;
                      padding: 0;
                      flex-grow: 0;
                      flex-shrink: 0;

                      img {
                        display: block;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: center;
                        border-radius: 6px;
                      }
                    }

                    .brand-user-pro {
                      display: flex;
                      flex-grow: 1;
                      margin-left: 10px;
                      min-width: 0px;
                      flex-direction: column;
                      width: 100%;
                      font-family: "SegoeUIBold";
                      color: #000;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        .withdraw-request {
          width: 100%;
          margin-top: 0;

          .favourite-brands-container {
            .ant-tabs .ant-tabs-nav {
              .ant-tabs-nav-wrap {
                padding-top: 13px;
                border-top: solid 1px #ccc;
              }
            }

            .purchase-data {
              padding: 0 30px;

              .table-responsive-lg {
                .table {
                  th {
                    font-size: 12px;
                    text-transform: uppercase;
                    font-family: "SegoeUIBold";
                  }

                  td {
                    font-family: "SegoeUIBold";
                    font-size: 12px;

                    .status-success {
                      color: green;
                    }

                    .status-failed {
                      color: red;
                    }

                    .status-pending {
                      color: #212529;
                    }

                    .status-process {
                      color: #3e3c07;
                    }

                    .pro-name {
                      display: flex;
                      min-width: 100%;
                      position: relative;

                      .pro-boxes-logos {
                        width: 55px;
                        height: 55px;
                        flex-basis: 55px;
                        position: relative;
                        overflow: hidden;
                        transition: box-shadow 0.25s;
                        padding: 0;
                        flex-grow: 0;
                        flex-shrink: 0;

                        img {
                          display: block;
                          width: 100%;
                          height: 100%;
                          object-fit: cover;
                          object-position: center;
                          border-radius: 6px;
                        }
                      }

                      .brand-user-pro {
                        display: flex;
                        flex-grow: 1;
                        margin-left: 10px;
                        min-width: 0px;
                        flex-direction: column;
                        width: 100%;
                        font-family: "SegoeUIBold";
                        color: #000;
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.w-98 {
  width: 98%;
}

.paypal-connect-box {
  padding: 25px 0;
  text-align: center;

  .paypal-logo {
    max-width: 200px;
    margin-bottom: 30px;
  }

  .connect-btn {
    background-color: #000080;
    border: 1px solid #000080;
    color: #fff;
    font-family: Nunito Sans, sans-serif !important;
    padding: 8px 26px;
    font-weight: 700;
    border-radius: 22px;
    display: inline-block;
    font-size: 16px;
    height: initial;
    cursor: pointer;

    &:hover {
      background-color: #000000;
      border: 1px solid #000000;
      color: #fff;
    }

    &.connected {
      background-color: green;
      border: 1px solid green;
    }
  }
}

@media screen and (max-width: 1700px) and (min-width: 1250px) {
  .w-20-desktop-main .col-xl-2 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media screen and (max-width: 1249px) and (min-width: 999px) {
  .w-20-desktop-main .col-xl-2 {
    flex: 0 0 25%;
    max-width: 25%;
  }
}

@media only screen and (max-width: 1270px) {
  .account-container-main {
    .account-inr-container {
      width: 100%;
      min-height: initial;
    }
  }

  .wallet-row-1 {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1200px) {
  .bioshop-body {
    .account-container-main {
      .layout-content {
        margin-left: 0;
      }
    }
  }

  .account-container-main {
    .ant-layout-has-sider {
      .ant-layout {
        .ant-layout-header {
          padding-left: 4rem !important;
        }
      }

      .sidebar-main {
        &.is-sticky {
          position: absolute;
        }
      }
    }
  }

  .ant-layout-sider-zero-width-trigger {
    width: 46px;
    height: 55px;
    font-size: 30px;
    right: -47px;
    top: 0;
    background-color: #000080;
  }
}

@media only screen and (max-width: 1199px) {
  .dashboard-sidebar {
    display: none;
  }

  .account-container-main
    .ant-layout-has-sider
    .ant-layout
    .ant-layout-content
    .site-layout-background
    .account-inr-container
    .account-left-content
    .favourite-brands-container
    .favourite-brands {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 990px) {
  .account-container-main .ant-layout-has-sider .ant-layout .ant-layout-content .site-layout-background .account-inr-container .account-left-content .account-detail.account-settings .user-profile .user-profile-image {
    flex-direction: column;
    gap: 20px!important;
  }
  .bioshop-body.shopper-bioshop-body .account-container-main {
    height: calc(100vh - 110px);
  }

  .account-container-main {
    .ant-layout-has-sider {
      .ant-layout {
        .ant-layout-content {
          .site-layout-background {
            .account-inr-container {
              padding: 20px;
            }
          }
        }
      }
    }

    .account-inr-container {
      margin: 20px auto;

      .account-sidebar {
        min-height: initial;
        float: none;
        width: 100%;
        margin-bottom: 20px;
        box-shadow: none;

        .sid-1 {
          padding: 10px 10px 10px 10px;
          border-top: solid 1px #ccc;

          .member-name {
            font-size: 20px;
            line-height: 26px;
            padding-bottom: 6px;
          }

          .member-edit {
            display: none;
          }

          .member-welcome {
            display: none;
          }

          span {
            text-align: left;
          }
        }

        .sid-2 {
          display: none;
        }
      }

      .account-left-content {
        min-height: initial;
        float: none;
        width: 100%;

        h1 {
          font-size: 18px;
          line-height: 24px;
          padding: 10px 15px 6px 15px;
        }

        h3 {
          padding: 10px 15px 0 15px;
          font-size: 16px;
          line-height: 20px;
          margin-bottom: 15px;
        }

        .account-detail {
          padding: 0 20px 15px 20px;

          .pr-op-block {
            padding: 0px;
            box-shadow: none;

            &.pro-inf-block {
              padding: 10px 0 0px 0;
            }

            .profile-form-main {
              .profile-form-button {
                .ant-form-item {
                  .ant-btn {
                    padding: 7px 20px;
                    font-size: 14px;
                    font-weight: 600;
                  }
                }
              }
            }

            .password-box {
              .change-password {
                padding: 7px 20px;
                font-size: 14px;
                font-weight: 600;
              }
            }

            .inf-cret-main {
              .inf-cret-left {
                a {
                  padding: 7px 20px;
                  font-size: 14px;
                  font-weight: 600;
                  max-width: fit-content;
                }
              }
            }
          }
        }

        .purchases-filter {
          .ant-btn {
            padding: 7px 20px;
            font-size: 14px;
            font-weight: 600;
          }
        }

        .favourite-brands-container {
          .ant-tabs {
            .ant-tabs-nav {
              .ant-tabs-nav-wrap {
                padding: 0 15px 10px 15px;
              }
            }
          }

          .favourite-brands {
            padding-left: 20px;
            padding-right: 20px;

            .indv-brand {
              a {
                .brand-boxes {
                  .brand-user {
                    .ft-2 {
                      span {
                        font-size: 12px;
                      }
                    }
                  }
                }
              }
            }
          }
        }

        .purchase-content-main {
          padding-left: 20px;
          padding-right: 20px;
        }

        .wallet-content-main {
          .wallet-row-1 {
            padding-left: 20px;
            padding-right: 20px;
          }
        }

        .wallet-content-main {
          .wallet-row-2 {
            padding-left: 20px;
            padding-right: 20px;
          }

          .withdraw-request {
            .favourite-brands-container {
              .purchase-data {
                padding: 0 20px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .bioshop-body {
    .mobile_footer_ifti {
      display: none;
    }
  }

  .account-container-main {
    .ant-layout-has-sider {
      .ant-layout {
        .ant-layout-content {
          .site-layout-background {
            .account-inr-container {
              .account-left-content {
                .favourite-brands-container {
                  .favourite-brands {
                    grid-template-columns: repeat(1, 1fr);
                  }
                }

                .pr-op-block {
                  .user-profile-image {
                    flex-direction: column;

                    .porf-btns {
                      label {
                        min-width: 70px;
                        padding: 0 8px;
                      }

                      button {
                        min-width: 70px;
                        padding: 0 8px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .w-98 {
    width: 100%;
  }

  .account-container-main {
    .account-inr-container {
      .account-left-content {
        .account-detail {
          .user-profile {
            ul {
              grid-template-columns: repeat(1, 1fr);
            }
          }

          .card-information-store {
            .cd-detail-box {
              &.cd-detail-box-gap {
                gap: 20px;
                flex-direction: column;
              }

              .cd-2col {
                width: 100%;
              }

              button {
                &:first-child {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .purchase-content-main {
          padding: 15px 15px 20px 15px;
        }

        .account-detail {
          .pr-op-block {
            .profile-form-main {
              .profile-form-col {
                flex: 0 0 100%;
                max-width: 100%;
              }
            }

            .inf-cret-main {
              .inf-cret-right {
                display: none;
              }
            }

            .password-box {
              flex-direction: column;
              align-items: flex-start;
            }
          }
        }

        .purchases-filter {
          .ant-space-vertical {
            &.w-98 {
              margin-bottom: 10px;
              margin-right: 10px;
            }
          }
        }

        .favourite-brands {
          grid-template-columns: repeat(1, 1fr);
          padding: 10px 20px;
        }

        .wallet-content-main {
          .wallet-row-1 {
            flex-direction: column;

            .balance-box {
              flex: 0 0 100%;
              max-width: 100%;
              margin-bottom: 20px;
              min-height: 80px;

              &:last-child {
                margin-bottom: 0;
              }

              .box-ammount {
                font-size: 28px;
              }
            }
          }

          .wallet-row-2 {
            .wallet-header {
              flex-direction: column;
              align-items: flex-start;
              margin-bottom: 10px;
            }
          }
        }

        .favourite-brands-container {
          .reorder-gallery-container {
            .reorder-content {
              .bioshop-area {
                grid-template-columns: repeat(2, minmax(0, 1fr));
              }

              .bioshopaction {
                .bioshoplimit {
                  min-width: 65%;
                }
              }
            }

            .reorder-title {
              h4 {
                font-size: 16px;
              }
            }
          }
        }
      }

      &.reset-page {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 370px) {
  .account-container-main {
    .account-inr-container {
      .account-left-content {
        .favourite-brands-container {
          .favourite-brands {
            padding-left: 10px;
            padding-right: 10px;
          }
        }

        .account-detail {
          padding-left: 10px;
          padding-right: 10px;
        }

        .purchase-content-main {
          padding-left: 10px;
          padding-right: 10px;
        }

        .wallet-content-main {
          .wallet-row-1 {
            padding-left: 10px;
            padding-right: 10px;
          }

          .wallet-row-2 {
            padding-left: 10px;
            padding-right: 10px;
          }

          .withdraw-request {
            .favourite-brands-container {
              .purchase-data {
                padding-left: 10px;
                padding-right: 10px;
              }
            }
          }
        }
      }
    }
  }
}

.delete-hd {
  color: red;
  font-size: 22px;
}

.support-txt {
  font-style: italic;
}

button.ant-btn.ant-btn-default.delete-btn {
  background: red;
  color: white;
  border-radius: 8px;
}

.delete-btn {
  background-color: red;
  border-color: red;
  color: #fff;
  font-family: Nunito Sans, sans-serif !important;
  padding: 0 15px;
  font-weight: 700;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;

  &:hover {
    color: #ffffff;
    background-color: #d50000;
    border: 1px solid #d50000;
  }

  &:focus {
    color: #ffffff;
    background-color: #d50000;
    border: 1px solid #d50000;
  }
}

.outline-btn {
  background-color: #fff;
  border-color: #000080;
  color: #000080;
  font-family: Nunito Sans, sans-serif !important;
  padding: 0 15px;
  font-weight: 700;
  border-radius: 4px;
  font-size: 14px;
  height: 40px;
  min-width: 100px;

  &:hover {
    background-color: #000080;
    border-color: #000080;
    color: #fff;
  }

  &:focus {
    background-color: #000080;
    border-color: #000080;
    color: #fff;
  }
}

.thank-you-modal {
  .text-link {
    color: #000080;
    &:hover {
      color: #000000;
    }
    &:focus {
      color: #000080;
    }
  }
}
.modal-600 {
  max-width: 600px !important;
}

.ant-btn-primary {
  &.main-btn {
    background-color: #000080;
    border: 1px solid #000080;
    border-radius: 3px;
    font-size: 13px;
    font-weight: 400 !important;
    height: 35px;
    min-width: 100px;

    &:hover {
      background-color: #000000;
      border-color: #000000;
    }
    &:focus {
      background-color: #000080;
      border-color: #000080;
    }
  }
}

.emoji-overlay{
    position:absolute;
    bottom:0;
    right:2em;
    z-index:20;
    padding:0;
    pointer-events:none;
    transition:transform .35s;
  }
  .emoji-overlay .emoji-batch{
    position:relative;
    z-index: 999;
  }
  .emoji-overlay .emoji-kind{
    display:inline-block;
    animation-duration:1s;
    animation-name:emoji-animation-1-horizontal;
    animation-timing-function:ease-in-out;
    position:absolute;
    font-size:26px;
    user-select:none;
    transform:translate3d(0, 0, 0);
    bottom:0;
    right:0;
    --fill-color: var(--main-heart-color);
    --stroke-color: var(--main-heart-border-color)
  }
  .portrait-horizontal-screen .emoji-overlay .emoji-kind{
    --fill-color: var(--inverted-heart-color);
    --stroke-color: var(--inverted-heart-border-color)
  }
  .emoji-overlay .emoji-kind .emoji-kind-vertical{
    display:inline-block;
    animation-duration:15s;
    animation-name:emoji-animation-1-vertical;
    animation-timing-function:cubic-bezier(0.1, 0.6, 0, 0);
    transform:translate3d(0, 0, 0)
  }
  .emoji-overlay .emoji-kind .emoji-kind-opacity{
    display:inline-block;
    animation-duration:15s;
    animation-name:emoji-animation-1-opacity;
    animation-timing-function:ease-in-out;
    transform:translate3d(0, 0, 0);
    opacity:0
  }
  @keyframes emoji-animation-1-horizontal{
    0%{
        transform:translate3d(0, 0, 0) scaleX(0.5) scaleY(0.5)
    }
    25%{
        transform:translate3d(8px, 0, 0) scaleX(1) scaleY(1)
    }
    50%{
        transform:translate3d(-5px, 0, 0)
    }
    70%{
        transform:translate3d(10px, 0, 0)
    }
    100%{
        transform:translate3d(-10px, 0, 0)
    }
  }
  @keyframes emoji-animation-1-vertical{
    0%{
        transform:translate3d(0, 0, 0)
    }
    100%{
        transform:translate3d(0, -45vh, 0)
    }
  }
  @keyframes emoji-animation-1-opacity{
    0%{
        opacity:0
    }
    30%{
        opacity:1
    }
    70%{
        opacity:.7
    }
    100%{
        opacity:0
    }
  }
  @keyframes emoji-animation-2-horizontal{
    0%{
        transform:translate3d(0, 0, 0) scaleX(0.5) scaleY(0.5)
    }
    25%{
        transform:translate3d(10px, 0, 0) scaleX(1) scaleY(1)
    }
    60%{
        transform:translate3d(-10px, 0, 0)
    }
    100%{
        transform:translate3d(-10px, 0, 0)
    }
  }
  @keyframes emoji-animation-2-vertical{
    0%{
        transform:translate3d(0, 0, 0)
    }
    100%{
        transform:translate3d(0, -60vh, 0)
    }
  }
  @keyframes emoji-animation-2-opacity{
    0%{
        opacity:0
    }
    30%{
        opacity:1
    }
    70%{
        opacity:0
    }
    100%{
        opacity:0
    }
  }
  @keyframes emoji-animation-3-horizontal{
    0%{
        transform:translate3d(0, 0, 0) scaleX(0.5) scaleY(0.5)
    }
    25%{
        transform:translate3d(-2px, 0, 0) scaleX(1) scaleY(1)
    }
    70%{
        transform:translate3d(2px, 0, 0)
    }
    100%{
        transform:translate3d(2px, 0, 0)
    }
  }
  @keyframes emoji-animation-3-vertical{
    0%{
        transform:translate3d(0, 0, 0)
    }
    100%{
        transform:translate3d(0, -90vh, 0)
    }
  }
  @keyframes emoji-animation-3-opacity{
    0%{
        opacity:0
    }
    30%{
        opacity:1
    }
    70%{
        opacity:0
    }
    100%{
        opacity:0
    }
  }
  @keyframes emoji-animation-local-opacity{
    0%{
        opacity:.5
    }
    30%{
        opacity:1
    }
    70%{
        opacity:0
    }
    100%{
        opacity:0
    }
  }
.steps-content {
    min-height: 200px;
    margin-top: 16px;
    padding-top: 80px;
    text-align: center;
    background-color: #fafafa;
    border: 1px dashed #e9e9e9;
    border-radius: 2px;
  }
  
  .steps-action {
    margin-top: 24px;
  }

  // @media only screen and (max-width: 600px) {
  //   .ant-steps-vertical{
  //     flex-direction: row;
  //   }
  // }
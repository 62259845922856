#frame {
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
#frame .content .messages>div+button {
  background-color: transparent;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoV2luZG93cykiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Rjc2QjlGQTk1NDQ1MTFFRDhCNzdGODI3QUZFQzY1MEQiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Rjc2QjlGQUE1NDQ1MTFFRDhCNzdGODI3QUZFQzY1MEQiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpGNzZCOUZBNzU0NDUxMUVEOEI3N0Y4MjdBRkVDNjUwRCIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpGNzZCOUZBODU0NDUxMUVEOEI3N0Y4MjdBRkVDNjUwRCIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PkyqUd0AAAEtSURBVHjaYvz//z8DPtDW1sYGpCYBsQcQ7wfirKqqqu+41DMxEAa6QJwOxPJAnADEpvgUE2MgBwE+yQb+I8An2UBGAnySDfxPgD/qQiq7kAUtV1gBqRIgfgbEtcAc8R6XC4FquYFUMxArAPFkoNr9GAYCwQIgVoWyDYGafID0FzQ1X6GGbQZiR5haIFbE5uWXSGyQa7cCsToQ/0XyLkjjJiTDQOANVi8DQRwQb4TmXxCwRGLDwHQg5kXi3wDiWHh4oJc2QO9IAqn1QGxORISdB+JAYPg9xBnLQMnnQMobWlThA0dBRRqyYTiTDVDRWyAVAMRbcBi2C2QpUN0rotMhUPEnIBUKxCvRpNaBLAPKf8SaC4gosZmBVD80GLYDcSHQsN84sxUhA0kFAAEGAF57V73/H6KmAAAAAElFTkSuQmCC);
  background-position: 50%;
  background-repeat: no-repeat;
  bottom: 35px;
  z-index: 100;
}
.message-input{
  .logged-in-name{
    font-size: 0.875rem;
    padding-bottom: 5px;
    span{
      font-family: Nunito Sans,sans-serif!important;
      font-weight: 700;
    }
  }
}
.message-ul{
  li{
    &.brand{
      .message-box-main{
        .msg-content{
          // border-left: solid 4px #010b40;
          background: #010b40;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          .user-name{
            color: #FFF;
            &:after{
              content: "\f14a";
              font-family: 'FontAwesome';
              margin-left: 5px;
            }
          }
          .user-message{
            color: #FFF;
          }
        }
      }
    }
    &.influencer{
      .message-box-main{
        .msg-content{
          border-left: solid 4px #010b40;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
        }
      }
    }
  }
}
.message-box-main{
display: flex;
width: 100%;
.msg-content{
  padding: 8px;
  border-radius: 0 7px 7px 7px;
  color: #000;
  max-width: 90%;
  background-color: #FFF;
  .user-name{
    font-weight: 700;
    font-family: Nunito Sans,sans-serif!important;
    margin-right: 10px;
  }
}
}


#frame .content {
  float: right;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}
// @media screen and (max-width: 735px) {
//   #frame .content {
//     width: calc(100% - 58px);
//     min-width: 300px !important;
//   }
// }

#frame .content .contact-profile {
  width: 100%;
  height: 60px;
  line-height: 60px;
  background: #f5f5f5;
}
#frame .content .contact-profile img {
  width: 40px;
  border-radius: 50%;
  float: left;
  margin: 9px 12px 0 9px;
}
#frame .content .contact-profile p {
  float: left;
}
#frame .content .contact-profile .social-media {
  float: right;
}
#frame .content .contact-profile .social-media i {
  margin-left: 14px;
  cursor: pointer;
}
#frame .content .contact-profile .social-media i:nth-last-child(1) {
  margin-right: 20px;
}
#frame .content .contact-profile .social-media i:hover {
  color: #435f7a;
}
#frame .content .messages {
  height: 722px;
  min-height: calc(100% - 70px);
  max-height: calc(100% - 70px);
  overflow-y: scroll;
  overflow-x: hidden;
}
@media screen and (max-width: 735px) {
  #frame .content .messages {
    max-height: calc(100% - 105px);
  }
}
#frame .content .messages::-webkit-scrollbar {
  width: 8px;
  background: transparent;
}
#frame .content .messages::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
}
#frame .content .messages ul li {
 margin-bottom: 3px;
 padding: 0;
}
.message-ul{
  padding: 0px;
  margin: 0px;
  display: flex;
  flex-direction: column;
}
#frame .content .messages .msg-date{
 text-align: right;
 position: relative;
 bottom: -10px;
}
#frame .content .messages .msg-content {
  // display: inline-grid;
  margin: 0;
}
#frame .content .messages ul li:nth-last-child(1) {
  margin-bottom: 20px;
}
#frame .content .messages ul li.sent img {
  margin: 6px 8px 0 0;
}
#frame .content .messages ul li.sent p {
  background: #FFF;
  color: #000;
}
#frame .content .messages ul li.replies img {
  float: right;
  margin: 6px 0 0 8px;
}
#frame .content .messages ul li.replies p {
  background: #FFF;
  color: #000;
  float: left;
}
#frame .content .messages ul li img {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  float: left;
}

#frame .content .messages ul li p {
  display: inline-block;
  padding: 8px;
  border-radius: 0 7px 7px 7px;
  max-width: 90%;
}
@media screen and (min-width: 735px) {
  #frame .content .messages ul li p {
    max-width: 90%;
  }
}
#frame .content .message-input {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
}
#frame .content .message-input .wrap {
  position: relative;
}
#frame .content .message-input .wrap input {
  font-family: "proxima-nova",  "Source Sans Pro", sans-serif;
  float: left;
  border: none;
  width: 86%;
  padding: 12px 5px;
  color: #32465a;
}
// @media screen and (max-width: 735px) {
//   #frame .content .message-input .wrap input {
//     padding: 15px 32px 16px 8px;
//   }
// }
#frame .content .message-input .wrap input:focus {
  outline: none;
}
#frame .content .message-input .wrap .attachment {
  position: absolute;
  right: 60px;
  z-index: 4;
  margin-top: 10px;
  font-size: 1.1em;
  color: #435f7a;
  opacity: .5;
  cursor: pointer;
}
@media screen and (max-width: 735px) {
  #frame .content .message-input .wrap .attachment {
    margin-top: 17px;
    right: 65px;
  }
}
#frame .content .message-input .wrap .attachment:hover {
  opacity: 1;
}
#frame .content .message-input .wrap button {
  float: right;
  border: none;
  width: 50px;
  padding: 12px 0;
  cursor: pointer;
  background: #32465a;
  color: #f5f5f5;
}
// @media screen and (max-width: 735px) {
//   #frame .content .message-input .wrap button {
//     padding: 16px 0;
//   }
// }
#frame .content .message-input .wrap button:hover {
  background: #435f7a;
}
#frame .content .message-input .wrap button:focus {
  outline: none;
}
.cart-drawer-main {
	// z-index: 9999;
	z-index: 999;
	.ant-drawer-content-wrapper {
		width: 531px!important;
	}
	.ant-drawer-body {
		padding: 0;
	}
	.ant-drawer-title {
		font-weight: 700;
		font-size: 18px;
		font-family: Nunito Sans, sans-serif !important;
		display: flex;
		justify-content: flex-end;
		padding-right: 15px;
	}
	.ant-drawer-close {
		// position: absolute;
		position: relative;
		top: -2px;
		left: 0;
		font-size: 18px;
		color: #000;
	}	
	.basket-icon {
		display: flex;
		// width: 100%;
		// height: 100%;
		justify-content: center;
		align-items: center;
		position: relative;
		img {
			width: 30px;
			position: absolute;
		}
		.cart-count {
			background: #000;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			position: absolute;
			top: -15px;
			color: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			font-size: 10px;
			z-index: 2;
			font-family: "Nunito Sans", sans-serif !important;
			font-weight: 600;
			left: 8px;
		}
	}
	.pro-list-main {
		height: calc(100vh - 133px);
		position: relative;
		padding-top: 10px;
		h2 {
			font-size: 1.15rem;
			font-weight: 800 !important;
			padding: 0 20px;
		}
		.pro-list-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 20px;
			list-style-type: none;
			overflow-y: auto;
			max-height: calc(100% - 0px);
			height: 100%;
			padding: 0 10px;
			.cart-empty {
				padding-left: 10px;
			}
	
			&::-webkit-scrollbar {
				width: 6px;
				height: 6px;
			}
			&::-webkit-scrollbar-track {
				transition-duration: 0.3s;
				box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
				border-radius: 10px;
			}
			&::-webkit-scrollbar-thumb {
				transition-duration: 0.3s;
				box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
				border-radius: 10px;
			}
			&:hover::-webkit-scrollbar-track {
				box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
			}
			&:hover::-webkit-scrollbar-thumb {
				box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
			}
	
			.list-col {
				width: 100%;
				display: inline-flex;
				flex-direction: column;
				margin-bottom: 10px;
				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
				}
	
				.prod-box {
					background-color: #fff;
					display: inline-flex;
					min-width: 100%;
					position: relative;
					border: solid 1px #d9d9d9;
					border-radius: 8px;
					padding: 10px;
					.prod-image {
						width: 65px;
						flex-basis: 65px;
						position: relative;
						overflow: hidden;
						transition: box-shadow 0.25s;
						flex-grow: 0;
						flex-shrink: 0;
						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					.prod-name {
						display: flex;
						flex-grow: 1;
						margin-left: 25px;
						min-width: 0px;
						flex-direction: column;
						width: 100%;
						padding-right: 15px;
						.prod-title {
							display: flex;
							flex-direction: column;
							.prod-name-inr {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
								font-size: 0.85rem;
								line-height: 18px;
								display: -webkit-box;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: normal;
								overflow-wrap: normal;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
							}
							.prod-veriants-inr {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
								font-size: 12px;
								line-height: 18px;
							}
						}
						.prod-price {
							display: flex;
							margin-top: 5px;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #000;
								&.original-price {
									color: #f00;
									margin-left: 0px;
									margin-right: 4px;
									text-decoration-line: line-through;
								}
							}
						}
						.prod-inf-ref-fee {
							display: flex;
							width: 100%;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #f00;
								font-size: 13px;
							}
						}
						.prod-cash-back-fee {
							display: flex;
							width: 100%;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: rgb(50, 102, 20);
								font-size: 13px;
							}
						}
						.prod-creator-fee {
							display: flex;
							width: 100%;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #000080;
								font-size: 13px;
							}
						}
						.qty-rmv-btn {
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
							margin-top: 5px;
							.remove-btn {
								color: #000;
								font-size: 12px;
								text-decoration: underline;
							}
						}
					}
					.prod-cart-btn {
						display: flex;
						flex-direction: column;
						margin-right: 0;
						gap: 10px;
						a {
							background-color: #000080;
							border-radius: 37px;
							color: #fff;
							cursor: pointer;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 12px;
							font-weight: 700;
							padding: 6px 0;
							min-width: 80px;
							text-align: center;
							&:hover {
								background-color: #000000;
							}
							&.share-product-oncart {
								background-color: #fff;
								color: #000;
								border: solid 1px #ccc;
								transition: none;
								&:hover {
									background-color: #000000;
									color: #fff;
								}
							}
						}
					}
				}
			}
			&.list-gap {
				// max-height: calc(100% - 90px);
				max-height: calc(100% - 340px);
			}
		}
		.checkout-buttons {
			display: flex;
			width: 100%;
			background-color: #fff;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 10px 20px;
			border-radius: 0 0 8px 8px;
			flex-direction: column;
			.grand-total {
				display: flex;
				width: 100%;
				justify-content: space-between;
				margin-bottom: 10px;
				.total-price {
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
				}
			}
			button {
				width: 100%;
				padding: 0.7rem 1rem;
				background-color: #000080;
				border-color: #000080;
				font-family: Nunito Sans, sans-serif !important;
				font-weight: 700;
				&:hover {
					background-color: #000000;
					border-color: #000000;
				}
			}
	
			.checkout-discount-section {
				// display: flex;
				width: 100%;
				gap: 15px;
				margin-top: 10px;
				padding-bottom: 20px;
				border-bottom: solid 1px #d9d9d9;
				margin-bottom: 20px;
				.ant-form {
					width: 100%;
					display: flex;
					gap: 10px;
					.ant-form-item {
						margin-bottom: 0;
					}
					.float-label {
						width: 100%;
						.ant-input {
							height: 45px;
							border-radius: 4px;
						}
					}
					.btn-primary {
						background-color: #000080;
						border-color: #000080;
						width: initial;
						&:hover {
							background-color: #000000;
							border-color: #000000;
						}
					}
				}
			}
		}
		&.pro-list-main-cart {
			height: calc(100vh - 64px);
		}
	
		.checkout-order-total {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 5px;
			.subtotal,
			.shipping {
				display: flex;
				width: 100%;
				justify-content: space-between;
				span {
					&:last-child {
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						color: #000;
					}
				}
			}
			.grand-total {
				display: flex;
				width: 100%;
				justify-content: space-between;
				border-top: solid 1px #d9d9d9;
				padding-top: 10px;
				margin-top: 15px;
				.gr-total {
					font-size: 16px;
				}
				.gr-t-price {
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
					color: #000;
					font-size: 22px;
				}
			}
		}
	}
	.checkout-frame {
		display: flex;
		width: 100%;
		background-color: #fff;
		position: absolute;
		height: calc(100vh - 86px);
		overflow-y: auto;
		// margin-top: 43px;

		&::-webkit-scrollbar {
			width: 6px;
			height: 6px;
		}
		&::-webkit-scrollbar-track {
			transition-duration: 0.3s;
			box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb {
			transition-duration: 0.3s;
			box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
			border-radius: 10px;
		}
		&:hover::-webkit-scrollbar-track {
			box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
		}
		&:hover::-webkit-scrollbar-thumb {
			box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
		}
		iframe {
			width: 100%;
			border: none;
		}
		.order-summery {
			width: 100%;
			.ant-collapse {
				border: none;
			}
			.ant-collapse-content {
				background-color: #fafafa;
			}
			.ant-collapse-header {
				padding: 13px 10px 13px 10px;
				border-radius: 0;
			}
			.ant-collapse-expand-icon {
				span {
					vertical-align: 0;
				}
			}
			.total-price-top {
				position: absolute;
				z-index: 2;
				font-family: "Nunito Sans", sans-serif !important;
				font-weight: 700;
				color: #000;
				font-size: 20px;
				right: 0;
				top: 0;
				padding: 8px 16px;
			}
			.ordered-products {
				width: 100%;
				.list-col {
					width: 100%;
					display: inline-flex;
					flex-direction: column;
					margin-bottom: 10px;
					&:last-child {
						padding-bottom: 0;
						border-bottom: none;
					}

					.prod-box {
						background-color: #fff;
						display: inline-flex;
						min-width: 100%;
						position: relative;
						border: solid 1px #d9d9d9;
						border-radius: 8px;
						padding: 10px;
						.prod-image {
							width: 65px;
							flex-basis: 65px;
							position: relative;
							transition: box-shadow 0.25s;
							flex-grow: 0;
							flex-shrink: 0;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: cover;
								object-position: center;
							}
							.pro-qty {
								position: absolute;
								background: rgba(114, 114, 114, 0.9);
								color: #fff;
								height: 1.75em;
								min-width: 1.75em;
								border-radius: 1.75em;
								display: flex;
								font-size: 0.85rem;
								justify-content: center;
								align-items: center;
								right: -5px;
								top: -6px;
								font-family: "Nunito Sans", sans-serif !important;
							}
						}
						.prod-name {
							display: flex;
							flex-grow: 1;
							margin-left: 25px;
							min-width: 0px;
							flex-direction: column;
							width: 100%;
							padding-right: 15px;
							.prod-title {
								display: flex;
								flex-direction: column;
								.prod-name-inr {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
									font-size: 0.85rem;
									line-height: 18px;
									display: -webkit-box;
									max-width: 100%;
									overflow: hidden;
									text-overflow: ellipsis;
									white-space: normal;
									overflow-wrap: normal;
									-webkit-box-orient: vertical;
									-webkit-line-clamp: 2;
								}
								.prod-veriants-inr {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
									font-size: 0.85rem;
									line-height: 18px;
								}
							}
							.prod-price {
								display: flex;
								margin-top: 5px;
								span {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 800;
									color: #000;
									&.original-price {
										color: #f00;
										margin-left: 10px;
										text-decoration-line: line-through;
									}
								}
							}
							.qty-rmv-btn {
								display: flex;
								width: 100%;
								justify-content: space-between;
								align-items: center;
								margin-top: 5px;
								.remove-btn {
									color: #000;
									font-size: 12px;
									text-decoration: underline;
								}
							}
						}
						.prod-cart-btn {
							display: flex;
							flex-direction: column;
							margin-right: 0;
							a {
								background-color: #000080;
								border-radius: 37px;
								color: #fff;
								cursor: pointer;
								font-family: Nunito Sans, sans-serif !important;
								font-size: 12px;
								font-weight: 700;
								padding: 6px 0;
								min-width: 80px;
								text-align: center;
								&:hover {
									background-color: #000;
								}
							}
						}
					}
				}
			}
			.checkout-discount-section {
				// display: flex;
				width: 100%;
				gap: 15px;
				margin-top: 10px;
				padding-bottom: 20px;
				border-bottom: solid 1px #d9d9d9;
				margin-bottom: 20px;
				.ant-form {
					width: 100%;
					display: flex;
					gap: 10px;
					.ant-form-item {
						margin-bottom: 0;
					}
					.float-label {
						width: 100%;
						.ant-input {
							height: 45px;
							border-radius: 4px;
						}
					}
					.btn-primary {
						background-color: #000080;
						border-color: #000080;
						&:hover {
							background-color: #000000;
							border-color: #000000;
						}
					}
				}
			}
			.checkout-order-total {
				width: 100%;
				display: flex;
				flex-direction: column;
				gap: 5px;
				.subtotal,
				.shipping {
					display: flex;
					width: 100%;
					justify-content: space-between;
					span {
						&:last-child {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
						}
					}
				}
				.grand-total {
					display: flex;
					width: 100%;
					justify-content: space-between;
					border-top: solid 1px #d9d9d9;
					padding-top: 10px;
					margin-top: 15px;
					.gr-total {
						font-size: 16px;
					}
					.gr-t-price {
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						color: #000;
						font-size: 22px;
					}
				}
			}
			.customer-information {
				width: 100%;
				margin-top: 10px;
				padding: 0 10px;
				.ant-tabs-top {
					.ant-tabs-nav {
						&:before {
							display: none;
						}
					}
				}
				.ant-tabs-nav-wrap {
					.ant-tabs-nav-list {
						width: auto;
						.ant-tabs-tab {
							box-shadow: none;
							width: auto;
							border: none;
							position: relative;
							padding-right: 15px;
							&:after {
								content: "\f105";
								font-family: "FontAwesome";
								position: absolute;
								right: 0;
								font-size: 15px;
							}
							&:nth-child(2) {
								&:after {
									display: none;
								}
							}
							&:hover {
								text-decoration: underline;
							}
						}
						.ant-tabs-ink-bar {
							display: none;
						}
					}
				}
				.checkout-stage-one {
					width: 100%;
					margin-top: 20px;
					h2 {
						font-size: 1.15rem;
						font-weight: 700 !important;
						padding: 0;
					}
					.ant-form {
						margin-top: 15px;
					}
					.inf-column {
						display: flex;
						width: 100%;
						padding: 8px 0;
						.float-label {
							width: 100%;
							.ant-input-lg,
							.ant-select-selector {
								height: 45px;
								border-radius: 4px;
							}
						}
					}
					.inf-button {
						width: 100%;
						margin-top: 10px;
						margin-bottom: 15px;
						button {
							width: 100%;
							padding: 0.7rem 1rem;
							background-color: #000080;
							border-color: #000080;
							font-family: Nunito Sans, sans-serif !important;
							font-weight: 700;
							&:hover {
								background-color: #000;
							}
						}
					}
				}
				.checkout-stage-two {
					width: 100%;
					margin-top: 20px;
					h2 {
						font-size: 1.15rem;
						font-weight: 700 !important;
						padding: 0;
					}
					.filed-detail {
						display: flex;
						flex-direction: column;
						border: solid 1px #d9d9d9;
						padding: 10px;
						border-radius: 4px;
						.contact-email {
							display: flex;
							flex-direction: column;
							position: relative;
							.contc-1 {
								color: #737373;
							}
							.contact-2 {
								strong {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
								}
							}
							a {
								position: absolute;
								top: 0;
								right: 10px;
								&:hover {
									text-decoration: underline;
								}
							}
						}
						.shipp-email {
							border-top: solid 1px #d9d9d9;
							padding-top: 10px;
							margin-top: 10px;
							a {
								top: 6px;
							}
						}
					}
					.more-addresses {
						width: 100%;

						h2 {
							font-size: 1.15rem;
							font-weight: 700 !important;
							padding: 0;
						}
						.slick-slider {
							margin-left: -5px;
							margin-right: -5px;
							.slick-list {
								.slick-track {
									margin: 0;
									.slick-slide {
										padding: 0 5px;
										// min-width: 256px;
										min-width: 248px;
									}
								}
							}
						}
						.address-box {
							border: solid 1px #d9d9d9;
							min-height: 135px;
							border-radius: 4px;
							padding: 16px;
							position: relative;
							cursor: pointer;
							&.dflt {
								border-color: #000080;
							}
							.address-inner-detail {
								display: flex;
								width: 100%;
								flex-direction: column;
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								font-size: 0.854rem;
								h4 {
									font-size: 1rem;
									font-weight: 700 !important;
									color: #737373;
								}
							}
							.edit-address {
								position: absolute;
								bottom: 16px;
								right: 16px;
								font-size: 16px;
								display: flex;
								align-items: center;
								i {
									cursor: pointer;
									margin-right: 15px;
								}
							}
						}
					}
					.select-shipping-rate {
						width: 100%;
						margin-top: 30px;
						.shipping-box {
							display: flex;
							flex-direction: column;
							border: solid 1px #d9d9d9;
							border-radius: 4px;
							position: relative;
							.ant-radio-wrapper {
								border-bottom: solid 1px #d9d9d9;
								width: 100%;
								padding: 12px 10px;
								&:last-child {
									border: none;
								}
								.ship-rates {
									display: flex;
									flex-direction: column;
									width: 250px;
									&.edit-address {
										width: 350px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: normal;
										overflow-wrap: normal;
										-webkit-box-orient: vertical;
										-webkit-line-clamp: 1;
										display: -webkit-box;
									}
									.small-text {
										color: #737373;
										font-size: 0.854rem;
									}
								}
								.ship-rates-right {
									position: absolute;
									right: 10px;
									top: 12px;
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
								}
							}
							&.style2 {
								background-color: #ffffff;
							}
							&.edit-radio {
								.ship-rates-right {
									&:hover {
										text-decoration: underline;
									}
								}
							}
							.ant-spin {
								position: absolute;
								display: flex;
								width: 100%;
								justify-content: center;
								align-items: center;
								height: 100%;
							}
							.edit-ship-address {
								position: absolute;
								display: flex;
								flex-direction: column;
								align-items: flex-end;
								right: 0;
								z-index: 9;
								span {
									padding: 12px 10px;
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									&:hover {
										text-decoration: underline;
										cursor: pointer;
									}
								}
							}
						}
					}
					.checkot-continue-btn {
						width: 100%;
						margin-top: 30px;
						margin-bottom: 20px;
						button {
							width: 100%;
							padding: 0.7rem 1rem;
							background-color: #000080;
							border-color: #000080;
							font-family: Nunito Sans, sans-serif !important;
							font-weight: 700;
							display: flex;
							align-items: center;
							justify-content: center;
							i {
								margin-right: 5px;
							}
							&:hover {
								background-color: #000000;
							}
						}
					}
					.gift-card-checkout {
						width: 100%;
						margin-top: 30px;
						h2 {
							font-size: 1.15rem;
							font-weight: 700 !important;
							padding: 0;
						}
						.checkout-discount-section {
							padding-bottom: 0;
							margin-bottom: 0;
							border: none;
						}
					}
					.select-cradit-card {
						width: 100%;
						margin-top: 30px;
						h2 {
							font-size: 1.15rem;
							font-weight: 700 !important;
							padding: 0;
							margin-bottom: 0;
						}
						p {
							color: #737373;
						}
						.filed-detail {
							padding-top: 15px;
							padding-bottom: 15px;
							.contact-email {
								.contact-2 {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700 !important;
									font-size: 16px;
									i {
										font-size: 16px;
										font-style: normal;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	
}



.btn-style-main {
    .primary-btn {
        background-color: #000080;
        color: #fff;
        border: 0px !important;
        font-family: Nunito Sans, sans-serif !important;
        padding: 9px 20px 8px 20px;
        font-weight: 700;
        border-radius: 5px;
        display: inline-block;
        font-size: 13px;
        cursor: pointer;
        height: initial;
  
      &:hover {
        background-color: #000000;
        border: 0px !important;
        color: #fff;
        box-shadow: none;
      }
    }
  }

.dash_content_profile {
    margin: 0 auto;
    overflow-x: visible;
    padding: 20px 50px;
    transition: opacity .2s ease-out;
    width: 100%;
    min-height: 300px;
    background-color: #fff;
    box-shadow: 0 0 2px 1px #e4e7ed;
}

.upload_area_2.uploadWidth {
    width: 90% !important;
    background: #f3f2f1;
    border: dashed 2px #000080;
    border-radius: 15px;
    &.full-width {
      width: 100% !important;

    }
    &:hover {
        border-color: navy!important;
    }
  }



.select-btn {
    font-family: Nunito Sans, sans-serif !important;
    font-size: 13px;
    font-weight: 400!important;
    margin: 0 5px 0;
    border-radius: 3px;
    color: #ffffff;
    background-color: #000080;
    border: 1px solid #000080;
    min-width: 100px;
    width: auto;
    height: 35px;
    padding: 5px 10px 3px;
    align-items: center;
    -webkit-filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    filter: drop-shadow(0 4px 7px rgba(0, 0, 0, 0.1));
    &:hover {
        background-color: #000;
        border: 1px solid #000;
        color: #fff;
    }
    &:focus {
        background-color: #000;
        border: 1px solid #000;
        color: #fff;
    }
     svg+span {
        line-height: normal;
        margin-left: 8px;
    }
}

.ant-upload-text-icon {
    padding-bottom: 5px;
}

.upload-box-main .input-style.ant-input {
    border: solid 1px #000;
    &:hover {
        border: solid 1px #000;
    }
}
.upload-box-main .select-style .ant-select-selector{
    border: solid 1px #000;
    &:hover {
        border: solid 1px #000;
    }
}



@media only screen and (max-width: 767px) {
.dash_content_profile {
    padding: 20px 20px;    
    min-height: initial;
}
}
.live-event-header-hide {
	.c-portal-header {
		display: none;
	}
	.brd_footer_main {
		display: none;
	}
}
.ant-tooltip-inner {
	background-color: rgba(50, 50, 50, 0.5);
}
.ant-tooltip-arrow-content {
	--antd-arrow-background-color: linear-gradient(to right bottom, rgba(50, 50, 50, 0.5), rgba(50, 50, 50, 0.5));
}
.order-confirmation {
	height: calc(100vh - 83px);
	display: flex;
	align-items: center;
	.col-md-12 {
		h1 {
			font-weight: 700 !important;
			font-size: 1.25rem;
		}
		button {
			background-color: #000080;
			border-color: #000080;
			padding: 6px 15px;
			border-radius: 4px;
			font-size: 14px;
			min-width: 100px;
			color: #fff;
			font-family: Nunito Sans, sans-serif !important;
			font-weight: 700;
			&:hover {
				background-color: #000000;
			}
		}
	}
}
.brand-detail {
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding: 10px;
	display: flex;
	width: 100%;
	background-color: #fff;
	.author-name {
		display: inline-flex;
		align-items: center;
		.auth-image {
			border: 1px solid rgba(0, 0, 0, 0.2);
			border-radius: 50%;
			position: relative;
			width: 40px;
			flex-basis: 40px;
			flex-grow: 0;
			flex-shrink: 0;
			height: 40px;
			overflow: hidden;
			img {
				display: block;
				height: 100%;
				object-fit: contain;
				object-position: center;
				width: 100%;
			}
		}
		.auth-detail {
			display: flex;
			flex-direction: column;
			margin-left: 8px;
			.auth-title {
				color: #000;
				font-family: "Nunito Sans", sans-serif !important;
				font-size: 1rem;
				font-weight: 700;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: normal;
				overflow-wrap: normal;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				display: -webkit-box;
				line-height: 1.65rem;
				margin-top: 0;
			}
			.brand-followers-live-event {
				display: flex;
				gap: 10px;
				.brand-followers-event {
					display: flex;
					gap: 10px;
					span {
						font-family: Nunito Sans, sans-serif !important;
						font-weight: 600;
						color: #fff;
						font-size: 0.875rem;
						strong {
							font-weight: 800;
						}
					}
				}
			}
			.event-name {
				color: #000;
				font-size: 0.875rem;
				// line-height: 1.25rem;
				margin-top: 0;
				font-family: Nunito Sans, sans-serif !important;
				font-weight: 600;
			}
		}
	}
}

.horizontal-camera-view {
	display: flex;
	width: 100%;
	position: relative;
	overflow: hidden;
	padding: 20px;
	background: rgba(0, 0, 0, 0.55);
	.ant-layout {
		background-color: #000;
	}
	.ant-layout-content {
		position: relative;
		.lv-cntr {
			width: 100%;
			padding: 0;
			display: flex;
			height: 100%;
			position: relative;
			.event-ended-main-box {
				width: 100%;
				height: 100%;
				background-color: #000;
				position: absolute;
				z-index: 3;
				display: flex;
				justify-content: center;
				.event-ended-box {
					position: absolute;
					width: 460px;
					max-width: 460px;
					height: 100%;
					z-index: 1;
					display: flex;
					.ended-event-poster {
						width: 100%;
						height: 100%;
						position: relative;
						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
						&.default-image {
							align-items: center;
							justify-content: center;
							display: flex;
							// background: rgba(33, 43, 92, 1);
							// background: -moz-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
							// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33, 43, 92, 1)), color-stop(100%, rgba(0, 12, 64, 1)));
							// background: -webkit-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
							// background: -o-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
							// background: -ms-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
							// background: linear-gradient(to bottom, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
							background: #00008a;
							background: -webkit-gradient(left top, left bottom, color-stop(0, #00008a), color-stop(100%, #000c40));
							background: linear-gradient(180deg, #00008a 0, #000c40);
							// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
							img {
								width: initial;
								height: initial;
								max-width: 100%;
								max-height: 100%;
							}
						}
						.event-ended-detail {
							position: absolute;
							width: 100%;
							height: 100%;
							background: rgba(0, 0, 0, 0.7);
							left: 0;
							top: 0;
							display: flex;
							align-items: center;
							.event-ended-detail-inner {
								display: flex;
								justify-content: center;
								width: 100%;
								align-items: center;
								padding: 0 20px;
								flex-direction: column;
								h2 {
									color: #fff;
									font-size: 1.5rem;
								}
								.ended-ev-title {
									width: 100%;
									text-align: center;
									display: block;
									color: #fff;
									font-family: Nunito Sans, sans-serif !important;
									font-size: 15px;
									margin-top: 20px;
								}
								.ended-auther-name {
									display: flex;
									align-items: center;
									flex-direction: column;
									margin-top: 20px;
									.ended-auth-image {
										width: 66px;
										height: 66px;
										border: solid 1px #fff;
										border-radius: 50%;
										position: relative;
										overflow: hidden;
										img {
											display: block;
											width: 100%;
											height: 100%;
											object-fit: contain;
											object-position: center;
										}
									}
									.ended-auth-title {
										color: #fff;
										font-size: 0.875rem;
										margin-left: 0;
										font-family: "SegoeUI_Medium";
										margin-top: 5px;
									}
								}
								.notify-button {
									width: 100%;
									display: flex;
									justify-content: center;
									margin-top: 20px;
									button {
										background-color: transparent;
										color: #fff;
										border-color: #ccc;
										font-family: Nunito Sans, sans-serif !important;
										font-size: 16px;
										font-weight: 700;
										border-radius: 22px;
										padding-left: 50px;
										padding-right: 50px;
										padding-top: 8px;
										padding-bottom: 8px;
										&:hover {
											background-color: #fff;
											color: #000;
										}
										&:focus {
											border-color: #fff;
											box-shadow: none;
										}
									}
								}
							}
						}
					}
				}
			}
			.MiniPlayer {
				max-width: 100%;
				position: static;
				.MiniPlayer-videoBox {
					border-radius: 0;
					display: flex;
					padding: 0;
					position: absolute;
					height: 100%;
					width: 100%;
					.MinPlayer-video {
						inset: 0 !important;
						border-radius: 0;
						transition: height 200ms ease-in-out 0s, width 200ms ease-in-out 0s;
						video {
							border-radius: 0;
						}
					}
				}
			}
			.drawer-buttons-outer {
				// display: flex;
				// flex: 1;
				// justify-content: space-between;
				// pointer-events: auto !important;
				// flex-direction: column;
				// max-height: 324px;
				// position: absolute;
				// // top: 20px;
				// top: 30px;
				// right: 40px;
				// margin-top: 80px;
				// transition: all 0.2s ease-in-out;
				// padding: 5px;
				// background-color: rgba(50, 50, 50, 0.5);
				// border-radius: 50px;
				// z-index: 2;
				display: flex;
				flex: 1;
				justify-content: flex-end;
				pointer-events: auto !important;
				position: absolute;
				bottom: 0;
				right: 0;
				transition: all 0.2s ease-in-out;
				padding: 5px;
				width: 100%;
				z-index: 2;
				.dr-1 {
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					button {
						width: 40px;
						height: 40px;
						border-radius: 50%;
						line-height: 1;
						// background-color: rgba(0, 0, 0, 0.5);
						background-color: transparent;
						padding: 0;
						transition: opacity 0.3s ease-out;
						transition: background-color 0.2s ease-in-out;
						margin-bottom: 8px;
						font-size: 20px;
						border: none;
						transition: all 0.3s i {
							font-size: 20px;
							color: #fff;
							transition: all 0.3s;
						}
						&:focus {
							box-shadow: none;
						}
						&:active {
							transform: scale(0.8);
						}
					}
					&.dr-like-count {
						display: flex;
						flex-direction: column;
						align-items: center;
						i {
							color: #f00;
						}
						span {
							color: #fff;
							font-size: 0.775rem;
							letter-spacing: 1px;
						}
					}
					&.dr-share-count {
						i {
							color: #ffc107;
						}
					}
					.count-number {
						color: #fff;
						font-size: 0.775rem;
						letter-spacing: 1px;
					}
				}
				&.drawer-for-recorded-events {
					width: auto;
					padding: 0;
					bottom: auto;
					z-index: 1;
					right: 20px;
					top: 48%;
					-ms-transform: translateY(-50%);
					transform: translateY(-50%);
					flex-direction: column;
					background-color: rgba(50, 50, 50, 0.5);
					padding: 5px;
					z-index: 9;
					border-radius: 50px;
					gap: 10px;
					.dr-1 {
						button {
							margin-bottom: 0;
							width: 40px;
							height: 40px;
							background-color: rgba(0, 0, 0, 0.5);
							border-radius: 50px;
							font-size: 20px;
						}
					}
				}
				&.live-bar {
					.dr-1 button {
						height: initial;
						+.count-number {
							top: -7px;
							position: relative;
						}
					}
				}
			}
			.custom-hearts {
				position: absolute;
				z-index: 1;
				bottom: 14px;
				right: 0;
			}
			.brand-detail-overlay {
				position: absolute;
				left: 0;
				top: 0;
				z-index: 2;
				display: flex;
				align-items: center;
				gap: 10px;
				.brand-detail {
					background-color: transparent;
					border: none;
					max-width: 550px;
					.author-name {
						.auth-detail {
							.auth-title {
								color: #fff;
							}
						}
					}
					.event-name {
						color: #fff;
					}
				}
				.live-txt {
					font-family: "Nunito Sans", sans-serif !important;
					letter-spacing: 0;
					color: #ffffff;
					background-color: #e71a1a;
					border-radius: 4px;
					text-align: center;
					padding: 2px 8px 2px 8px;
					margin: 0;
					font-size: 14px;
					right: 5px;
					line-height: initial;
					box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
					font-weight: 700;
				}
				.live-audiance {
					font-family: "Nunito Sans", sans-serif !important;
					letter-spacing: 0;
					color: #ffffff;
					background-color: rgba(50, 50, 50, 0.5);
					border-radius: 4px;
					text-align: center;
					padding: 2px 8px 2px 8px;
					margin: 0;
					font-size: 14px;
					right: 5px;
					line-height: initial;
					box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
					font-weight: 700;
					display: flex;
					align-items: center;
					.anticon-eye {
						margin-right: 5px;
					}
				}
				.promoted {
					display: flex;
					flex-direction: row;
					gap: 5px;
					align-items: center;
					font-size: 12px;
					background-color: green;
					color: white;
					border-radius: 100px;
					padding: 1px 8px;
					-webkit-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
					-moz-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
					box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);

					.promote-icon {
						color: white;
						font-size: 11px;
					}
				}
			}
			.close-live-event {
				position: absolute;
				z-index: 4;
				top: 0;
				right: 40px;
				padding: 5px;
				.close-video {
					background: rgba(50, 50, 50, 0.5);
					color: #fff;
					font-size: 14px;
					padding: 10px;
					border-radius: 100%;
					width: 40px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					.filter-gray {
						filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg) brightness(109%) contrast(101%);
						width: 100%;
						height: 100%;
					}
				}
				.live-txt {
					font-family: "Nunito Sans", sans-serif !important;
					letter-spacing: 0;
					color: #ffffff;
					background-color: #e71a1a;
					border-radius: 100px;
					text-align: center;
					padding: 2px 15px 2px 15px;
					margin: 10px 0 0 0;
					position: absolute;
					font-size: 14px;
					right: 5px;
					line-height: initial;
					box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
					font-weight: 700;
				}
			}
		}
	}
	.ant-layout {
		.ant-layout-sider {
			background-color: #f5f5f5;
			flex: 0 0 531px !important;
			max-width: 531px !important;
			min-width: 531px !important;
			width: 531px !important;
			&.ant-layout-sider-zero-width {
				flex: 0 0 0 !important;
				max-width: 0 !important;
				min-width: 0 !important;
				width: 0 !important;
			}
			.slide-pannel-new {
				width: 100%;
				display: flex;
				flex-direction: column;
				height: 100%;
				.close-slide-panel {
					position: absolute;
					top: 42px;
					left: -45px;
					z-index: 2;
					margin: 0;
					background-color: #fff;
					border-top-right-radius: 0;
					border-bottom-right-radius: 0;
					border-top-left-radius: 50%;
					border-bottom-left-radius: 50%;
					transition: all 0.5s ease 0s;
					height: 50px;
					padding-right: 0;
					font-size: 20px;
					width: 45px;
					display: flex;
					justify-content: center;
					align-items: center;
					cursor: pointer;
					border: 1px solid rgba(0, 0, 0, 0.2);
					border-right: none;
					i {
						position: relative;
						left: 5px;
						font-size: 40px;
					}
				}
				// .ant-tabs {
				//   height: 100%;
				//   .ant-tabs-content-holder {
				//     height: 100%;
				//     .ant-tabs-content {
				//       height: 100%;
				//     }
				//   }
				// }
				.ant-tabs-top {
					.ant-tabs-nav {
						margin-bottom: 0;
						&:before {
							border-bottom: 1px solid rgba(0, 0, 0, 0.2);
						}
						.ant-tabs-nav-operations {
							display: none;
						}
					}
				}
				.ant-tabs-nav-wrap {
					width: 100%;
					.ant-tabs-nav-list {
						width: 100%;
						justify-content: space-between;
						.ant-tabs-tab {
							padding: 10px 10px;
							width: 33.333333333%;
							justify-content: center;
							box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
							border-top: solid 1px rgb(0 0 0 / 10%);
							margin: 0;
							&:hover {
								color: #000080;
							}
							&.ant-tabs-tab-active {
								.ant-tabs-tab-btn {
									color: #000080;
								}
							}
							.basket-icon {
								display: flex;
								width: 100%;
								height: 100%;
								justify-content: center;
								align-items: center;
								position: relative;
								img {
									width: 30px;
									position: absolute;
								}
								.cart-count {
									background: #000;
									width: 15px;
									height: 15px;
									border-radius: 50%;
									position: absolute;
									top: -15px;
									color: #fff;
									display: flex;
									justify-content: center;
									align-items: center;
									font-size: 10px;
									z-index: 2;
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 600;
									left: 8px;
								}
							}
						}
						.ant-tabs-ink-bar {
							background-color: #000080;
						}
					}
				}
				.prod-for-purchase {
					background-color: #fff;
					height: 49px;
					border-bottom: 1px solid rgba(0, 0, 0, 0.2);
					padding: 10px;
					display: flex;
					width: 100%;
					flex-direction: column;
					position: relative;
					h4 {
						font-size: 1.15rem;
						font-weight: 800 !important;
						padding: 0;
						margin: 0;
					}
					.available-for-purchase {
						font-size: 0.75rem;
						margin-top: 1px;
						color: #757575;
						line-height: 1;
					}
					.go-back {
						position: absolute;
						right: 30px;
						top: 14px;
						cursor: pointer;
						&:hover {
							border-bottom: solid 1px #000;
						}
					}
				}
				.brand-description {
					padding: 10px;
					display: flex;
					width: 100%;
					margin-top: 20px;
					height: calc(100vh - 280px);
					p {
						font-size: 1.1rem;
					}
				}
				.live-chat-comp {
					width: 100%;
					display: flex;
					padding: 10px;
					height: calc(100vh - 88px);
					flex-direction: column;
					.ant-comment {
						width: 100%;
					}
				}
				.pro-list-main {
					height: calc(100vh - 133px);
					position: relative;
					padding-top: 10px;
					h2 {
						font-size: 1.15rem;
						font-weight: 800 !important;
						padding: 0 20px;
					}
					.pro-list-inner {
						width: 100%;
						display: flex;
						flex-direction: column;
						padding-top: 20px;
						list-style-type: none;
						overflow-y: auto;
						max-height: calc(100% - 0px);
						height: 100%;
						padding: 0 10px;
						.cart-empty {
							padding-left: 10px;
						}

						&::-webkit-scrollbar {
							width: 6px;
							height: 6px;
						}
						&::-webkit-scrollbar-track {
							transition-duration: 0.3s;
							box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
							border-radius: 10px;
						}
						&::-webkit-scrollbar-thumb {
							transition-duration: 0.3s;
							box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
							border-radius: 10px;
						}
						&:hover::-webkit-scrollbar-track {
							box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
						}
						&:hover::-webkit-scrollbar-thumb {
							box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
						}

						.list-col {
							width: 100%;
							display: inline-flex;
							flex-direction: column;
							margin-bottom: 10px;
							&:last-child {
								padding-bottom: 0;
								border-bottom: none;
							}

							.prod-box {
								background-color: #fff;
								display: inline-flex;
								min-width: 100%;
								position: relative;
								border: solid 1px #d9d9d9;
								border-radius: 8px;
								padding: 10px;
								.prod-image {
									width: 65px;
									flex-basis: 65px;
									position: relative;
									overflow: hidden;
									transition: box-shadow 0.25s;
									flex-grow: 0;
									flex-shrink: 0;
									img {
										display: block;
										width: 100%;
										height: 100%;
										object-fit: cover;
										object-position: center;
									}
								}
								.prod-name {
									display: flex;
									flex-grow: 1;
									margin-left: 25px;
									min-width: 0px;
									flex-direction: column;
									width: 100%;
									padding-right: 15px;
									.prod-title {
										display: flex;
										flex-direction: column;
										.prod-name-inr {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											color: #000;
											font-size: 0.85rem;
											line-height: 18px;
											display: -webkit-box;
											max-width: 100%;
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: normal;
											overflow-wrap: normal;
											-webkit-box-orient: vertical;
											-webkit-line-clamp: 2;
										}
										.prod-veriants-inr {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											color: #000;
											font-size: 12px;
											line-height: 18px;
										}
									}
									.prod-price {
										display: flex;
										margin-top: 5px;
										span {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 800;
											color: #000;
											&.original-price {
												color: #f00;
												margin-left: 0px;
												margin-right: 4px;
												text-decoration-line: line-through;
											}
										}
									}
									.prod-inf-ref-fee {
										display: flex;
										width: 100%;
										span {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 800;
											color: #f00;
											font-size: 13px;
										}
									}
									.prod-cash-back-fee {
										display: flex;
										width: 100%;
										span {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 800;
											color: rgb(50, 102, 20);
											font-size: 13px;
										}
									}
									.prod-creator-fee {
										display: flex;
										width: 100%;
										span {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 800;
											color: #000080;
											font-size: 13px;
										}
									}
									.qty-rmv-btn {
										display: flex;
										width: 100%;
										justify-content: space-between;
										align-items: center;
										margin-top: 5px;
										.remove-btn {
											color: #000;
											font-size: 12px;
											text-decoration: underline;
										}
									}
								}
								.prod-cart-btn {
									display: flex;
									flex-direction: column;
									margin-right: 0;
									gap: 10px;
									a {
										background-color: #000080;
										border-radius: 37px;
										color: #fff;
										cursor: pointer;
										font-family: Nunito Sans, sans-serif !important;
										font-size: 12px;
										font-weight: 700;
										padding: 6px 0;
										min-width: 80px;
										text-align: center;
										&:hover {
											background-color: #000000;
										}
										&.share-product-oncart {
											background-color: #fff;
											color: #000;
											border: solid 1px #ccc;
											transition: none;
											&:hover {
												background-color: #000000;
												color: #fff;
											}
										}
									}
								}
							}
						}
						&.list-gap {
							// max-height: calc(100% - 90px);
							max-height: calc(100% - 340px);
							&.gap-new {
								max-height: calc(100% - 235px);
							}
						}
					}
					.checkout-buttons {
						display: flex;
						width: 100%;
						background-color: #fff;
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 10px 20px;
						border-radius: 0 0 8px 8px;
						flex-direction: column;
						.grand-total {
							display: flex;
							width: 100%;
							justify-content: space-between;
							margin-bottom: 10px;
							.total-price {
								font-family: Nunito Sans, sans-serif !important;
								font-weight: 700;
							}
						}
						button {
							width: 100%;
							padding: 0.7rem 1rem;
							background-color: #000080;
							border-color: #000080;
							font-family: Nunito Sans, sans-serif !important;
							font-weight: 700;
							&:hover {
								background-color: #000000;
								border-color: #000000;
							}
						}

						.checkout-discount-section {
							// display: flex;
							width: 100%;
							gap: 15px;
							margin-top: 10px;
							padding-bottom: 20px;
							border-bottom: solid 1px #d9d9d9;
							margin-bottom: 20px;
							.ant-form {
								width: 100%;
								display: flex;
								gap: 10px;
								.ant-form-item {
									margin-bottom: 0;
								}
								.float-label {
									width: 100%;
									.ant-input {
										height: 45px;
										border-radius: 4px;
									}
								}
								.btn-primary {
									background-color: #000080;
									border-color: #000080;
									width: initial;
									&:hover {
										background-color: #000000;
										border-color: #000000;
									}
								}
							}
						}
					}
					&.pro-list-main-cart {
						height: calc(100vh - 84px);
					}

					.checkout-order-total {
						width: 100%;
						display: flex;
						flex-direction: column;
						gap: 5px;
						.subtotal,
						.shipping {
							display: flex;
							width: 100%;
							justify-content: space-between;
							span {
								&:last-child {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
								}
							}
						}
						.grand-total {
							display: flex;
							width: 100%;
							justify-content: space-between;
							border-top: solid 1px #d9d9d9;
							padding-top: 10px;
							margin-top: 15px;
							.gr-total {
								font-size: 16px;
							}
							.gr-t-price {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
								font-size: 22px;
							}
						}
					}
				}

				.checkout-frame {
					display: flex;
					width: 100%;
					background-color: #fff;
					position: absolute;
					height: calc(100vh - 86px);
					overflow-y: auto;
					// margin-top: 43px;

					&::-webkit-scrollbar {
						width: 6px;
						height: 6px;
					}
					&::-webkit-scrollbar-track {
						transition-duration: 0.3s;
						box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
						border-radius: 10px;
					}
					&::-webkit-scrollbar-thumb {
						transition-duration: 0.3s;
						box-shadow: rgba(0, 0, 0, 30%) 0px 0px 6px inset;
						border-radius: 10px;
					}
					&:hover::-webkit-scrollbar-track {
						box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
					}
					&:hover::-webkit-scrollbar-thumb {
						box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
					}
					iframe {
						width: 100%;
						border: none;
					}
					.order-summery {
						width: 100%;
						.ant-collapse {
							border: none;
						}
						.ant-collapse-content {
							background-color: #fafafa;
						}
						.ant-collapse-header {
							padding: 13px 10px 13px 10px;
							border-radius: 0;
						}
						.ant-collapse-expand-icon {
							span {
								vertical-align: 0;
							}
						}
						.total-price-top {
							position: absolute;
							z-index: 2;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
							font-size: 20px;
							right: 0;
							top: 0;
							padding: 8px 16px;
						}
						.ordered-products {
							width: 100%;
							.list-col {
								width: 100%;
								display: inline-flex;
								flex-direction: column;
								margin-bottom: 10px;
								&:last-child {
									padding-bottom: 0;
									border-bottom: none;
								}

								.prod-box {
									background-color: #fff;
									display: inline-flex;
									min-width: 100%;
									position: relative;
									border: solid 1px #d9d9d9;
									border-radius: 8px;
									padding: 10px;
									.prod-image {
										width: 65px;
										flex-basis: 65px;
										position: relative;
										transition: box-shadow 0.25s;
										flex-grow: 0;
										flex-shrink: 0;
										img {
											display: block;
											width: 100%;
											height: 100%;
											object-fit: cover;
											object-position: center;
										}
										.pro-qty {
											position: absolute;
											background: rgba(114, 114, 114, 0.9);
											color: #fff;
											height: 1.75em;
											min-width: 1.75em;
											border-radius: 1.75em;
											display: flex;
											font-size: 0.85rem;
											justify-content: center;
											align-items: center;
											right: -5px;
											top: -6px;
											font-family: "Nunito Sans", sans-serif !important;
										}
									}
									.prod-name {
										display: flex;
										flex-grow: 1;
										margin-left: 25px;
										min-width: 0px;
										flex-direction: column;
										width: 100%;
										padding-right: 15px;
										.prod-title {
											display: flex;
											flex-direction: column;
											.prod-name-inr {
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700;
												color: #000;
												font-size: 0.85rem;
												line-height: 18px;
												display: -webkit-box;
												max-width: 100%;
												overflow: hidden;
												text-overflow: ellipsis;
												white-space: normal;
												overflow-wrap: normal;
												-webkit-box-orient: vertical;
												-webkit-line-clamp: 2;
											}
											.prod-veriants-inr {
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700;
												color: #000;
												font-size: 0.85rem;
												line-height: 18px;
											}
										}
										.prod-price {
											display: flex;
											margin-top: 5px;
											span {
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 800;
												color: #000;
												&.original-price {
													color: #f00;
													margin-left: 10px;
													text-decoration-line: line-through;
												}
											}
										}
										.qty-rmv-btn {
											display: flex;
											width: 100%;
											justify-content: space-between;
											align-items: center;
											margin-top: 5px;
											.remove-btn {
												color: #000;
												font-size: 12px;
												text-decoration: underline;
											}
										}
									}
									.prod-cart-btn {
										display: flex;
										flex-direction: column;
										margin-right: 0;
										a {
											background-color: #000080;
											border-radius: 37px;
											color: #fff;
											cursor: pointer;
											font-family: Nunito Sans, sans-serif !important;
											font-size: 12px;
											font-weight: 700;
											padding: 6px 0;
											min-width: 80px;
											text-align: center;
											&:hover {
												background-color: #000;
											}
										}
									}
								}
							}
						}
						.checkout-discount-section {
							// display: flex;
							width: 100%;
							gap: 15px;
							margin-top: 10px;
							padding-bottom: 20px;
							border-bottom: solid 1px #d9d9d9;
							margin-bottom: 20px;
							.ant-form {
								width: 100%;
								display: flex;
								gap: 10px;
								.ant-form-item {
									margin-bottom: 0;
								}
								.float-label {
									width: 100%;
									.ant-input {
										height: 45px;
										border-radius: 4px;
									}
								}
								.btn-primary {
									background-color: #000080;
									border-color: #000080;
									&:hover {
										background-color: #000000;
										border-color: #000000;
									}
								}
							}
						}
						.checkout-order-total {
							width: 100%;
							display: flex;
							flex-direction: column;
							gap: 5px;
							.subtotal,
							.shipping {
								display: flex;
								width: 100%;
								justify-content: space-between;
								span {
									&:last-child {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
										color: #000;
									}
								}
							}
							.grand-total {
								display: flex;
								width: 100%;
								justify-content: space-between;
								border-top: solid 1px #d9d9d9;
								padding-top: 10px;
								margin-top: 15px;
								.gr-total {
									font-size: 16px;
								}
								.gr-t-price {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
									font-size: 22px;
								}
							}
						}
						.customer-information {
							width: 100%;
							margin-top: 10px;
							padding: 0 10px;
							.ant-tabs-top {
								.ant-tabs-nav {
									&:before {
										display: none;
									}
								}
							}
							.ant-tabs-nav-wrap {
								.ant-tabs-nav-list {
									width: auto;
									.ant-tabs-tab {
										box-shadow: none;
										width: auto;
										border: none;
										position: relative;
										padding-right: 15px;
										&:after {
											content: "\f105";
											font-family: "FontAwesome";
											position: absolute;
											right: 0;
											font-size: 15px;
										}
										&:nth-child(2) {
											&:after {
												display: none;
											}
										}
										&:hover {
											text-decoration: underline;
										}
									}
									.ant-tabs-ink-bar {
										display: none;
									}
								}
							}
							.checkout-stage-one {
								width: 100%;
								margin-top: 20px;
								h2 {
									font-size: 1.15rem;
									font-weight: 700 !important;
									padding: 0;
								}
								.ant-form {
									margin-top: 15px;
								}
								.inf-column {
									display: flex;
									width: 100%;
									padding: 8px 0;
									.float-label {
										width: 100%;
										.ant-input-lg,
										.ant-select-selector {
											height: 45px;
											border-radius: 4px;
										}
									}
								}
								.inf-button {
									width: 100%;
									margin-top: 10px;
									margin-bottom: 15px;
									button {
										width: 100%;
										padding: 0.7rem 1rem;
										background-color: #000080;
										border-color: #000080;
										font-family: Nunito Sans, sans-serif !important;
										font-weight: 700;
										&:hover {
											background-color: #000;
										}
									}
								}
							}
							.checkout-stage-two {
								width: 100%;
								margin-top: 20px;
								h2 {
									font-size: 1.15rem;
									font-weight: 700 !important;
									padding: 0;
								}
								.filed-detail {
									display: flex;
									flex-direction: column;
									border: solid 1px #d9d9d9;
									padding: 10px;
									border-radius: 4px;
									.contact-email {
										display: flex;
										flex-direction: column;
										position: relative;
										.contc-1 {
											color: #737373;
										}
										.contact-2 {
											strong {
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700;
											}
										}
										a {
											position: absolute;
											top: 0;
											right: 10px;
											&:hover {
												text-decoration: underline;
											}
										}
									}
									.shipp-email {
										border-top: solid 1px #d9d9d9;
										padding-top: 10px;
										margin-top: 10px;
										a {
											top: 6px;
										}
									}
								}
								.more-addresses {
									width: 100%;

									h2 {
										font-size: 1.15rem;
										font-weight: 700 !important;
										padding: 0;
									}
									.slick-slider {
										margin-left: -5px;
										margin-right: -5px;
										.slick-list {
											.slick-track {
												margin: 0;
												.slick-slide {
													padding: 0 5px;
													// min-width: 256px;
													min-width: 248px;
												}
											}
										}
									}
									.address-box {
										border: solid 1px #d9d9d9;
										min-height: 135px;
										border-radius: 4px;
										padding: 16px;
										position: relative;
										cursor: pointer;
										&.dflt {
											border-color: #000080;
										}
										.address-inner-detail {
											display: flex;
											width: 100%;
											flex-direction: column;
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											font-size: 0.854rem;
											h4 {
												font-size: 1rem;
												font-weight: 700 !important;
												color: #737373;
											}
										}
										.edit-address {
											position: absolute;
											bottom: 16px;
											right: 16px;
											font-size: 16px;
											display: flex;
											align-items: center;
											i {
												cursor: pointer;
												margin-right: 15px;
											}
										}
									}
								}
								.select-shipping-rate {
									width: 100%;
									margin-top: 30px;
									.shipping-box {
										display: flex;
										flex-direction: column;
										border: solid 1px #d9d9d9;
										border-radius: 4px;
										position: relative;
										.ant-radio-wrapper {
											border-bottom: solid 1px #d9d9d9;
											width: 100%;
											padding: 12px 10px;
											&:last-child {
												border: none;
											}
											.ship-rates {
												display: flex;
												flex-direction: column;
												width: 250px;
												&.edit-address {
													width: 350px;
													overflow: hidden;
													text-overflow: ellipsis;
													white-space: normal;
													overflow-wrap: normal;
													-webkit-box-orient: vertical;
													-webkit-line-clamp: 1;
													display: -webkit-box;
												}
												.small-text {
													color: #737373;
													font-size: 0.854rem;
												}
											}
											.ship-rates-right {
												position: absolute;
												right: 10px;
												top: 12px;
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700;
											}
										}
										&.style2 {
											background-color: #ffffff;
										}
										&.edit-radio {
											.ship-rates-right {
												&:hover {
													text-decoration: underline;
												}
											}
										}
										.ant-spin {
											position: absolute;
											display: flex;
											width: 100%;
											justify-content: center;
											align-items: center;
											height: 100%;
										}
										.edit-ship-address {
											position: absolute;
											display: flex;
											flex-direction: column;
											align-items: flex-end;
											right: 0;
											z-index: 9;
											span {
												padding: 12px 10px;
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700;
												&:hover {
													text-decoration: underline;
													cursor: pointer;
												}
											}
										}
									}
								}
								.checkot-continue-btn {
									width: 100%;
									margin-top: 30px;
									margin-bottom: 20px;
									button {
										width: 100%;
										padding: 0.7rem 1rem;
										background-color: #000080;
										border-color: #000080;
										font-family: Nunito Sans, sans-serif !important;
										font-weight: 700;
										display: flex;
										align-items: center;
										justify-content: center;
										i {
											margin-right: 5px;
										}
										&:hover {
											background-color: #000000;
										}
									}
								}
								.gift-card-checkout {
									width: 100%;
									margin-top: 30px;
									h2 {
										font-size: 1.15rem;
										font-weight: 700 !important;
										padding: 0;
									}
									.checkout-discount-section {
										padding-bottom: 0;
										margin-bottom: 0;
										border: none;
									}
								}
								.select-cradit-card {
									width: 100%;
									margin-top: 30px;
									h2 {
										font-size: 1.15rem;
										font-weight: 700 !important;
										padding: 0;
										margin-bottom: 0;
									}
									p {
										color: #737373;
									}
									.filed-detail {
										padding-top: 15px;
										padding-bottom: 15px;
										.contact-email {
											.contact-2 {
												font-family: "Nunito Sans", sans-serif !important;
												font-weight: 700 !important;
												font-size: 16px;
												i {
													font-size: 16px;
													font-style: normal;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.mobile-checkout-panel {
	display: none;
	width: 100%;
	// .ant-tabs-content{
	//   min-height: 400px;
	// }
	.login-before-chat {
		height: 100%;
		.load-container-main {
			height: 100%;
		}
	}
	.customer-information {
		width: 100%;
		margin-top: 10px;
		padding: 0 10px;
		.ant-tabs-top {
			.ant-tabs-nav {
				&:before {
					display: none;
				}
			}
		}
		.ant-tabs-nav-wrap {
			.ant-tabs-nav-list {
				width: auto;
				.ant-tabs-tab {
					box-shadow: none;
					width: auto;
					border: none;
					position: relative;
					padding-right: 15px;
					&:after {
						content: "\f105";
						font-family: "FontAwesome";
						position: absolute;
						right: 0;
						font-size: 15px;
					}
					&:nth-child(2) {
						&:after {
							display: none;
						}
					}
					&:hover {
						text-decoration: underline;
					}
				}
				.ant-tabs-ink-bar {
					display: none;
				}
			}
		}
		.checkout-stage-one {
			width: 100%;
			margin-top: 20px;
			h2 {
				font-size: 1.15rem;
				font-weight: 700 !important;
				padding: 0;
			}
			.ant-form {
				margin-top: 15px;
			}
			.inf-column {
				display: flex;
				width: 100%;
				padding: 8px 0;
				.float-label {
					width: 100%;
					.ant-input-lg,
					.ant-select-selector {
						height: 45px;
						border-radius: 4px;
					}
				}
			}
			.inf-button {
				width: 100%;
				margin-top: 10px;
				margin-bottom: 15px;
				button {
					width: 100%;
					padding: 0.7rem 1rem;
					background-color: #000080;
					border-color: #000080;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
					&:hover {
						background-color: #000;
					}
				}
			}
		}
		.checkout-stage-two {
			width: 100%;
			margin-top: 20px;
			h2 {
				font-size: 1.15rem;
				font-weight: 700 !important;
				padding: 0;
			}
			.filed-detail {
				display: flex;
				flex-direction: column;
				border: solid 1px #d9d9d9;
				padding: 10px;
				border-radius: 4px;
				.contact-email {
					display: flex;
					flex-direction: column;
					position: relative;
					.contc-1 {
						color: #737373;
					}
					.contact-2 {
						strong {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
						}
					}
					a {
						position: absolute;
						top: 0;
						right: 10px;
						&:hover {
							text-decoration: underline;
						}
					}
				}
				.shipp-email {
					border-top: solid 1px #d9d9d9;
					padding-top: 10px;
					margin-top: 10px;
					a {
						top: 6px;
					}
				}
			}
			.more-addresses {
				width: 100%;

				h2 {
					font-size: 1.15rem;
					font-weight: 700 !important;
					padding: 0;
				}
				.slick-slider {
					margin-left: -5px;
					margin-right: -5px;
					.slick-list {
						.slick-track {
							margin: 0;
							.slick-slide {
								padding: 0 5px;
								// min-width: 256px;
								min-width: 248px;
							}
						}
					}
				}
				.address-box {
					border: solid 1px #d9d9d9;
					min-height: 135px;
					border-radius: 4px;
					padding: 16px;
					position: relative;
					&.dflt {
						border-color: #000080;
					}
					.address-inner-detail {
						display: flex;
						width: 100%;
						flex-direction: column;
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						font-size: 0.854rem;
						h4 {
							font-size: 1rem;
							font-weight: 700 !important;
							color: #737373;
						}
					}
					.edit-address {
						position: absolute;
						bottom: 16px;
						right: 16px;
						font-size: 16px;
						display: flex;
						align-items: center;
						i {
							cursor: pointer;
							margin-right: 15px;
						}
					}
				}
			}
			.select-shipping-rate {
				width: 100%;
				margin-top: 30px;
				.shipping-box {
					display: flex;
					flex-direction: column;
					border: solid 1px #d9d9d9;
					border-radius: 4px;
					position: relative;
					.ant-radio-wrapper {
						border-bottom: solid 1px #d9d9d9;
						width: 100%;
						padding: 12px 10px;
						&:last-child {
							border: none;
						}
						.ship-rates {
							display: flex;
							flex-direction: column;
							width: 250px;
							.small-text {
								color: #737373;
								font-size: 0.854rem;
							}
							&.edit-address {
								width: 260px;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: normal;
								overflow-wrap: normal;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 1;
								display: -webkit-box;
							}
						}
						.ship-rates-right {
							position: absolute;
							right: 10px;
							top: 12px;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
						}
					}
					&.style2 {
						background-color: #ffffff;
					}
					.ant-spin {
						position: absolute;
						display: flex;
						width: 100%;
						justify-content: center;
						align-items: center;
						height: 100%;
					}
					.edit-ship-address {
						position: absolute;
						display: flex;
						flex-direction: column;
						align-items: flex-end;
						right: 0;
						z-index: 9;
						span {
							padding: 12px 10px;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							&:hover {
								text-decoration: underline;
								cursor: pointer;
							}
						}
					}
				}
			}
			.checkot-continue-btn {
				width: 100%;
				margin-top: 30px;
				margin-bottom: 20px;
				button {
					width: 100%;
					padding: 1rem 1rem;
					background-color: #000080;
					border-color: #000080;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
					&:hover {
						background-color: #000000;
					}
				}
			}
			.gift-card-checkout {
				width: 100%;
				margin-top: 30px;
				h2 {
					font-size: 1.15rem;
					font-weight: 700 !important;
					padding: 0;
				}
				.checkout-discount-section {
					padding-bottom: 0;
					margin-bottom: 0;
					border: none;
				}
			}
			.select-cradit-card {
				width: 100%;
				margin-top: 30px;
				h2 {
					font-size: 1.15rem;
					font-weight: 700 !important;
					padding: 0;
					margin-bottom: 0;
				}
				p {
					color: #737373;
				}
				.filed-detail {
					padding-top: 15px;
					padding-bottom: 15px;
					.contact-email {
						.contact-2 {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700 !important;
							font-size: 16px;
							i {
								font-size: 16px;
								font-style: normal;
							}
						}
					}
				}
			}
		}
	}
	.total-price-top {
		position: absolute;
		z-index: 2;
		font-family: "Nunito Sans", sans-serif !important;
		font-weight: 700;
		color: #000;
		font-size: 20px;
		right: 0;
		top: 0;
		padding: 8px 16px;
	}
	.checkout-order-total {
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 5px;
		.subtotal,
		.shipping {
			display: flex;
			width: 100%;
			justify-content: space-between;
			span {
				&:last-child {
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
					color: #000;
				}
			}
		}
		.grand-total {
			display: flex;
			width: 100%;
			justify-content: space-between;
			border-top: solid 1px #d9d9d9;
			padding-top: 10px;
			margin-top: 15px;
			.gr-total {
				font-size: 16px;
			}
			.gr-t-price {
				font-family: "Nunito Sans", sans-serif !important;
				font-weight: 700;
				color: #000;
				font-size: 22px;
			}
		}
	}
	.checkout-discount-section {
		// display: flex;
		width: 100%;
		gap: 15px;
		margin-top: 10px;
		padding-bottom: 20px;
		border-bottom: solid 1px #d9d9d9;
		margin-bottom: 20px;
		.ant-form {
			width: 100%;
			display: flex;
			gap: 10px;
			.ant-form-item {
				margin-bottom: 0;
			}
			.float-label {
				width: 100%;
				.ant-input {
					height: 45px;
					border-radius: 4px;
				}
			}
			.btn-primary {
				background-color: #000080;
				border-color: #000080;
				max-width: 50px;
			}
		}
	}
	.ordered-products {
		width: 100%;
		.list-col {
			width: 100%;
			display: inline-flex;
			flex-direction: column;
			margin-bottom: 10px;
			&:last-child {
				padding-bottom: 0;
				border-bottom: none;
			}

			.prod-box {
				background-color: #fff;
				display: inline-flex;
				min-width: 100%;
				position: relative;
				border: solid 1px #d9d9d9;
				border-radius: 8px;
				padding: 10px;
				.prod-image {
					width: 65px;
					flex-basis: 65px;
					position: relative;
					transition: box-shadow 0.25s;
					flex-grow: 0;
					flex-shrink: 0;
					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: cover;
						object-position: center;
					}
					.pro-qty {
						position: absolute;
						background: rgba(114, 114, 114, 0.9);
						color: #fff;
						height: 1.75em;
						min-width: 1.75em;
						border-radius: 1.75em;
						display: flex;
						font-size: 0.85rem;
						justify-content: center;
						align-items: center;
						right: -5px;
						top: -6px;
						font-family: "Nunito Sans", sans-serif !important;
					}
				}
				.prod-name {
					display: flex;
					flex-grow: 1;
					margin-left: 25px;
					min-width: 0px;
					flex-direction: column;
					width: 100%;
					padding-right: 15px;
					.prod-title {
						display: flex;
						flex-direction: column;
						.prod-name-inr {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
							font-size: 0.85rem;
							line-height: 18px;
							display: -webkit-box;
							max-width: 100%;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: normal;
							overflow-wrap: normal;
							-webkit-box-orient: vertical;
							-webkit-line-clamp: 2;
						}
						.prod-veriants-inr {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
							color: #000;
							font-size: 0.85rem;
							line-height: 18px;
						}
					}
					.prod-price {
						display: flex;
						margin-top: 5px;
						span {
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 800;
							color: #000;
							&.original-price {
								color: #f00;
								margin-left: 10px;
								text-decoration-line: line-through;
							}
						}
					}
					.qty-rmv-btn {
						display: flex;
						width: 100%;
						justify-content: space-between;
						align-items: center;
						margin-top: 5px;
						.remove-btn {
							color: #000;
							font-size: 12px;
							text-decoration: underline;
						}
					}
				}
				.prod-cart-btn {
					display: flex;
					flex-direction: column;
					margin-right: 0;
					a {
						background-color: #000080;
						border-radius: 37px;
						color: #fff;
						cursor: pointer;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 12px;
						font-weight: 700;
						padding: 6px 0;
						min-width: 80px;
						text-align: center;
						&:hover {
							background-color: #000;
						}
					}
				}
			}
		}
	}

	.checkout-container {
		height: auto;
		.checkout-list-container {
			.checkout-list-inner {
				height: auto;
				.product-check-detail {
					.checkout-buttons {
						position: relative;
						padding: 0;
					}
				}
			}
		}
	}
	.ant-tabs {
		width: 100%;
		.brand-detail {
			padding: 10px 20px;
			.author-name {
				.auth-detail {
					.auth-title {
						font-size: 1rem;
						line-height: 1.25rem;
					}
				}
			}
		}
	}

	.ant-tabs-top {
		.ant-tabs-nav {
			margin-bottom: 0;
			position: fixed;
			z-index: 9;
			background-color: #fff;
			width: 100%;
			&:before {
				border-bottom: 1px solid rgba(0, 0, 0, 0.2);
			}
			.ant-tabs-nav-operations {
				display: none;
			}
		}
		.ant-tabs-content-holder {
			margin-top: 43px;
		}
	}
	.ant-tabs-nav-wrap {
		width: 100%;
		.ant-tabs-nav-list {
			width: 100%;
			justify-content: space-between;
			.ant-tabs-tab {
				padding: 10px 10px;
				width: 33.333333333%;
				justify-content: center;
				box-shadow: 1px 0 0 rgb(0 0 0 / 10%);
				border-top: solid 1px rgb(0 0 0 / 10%);
				margin: 0;
				&:hover {
					color: #000080;
				}
				&.ant-tabs-tab-active {
					.ant-tabs-tab-btn {
						color: #000080;
					}
				}
				.basket-icon {
					display: flex;
					width: 100%;
					height: 100%;
					justify-content: center;
					align-items: center;
					position: relative;
					.cart-count {
						background: #000;
						width: 15px;
						height: 15px;
						border-radius: 50%;
						position: absolute;
						top: -15px;
						color: #fff;
						display: flex;
						justify-content: center;
						align-items: center;
						font-size: 10px;
						z-index: 2;
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 600;
						left: 8px;
					}
					img {
						width: 30px;
						position: absolute;
					}
				}
			}
			.ant-tabs-ink-bar {
				background-color: #000080;
			}
		}
	}
	.prod-for-purchase {
		background-color: #fff;
		height: 50px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.2);
		padding: 10px 20px;
		display: flex;
		width: 100%;
		flex-direction: column;
		position: relative;
		h4 {
			font-size: 1.15rem;
			font-weight: 800 !important;
			padding: 0;
			margin: 0;
		}
		.available-for-purchase {
			font-size: 0.75rem;
			margin-top: 1px;
			color: #757575;
			line-height: 1;
		}
		.go-back {
			position: absolute;
			right: 30px;
			top: 14px;
			cursor: pointer;
			&:hover {
				border-bottom: solid 1px #000;
			}
		}
	}
	.brand-description {
		padding: 10px 20px;
		display: flex;
		width: 100%;
		margin-top: 10px;
		p {
			font-size: 1rem;
		}
	}
	.live-chat-comp {
		width: 100%;
		display: flex;
		padding: 10px;
		margin-top: 0;
		flex-direction: column;
		background: #f5f5f5;
		#frame {
			padding: 0;
			.content {
				.messages > div + button {
					bottom: 5px;
					right: 0;
				}
				.message-input {
					.wrap input {
						width: 83%;
					}
				}
			}
		}
		.ant-comment {
			width: 100%;
		}
	}
	.pro-list-main {
		position: relative;
		padding: 20px 10px;
		h2 {
			font-size: 1.15rem;
			font-weight: 800 !important;
			padding: 0 20px;
		}
		.pro-list-inner {
			width: 100%;
			display: flex;
			flex-direction: column;
			padding-top: 20px;
			list-style-type: none;
			overflow-y: auto;
			padding: 0 10px;
			.cart-empty {
				padding-left: 10px;
			}

			.list-col {
				width: 100%;
				display: inline-flex;
				flex-direction: column;
				margin-bottom: 10px;
				&:last-child {
					padding-bottom: 0;
					border-bottom: none;
				}

				.prod-box {
					background-color: #fff;
					display: inline-flex;
					min-width: 100%;
					position: relative;
					border: solid 1px #d9d9d9;
					border-radius: 8px;
					padding: 10px;
					.prod-image {
						width: 65px;
						flex-basis: 65px;
						position: relative;
						overflow: hidden;
						transition: box-shadow 0.25s;
						flex-grow: 0;
						flex-shrink: 0;
						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}
					.prod-name {
						display: flex;
						flex-grow: 1;
						margin-left: 25px;
						min-width: 0px;
						flex-direction: column;
						width: 100%;
						padding-right: 15px;
						.prod-title {
							display: flex;
							flex-direction: column;
							.prod-name-inr {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
								font-size: 0.85rem;
								line-height: 18px;
								display: -webkit-box;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: normal;
								overflow-wrap: normal;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 2;
							}
							.prod-veriants-inr {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 700;
								color: #000;
								font-size: 12px;
								line-height: 18px;
							}
						}
						.prod-price {
							display: flex;
							margin-top: 5px;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #000;
								&.original-price {
									color: #f00;
									margin-left: 10px;
									text-decoration-line: line-through;
								}
							}
						}
						.prod-inf-ref-fee {
							display: flex;
							width: 100%;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #f00;
								font-size: 13px;
							}
						}
						.prod-cash-back-fee {
							display: flex;
							width: 100%;
							span {
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: rgb(50, 102, 20);
								font-size: 13px;
							}
						}

						.prod-inf-ref-desc {
							display: flex;
							width: 100%;

							.ant-collapse > .ant-collapse-item > .ant-collapse-header {
								padding: 5px 16px;
								font-family: "Nunito Sans", sans-serif !important;
								font-weight: 800;
								color: #000;
								font-size: 13px;

								.ant-collapse-expand-icon {
									line-height: initial;
									position: relative;
									top: -2px;

									.ant-collapse-arrow {
										margin-right: 7px;
									}
								}
							}

							.ant-collapse-content-box {
								font-size: 12px;
								padding: 10px;
								line-height: 17px;
							}
						}

						.qty-rmv-btn {
							display: flex;
							width: 100%;
							justify-content: space-between;
							align-items: center;
							margin-top: 5px;
							.remove-btn {
								color: #000;
								font-size: 12px;
								text-decoration: underline;
							}
						}
					}
					.prod-cart-btn {
						display: flex;
						flex-direction: column;
						margin-right: 0;
						gap: 10px;
						a {
							background-color: #000080;
							border-radius: 37px;
							color: #fff;
							cursor: pointer;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 12px;
							font-weight: 700;
							padding: 6px 0;
							min-width: 80px;
							text-align: center;
							&:hover {
								background-color: #000000;
							}
							&.share-product-oncart {
								background-color: #fff;
								border: 1px solid #ccc;
								color: #000;
								&:hover {
									background-color: #000000;
									color: #fff;
								}
							}
						}
					}
				}
			}
		}
		.checkout-buttons {
			display: flex;
			width: 100%;
			background-color: #fff;
			position: relative;
			bottom: 0;
			left: 0;
			padding: 10px 10px;
			border-radius: 0 0 8px 8px;
			flex-direction: column;
			.grand-total {
				display: flex;
				width: 100%;
				justify-content: space-between;
				margin-bottom: 10px;
				.total-price {
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
				}
			}
			button {
				width: 100%;
				padding: 0.7rem 1rem;
				background-color: #000080;
				border-color: #000080;
				font-family: Nunito Sans, sans-serif !important;
				font-weight: 700;
				&:hover {
					background-color: #000000;
					border-color: #000000;
				}
			}
		}

		.checkout-order-total {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 5px;
			.subtotal,
			.shipping {
				display: flex;
				width: 100%;
				justify-content: space-between;
				span {
					&:last-child {
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						color: #000;
					}
				}
			}
			.grand-total {
				display: flex;
				width: 100%;
				justify-content: space-between;
				border-top: solid 1px #d9d9d9;
				padding-top: 10px;
				margin-top: 15px;
				.gr-total {
					font-size: 16px;
				}
				.gr-t-price {
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
					color: #000;
					font-size: 22px;
				}
			}
		}
	}
}

.filter-modal-box {
	max-width: 580px !important;
	.modal-content {
		.filter-close {
			position: absolute;
			top: 8px;
			right: 8px;
			background: rgb(64, 64, 64);
			color: #fff;
			font-size: 14px;
			padding: 8px;
			border-radius: 100%;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 3;
			.filter-gray {
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg) brightness(109%) contrast(101%);
				width: 100%;
				height: 100%;
			}
		}
		.modal-body {
			padding: 0;
			padding-top: 10px;
			.filter-container {
				width: 100%;
				padding: 14px 24px 10px 24px;
				h3 {
					font-weight: 800 !important;
					font-size: 16px;
					padding: 8px;
					margin: 0;
					line-height: 1;
				}
				.radio-box {
					padding: 8px;
					padding-bottom: 14px;
					display: flex;
					border-bottom: 1px solid rgb(229, 229, 229);
					.ant-radio-group {
						width: 100%;
						margin-top: -10px;
						.ant-space-vertical {
							width: 100%;
							.ant-space-item {
								label {
									width: 100%;
									width: 100%;
									margin-right: 0;
									flex-direction: row-reverse;
									justify-content: space-between;
									span {
										padding: 0;
										font-family: Nunito Sans, sans-serif !important;
										font-weight: 600;
									}
									.ant-radio-checked {
										.ant-radio-inner {
											border-color: #000080;
										}
									}
									.ant-radio-inner {
										width: 24px;
										height: 24px;
										&::after {
											width: 24px;
											height: 24px;
											top: 32%;
											left: 32%;
											background-color: #000080;
										}
									}
									&::after {
										display: none;
									}
								}
							}
						}
					}
				}
				.filter-box {
					padding: 8px;
					padding-bottom: 14px;
					display: grid;
					grid-template-rows: auto;
					grid-template-columns: 1fr 1fr 1fr;
					gap: 8px;
					border-bottom: 1px solid rgb(229, 229, 229);
					button {
						color: rgb(64, 64, 64);
						font-size: 0.875rem;
						display: flex;
						justify-content: center;
						align-items: center;
						text-align: center;
						border-color: #dce4ec;
						background-color: #fff;
						padding: 8px;
						border-radius: 0;
						font-family: "Nunito Sans", sans-serif !important;
						font-weight: 700;
						text-transform: uppercase;
						line-height: 1.25rem;
						position: relative;
						&:hover {
							background-color: #e1e2e4;
						}
						&:focus {
							box-shadow: none;
						}
						&:focus-visible {
							outline: none;
						}
						&.active {
							border-color: #000;
							&:before {
								position: absolute;
								right: -4px;
								top: -7px;
								content: "\f00c";
								font-family: Fontawesome;
								z-index: 1;
								font-size: 10px;
								background: #e80c5b;
								width: 16px;
								height: 16px;
								border-radius: 50%;
								color: #fff;
								font-weight: normal;
							}
						}
					}
				}
				&:last-child {
					.filter-box {
						padding-bottom: 8px;
						border: none;
					}
				}
			}
		}
		.modal-footer {
			justify-content: flex-start;
			padding-left: 24px;

			button {
				background-color: #fff;
				color: #000;
				border-color: #ccc;
				font-family: Nunito Sans, sans-serif !important;
				font-size: 16px;
				font-weight: 700;
				border-radius: 22px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 8px;
				padding-bottom: 8px;
				&:last-child {
					background-color: #000080;
					color: #fff;
					border-color: #000080;
				}
				&:hover {
					background-color: #000000;
					color: #fff;
					border-color: #000000;
				}
			}
		}
	}
}
.live-event-main {
	&.shows-height {
		min-height: 900px;
	}
	width: 100%;
	// min-height: 900px;
	.live-shows {
		width: 100%;
		position: relative;
		.event-filter {
			// position: absolute;
			// right: 0;
			// top: 20px;
			// z-index: 1;
			margin-top: 20px;
			display: flex;
			gap: 10px;
			button {
				background-color: #fff;
				color: #000;
				border-color: #ccc;
				font-family: Nunito Sans, sans-serif !important;
				font-size: 14px;
				font-weight: 700;
				border-radius: 22px;
				padding-left: 20px;
				padding-right: 20px;
				&:focus {
					box-shadow: none;
				}
				&:focus-visible {
					outline: none;
				}
				&:hover {
					background-color: #000080;
					color: #fff;
				}
				&.active {
					background-color: #000080;
					color: #fff;
					border-color: #000080;
					&:focus {
						box-shadow: none;
					}
				}
			}
			a {
				background-color: #fff;
				color: #000;
				border: solid 1px #ccc;
				font-family: Nunito Sans, sans-serif !important;
				font-size: 16px;
				font-weight: 700;
				border-radius: 22px;
				padding-left: 20px;
				padding-right: 20px;
				padding-top: 0.375rem;
				padding-bottom: 0.375rem;
				line-height: 1.5;
				&:hover {
					background-color: #000;
					color: #fff;
				}
			}
		}
		.no_data_main {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			min-height: 400px;
			.no_data_content {
				width: 100%;
				.error_header {
					text-align: center;
					h3 {
						color: #252525;
						font-weight: 800 !important;
						font-size: 30px;
						margin-bottom: 10px;
					}
					p {
						color: #575757;
						font-weight: 400 !important;
						font-size: 20px;
						margin-bottom: 0;
					}
					a {
						background-color: #000080;
						color: #fff;
						font-family: Nunito Sans, sans-serif !important;
						padding: 10px 35px;
						font-weight: 700;
						border-radius: 37px;
						display: inline-block;
						margin-top: 45px;
						font-size: 16px;
						&:hover {
							background-color: #000;
						}
					}
				}
			}
		}
	}

	.event-container {
		width: 100%;
		margin-top: 20px;
		padding-bottom: 30px;
		border-bottom: solid 1px #dce4ec;
		position: relative;
		overflow: hidden;
		.event-header {
			display: flex;
			width: 100%;
			justify-content: space-between;
			margin-bottom: 10px;
			align-items: flex-end;
			h3 {
				font-weight: 800 !important;
				font-size: 1.25rem;
				margin-bottom: 0;
			}
			.event-filter {
				button {
					background-color: #fff;
					color: #000;
					border-color: #ccc;
					font-family: Nunito Sans, sans-serif !important;
					font-size: 16px;
					font-weight: 700;
					border-radius: 22px;
					padding-left: 20px;
					padding-right: 20px;
					&:focus {
						box-shadow: none;
					}
					&:focus-visible {
						outline: none;
					}
					&:hover {
						background-color: #000;
						color: #fff;
					}
					&:active {
						background-color: #000;
						color: #fff;
					}
				}
			}
		}

		.event-grid {
			display: grid;
			grid-row-gap: 32px;
			grid-column-gap: 24px;
			grid-template-columns: repeat(6, 1fr);
			border-bottom: solid 1px #dce4ec;
			padding-bottom: 30px;
		}
		.slick-slider {
			margin-left: -5px;
			margin-right: -5px;
			.slick-list {
				.slick-track {
					margin: 0;
					.slick-slide {
						padding: 0 5px;
						& > div {
							display: flex;
						}
					}
				}
			}
			.slick-prev,
			.slick-next {
				left: auto;
				width: 42px;
				height: 42px;
				background-color: rgb(64, 64, 64);
				color: #fff;
				z-index: 3;
				border-radius: 50%;
				&.slick-disabled {
					display: none !important;
				}
			}
			.slick-prev {
				left: 20px;
				&:before {
					content: "\f104";
					color: #fff;
					font-family: "Fontawesome";
					line-height: normal;
					font-size: 32px;
					position: absolute;
					top: 5px;
					left: -4px;
					right: 0;
					margin: 0 auto;
				}
			}
			.slick-next {
				right: 20px;
				&:before {
					content: "\f105";
					color: #fff;
					font-family: "Fontawesome";
					line-height: normal;
					font-size: 32px;
					position: absolute;
					top: 5px;
					left: 4px;
					right: 0;
					margin: 0 auto;
				}
			}
		}

		.event-col-box {
			position: relative;
			display: block;
			width: 100%;
			padding-top: 133%;
			background: #eee;

			.live-vid-link {
				display: block;
				overflow: hidden;
				.vid-item {
					position: absolute;
					top: 0px;
					left: 0px;
					width: 100%;
					height: 100%;
					background-color: #ddd;
					img {
						width: 100%;
						height: 100%;
						object-fit: cover;
						display: block;
					}
					.vid-item-detail {
						position: absolute;
						display: flex;
						background-color: rgba(0, 0, 0, 0.15);
						width: 100%;
						height: 100%;
						top: 0;
						left: 0;
						padding: 8px;
						flex-direction: column;
						justify-content: space-between;
						.vid-time {
							display: flex;
							justify-content: space-between;
							.left-area{
                .ant-dropdown-trigger {
   
                  font-size: 12px;
                  background-color: rgba(0, 0, 0, 0.6);
                  color: white;
                  border-radius: 100px;
                  padding: 1px 8px;
                  -webkit-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
                  -moz-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
                  box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
                }

								display: flex;
								flex-direction: row;
								gap: 5px;
								.promoted {
									display: flex;
									flex-direction: row;
									gap: 5px;
									align-items: center;
									font-size: 12px;
									background-color: green;
									color: white;
									border-radius: 100px;
									padding: 1px 8px;
									-webkit-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
									-moz-box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);
									box-shadow: 0px 0px 8px -6px rgba(0, 0, 0, 0.75);

              

									.promote-icon {
										color: white;
										font-size: 11px;
									}
									&.liked {
										background-color: #802cb7;
									}
									&.viewed {
										background-color: #4d65ff;
									}
									&.sold {
										background-color: #ed2939;
									}
								}
							}
							span {
								font-size: 0.85rem;
								line-height: 1.25rem;
								font-weight: 800;
								display: inline-flex;
								color: rgb(255, 255, 255);
								padding: 4px 16px;
								border-radius: 44px;
								background: rgba(0, 0, 0, 0.6);
								z-index: 3;
								align-items: center;
								font-family: Nunito Sans, sans-serif !important;
								letter-spacing: 0.02rem;
								&.off-percentage {
									color: #f85c5b;
								}

								&.live-now {
									animation: blinkingText 1.2s infinite;

									@keyframes blinkingText {
										0% {
											color: #f85c5b;
										}
										49% {
											color: #f85c5b;
										}
										60% {
											color: transparent;
										}
										99% {
											color: transparent;
										}
										100% {
											color: #f85c5b;
										}
									}
								}
							}

							.vid-views {
								padding: 4px 6px;
								border-radius: 4px;
								justify-content: center;
								align-items: center;
								i {
									margin-right: 6px;
									font-size: 0.775rem;
								}
							}
						}
						.vid-title {
							.v-title {
								color: #fff;
								font-family: Nunito Sans, sans-serif !important;
								font-weight: 800;
								text-transform: uppercase;
								color: rgb(255, 255, 255);
								display: -webkit-box;
								max-width: 100%;
								overflow: hidden;
								text-overflow: ellipsis;
								white-space: normal;
								overflow-wrap: normal;
								-webkit-box-orient: vertical;
								-webkit-line-clamp: 4;
								font-size: 1.6rem;
								line-height: 1.85rem;
							}
							.author-name {
								display: flex;
								align-items: center;
								margin-top: 15px;
								.auth-left {
									display: flex;
									align-items: center;
								}
								.auth-image {
									width: 32px;
									height: 32px;
									border: solid 1px #fff;
									border-radius: 50%;
									position: relative;
									overflow: hidden;
									img {
										display: block;
										width: 100%;
										height: 100%;
										object-fit: contain;
										object-position: center;
									}
								}
								.auth-right {
									display: flex;
									flex-direction: column;
									font-family: "Nunito Sans", sans-serif !important;
									font-size: 0.85rem;
									color: #fff;
									font-weight: 800;
									color: #fff;
									.ref-fee {
										color: #fff;
									}
									.inf-fee {
										color: #fff;
									}
								}
								.auth-title {
									color: #fff;
									font-size: 0.875rem;
									margin-left: 8px;
									font-family: "SegoeUI_Medium";
								}
							}
						}
					}

					&.default-image {
						align-items: center;
						justify-content: center;
						display: flex;
						background: #00008a;
						background: -webkit-gradient(left top, left bottom, color-stop(0, #00008a), color-stop(100%, #000c40));
						background: linear-gradient(180deg, #00008a 0, #000c40);
						// background: rgba(33, 43, 92, 1);
						// background: -moz-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33, 43, 92, 1)), color-stop(100%, rgba(0, 12, 64, 1)));
						// background: -webkit-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						// background: -o-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						// background: -ms-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						// background: linear-gradient(to bottom, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
						// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
						img {
							width: initial;
							height: initial;
							max-width: 100%;
							max-height: 100%;
						}
					}
				}
			}
		}
		&.popular-channels-sec {
			.event-col-box {
				.live-vid-link {
					.vid-item {
						.vid-item-detail {
							justify-content: flex-end;
						}
					}
				}
			}
		}
	}

	.live-video-container {
		width: 100%;
		background-color: #000;
		position: relative;

		.close-video {
			position: absolute;
			top: 16px;
			right: 16px;
			background: rgb(64, 64, 64);
			color: #fff;
			font-size: 14px;
			padding: 10px;
			border-radius: 100%;
			width: 50px;
			height: 50px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 3;
			.filter-gray {
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg) brightness(109%) contrast(101%);
				width: 70%;
				height: 70%;
			}
		}

		.video-boxes-main {
			width: 100%;
			display: flex;
			// max-height: 718px;
			// height: calc(90vh - 66px);
			height: calc(100vh - 133px);
			justify-content: center;

			.upcoming-poster {
				flex: 0 0 460px;
				max-width: 460px;
				padding: 0;
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
				&.default-image {
					align-items: center;
					justify-content: center;
					display: flex;
					background: #00008a;
					background: -webkit-gradient(left top, left bottom, color-stop(0, #00008a), color-stop(100%, #000c40));
					background: linear-gradient(180deg, #00008a 0, #000c40);
					// background: rgba(33, 43, 92, 1);
					// background: -moz-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
					// background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(33, 43, 92, 1)), color-stop(100%, rgba(0, 12, 64, 1)));
					// background: -webkit-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
					// background: -o-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
					// background: -ms-linear-gradient(top, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
					// background: linear-gradient(to bottom, rgba(33, 43, 92, 1) 0%, rgba(0, 12, 64, 1) 100%);
					// filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212b5c', endColorstr='#000c40', GradientType=0 );
					img {
						width: initial;
						height: initial;
						max-width: 100%;
						max-height: 100%;
					}
				}
			}
			.upcoming-timer {
				min-width: 460px;
				width: calc((100% - 400px) / 2);
				padding: 0;
				position: relative;
				overflow: hidden;
				display: flex;
				justify-content: center;

				.countdown {
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: center;
					padding: 80px 24px 24px;

					.timer-title {
						color: #fff;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 16px;
						margin-bottom: 15px;
					}
					.ant-statistic-content-value {
						width: 320px;
						display: flex;
						justify-content: space-evenly;
						max-width: 320px;
						.dhms {
							position: relative;
							display: flex;
							flex-direction: column;
							-webkit-box-align: center;
							align-items: center;
							width: 100%;
							color: #fff;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 40px;
							font-weight: 800;
							letter-spacing: 2px;
							span {
								font-size: 0.725rem;
								font-weight: 400;
							}
							&:after {
								position: absolute;
								width: 0px;
								height: 24px;
								right: 0px;
								top: 25%;
								content: "";
								border: 1px solid rgb(64, 64, 64);
							}
							&:last-child {
								&:after {
									display: none;
								}
							}
						}
					}
					.rsvp-btns {
						display: flex;
						width: 100%;
						justify-content: center;
						gap: 10px;
						a {
							margin-top: 30px;
							background-color: #fff;
							border: none;
							border-radius: 4px;
							color: #000;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 14px;
							font-weight: 700;
							height: auto;
							padding: 4px 15px;
							text-decoration: none;
							min-width: 80px;
							text-align: center;
						}
					}
					.fee-row {
						color: #fff;
						font-family: Nunito Sans, sans-serif !important;
						display: flex;
						gap: 10px;
						justify-content: center;
						span {
							border: solid 1px #fff;
							padding: 4px 16px;
							border-radius: 44px;
							font-weight: 700;
							min-width: 80px;
							margin-top: 30px;
							text-align: center;
						}
					}
					.notify-button {
						width: 100%;
						display: flex;
						justify-content: center;
						margin-top: 30px;
						button {
							background-color: transparent;
							color: #fff;
							border-color: #ccc;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 16px;
							font-weight: 700;
							border-radius: 22px;
							padding-left: 50px;
							padding-right: 50px;
							padding-top: 8px;
							padding-bottom: 8px;
							&:hover {
								background-color: #fff;
								color: #000;
							}
						}
					}
					.broadcaster-name {
						width: 100%;
						text-align: center;
						display: block;
						color: #fff;
						font-family: Nunito Sans, sans-serif !important;
						font-size: 18px;
						margin-top: 30px;
					}
					.tim-author-name {
						display: flex;
						align-items: center;
						margin-top: 8px;
						flex-direction: column;
						margin-top: 30px;
						.tim-auth-image {
							width: 32px;
							height: 32px;
							border: solid 1px #fff;
							border-radius: 50%;
							position: relative;
							overflow: hidden;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}
						}
						.tim-auth-title {
							color: #fff;
							font-size: 0.875rem;
							margin-left: 0;
							font-family: "SegoeUI_Medium";
							margin-top: 5px;
						}
					}
				}
			}

			.comment-container {
				flex: 0 0 460px;
				max-width: 460px;
				padding: 8px 24px 24px;
				position: relative;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			.lv-video-container {
				flex: 0 0 404px;
				max-width: 404px;
				height: 100%;

				.sc-dbm {
					position: relative;
					height: 100%;
					.dbm1 {
						position: absolute;
						top: 0px;
						left: 0px;
						width: 100%;
						height: 100%;

						.dbm2 {
							position: relative;
							height: 100%;

							.dbm3 {
								position: relative;
								display: flex;
								overflow: hidden;
								-webkit-box-align: center;
								align-items: center;
								-webkit-box-pack: center;
								justify-content: center;
								height: 100%;

								.dbm4 {
									width: 100%;
									height: 100%;
									position: relative;

									.author-name {
										align-items: center;
										display: inline-flex;
										position: absolute;
										z-index: 2;
										top: 16px;
										left: 16px;
										width: 78%;

										.auth-image {
											border: 1px solid #fff;
											border-radius: 50%;
											position: relative;
											width: 50px;
											flex-basis: 56px;
											flex-grow: 0;
											flex-shrink: 0;
											height: 56px;
											overflow: hidden;
											img {
												display: block;
												height: 100%;
												object-fit: contain;
												object-position: center;
												width: 100%;
											}
										}
										.auth-detail {
											display: flex;
											flex-direction: column;
											margin-left: 8px;
											.auth-title {
												color: #fff;
												font-family: SegoeUI_Medium;
												font-size: 0.875rem;
											}
											.event-name {
												-webkit-box-orient: vertical;
												-webkit-line-clamp: 2;
												color: #fff;
												display: -webkit-box;
												font-size: 0.875rem;
												overflow: hidden;
												overflow-wrap: normal;
												text-overflow: ellipsis;
												white-space: normal;
												line-height: 1.25rem;
											}
										}
									}

									video {
										object-fit: cover;
										display: inline-block;
										max-width: 100%;
										border-radius: 0;
										-webkit-transform: scaleX(-1);
										transform: scaleX(-1);
									}
									.live-cam {
										display: flex;
										width: 100%;
										height: 100%;

										canvas {
											object-fit: cover;
											display: inline-block;
											max-width: 100%;
										}
										.start-stop {
											position: absolute;
											top: 0;
											left: 0;
											display: flex;
											margin-top: 5px;
											padding: 10px 20px;

											button {
												margin-right: 15px;
												right: 0;
												color: #fff;
												background-color: #000080;
												border-radius: 37px;
												color: #fff;
												cursor: pointer;
												font-family: Nunito Sans, sans-serif !important;
												font-size: 14px;
												font-weight: 700;
												padding: 6px 15px;
												margin-right: 16px;
												border: none;
												min-width: 70px;
											}
										}
									}
									.lv-cntr {
										padding: 0;
										width: 100%;
										height: 100%;

										.MiniPlayer,
										.MiniPlayer-videoBox,
										.MinPlayer-video {
											width: 100%;
											max-width: 100%;
											height: 100% !important;
											display: flex;
											border-radius: 0;
											padding: 0;
											inset: auto !important;
										}
									}
								}
							}
						}
					}
					*::-webkit-media-controls-panel {
						display: none !important;
						-webkit-appearance: none;
					}
					.mobi-prodts-list {
						position: absolute;
						bottom: 80px;
						right: 20px;
						display: none;
						button {
							border: none;
							font-size: 0.85rem;
							font-weight: 700;
							background: rgb(64, 64, 64);
							color: rgb(255, 255, 255);
							white-space: nowrap;
							display: flex;
							-webkit-box-pack: center;
							justify-content: center;
							-webkit-box-align: center;
							align-items: center;
							padding: 6px 12px;
							border-radius: 16px;
							font-family: Nunito Sans, sans-serif !important;
							&:focus {
								box-shadow: none;
							}
							&:focus-visible {
								outline: none;
							}
						}
					}
				}
			}
			.product-container {
				flex: 0 0 460px;
				max-width: 460px;
				padding: 24px;

				.pro-list-main {
					background-color: #fff;
					height: 100%;
					padding: 20px 0;
					border-radius: 8px;
					position: relative;
					h2 {
						font-size: 1.15rem;
						font-weight: 800 !important;
						padding: 0 20px;
					}
					.pro-list-inner {
						width: 100%;
						display: flex;
						flex-direction: column;
						padding-top: 20px;
						list-style-type: none;
						overflow-y: auto;
						max-height: calc(100% - 1.5rem);
						height: 100%;
						padding: 0 20px;

						&::-webkit-scrollbar {
							width: 6px;
							height: 6px;
						}
						&::-webkit-scrollbar-track {
							transition-duration: 0.3s;
							box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
							border-radius: 10px;
						}
						&::-webkit-scrollbar-thumb {
							transition-duration: 0.3s;
							box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
							border-radius: 10px;
						}
						&:hover::-webkit-scrollbar-track {
							box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
						}
						&:hover::-webkit-scrollbar-thumb {
							box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
						}

						.list-col {
							width: 100%;
							display: inline-flex;
							flex-direction: column;
							border-bottom: solid 1px #dce4ec;
							padding-bottom: 10px;
							margin-bottom: 10px;
							&:last-child {
								padding-bottom: 0;
								border-bottom: none;
							}

							.prod-box {
								background-color: #fff;
								display: inline-flex;
								min-width: 100%;
								align-items: center;
								position: relative;
								.prod-image {
									width: 65px;
									flex-basis: 65px;
									position: relative;
									overflow: hidden;
									transition: box-shadow 0.25s;
									flex-grow: 0;
									flex-shrink: 0;
									img {
										display: block;
										width: 100%;
										height: 100%;
										object-fit: cover;
										object-position: center;
										border-radius: 6px;
									}
								}
								.prod-name {
									display: flex;
									flex-grow: 1;
									margin-left: 25px;
									min-width: 0px;
									flex-direction: column;
									width: 100%;
									padding-right: 15px;
									.prod-title {
										display: flex;
										flex-direction: column;
										.prod-name-inr {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											color: #000;
											font-size: 13px;
											line-height: 18px;
											overflow: hidden;
											text-overflow: ellipsis;
											white-space: nowrap;
											overflow-wrap: normal;
										}
										.prod-veriants-inr {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 700;
											color: #000;
											font-size: 12px;
											line-height: 18px;
										}
									}
									.prod-price {
										display: flex;
										margin-top: 5px;
										span {
											font-family: "Nunito Sans", sans-serif !important;
											font-weight: 800;
											color: #000;
											&.original-price {
												color: #f00;
												margin-left: 10px;
												text-decoration-line: line-through;
											}
										}
									}
									.qty-rmv-btn {
										display: flex;
										width: 100%;
										justify-content: space-between;
										align-items: center;
										margin-top: 5px;
										.remove-btn {
											color: #000;
											font-size: 12px;
											text-decoration: underline;
										}
									}
								}
								.prod-cart-btn {
									display: flex;
									flex-direction: column;
									margin-right: 0;
									a {
										background-color: #000080;
										border-radius: 37px;
										color: #fff;
										cursor: pointer;
										font-family: Nunito Sans, sans-serif !important;
										font-size: 12px;
										font-weight: 700;
										padding: 6px 0;
										min-width: 80px;
										text-align: center;
										&:hover {
											background-color: #000;
										}
									}
								}
							}
						}
						&.list-gap {
							max-height: calc(100% - 5rem);
						}
					}
					.checkout-buttons {
						display: flex;
						width: 100%;
						background-color: #fff;
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 10px 20px;
						border-radius: 0 0 8px 8px;
						button {
							width: 100%;
							padding: 0.7rem 1rem;
							background-color: #000080;
							border-color: #000080;
							font-family: Nunito Sans, sans-serif !important;
							font-weight: 700;
							&:hover {
								background-color: #000;
								border-color: #000;
							}
						}
					}
				}
			}
		}
	}
}

.live-products-modal-box {
	max-width: 580px !important;
	.modal-content {
		.filter-close {
			position: absolute;
			top: 8px;
			right: 8px;
			background: rgb(64, 64, 64);
			color: #fff;
			font-size: 14px;
			padding: 8px;
			border-radius: 100%;
			width: 32px;
			height: 32px;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			z-index: 3;
			.filter-gray {
				filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(139deg) brightness(109%) contrast(101%);
				width: 100%;
				height: 100%;
			}
		}
		.modal-body {
			padding: 0;
			padding-top: 10px;

			.pro-list-main {
				background-color: #fff;
				height: 100%;
				padding: 10px 0;
				border-radius: 8px;

				h2 {
					font-size: 1.15rem;
					font-weight: 800 !important;
					padding: 0 20px;
				}
				.pro-list-inner {
					width: 100%;
					display: flex;
					flex-direction: column;
					padding-top: 20px;
					list-style-type: none;
					overflow-y: auto;
					max-height: calc(100% - 1.5rem);
					height: 100%;
					justify-content: space-between;
					padding: 0 20px;

					&::-webkit-scrollbar {
						width: 6px;
						height: 6px;
					}
					&::-webkit-scrollbar-track {
						transition-duration: 0.3s;
						box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
						border-radius: 10px;
					}
					&::-webkit-scrollbar-thumb {
						transition-duration: 0.3s;
						box-shadow: rgba(0, 0, 0, 0) 0px 0px 6px inset;
						border-radius: 10px;
					}
					&:hover::-webkit-scrollbar-track {
						box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
					}
					&:hover::-webkit-scrollbar-thumb {
						box-shadow: rgb(0 0 0 / 30%) 0px 0px 6px inset;
					}

					.list-col {
						width: 100%;
						display: inline-flex;
						flex-direction: column;
						border-bottom: solid 1px #dce4ec;
						padding-bottom: 10px;
						margin-bottom: 10px;
						&:last-child {
							padding-bottom: 0;
							border-bottom: none;
						}

						.prod-box {
							background-color: #fff;
							display: inline-flex;
							min-width: 100%;
							align-items: center;
							position: relative;
							.prod-image {
								width: 65px;
								flex-basis: 65px;
								position: relative;
								overflow: hidden;
								transition: box-shadow 0.25s;
								flex-grow: 0;
								flex-shrink: 0;
								img {
									display: block;
									width: 100%;
									height: 100%;
									object-fit: cover;
									object-position: center;
									border-radius: 6px;
								}
							}
							.prod-name {
								display: flex;
								flex-grow: 1;
								margin-left: 25px;
								min-width: 0px;
								flex-direction: column;
								width: 100%;
								padding-right: 15px;
								.prod-title {
									display: flex;
									flex-direction: column;
									.prod-name-inr {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 700;
										color: #000;
										font-size: 13px;
										line-height: 18px;
										overflow: hidden;
										text-overflow: ellipsis;
										white-space: nowrap;
										overflow-wrap: normal;
									}
								}
								.prod-price {
									display: flex;
									margin-top: 5px;
									span {
										font-family: "Nunito Sans", sans-serif !important;
										font-weight: 800;
										color: #000;
										&.original-price {
											color: #f00;
											margin-left: 10px;
											text-decoration-line: line-through;
										}
									}
								}
							}
							.prod-cart-btn {
								display: flex;
								flex-direction: column;
								margin-right: 0;
								a {
									background-color: #000080;
									border-radius: 37px;
									color: #fff;
									cursor: pointer;
									font-family: Nunito Sans, sans-serif !important;
									font-size: 12px;
									font-weight: 700;
									padding: 6px 0;
									min-width: 80px;
									text-align: center;
									&:hover {
										background-color: #000;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.accordion-style2 {
	.ant-collapse-content {
		// background-color: white!important;
		.ant-collapse-content-box {
			padding: 0px !important;
			.customer-information {
				margin-top: 0px !important;
				padding: 0 16px !important;
				.checkout-stage-two {
					.filed-detail {
						background-color: white;
					}
					.more-addresses {
						.address-box {
							background-color: white;
						}
					}
				}
			}
		}
	}

	.cd-detail-box {
		width: 100%;
		display: flex;
		padding: 10px 0;
		.ant-form-item {
			margin-bottom: 0;
		}
		&.cd-detail-box-gap {
			gap: 10px;
		}
		.float-label {
			width: 100%;
			.ant-form-item {
				margin-bottom: 0;
			}
			.ant-input-lg {
				height: 45px;
			}
			.ant-select-selector {
				height: 45px;
			}
			label {
				background-color: #fff;
				width: 90%;
				&.label-float {
					width: auto;
				}
			}
		}
		.cd-2col {
			width: 50%;
		}
		.ant-checkbox-inner {
			width: 18px;
			height: 18px;
		}
		button {
			background-color: #000080;
			border-color: #000080;
			color: #fff;
			font-family: Nunito Sans, sans-serif !important;
			font-weight: 700;
			border-radius: 37px;
			display: inline-block;
			font-size: 16px;
			cursor: pointer;
			width: 100%;
			&:hover {
				background-color: #000;
			}
		}
	}
	.terms-para {
		text-align: left;
		font-size: 13px;
		padding-bottom: 20px;
		margin-bottom: 0;
		a {
			color: black;
			text-decoration: underline;
		}
	}
	.credit-bottom {
		text-align: center;
		color: #afafaf;
		margin-bottom: 0;
		padding-bottom: 5px;
	}
	.message-para {
		text-align: center;
		font-size: 13px;
		color: grey;
		margin-bottom: 0;
		line-height: 18px;
	}
}
.form-style2 {
	.ant-form-item-explain-connected {
		position: absolute;
		bottom: -22px;
	}
}

.discount-main {
	display: flex;
	width: 100%;
	justify-content: space-between;
	gap: 10px;
	.discount-left {
		display: flex;
		justify-content: start;
		align-items: center;
		gap: 7px;
		.fa-tags {
			font-size: 16px;
			color: grey;
		}
		p {
			margin-bottom: 0;
		}
	}
	.discount-right {
		display: flex;
		justify-content: end;
		align-items: center;
		gap: 10px;
		.discount-txt {
			margin-bottom: 0;
			font-weight: 700;
		}
		.remove-discount {
			.fa-times {
				color: #e32e2e;
			}
		}
	}
}

.error-wrong-coupon-code {
	// position: absolute;
	left: 0px;
	// bottom: -20px;
}
.prod-discount {
	display: flex;
	align-items: center;
	gap: 4px;
	&.new {
		.discount-tag {
			color: grey;
			font-size: 11px;
		}
		.discount-amount {
			color: grey;
			font-size: 13px;
		}
	}
}
.prod-price-cont {
	display: flex;
	justify-content: space-between;
}
.variant-list {
	display: flex;
	gap: 10px;
}
@media only screen and (max-width: 1600px) {
	.live-event-main {
		.event-container {
			.event-col-box {
				padding-top: 148%;
				.live-vid-link {
					.vid-item {
						.vid-item-detail {
							.vid-title {
								.v-title {
									-webkit-line-clamp: 3;
									font-size: 1.15rem;
									line-height: 1.35rem;
								}
							}
						}
					}
				}
			}
		}
	}
	.horizontal-camera-view .ant-layout .ant-layout-sider {
		background-color: #f5f5f5;
		flex: 0 0 502px !important;
		max-width: 502px !important;
		min-width: 502px !important;
		width: 502px !important;
		.ant-layout-sider-zero-width {
			flex: 0 0 !important;
			max-width: 0 !important;
			min-width: 0 !important;
			width: 0 !important;
		}
	}
}
@media only screen and (max-width: 1450px) {
	.horizontal-camera-view .ant-layout .ant-layout-sider {
		background-color: #f5f5f5;
		flex: 0 0 33% !important;
		max-width: 33% !important;
		min-width: 33% !important;
		width: 33% !important;
		.ant-layout-sider-zero-width {
			flex: 0 0 !important;
			max-width: 0 !important;
			min-width: 0 !important;
			width: 0 !important;
		}
		.slide-pannel-new {
			.checkout-frame {
				.order-summery {
					.customer-information {
						.checkout-stage-two {
							.select-shipping-rate {
								.shipping-box {
									.ant-radio-wrapper {
										.ship-rates {
											&.edit-address {
												width: 300px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.horizontal-camera-view {
		.ant-layout-content {
			.lv-cntr {
				.brand-detail-overlay {
					.brand-detail {
						max-width: 350px;
					}
				}
			}
		}
	}
}
@media only screen and (max-width: 1200px) {
	.live-event-main {
		// .midd-width-ifti {
		//   padding-inline-end: 20px;
		//   padding-inline-start: 20px;
		// }
		.live-video-container {
			.video-boxes-main {
				height: calc(100vh - 133px);
				max-height: calc(100vh - 133px);

				.lv-video-container {
					flex: 0 0 70%;
					max-width: 70%;
				}
				.comment-container {
					display: none;
				}
				.product-container {
					display: none;
				}
				&.upcoming-box-main {
					flex-direction: column;
					justify-content: flex-start;
					position: relative;
					overflow: hidden;
					align-items: center;
					.upcoming-poster {
						flex: 0 0 520px;
						max-width: 520px;
						width: 100%;
						position: absolute;
						height: 100%;
					}
					.upcoming-timer {
						flex: 0 0 520px;
						max-width: 520px;
						width: 100%;
						position: absolute;
						bottom: 0;
						background: rgb(0 0 0 / 60%);
						.countdown {
							padding-top: 24px;
						}
					}
				}
			}
		}
	}
	.live-event-main {
		.live-video-container {
			.video-boxes-main {
				.lv-video-container {
					.sc-dbm {
						.mobi-prodts-list {
							display: block;
						}
					}
				}
			}
		}
	}
	.horizontal-camera-view .ant-layout .ant-layout-sider {
		background-color: #f5f5f5;
		flex: 0 0 40% !important;
		max-width: 40% !important;
		min-width: 40% !important;
		width: 40% !important;
	}
}

@media only screen and (max-device-width: 1024px) and (orientation: landscape) {
	.live-event-main {
		.live-video-container {
			.video-boxes-main {
				.lv-video-container {
					flex: 0 0 404px;
					max-width: 404px;
				}
			}
		}
	}
}

@media only screen and (max-width: 990px) {
	.live-event-main {
		.event-container {
			.slick-slider {
				.slick-next {
					right: 10px;
					width: 32px;
					height: 32px;
					&:before {
						top: 3px;
						left: 2px;
						font-size: 24px;
					}
				}
				.slick-prev {
					left: 10px;
					width: 32px;
					height: 32px;
					&:before {
						top: 3px;
						left: -2px;
						font-size: 24px;
					}
				}
			}
			.event-col-box {
				.live-vid-link {
					.vid-item {
						.vid-item-detail {
							.vid-title {
								.author-name {
									.auth-right {
										font-size: 0.75rem;
									}
								}
							}
						}
					}
				}
			}
		}
		.live-video-container {
			.close-video {
				width: 40px;
				height: 40px;
			}
			.video-boxes-main {
				height: calc(100vh - 112px);
				max-height: calc(100vh - 112px);
				.lv-video-container {
					flex: 0 0 100%;
					max-width: 100%;
					.sc-dbm {
						.dbm1 {
							.dbm2 {
								.dbm3 {
									.dbm4 {
										.author-name {
											left: 76px;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.live-products-modal-box {
		&.modal-dialog-centered.modal-dialog-scrollable {
			.modal-content {
				border: none;
				border-radius: 14px 14px 0 0;
			}
			max-width: 100% !important;
			width: 100%;
			height: 80%;
			margin: 0;
			min-height: calc(80% - 0rem);
			max-height: calc(80% - 0rem);
			position: fixed;
			bottom: 0;
			justify-content: flex-end;
		}
	}
	.horizontal-camera-view {
		.ant-layout-content {
			.lv-cntr {
				.custom-hearts {
					display: none;
				}
				.event-ended-main-box {
					.event-ended-box {
						width: 350px;
						max-width: 350px;
					}
				}
			}
		}
	}
	.live-event-header-hide {
		.mobile-front-menu {
			display: none;
		}
	}
}
@media only screen and (max-width: 767px) {
	.live-event-main .event-container .event-col-box .live-vid-link .vid-item .vid-item-detail .vid-time .left-area {
		flex-direction: column;
	}
	.live-event-header-hide {
		.mobile_footer_ifti {
			display: none;
		}
	}
	.live-event-main {
		.live-video-container {
			.video-boxes-main {
				&.upcoming-box-main {
					.upcoming-poster {
						flex: 0 0 100%;
						max-width: 100%;
						&.default-image {
							align-items: flex-start;
						}
					}
					.upcoming-timer {
						flex: 0 0 100%;
						max-width: 100%;
						.countdown {
							.rsvp-btns {
								gap: 8px;
								a {
									font-size: 13px;
									min-width: 50px;
									padding: 4px 10px;
								}
							}
						}
					}
				}
			}
		}
		&.shows-height {
			min-height: 700px;
		}
	}
	.filter-modal-box {
		&.modal-dialog-centered.modal-dialog-scrollable {
			.modal-content {
				border: none;
				border-radius: 14px 14px 0 0;
				height: 100%;
			}
			height: 80%;
			margin: 0;
			min-height: calc(80% - 0rem);
			max-height: calc(80% - 0rem);
			position: fixed;
			bottom: 0;
			width: 100%;
		}
	}

	.bioshop-live-event-container {
		padding-inline-end: 20px;
		padding-inline-start: 20px;
	}
	.filter-modal-box {
		.modal-content {
			.modal-body {
				.filter-container {
					padding: 10px 12px 10px 12px;
					.filter-box button {
						font-size: 0.675rem;
						padding-left: 4px;
						padding-right: 4px;
					}
				}
			}
		}
	}

	.horizontal-camera-view {
		padding: 0;
		position: fixed;
		top: 0;
		z-index: 99;
		.ant-layout-has-sider {
			.ant-layout {
				.ant-layout-content {
					height: auto;
					min-height: 210px !important;
					.lv-cntr {
						.custom-hearts {
							display: none;
						}
						.brand-detail-overlay {
							.live-txt,
							.live-audiance {
								font-size: 12px;
								padding: 2px 5px 2px 5px;
							}
						}
						.MiniPlayer {
							.MiniPlayer-videoBox {
								position: relative;
								.MinPlayer-video {
									// position: relative;
									width: 100%;
								}
							}
						}
						.brand-detail-overlay {
							top: 0;
							left: 0;
							gap: 5px;
							.brand-detail {
								padding: 5px;
								max-width: 170px;
								.author-name {
									.auth-image {
										width: 36px;
										flex-basis: 36px;
										height: 36px;
									}
									.auth-detail {
										.auth-title {
											font-size: 1rem;
											line-height: 1rem;
										}
										.event-name {
											line-height: 1rem;
											margin-top: 2px;
											font-size: 0.875rem;
										}
										.brand-followers-live-event {
											.brand-followers-event {
												display: none;
											}
										}
									}
								}
							}
						}
						.drawer-buttons-outer {
							gap: 5px;
							width: auto;
							.dr-1 {
								button {
									margin-bottom: 0;
									width: 30px;
									height: 30px;
									background-color: rgba(0, 0, 0, 0.5);
									border-radius: 50px;
									font-size: 15px;
									display: flex;
									align-items: center;
									justify-content: center;
								}
							}
							.dr-detail,
							.dr-shop,
							.dr-cart {
								display: none;
							}
							&.drawer-for-recorded-events {
								background-color: transparent;
								right: 0;
							}
						}
						.close-live-event {
							right: 0;
							top: 0;
						}
						.PlayerControls {
							top: 0;
							right: 45px;
							.PlayerControls-button {
								padding: 8px;
							}
						}
						.event-ended-main-box {
							.event-ended-box {
								width: 100%;
								max-width: 100%;
								.ended-event-poster {
									img {
										object-fit: contain;
									}
									.event-ended-detail {
										.event-ended-detail-inner {
											h2 {
												font-size: 1.25rem;
											}
											.ended-ev-title {
												margin-top: 0;
												font-size: 14px;
											}
											.ended-auther-name {
												margin-top: 10px;
												.ended-auth-image {
													width: 32px;
													height: 32px;
												}
											}
											.notify-button {
												margin-top: 10px;
												button {
													padding-left: 30px;
													padding-right: 30px;
													padding-top: 5px;
													padding-bottom: 5px;
													font-size: 14px;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.ant-layout-sider {
				display: none;
			}
		}
		&.portrait {
			.ant-layout-has-sider {
				.ant-layout {
					.ant-layout-content {
						height: 500px;
						.lv-cntr {
							.MiniPlayer {
								.MiniPlayer-videoBox {
									.MinPlayer-video {
										video {
											object-fit: cover;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.brand-detail {
		.author-name {
			align-items: center;
		}
	}

	.mobile-checkout-panel {
		display: flex;
		&.is-sticky {
			position: fixed;
			z-index: 999;
			animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
		}
		.ant-tabs-content {
			height: 100%;
		}
	}
}

@media only screen and (max-width: 1500px) and (min-width: 1200px) {
	.infl-review-video-height {
		video {
			object-fit: cover;
		}
	}
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
	.infl-review-video-height {
		video {
			object-fit: cover;
		}
	}
}

.download-popup {
	.popup-logo {
		width: 120px;
		margin: 0 auto 20px auto;
	}
	.ant-modal-close-x {
		display: none;
	}
	.popup-para {
		font-size: 14px;
		font-weight: 600;
		text-align: center;
	}

	.default-btn {
		background-color: #000080;
		color: #fff;
		font-family: Nunito Sans, sans-serif !important;
		padding: 8px 25px;
		font-weight: 700;
		border-radius: 37px;
		display: inline-block;
		font-size: 13px;
		cursor: pointer;
		&:hover {
			background-color: #000000;
			color: #fff;
		}
	}
}

.horizontal-camera-view .ant-layout-content .lv-cntr .drawer-buttons-outer .dr-1 button.show-hearts-main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 5px;
	position: relative;
	top: 7px;
}

/* Animation for slide-right effect */
@keyframes slideRight {
	from {
		transform: translateX(-100%);
		opacity: 0;
	}
	to {
		transform: translateX(0);
		opacity: 1;
	}
}

/* Customize Ant Design Menu Item */
.ant-left-area-dropdown .ant-dropdown-menu-item {
	border-bottom: none; /* Removes the border */
	line-height: 2; /* Adjusts the line height */
}

/* Adjust Width of the Dropdown */
.ant-left-area-dropdown .ant-dropdown-menu {
	width: 200px; /* Adjusts the width */
}


:root {
  --border-radius: 10px;
  --controls-transition-duration: 0.3s;
}

.MiniPlayer {
  position: relative;
  width: 100%;
  max-width: 900px;
  border-radius: var(--border-radius);
}

.MiniPlayer-videoBox {
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  border-radius: var(--border-radius);
}

.MinPlayer-video {
  position: absolute;
  border-radius: var(--border-radius);
  overflow: hidden;
}

.MinPlayer-video video {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius);
}

.MinPlayer-video.small {
  position: fixed;
}

.PlayerControls {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 95px;
  /* height: 100%; */
  /* width: 100%; */
  padding: 0;
  background-color: transparent;
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  padding: 5px;
  
  transition: visibility 0s linear var(--controls-transition-duration),
    opacity var(--controls-transition-duration);
}

.MinPlayer-video.small:hover .PlayerControls {
  opacity: 1;
  visibility: visible;
  transition: visibility 0s linear 0s,
    opacity var(--controls-transition-duration);
}

.PlayerControls-button {
  width: 40px;
  height: 40px;
  background-color: rgba(50, 50, 50, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding: 10px;
  border: none;
}
.PlayerControls-button:first-child{
  display: none;
}
.PlayerControls-button:last-child{
  margin-right: 0;
}

/* @media only screen and (max-width: 1400px) {
  .PlayerControls-button:first-child{
    display: flex;
  }
}
@media only screen and (max-width: 1023px) {
  .PlayerControls-button:first-child{
    display: none !important;
  }
} */

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation:portrait) {
  .PlayerControls-button:first-child{
    display: flex;
  }
}
@media all and (device-width: 1024px) and (device-height: 768px) and (orientation:landscape) {
  .PlayerControls-button:first-child{
    display: flex;
  }
}


/* ----------- iPad Pro 12.9" ----------- */

/* Portrait and Landscape */
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .PlayerControls-button:first-child{
      display: flex;
    }
}

/* Portrait */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1024px) 
  and (max-device-width: 1024px) 
  and (orientation: portrait) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .PlayerControls-button:first-child{
      display: flex;
    }
}

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen 
  and (min-device-width: 1366px) 
  and (max-device-width: 1366px) 
  and (orientation: landscape) 
  and (-webkit-min-device-pixel-ratio: 2) {
    .PlayerControls-button:first-child{
      display: flex;
    }
}
.svg-icon {
    width: 1em;
    height: 1em;
}

.svg-icon path,
.svg-icon polygon,
.svg-icon rect {
    fill: #252525;
}

.svg-icon circle {
    stroke: #252525;
    stroke-width: 1;
}

.boost-sale-header {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;

    &.border-line {
        border-top: 1px solid #434433;
        padding-top: 40px;
    }

    h3 {
        color: #252525;
        font-weight: 400 !important;
        font-size: 28px;
        margin-bottom: 5px;
    }

    h4 {
        color: #252525;
        font-weight: 400 !important;
        font-size: 20px;
        margin-bottom: 5px;
        text-align: center;
        line-height: 1.4em;
        &.theme-color {
            color: #000080;
        }
    }

    h2 {
        color: #252525;
        font-weight: 800 !important;
        font-size: 30px;
        text-align: center;
    }
}

.brand-sec-banner {
    width: 100%;
    background-color: #07083e;
    display: flex;
    height: calc(100vh - 133px);
    position: relative;
    overflow: hidden;
    justify-content: flex-end;

    &.influ-sec-banner {
        background-color: #E5E0FA;

        .brn-bnr-box-left {
            .bnr-content {
                h1 {
                    color: #252525;
                }

                p {
                    color: #252525;
                }
            }
        }
    }

    .brn-bnr-box-left {
        max-width: 1632px;
        display: flex;
        align-items: center;
        position: absolute;
        height: 100%;
        padding-inline-start: 144px;
        margin: 0 auto;
        left: 0;
        right: 0;
        padding-inline-end: 144px;
        box-sizing: content-box;

        .bnr-content {
            .sub-hd {
                color: #000080;
                margin-bottom: 20px;
                font-size: 25px;
                font-weight: 800;
                text-transform: uppercase;
                font-family: Nunito Sans, sans-serif !important;
                letter-spacing: 1.5px;

                .new {
                    color: #ffffff;
                    font-weight: 500;
                }

                &.white-hd {
                    color: white;
                }
            }

            h1 {
                color: #FFF;
                margin-bottom: 0;
                font-size: 50px;

                span {
                    font-weight: 800;
                    font-size: 50px;

                    &.big-size {
                        font-size: 70px;
                    }
                }
            }

            p {
                color: #FFF;
                margin-top: 0px;
                padding-top: 10PX;
                margin-bottom: 0;
                font-size: 17px;
                max-width: 515px;
            }

            .banner-sub-hd {
                // color: #FFF;
                color: #000080;
                margin-top: 20px;
                margin-bottom: 0;
                font-size: 20px;
                max-width: 515px;
                font-weight: 800 !important;
                font-family: Nunito Sans, sans-serif !important;
            }

            a {
                font-family: Nunito Sans, sans-serif !important;
                padding: 10px 35px;
                background-color: #FFF;
                font-weight: 700;
                color: #000;
                border-radius: 37px;
                display: inline-block;
                margin-top: 35px;
                font-size: 16px;
                box-shadow: 0px 4px 8px #0a164626;

                &:hover {
                    background-color: #000;
                    color: #FFF;
                }
            }
        }
    }

    .brn-bnr-box-right {
        display: flex;
        width: 50%;
        justify-content: flex-end;
        z-index: 1;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: 50% 40%;

            &.public-home-img {
                position: relative;
                left: 0;
            }
        }
    }

    &.home-banner {
        background-color: #07083e;
        height: 584px;
    }

    &.inf-banner {
        background-color: #7ab4c2;
        height: 584px;
    }

    &.new-style {
        // background-color: #e7cfb4;
        // background-position: center right;
        // background-size: contain;
        background-color: #cfe8f9;
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;

        // height: 584px;
        .brn-bnr-box-left {
            .bnr-content {
                h1 {
                    color: #000080;
                }

                p {
                    color: #000080;
                }
            }
        }

        &.new-theme-banner {
            background-position: center top;
            background-size: cover;
            .brn-bnr-box-right {
                padding-inline-end: 0;
                display: initial;
                width: initial;
                padding-top: 0;
                padding-bottom: 0;
                video {
                    max-width: 100%;
                    height: auto;
                }
            }
        }
    }
    &.bg2 {
        background-position: center top;
        background-size: cover;   
        .brn-bnr-box-left .bnr-content{
        .sub-hd {
            font-family: 'AvenirHeavy'!important;
        } 
        h1 {
            font-family: 'AvenirHeavy'!important;
        }
        p {
            font-family: 'AvenirNextDemiBold'!important;
            // font-size: 20px;
        }
    }
    }
    &.font-style {

        .brn-bnr-box-left {
    
            .bnr-content {
                .sub-hd {
                    font-family: 'AvenirHeavy'!important;
                }
                h1 {
    
                    span {
                        font-family: 'AvenirHeavy'!important;
    
                        &.big-size {
                            font-size: 70px;
                        }
                    }
                }
    
                p {
                    font-family: 'AvenirNextDemiBold'!important;
                }
    
                .banner-sub-hd {
                    font-family: 'AvenirHeavy'!important;
                }
    
            
            }
        }
    }
}

.bnr-section-2 {
    width: 100%;

    .one-mark {
        width: 100%;
        margin: 50px 0;
        display: flex;
        flex-direction: column;
        // border-top: solid 1px #707070;
        // border-bottom: solid 1px #707070;
        align-items: center;


        h2 {
            color: #252525;
            font-weight: 800 !important;
            font-size: 30px;
            margin-bottom: 10px;
        }

        h3 {
            color: #252525;
            font-weight: 400 !important;
            font-size: 28px;
            margin-bottom: 0;
        }

        a {
            background-color: #000080;
            color: #FFF;
            font-family: Nunito Sans, sans-serif !important;
            padding: 10px 35px;
            font-weight: 700;
            border-radius: 37px;
            display: inline-block;
            margin-top: 25px;
            font-size: 16px;

            &:hover {
                background-color: #000;
                color: #FFF;
            }
        }
    }

    &.get-started {
        .one-mark {
            margin: 0 0 50px 0;
        }
    }

    &.new-cta-style {
        // background-color: #efdfcb;
        background-color: #cfe8f9;
        margin-top: 0;
        padding-top: 50px;

        .one-mark {
            h2 {
                color: #000080;
            }

            h3 {
                color: #000080;
            }

            a {
                background-color: #FFF;
                color: #000;

                &:hover {
                    background-color: #000;
                    color: #fff;
                }
            }
        }
    }
}

.video-section {
    .new-style {
    a {
        background-color: #FFF;
        color: #000;

        &:hover {
            background-color: #000;
            color: #fff;
        }
    }
}
}
.brnd-sec-3 {
    background-color: #2d266c;
    width: 100%;

    &.new {
        background-color: #ffffff;
    }

    &.secondary-style {
        // background-color: #737997;
        background-color: #4cc9f2;    
    }
    

    .boost-sale-main {
        .boost-sale-header {

            h3,
            h2 {
                color: #FFF;
            }

            &.new {

                h3,
                h2 {
                    color: #000;
                }

                &.secondary {

                    h3,
                    h2 {
                        // color: #efdfcb;
                        color: #000080;
                    }
                }
            }

        }

        padding: 60px 0;
        width: 100%;

        .boost-sale-content-main {
            display: flex;
            max-width: 1140px;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 40px;
        }

        .boost-sale-content {
            width: 100%;
            display: flex;

            .boost-sale-content-box {
                width: 50%;
                border-bottom: solid 1px #FFF;
                padding-bottom: 30px;
                padding-right: 30px;
                padding-left: 30px;

                &:first-child {
                    border-right: solid 1px #FFF;
                }

                .boost-inr {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;

                    .boost-inr-left {
                        width: 65px;
                        height: 65px;
                        flex-basis: 65px;
                        position: relative;
                        overflow: hidden;
                        transition: box-shadow 0.25s;
                        padding: 4px;
                        flex-grow: 0;
                        flex-shrink: 0;

                        svg {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .boost-inr-right {
                        padding-left: 40px;

                        h2 {
                            color: #FFF;
                            margin-bottom: 5px;
                            font-weight: 800 !important;
                            font-size: 20px;
                        }

                        p {
                            color: #FFF;
                        }
                    }
                }
            }

            &.boost-gap {
                .boost-sale-content-box {
                    padding-top: 30px;
                }
            }

            &.boost-gap-bottom {
                .boost-sale-content-box {
                    padding-bottom: 0;
                    border-bottom: none;
                }
            }
        }

        .promote-brand-main {
            width: 1140px;
            margin: 0 auto;
            margin-top: 40px;
            display: flex;
            justify-content: space-between;

            .promote-brand-box {
                flex: 0 0 25%;
                max-width: 25%;
                display: flex;
                flex-direction: column;
                align-items: center;

                .promote-icon {
                    width: 65px;
                    height: 65px;
                    flex-basis: 65px;
                    position: relative;
                    overflow: hidden;
                    transition: box-shadow 0.25s;
                    padding: 4px;
                    flex-grow: 0;
                    flex-shrink: 0;

                    svg,
                    img {
                        width: 100%;
                        height: 100%;
                        display: block;
                    }
                }

                .promote-content {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    h2 {
                        color: #efdfcb;
                        // margin-top: 0.5em;
                        font-size: 60px;
                        font-weight: 700 !important;
                        margin-bottom: 10px;
                    }

                    p {
                        // color: #FFF;
                        color: #000080;
                        font-family:'AvenirNext-Regular';
                        text-align: center;
                        font-size: 18px;
                    }

                    &.small-content {
                        align-items: center;

                        h2 {
                            font-size: 24px;
                            margin-top: 0.5em;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }

            &.new {

                // border-top: 1px solid #434433;
                // border-bottom: 1px solid #434433;
                // padding: 25px 0;
                .promote-brand-box {

                    .promote-content {
                        h2 {
                            color: #2d266c;
                        }

                        p {
                            color: #000000;
                            // text-align: left;
                            // font-size: 24px;
                            // font-weight: 500;
                            // line-height: 30px;
                        }
                    }
                }
            }
        }


 


    }

    &.easy-to-buy {
        background-color: #e5e0fa;

        .easy-buy-wrapper {
            width: 1140px;
            margin: 0 auto;
            margin-top: 40px;

            .pop-cat-box-main {
                display: grid;
                margin-top: 40px;
                grid-row-gap: 65px;
                grid-column-gap: 150px;
                grid-template-columns: repeat(3, 1fr);

                .cat-box-pop-inr {
                    .cat-para {
                        text-align: center;
                        padding: 40px 0 0 0;
                    }
                }

                .cat-box-link {
                    position: relative;

                    .lv-item {
                        position: relative;
                        width: 100%;

                        .lv-item-image {
                            position: relative;
                            width: 100%;
                            box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
                            padding-bottom: 66.38%;
                            background-size: cover;
                            border-radius: 8px;
                            transition: box-shadow 0.25s ease 0s;
                            overflow: hidden;
                            background-position: center center;
                            background-repeat: no-repeat;
                        }

                        .d-shop-main {
                            position: absolute;
                            width: 100%;
                            bottom: -15px;
                            display: flex;

                            .d-shop-now {
                                background: #FFF;
                                color: #334251;
                                padding: 6px 22px;
                                margin: 0 auto;
                                border-radius: 9999px;
                                box-shadow: 0px 4px 5px rgba(10, 22, 70, 0.1490196078);
                                z-index: 1;
                                font-family: "Nunito Sans", sans-serif !important;
                                font-weight: 700;
                                display: inline-block;
                                min-width: 100px;
                                text-align: center;
                                border: 1px solid #ebebeb;

                                &:hover {
                                    background-color: #000;
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }


        .boost-sale-main {
            .boost-sale-header {
                h2 {
                    color: #000;
                }

                h3 {
                    color: #000;
                    // font-size: 1rem;
                }
            }

            .promote-brand-main {
                .promote-brand-box {
                    .promote-content {
                        p {
                            color: #000;
                        }

                        h2 {
                            color: #000;
                        }
                    }
                }
            }
        }

    }
    &.secondary-style {
        &.secondary-style {
            .promote-brand-main {
                .promote-brand-box {
                    .promote-content {
                        h2 {
                            // color: #fff;
                            color: #000080;
                        }
                    }
                }
            }
        }
    }
}

.brand-sec-4 {
    width: 100%;

    .brand-inf-pop-cats {
        width: 1140px;
        padding: 60px 0;
        margin: 0 auto;

        .pop-cat-box-main {
            display: grid;
            margin-top: 40px;
            grid-row-gap: 65px;
            grid-column-gap: 45px;
            grid-template-columns: repeat(3, 1fr);

            .cat-box-link {
                position: relative;

                .lv-item {
                    position: relative;
                    width: 100%;

                    .lv-item-image {
                        position: relative;
                        width: 100%;
                        box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
                        padding-bottom: 66.38%;
                        background-size: cover;
                        border-radius: 8px;
                        transition: box-shadow 0.25s ease 0s;
                        overflow: hidden;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }

                    .d-shop-main {
                        position: absolute;
                        width: 100%;
                        bottom: -15px;
                        display: flex;

                        .d-shop-now {
                            background: #FFF;
                            color: #334251;
                            padding: 6px 22px;
                            margin: 0 auto;
                            border-radius: 9999px;
                            box-shadow: 0px 4px 5px rgba(10, 22, 70, 0.1490196078);
                            z-index: 1;
                            font-family: "Nunito Sans", sans-serif !important;
                            font-weight: 700;
                            display: inline-block;
                            min-width: 100px;
                            text-align: center;

                            &:hover {
                                background-color: #000;
                                color: #FFF;
                            }
                        }
                    }
                }
            }
        }

        .pop-cat-link {
            display: flex;
            width: 100%;
            justify-content: center;
            margin-top: 40px;

            a {
                background-color: #000080;
                color: #FFF;
                font-family: Nunito Sans, sans-serif !important;
                padding: 10px 35px;
                font-weight: 700;
                border-radius: 37px;
                display: inline-block;
                margin-top: 25px;
                font-size: 16px;

                &:hover {
                    background-color: #000;
                    color: #FFF;
                }
            }
        }
    }

    &.our-people {
        background-color: #fcfafb;
        margin-bottom: 60px;
    }
}

.brand-sec-5 {
    width: 100%;
    background-color: #434433;

    .connecting-shopper {
        width: 1140px;
        padding: 60px 0;
        margin: 0 auto;

        .boost-sale-header {

            h2,
            h3 {
                color: #FFF;
            }
        }

        .reward-box-main {
            display: grid;
            margin-top: 40px;
            grid-row-gap: 65px;
            grid-column-gap: 45px;
            grid-template-columns: repeat(2, 1fr);

            .reward-boxes {
                position: relative;
                background-color: #FFF;
                border-radius: 8px;
                padding: 20px;

                .reward-box-iner {
                    display: inline-flex;
                    width: 100%;
                    align-items: center;

                    .reward-icon {
                        width: 65px;
                        height: 65px;
                        flex-basis: 65px;
                        position: relative;
                        overflow: hidden;
                        transition: box-shadow 0.25s;
                        padding: 4px;
                        flex-grow: 0;
                        flex-shrink: 0;

                        svg {
                            width: 100%;
                            height: 100%;
                            display: block;
                        }
                    }

                    .reward-content {
                        padding-left: 40px;

                        h2 {
                            color: #252525;
                            margin-bottom: 5px;
                            font-weight: 800 !important;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
    }
}

.brand-sec-6 {
    width: 100%;

    .integrated-sec {
        width: 1140px;
        padding: 60px 0;
        margin: 0 auto;

        .plateforms {
            display: flex;
            width: 100%;
            margin-top: 20px;

            .shop-logo {
                flex: 0 0 33.333333%;
                max-width: 33.333333%;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;

                .sl-1 {
                    width: 200px;
                }

                .sl-2 {
                    width: 200px;
                }

                .sl-3 {
                    width: 130px;
                }
            }
        }

        .select-plan-main {
            width: 100%;
            margin-top: 60px;

            .package_parent {
                display: flex;
                margin-top: 40px;
                grid-column-gap: 45px;
                grid-template-columns: repeat(2, 1fr);
                justify-content: center;

                .custom_pkg_main {
                    flex: 0 0 40%;
                    max-width: 40%;
                    display: flex;
                    flex-direction: column;

                    &.flex-w-100 {
                        flex: 0 0 100%;
                        max-width: 100%;

                    }

                    h3 {
                        width: 100%;
                        background: #000080;
                        color: #FFF;
                        margin-bottom: 0;
                        text-align: center;
                        padding: 10px 0;
                        font-size: 1rem;
                        text-transform: uppercase;
                        font-weight: 800 !important;

                        span {
                            font-size: 0.875rem;
                        }
                    }

                    .custom_pkg {
                        min-height: 600px;
                        border: solid 1px #ccc;
                        padding: 1.5rem;
                        position: relative;
                        width: 100%;

                        .pkg_price_ifti {
                            margin: 0 0 1rem 0;
                            font-family: "Rubik";

                            .pkg_limit {
                                font-size: 1rem;
                                display: flex;
                                font-weight: 500;
                            }

                            sup {
                                font-size: 2rem;
                                top: -32px;
                                font-weight: 400;
                            }

                            .display-5 {
                                font-size: 4rem;
                                color: #263238;
                                font-weight: 500;
                            }

                            small {
                                font-size: 1.2rem;
                                font-weight: 400;
                            }

                            .pkg_billed {
                                font-size: 1rem;
                                position: relative;
                                top: -10px;
                                display: flex;
                                font-weight: 500;
                            }
                        }

                        .pkg_detail_list_ift {
                            padding: 0;
                            margin: 0;

                            li {
                                padding-bottom: 10px;
                                padding-left: 0;
                                position: relative;
                                line-height: 1.5rem;
                                list-style-type: none;
                                display: flex;
                                align-items: center;

                                i {
                                    font-size: 24px;
                                    margin-right: 10px;
                                }
                            }
                        }

                        .incld-live-shoping {
                            width: 100%;

                            .includes-lv {
                                font-family: "Rubik";
                                font-size: 1rem;
                                position: relative;
                                display: flex;
                                font-weight: 500;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }

                            .foot-notes {
                                width: 100%;

                                span {
                                    font-family: "Rubik";
                                    font-weight: 500;
                                    font-size: 12px;
                                }

                                ol {
                                    padding-left: 1.5rem;
                                    margin-top: 10px;
                                    list-style-type: disclosure-closed;
                                    margin-top: 0;

                                    li {
                                        font-size: 12px;
                                    }
                                }
                            }

                            ul {
                                padding: 0;
                                margin: 0;

                                li {
                                    padding-bottom: 10px;
                                    padding-left: 0;
                                    position: relative;
                                    line-height: 1.5rem;
                                    list-style-type: none;
                                    display: flex;
                                    align-items: center;

                                    i {
                                        font-size: 24px;
                                        margin-right: 10px;
                                    }
                                }
                            }
                        }
                    }

                    &:last-child {
                        display: none;
                    }
                }

                .select-pkg {
                    width: 90%;
                    background-color: #000080;
                    border-radius: 25px;
                    padding: 12px 0;
                    border-color: #000080;
                    font-family: "Nunito Sans", sans-serif;
                    font-weight: 600;
                    font-size: 1rem;
                    position: absolute;
                    bottom: 1rem;
                    left: 0;
                    right: 0;
                    margin: 0 auto;

                    &:hover {
                        background-color: #000;
                    }
                }
            }
        }

        .about-inf-mkt {
            width: 100%;
            margin-top: 60px;
            display: none;

            .social-inf-main {
                width: 100%;
                display: flex;
                margin-top: 30px;
                justify-content: space-between;

                .social-inf-box {
                    flex: 0 0 46%;
                    position: relative;

                    .social-inf-box-inr {
                        position: relative;
                        width: 100%;
                        box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
                        padding-bottom: 66.38%;
                        background-size: cover;
                        border-radius: 8px;
                        transition: box-shadow 0.25s ease 0s;
                        overflow: hidden;
                        background-position: center center;
                        background-repeat: no-repeat;
                    }

                    a {
                        color: #252525;
                        font-family: "Nunito Sans", sans-serif !important;
                        font-weight: 600;
                        display: block;
                        text-align: center;
                        font-size: 16px;
                        margin-top: 20px;

                        &:hover {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}

.featured-brand-brand-page {
    width: 100%;
    padding: 60px 0;

    .top_picks_main {
        margin-top: 0;

        .top_picks {
            .pik-header {
                display: none;
            }

            .brand-logos {
                margin-top: 40px;
            }
        }
    }
}

.testimonials-main {
    width: 100%;
    padding: 0 0 60px 0;

    .testimonial-container {
        width: 100%;

        .testimonial-slider {
            width: 100%;
            margin-top: 40px;
        }

        .testimonial-inr-slide {
            width: 100%;
            display: inline-flex;
            align-items: center;

            .cus-image {
                width: 200px;
                height: 200px;
                flex-basis: 200px;
                position: relative;
                overflow: hidden;
                flex-grow: 0;
                flex-shrink: 0;
                background-color: #ccc;
                border-radius: 50%;
                box-shadow: 6px 6px 0px #07083E;

                img {
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                }
            }

            .cus-review {
                display: flex;
                flex-grow: 1;
                margin-left: 40px;
                margin-right: 40px;
                min-width: 0px;
                flex-direction: column;
                width: 100%;

                .review-inr {
                    display: flex;
                    align-items: center;

                    p {
                        font-size: 16px;
                        font-style: italic;
                        font-family: 'SegoeUI_Medium';
                        text-align: center;
                        margin-bottom: 0;
                    }

                    span {
                        font-family: "Nunito Sans", sans-serif !important;
                        font-size: 16px;
                        font-weight: 800;
                        margin-top: 20px;
                        display: block;
                        font-style: normal;
                    }
                }
            }
        }
    }
}

.bioshop-sec-main {
    width: 100%;
    padding: 60px 0;
    // background-color: #E5E0FA;
    background-color: #ffffff;

    &.why-choose {
        // background-color: #737997;
        background-color: #4cc9f2;
    }

    &.new-style-bio {
        // background-color: #737997;
        background-color: #ffffff;
    }

    &.get-paid-style {
        // background-color: #737997;
        background-color: #4cc9f2;
    }

    .bioshop-container {
        display: flex;
        width: 100%;

        &.w-initial {
            max-width: initial;
        }

        .bioshop-box {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            align-items: center;

            img {
                width: 100%;

            }

            // .public-why-home-img {
            //     width: 70%;
            // }
            .bioshop-box-inner {
                // max-width: 515px;
                max-width: 650px;
                padding-right: 20px;

                &.max-initial {
                    max-width: initial;

                    &.max-full {
                        width: 100%;
                        max-width: 100%;
                    }
                }

                h2 {
                    font-size: 30px;
                    font-weight: 800 !important;
                }

                p {
                    font-size: 16px;
                    &.text-color-new {
                        color: #000080;
                    }
                }

                .bio-hd-sub {
                    font-size: 30px;
                    color: white;
                    padding-bottom: 20px;
                    line-height: initial;
                }

                .bio-para {
                    font-size: 16px;
                    // color: white;
                    color: #000080;
                    padding-bottom: 30px;
                }

                .bio-hd-end {
                    font-size: 20px;
                    font-weight: 700 !important;
                    color: white;
                }

                .bioshop-link {
                    display: flex;
                    width: 100%;
                    padding-top: 15px;

                    a {
                        font-family: Nunito Sans, sans-serif !important;
                        padding: 10px 35px;
                        background-color: #FFF;
                        font-weight: 700;
                        color: #000;
                        border-radius: 37px;
                        display: inline-block;
                        font-size: 16px;

                        &:hover {
                            background-color: #000;
                            color: #FFF;
                        }
                    }
                }

                &.why-choose-inner {
                    h2 {
                        // color: #efdfcb;
                        color: #000080;
                    }

                    .features-box {
                        h3 {
                            // color: white;
                            color: #000080;
                        }

                        p {
                            // color: white;
                            color: #000080;
                        }
                    }
                }

                &.new-style-bio-inner {
                    h2 {
                        // color: #efdfcb;
                        color: #000080;
                    }

                    .features-box {
                        h3 {
                            // color: white;
                        color: #000080;
                        }

                        p {
                            // color: white;
                        color: #000080;
                        }
                    }
                }

                &.get-paid-style-inner {
                    h2 {
                        // color: #efdfcb;
                        color: #000080;
                    }

                    .features-box {
                        h3 {
                            color: white;
                        }

                        p {
                            color: white;
                        }
                    }
                }
            }

            &.w-47 {
                flex: 0 0 47%;
                max-width: 47%;
            }

            &.full-width {
                flex: 0 0 100%;
                max-width: 100%;
            }
        }
    }



    &.dark {
        // background-color: #2d266c;
        // background-color: #efdfcb;
        background-color: #cfe8f9;
        padding: 120px 0;

        .bioshop-box-inner {

            // padding-left: 20px;
            h2 {
                color: #000080;
            }

            .bio-hd-sub {
                // color: #513e43 !important;
                color: #000080 !important;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 800 !important;
            }

            .bio-para {
                // color: #000000 !important;
                color: #000080 !important;
            }

            .bio-hd-end {
                // color: #513e43 !important;
                color: #000080 !important;
            }


            .why-list {
                text-align: left;
                padding-left: 20px;
                margin-bottom: 30px;

                li {
                    color: #ffffff;
                    font-size: 16px;
                    padding-bottom: 10px;
                }
            }

        }
    }

    &.our-goal {
        background-color: #e5e0fa;
    }

    &.grey {
        background-color: #838383;
    }

    &.light-bg {
        background-color: #e7e6e6;
    }

    &.color-bg {
        background-color: #e5e0fa;
    }

    &.white-bg {
        background-color: white;
    }

    &.space-30 {
        padding: 30px 0;
    }



}


.bioshop-sec-main.color-bg .bioshop-box-inner h2 {
    color: #000;
}

.bioshop-sec-main.color-bg .bioshop-box-inner .bio-para {
    color: #000 !important;
}

.no-bg {
    background: transparent;
}

.analaytics-sec-main {
    width: 100%;
    padding: 60px 0;
    background-color: #FFF;

    .analytics-container {
        display: flex;
        width: 100%;

        .analytics-box {
            flex: 0 0 50%;
            max-width: 50%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            img {
                width: 100%;
            }

            .analytics-box-inner {
                max-width: 515px;
                padding-left: 20px;

                h2 {
                    font-size: 30px;
                    font-weight: 800 !important;
                }

                p {
                    font-size: 16px;
                }

                .app-links {
                    display: flex;
                    width: 100%;
                    padding-top: 15px;

                    a {
                        margin-right: 10px;

                        &:last-child {
                            margin-right: 0;
                        }

                        img {
                            width: 150px;
                        }
                    }
                }
            }
        }
    }
}

.brand-sec-banner {
    &.about-sec-banner {
        background-color: #ffffff;
        // border-bottom: solid 1px #dce4ec;

        .brn-bnr-box-left {
            .bnr-content {
                h1 {
                    color: #000000;
                }

                p {
                    color: #000000;
                }

                a {
                    background-color: #000080;
                    color: #ffffff;
                }
            }
        }

        .brn-bnr-box-right {
            .about-img {
                // width: initial;
                // height: inherit;
                // object-position: initial;
                // object-fit: initial;
                // display: table;
                // margin: auto;
            }
        }
    }
}


.features-box {
    h3 {
        font-size: 20px;
        font-weight: 800 !important;
        margin-bottom: 10px;
    }

    p {
        font-size: 16px;
        font-weight: 400 !important;
        margin-bottom: 30px;


    }
}

.video-section {
    // background: #737997;
    background: #4cc9f2;
    padding: 120px 0 60px 0;
}

.integrated-section {
    // background-color: #efdfcb;
    background-color: #cfe8f9;

    .boost-sale-header {
        h2 {
            color: #000080;
        }
    }
}

@media screen and (max-width: 1200px) and (min-width: 991px) {
    .brand-sec-banner.new-style .brn-bnr-box-left .bnr-content p {
        max-width: 60%;
    }
}

@media only screen and (min-width: 1280px) {
    .feature-para {
        font-size: 18px;

        &.w-300 {
            max-width: 300px;

        }
    }
}

@media only screen and (min-width: 1640px) {
    .brand-sec-banner.new-style .brn-bnr-box-left .bnr-content {
        // padding-left: 200px;
    }
}


@media only screen and (max-width: 1440px) {


    .brand-sec-banner.about-sec-banner .brn-bnr-box-right {
        align-items: center;

        img.public-home-img {
            object-fit: contain;
            object-position: 50%;
        }
    }

    .brand-sec-banner {
        .brn-bnr-box-left {
            margin: 0;
            padding-inline-start: 40px;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .brand-sec-banner.new-style {
        background-size: cover;
    }
}

@media only screen and (max-width: 1270px) {

    .features-box h3 {
        font-size: 20px;
    }

    .brnd-sec-3 .boost-sale-main .promote-brand-main .promote-brand-box .promote-content h2 {
        font-size: 30px;
    }

    .brnd-sec-3 .boost-sale-main .promote-brand-main .promote-brand-box .promote-content p {
        font-size: 18px;
        line-height: 22px;
    }

    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner h2 {
        font-size: 25px;
    }

    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner .bio-hd-sub {
        font-size: 20px;
    }

    .brnd-sec-3 {
        &.easy-to-buy {
            .easy-buy-wrapper {
                width: initial;
                max-width: 1140px;
            }
        }
    }

    .brand-sec-banner {
        .brn-bnr-box-left {


            .bnr-content {
                .sub-hd {
                    font-size: 18px;
                }

                h1 {
                    font-size: 25px;

                    span {
                        font-size: 25px;

                        &.big-size {
                            font-size: 25px;
                        }
                    }
                }

                p {
                    font-size: 16px;
                    font-size: 16px;
                }

                a {
                    padding: 10px 25px;
                    margin-top: 25px;
                    font-size: 14px;
                }
            }
        }
    }

    .brnd-sec-3 {
        .boost-sale-main {
            .promote-brand-main {
                width: initial;
                max-width: 1140px;
                margin-top: 15px;
            }

        }
    }

    .brand-sec-4 {
        .brand-inf-pop-cats {
            width: initial;
            max-width: 1140px;
        }
    }

    .brand-sec-5 {
        .connecting-shopper {
            width: initial;
            max-width: 1140px;
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            width: initial;
            max-width: 1140px;
        }
    }

    .bioshop-sec-main {
        .bioshop-container {
            .bioshop-box {
                .bioshop-box-inner {
                    padding: 0 20px;
                }
            }
        }
    }
}

@media only screen and (max-width: 990px) {
    .brand-sec-banner.about-sec-banner .brn-bnr-box-right {
        align-items: center;

        img.public-home-img {
            object-fit: contain;
            object-position: 50% 0;
        }
    }

    .brand-sec-banner {
        &.about-sec-banner {
            .brn-bnr-box-left {
                .bnr-content {
                    a {
                        color: #000 !important;
                        background-color: #FFF !important;
                    }
                }
            }
        }
    }

    .brnd-sec-3 {
        &.easy-to-buy {
            .easy-buy-wrapper {
                .pop-cat-box-main {
                    grid-column-gap: 50px;
                }
            }

            .boost-sale-main {
                .boost-sale-header {
                    h3 {
                        font-size: 16px;
                        line-height: 22px;
                    }
                }
            }
        }
    }

    .analaytics-sec-main {
        padding: 40px 0;

        .analytics-container {
            .analytics-box {
                .analytics-box-inner {
                    h2 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 14px;
                    }

                    .app-links {
                        padding-top: 5px;
                    }
                }
            }
        }
    }




    .bioshop-sec-main {
        // padding: 40px 0;

        .bioshop-container {

            .bioshop-box {

                .bioshop-box-inner {
                    h2 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 14px;
                    }

                    .bioshop-link {
                        a {
                            padding: 7px 25px;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }



    .brnd-sec-3 {
        .boost-sale-main {
            .promote-brand-main {
                .promote-brand-box {
                    .promote-icon {
                        width: 55px;
                        height: 55px;
                        flex-basis: 55px;
                    }
                }
            }
        }
    }



    .featured-brand-brand-page {
        padding: 40px 0;
    }

    .featured-brand-brand-page {
        .top_picks_main {
            .top_picks .brand-logos {
                margin-top: 20px;
            }
        }
    }

    .brand-sec-banner {
        height: 400px;
        margin-top: 0;

        .brn-bnr-box-left {
            padding-inline-start: 0;
            padding-inline-end: 0;
            display: initial;
            z-index: 2;
            height: initial;
            width: 90%;
            margin: 0 auto;
            left: 0;
            right: 0;
            bottom: 10px;

            .bnr-content {
                background: rgba(0, 0, 0, 0.55);
                padding: 25px;
                border-radius: 5px;
                z-index: 100;

                .sub-hd {
                    font-size: 16px;
                    color: #ffffff;
                }

                h1 {
                    font-size: 25px;

                    span {
                        font-size: 25px;
                        color: #ffffff;
                    }
                }

                p {
                    display: none;
                }
            }
        }

        .brn-bnr-box-right {
            width: 100%;
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            .plateforms {
                .shop-logo {
                    .sl-1 {
                        width: 150px;
                    }
                }
            }

            .select-plan-main {
                margin-top: 40px;
            }
        }
    }

    .brand-sec-4 {
        .brand-inf-pop-cats {
            padding: 40px 0;

            .pop-cat-link {
                a {
                    padding: 7px 25px;
                    margin-top: 15px;
                    font-size: 14px;
                    font-weight: 600;
                }
            }
        }

        &.our-people {
            margin-bottom: 40px;
        }
    }

    .brand-sec-5 {
        .connecting-shopper {
            padding: 40px 0;

            .reward-box-main {
                margin-top: 40px;
                grid-row-gap: 40px;
                grid-column-gap: 25px;

                .reward-boxes {
                    .reward-box-iner {
                        .reward-content {
                            padding-left: 15px;

                            h2 {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            padding: 40px 0;
        }
    }

    .bnr-section-2 {
        width: 100%;

        .one-mark {
            margin: 40px 0;

            h2 {
                font-size: 1.5rem;
            }

            h3 {
                font-size: 16px;
                text-align: center;
            }

            a {
                padding: 7px 25px;
                margin-top: 15px;
                font-size: 14px;
                font-weight: 600;
            }

            &.btm-space {
                margin-bottom: 20px;
            }
        }

        &.get-started {
            .one-mark {
                margin: 0 0 25px 0;
            }
        }
    }

    .app-buttons {
        gap: 10px;

        &.new-style {
            flex-direction: column;
            gap: 0;
        }
    }

    .brnd-sec-3 {
        .boost-sale-main {
            padding: 40px 0;

            .boost-sale-content-main {
                max-width: 100%;
                margin-top: 30px;
            }

            .boost-sale-content {
                .boost-sale-content-box {
                    padding-bottom: 15px;
                    padding-right: 15px;
                    padding-left: 15px;

                    .boost-inr {

                        .boost-inr-right {
                            padding-left: 20px;

                            h2 {
                                font-size: 18px;
                            }

                            p {
                                line-height: initial;
                            }
                        }
                    }
                }

                &.boost-gap {
                    .boost-sale-content-box {
                        padding-top: 20px;
                    }
                }
            }
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            .select-plan-main {
                .boost-sale-header {
                    h4 {
                        br {
                            display: none;
                        }
                    }
                }

                .package_parent {
                    grid-column-gap: 0;
                    margin-top: 20px;

                    .custom_pkg_main {
                        flex: 0 0 100%;
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .boost-sale-header {
        h3 {
            font-size: 28px;
            margin-bottom: 5px;
        }

        h4 {
            font-size: 14px;
            margin-bottom: 5px;
            line-height: 22px;
        }

        h2 {
            font-size: 1.5rem;
        }
    }
}

@media only screen and (max-width: 767px) {
    .video-section {
        padding: 30px 0 30px 0;
    }
    .bioshop-sec-main {
        padding: 20px 0;
        &.dark {
            padding: 30px 0;
        }
    }

    .brnd-sec-3 {
        &.easy-to-buy {
            .boost-sale-main {
                .boost-sale-header {
                    br {
                        display: none;
                    }
                }
            }

            .easy-buy-wrapper {
                .pop-cat-box-main {
                    margin-top: 20px;
                    grid-row-gap: 35px;
                    grid-column-gap: 15px;
                }
            }
        }
    }

    .analaytics-sec-main {
        padding: 40px 0;

        .analytics-container {
            flex-direction: column;
            align-items: center;
            gap: 25px;

            .analytics-box {
                flex: 0 0 100%;
                max-width: 100%;

                .analytics-box-inner {
                    padding-left: 0;
                    text-align: center;

                    h2 {
                        font-size: 1.5rem;
                    }

                    p {
                        font-size: 14px;
                    }

                    .app-links {
                        justify-content: center;
                    }
                }
            }
        }
    }

    .bioshop-sec-main {

        .bioshop-container {
            flex-direction: column;
            align-items: center;
            gap: 25px;
            flex-direction: column-reverse;

            .bioshop-box {
                flex: 0 0 100%;
                max-width: 100%;

                .bioshop-box-inner {
                    text-align: center;
                    padding-right: 0;

                    .bioshop-link {
                        a {
                            padding: 7px 25px;
                            font-size: 14px;
                            font-weight: 600;
                            display: table;
                            margin: auto;
                        }
                    }
                }

                &.ml-5 {
                    margin-left: 0 !important;

                    .bioshop-box-inner {
                        padding: 0 !important;
                    }
                }
            }
        }
    }

    .bioshop-sec-main .bioshop-container .bioshop-box.w-47 {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner {
        padding: 0 !important;
    }

    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner h2 {
        margin-bottom: 20px !important;
    }

    .signup-border-content {
        padding: 20px 0 !important;
    }

    .testimonials-main {
        .testimonial-container {
            .testimonial-inr-slide {
                display: block;

                .cus-image {
                    margin: 0px auto 20px auto;
                }

                .cus-review {
                    flex-direction: row;
                    margin: 0;
                }
            }
        }
    }

    .brnd-sec-3 {
        .boost-sale-main {
            .promote-brand-main {
                display: block;
                justify-content: center;

                .promote-brand-box {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-top: 15px;
                }
            }
        }
    }


    .brand-sec-banner {

        .brn-bnr-box-left {
            // left: 15px;
            bottom: 15px;
            width: calc(100% - 30px);

            .bnr-content {
                padding: 15px;
                text-align: center;

                h1 {
                    font-size: 20px;

                    span {
                        font-size: 25px;
                    }
                }

                p {
                    display: block;
                    color: #FFF !important;
                    max-width: 100%;
                    font-size: 14px;
                }

                a {
                    padding: 7px 25px;
                    margin-top: 20px;
                    font-size: 14px;
                }
            }
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            .select-plan-main {
                .package_parent {
                    grid-column-gap: 0;
                    grid-template-columns: repeat(1, 1fr);
                    flex-direction: column;

                    .custom_pkg_main {
                        flex: 0 0 100%;
                        max-width: 100%;

                        &:last-child {
                            margin-bottom: 0;
                        }

                        .custom_pkg {
                            min-height: 580px;

                            .pkg_detail_list_ift {
                                li {
                                    font-size: 13px;
                                    padding-bottom: 8px;
                                }
                            }

                            .incld-live-shoping {
                                ul {
                                    li {
                                        font-size: 13px;
                                        padding-bottom: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .plateforms {
                .shop-logo {
                    .sl-1 {
                        width: 120px;
                    }

                    .sl-2 {
                        width: 120px;
                    }

                    .sl-3 {
                        width: 100px;
                    }
                }
            }
        }
    }

    .brand-sec-4 {
        .brand-inf-pop-cats {
            .pop-cat-box-main {
                margin-top: 20px;
                grid-row-gap: 35px;
                grid-column-gap: 15px;
            }
        }
    }

    .brnd-sec-3 {
        .boost-sale-main {
            padding: 40px 0;

            .boost-sale-content-main {
                max-width: 100%;
                margin-top: 30px;
            }

            .boost-sale-content {
                display: block;

                .boost-sale-content-box {
                    width: 100%;
                    margin-bottom: 20px;
                    padding-bottom: 10px;
                    padding-right: 10px;
                    padding-left: 10px;

                    &:first-child {
                        border-right: none;
                        border-bottom: solid 1px #FFF;
                    }

                    .boost-inr {
                        .boost-inr-left {
                            width: 60px;
                            height: 60px;
                            flex-basis: 60px;
                        }

                        .boost-inr-right {
                            padding-left: 20px;

                            h2 {
                                font-size: 18px;
                            }

                            p {
                                line-height: initial;
                            }
                        }
                    }
                }

                &.boost-gap {
                    .boost-sale-content-box {
                        padding-top: 0px;
                    }
                }

                &.boost-gap-bottom {
                    .boost-sale-content-box {
                        padding-bottom: 10px;
                    }
                }

                &:last-child {
                    .boost-sale-content-box {
                        &:last-child {
                            margin-bottom: 0px;
                        }

                    }
                }
            }
        }
    }

}

@media only screen and (max-width: 650px) {

    .brnd-sec-3 {
        &.easy-to-buy {
            .easy-buy-wrapper {
                .pop-cat-box-main {
                    grid-template-columns: repeat(1, 1fr);
                }
            }
        }
    }



    .brand-sec-4 {
        .brand-inf-pop-cats {
            .pop-cat-box-main {
                grid-template-columns: repeat(2, 1fr);

                .cat-box-link {
                    .lv-item {
                        .d-shop-main {
                            .d-shop-now {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .brand-sec-5 {
        .connecting-shopper {

            .reward-box-main {
                margin-top: 25px;
                grid-row-gap: 20px;
                grid-column-gap: 15px;
                grid-template-columns: repeat(1, 1fr);

                .reward-boxes {
                    padding: 15px;

                    .reward-box-iner {
                        .reward-icon {
                            width: 55px;
                            height: 55px;
                            flex-basis: 55px;
                        }
                    }
                }
            }
        }
    }

    .brand-sec-6 {
        .integrated-sec {
            .plateforms {
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-row-gap: 25px;

                .shop-logo {
                    flex: auto;
                    max-width: initial;
                }
            }

            .about-inf-mkt {
                .social-inf-main {
                    justify-content: space-around;

                    .social-inf-box {
                        a {
                            font-size: 14px;

                            br {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 350px) {
    .brand-sec-6 {
        .integrated-sec {
            .select-plan-main {
                .package_parent {
                    .custom_pkg_main {
                        .custom_pkg {
                            .pkg_detail_list_ift {
                                li {
                                    font-size: 12px;
                                }
                            }

                            .incld-live-shoping {
                                ul {
                                    li {
                                        font-size: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}



/* Portrait Ipad Pro*/
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    .brand-sec-banner {
        height: calc(100vh - 60vh);
    }
}


.signup-border-content {
    // background: #efdfcb;
    background: #cfe8f9;
    margin: 0 auto 0px;
    padding: 50px 0;
    width: 100%;

    h2 {
        font-size: 30px;
        font-weight: 800 !important;
        color: #000080;
    }
}

.customer-video {
    width: 70%;
    border-radius: 10px;
}

.mb-50 {
    margin-bottom: 50px !important;
}

.app-buttons {
    display: flex;
    gap: 30px;
}

.roud-link {
    background-color: #FFF;
    color: #000;
    font-family: Nunito Sans, sans-serif !important;
    padding: 10px 35px;
    font-weight: 700;
    border-radius: 37px;
    display: inline-block;
    margin-top: 25px;
    font-size: 16px;

    &:hover {
        background-color: #000;
        color: #FFF;
    }
}



@media screen and (min-width: 1440px) {
    .bioshop-sec-main .bioshop-container .bioshop-box .public-why-home-img {
        width: 70%;
    }

    .mid-width {
        -webkit-padding-end: 144px;
        -webkit-padding-start: 144px;
        box-sizing: content-box;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        max-width: 1632px;
        padding-inline-end: 144px;
        padding-inline-start: 144px;
    }

    .max-1140 {
        max-width: 1140px !important;
    }
}

@media screen and (min-width: 600px) {
    .mid-width {
        -webkit-padding-end: 48px;
        -webkit-padding-start: 48px;
        padding-inline-end: 48px;
        padding-inline-start: 48px;
    }
}

@media screen and (min-width: 100px) {
    .mid-width {
        -webkit-padding-end: 20px;
        -webkit-padding-start: 20px;
        padding-inline-end: 20px;
        padding-inline-start: 20px;
    }
}

@media only screen and (max-width: 991px) {
    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner .bio-hd-end {
        padding-top: 20px;
    }

    .general-section {
        padding: 40px 0;
    }

}

@media only screen and (min-width: 100px) {}


@media only screen and (max-width: 990px) {
    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner .bio-para {
        font-size: 14px;
        padding-bottom: 0px !important;
    }

    .middle-width1 {
        padding-inline-end: 20px !important;
        padding-inline-start: 20px !important;
    }

    .mobile-menu-ifti {
        display: block;
        // position: absolute;
        left: 0;
        width: 32px;
        height: 32px;
        border: solid 1px #CCC;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .barsBtn {
            img {
                width: 16px;
                display: block;
            }
        }
    }

    .public-header .public-header-content {
        padding: 12px 0;
    }


    .ant-drawer-content {
        .ant-drawer-header {
            padding-left: 16px;
            padding-right: 16px;

            .ant-drawer-header-title {
                button {
                    padding-left: 0;
                    font-size: 18px;
                    color: #000;
                }
            }
        }
    }

    .public-menu {
        &.hide-mobile {
            display: none;
        }
    }

    .public-menu {
        >ul {
            flex-direction: column;

            >li {
                padding: 16px 0;

                >a {
                    font-size: 16px;
                    font-weight: 600;
                }
            }
        }
    }

    .sign-mobile-drawer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px;

        h4 {
            font-size: 1.3rem;
            font-weight: 400 !important;
            margin-bottom: 16px;
            text-align: center;

            span {
                font-weight: 700;
            }
        }

        .sign-mb-btns {
            display: flex;
            width: 100%;
            justify-content: center;
            grid-gap: 16px;
            justify-content: space-between;
            text-align: center;
            border-bottom: solid 1px #b9b9b9;
            padding-bottom: 32px;
            margin-bottom: 32px;

            a {
                display: inline-flex;
                flex: 1 1 0%;
                border-radius: 100px;
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 10px;
                padding-bottom: 10px;
                transition: all 200ms ease 0s;
                box-shadow: 0px 4px 8px #0a164626;
                justify-content: center;
                color: #FFF;
                font-family: Nunito Sans, sans-serif !important;
                font-weight: 700;
                background-color: #010b40;
                font-size: 16px;
            }
        }
    }

}



@media only screen and (max-width: 767px) {
    .bnr-section-2.new-cta-style {

        padding-top: 30px;
        padding-bottom: 5px;
    }
    .roud-link {
        padding: 7px 25px;
        font-size: 14px;
    }

    .bnr-section-2 .one-mark a {
        font-size: 14px;
    }

    .signup-border-content {
        h2 {
            font-size: 1.5rem;
        }
    }

    .privacy_header {
        h5 {
            font-size: 16px;
            line-height: 20px;
            font-weight: 700 !important;
        }
    }

    .public-header {
        // padding: 7px 0;
        padding: 0px 0;

        .mid-width {
            -webkit-padding-end: 10px;
            -webkit-padding-start: 10px;
            padding-inline-end: 10px;
            padding-inline-start: 10px;
        }

        .public-logo {
            img {
                width: 70px;
            }
        }


    }


    .customer-video {
        width: 100%;
    }

    .public-header {
        padding: 7px 0;

        .mid-width {
            -webkit-padding-end: 10px;
            -webkit-padding-start: 10px;
            padding-inline-end: 10px;
            padding-inline-start: 10px;
        }

        .public-menu {
            ul {
                padding: 0;

                li {
                    padding: 0 5px;

                    a {
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .default-btn {
        &.rounded {
            font-size: 12px;
            margin: 0 0 0 5px;
            padding: 5px 10px 0px 10px !important;
            height: 30px;
        }
    }


    .brd_footer_main {
        display: none;
    }


    .mobile_footer_ifti {
        display: block;
        background-color: #252525;

        .ant-collapse {
            background-color: #252525;

            .ant-collapse-header {
                font-family: Nunito Sans, sans-serif !important;
                text-transform: uppercase;
                color: #FFF;
                opacity: .7;
            }

            .ant-collapse-content {
                background-color: #252525;
                border: none;

                .ant-collapse-content-box {
                    padding-top: 0;

                    .brd-footer-box {
                        ul {
                            list-style-type: none;
                            margin: 0;
                            padding: 0;

                            li {
                                width: 100%;

                                a {
                                    color: #fff;
                                    display: block;
                                    font-size: 14px;
                                    padding-bottom: 5px;
                                    padding: 10px 0;
                                }
                            }
                        }
                    }
                }
            }
        }

        .brd_footer {
            width: 100%;
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px 0;

            .brd-right {
                align-items: center;
                display: flex;

                .brd-social {
                    display: flex;
                    flex-direction: row;
                    grid-column-gap: 10px;

                    .fa-stack {
                        font-size: 1.5rem;

                        .fa-stack-2x {
                            color: #FFF;
                            transition: all 0.2s ease;
                            opacity: 0.7;
                        }

                        .fa-stack-1x {
                            color: #000;
                            transition: all 0.2s ease;
                            font-size: 1rem;
                        }
                    }
                }
            }

            .brd-copyright {
                color: #fff;
                font-size: 1rem;
                margin-top: 10px;
            }
        }
    }


}

@media only screen and (max-width: 500px) {
    .public-header {
        .default-btn.rounded {
            padding: 5px 7px 0px 7px !important;
        }

        .public-header-content {
            gap: 0;

            .header-left {
                gap: 10px;

                .public-menu {
                    width: 120px;

                    ul {
                        padding: 0;

                        li {
                            a {
                                font-size: 14px;
                            }

                            padding: 0 10px;

                            &:first-child {
                                // padding-left: 0;
                            }

                            &:last-child {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}



@media screen and (min-width: 100px) {
    .midd-width-footer {
        padding-inline-end: 20px;
        padding-inline-start: 20px;
    }
}

@media screen and (min-width: 600px) {
    .midd-width-footer {
        padding-inline-end: 24px;
        padding-inline-start: 24px;
    }
}

@media screen and (min-width: 1440px) {
    .midd-width-footer {
        max-width: 100%;
        padding-inline-end: 24px;
        padding-inline-start: 24px;
        display: flex;
        justify-content: space-between;
        box-sizing: content-box;
        margin: 0 auto;
    }

    .max-1140 {
        max-width: 1140px !important;
    }
}

@media screen and (min-width: 1440px) {
    .midd-width-footer {
        max-width: 100%;
        padding-inline-end: 24px;
        padding-inline-start: 24px;
        display: flex;
        justify-content: space-between;
        box-sizing: content-box;
        margin: 0 auto;
    }

}

.no-bg {
    background: transparent;
}


.ff-avenir-regular {
    font-family:'AvenirNext-Regular'!important;
}

.ff-avenir-demi {
    font-family: 'AvenirNextDemiBold'!important;
}
.ff-avenir {
    font-family: 'AvenirHeavy'!important;
}


.influencers-grid {
  width: 100%;
  display: grid;
  margin-top: 20px;
  margin-bottom: 20px;
  grid-row-gap: 10px;
  grid-column-gap: 10px;
  grid-template-columns: repeat(6, 1fr);
  .influencer-column {
    background-color: #fff;
    padding: 20px;
    min-height: 300px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    align-items: center;
    .influencer-detail-col {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: center;
      .influencer-thumb-main {
        background-color: #e7e7ef;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px;
        border-radius: 50%;
        .influencer-thumb {
          width: 100px;
          height: 100px;
          background-color: #eee;
          border-radius: 50%;
          position: relative;
          overflow: hidden;
          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }
        }
      }
      .influencer-name-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin-top: 20px;
        .influencer-full-name {
          font-size: 16px;
          font-weight: 800;
          font-family: Nunito Sans, sans-serif !important;
          line-height: 20px;
          color: #000;
        }
        .influencer-handle {
          font-family: Nunito Sans, sans-serif !important;
          font-weight: 600;
          color: #7b8994;
          font-size: 1rem;
        }
      }
      .influencer-follow-main {
        display: flex;
        gap: 10px;
        margin-top: 20px;
        span {
          font-family: Nunito Sans, sans-serif !important;
          font-weight: 600;
          color: #000;
          strong {
            font-weight: 800;
            text-transform: uppercase;
          }
        }
      }
      .influencer-social-links{
        display: flex;
        font-size: 20px;
        gap: 10px;
        margin-top: 20px;
      }
    }
    &.loader-bg{
      background-color: #eee;
      box-shadow: none;
    }
  }
  @media only screen and (max-width: 1450px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media only screen and (max-width: 1349px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media only screen and (max-width: 990px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media only screen and (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
    .influencer-column{
        padding: 10px;
    }
  }
}

.inf-hd-main {
  color: #252525;
  font-weight: 800 !important;
  font-size: 30px;
  margin-bottom: 20px;
  text-align: center;
}

.fw-bold {
  font-weight: 700 !important;
}
.td-width-40 {
  min-width: 40px;
  max-width: 40px;
  width: 40px;
}

.td-width-50 {
  min-width: 50px;
  max-width: 50px;
  width: 50px;
}

.td-width-60 {
  min-width: 60px;
  max-width: 60px;
  width: 60px;
}

.td-width-70 {
  min-width: 70px;
  max-width: 70px;
  width: 70px;
}

.td-width-80 {
  min-width: 80px;
  max-width: 80px;
  width: 80px;
}

.td-width-90 {
  min-width: 90px;
  max-width: 90px;
  width: 90px;
}

.td-width-100 {
  min-width: 100px;
  max-width: 100px;
  width: 100px;
}

.td-width-110 {
  min-width: 110px;
  max-width: 110px;
  width: 110px;
}

.td-width-120 {
  min-width: 120px;
  max-width: 120px;
  width: 120px;
}

.td-width-130 {
  min-width: 130px;
  max-width: 130px;
  width: 130px;
}

.td-width-140 {
  min-width: 140px;
  max-width: 140px;
  width: 140px;
}

.td-width-150 {
  min-width: 150px;
  max-width: 150px;
  width: 150px;
}
.td-width-180 {
  min-width: 180px;
  max-width: 180px;
  width: 180px;
}

.td-width-200 {
  min-width: 200px;
  max-width: 200px;
  width: 200px;
}

.td-width-250 {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}

.td-width-300 {
  min-width: 300px;
  max-width: 300px;
  width: 300px;
}
@media screen and (min-width: 1440px) {
  .max-1320 {
    max-width: 1320px !important;
  }
}
.influencers_page_main {
  width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
  .influencers_content {
    width: 100%;
  }
}
.color-theme {
  color: #000080 !important;
}

.influecers-list-wrapper {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
  font-size: 16px;
  .inf-info {
    display: flex;
    align-items: center;
    gap: 10px;
    .text-info {
      text-align: left;
      .inf-name {
        font-weight: 700;
        color: #000080;
        font-size: 16px;
      }
      .inf-link {
        a {
          font-weight: 700;
          color: #7b8994;
          font-size: 16px;
        }
      }
    }
  }
  .number-list {
    font-weight: 700;
    font-size: 18px;
    color: #000080;
  }
  thead {
    vertical-align: bottom;
  }
  th.name {
    min-width: 300px;
  }
  th.profile {
    min-width: 180px;
  }
  th {
    font-size: 16px;
    font-weight: 700;
  }
  tr {
    border-color: #f6f3f8;
  }
  .badge {
    background: #e5e8ee !important;
    padding: 0.45em 0.75em;

    a {
      color: #000000 !important;
      font-size: 0.85rem;
      font-weight: 400;
      &:hover {
        color: #000000 !important;
      }
    }
  }
  .avatar {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 1px solid #d8d8d8;
  }

  .topic-wrap {
    max-width: 250px;
  }
  .topic {
    max-width: 120px;
  }
  .view-profile {
    background-color: #000080;
    border-color: #000080;
    color: #fff;
    font-family: Nunito Sans, sans-serif !important;
    padding: 10px 15px;
    font-weight: 700;
    border-radius: 37px;
    height: auto;
    font-size: 13px;
    &:hover {
      background-color: #000000;
      border-color: #000000;
      color: #fff;
    }
  }
}

.influecers-list-wrapper > :not(caption) > * > * {
  padding: 15px;
  border-style: solid;
  border-width: 0;
  border-bottom-width: 1px;
  border-bottom-color: #e5e5e5;
}
.influecers-list-wrapper > :not(:first-child) {
  border-top: 1px solid #f6f3f8;
}

.influencers-breadcrum-bar {
  .download-link {
    background-color: #000080;
    border-color: #000080;
    color: #fff;
    font-family: Nunito Sans, sans-serif !important;
    padding: 10px 15px;
    font-weight: 700;
    border-radius: 37px;
    height: auto;
    font-size: 13px;
    &:hover {
      background-color: #000000;
      border-color: #000000;
      color: #fff;
    }
  }
}

.country-flags {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  .flag-link {
    color: #000000 !important;
    font-weight: 400;
    &:hover {
      color: #000000 !important;
    }
  }
  .flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
    &.flag-icon-pt {
      background-image: url(https://starngage.com/plus/assets/flags/4x3/us.svg);
      &:before {
        content: "\00a0";
      }
    }
  }
}

.footer-fixed {
display: flex;
flex-direction: column;
min-height: 100vh; 
.live-event-main {
  flex: 1;
}
}
.bnr-section-2 {
    .one-mark {
        &.starter-intro {
            h2 {
                text-align: center;
            }
        }
    }

    &.get-started {
        margin-top: 50px;
    }
}

.brand-sec-banner {
    &.starter-sec-banner {
        background-color: #07083e;
        height: 584px;

        .brn-bnr-box-left {
            .bnr-content {
                h1 {
                    color: #ffffff;
                    text-transform: uppercase;

                    span {
                        font-size: 50px;
                        line-height: 50px;
                    }
                }

                h2 {
                    color: #FFF;
                    font-size: 40px;
                    font-weight: 800 !important;
                    margin-bottom: 0;
                    margin-top: 40px;

                    span {}
                }

                p {
                    color: #ffffff;
                    font-size: 17px;
                    margin-top: 0;
                    padding: 10px 0 0;
                }

                .cashback-box-main {
                    .cashback-box {
                        padding: 6px 20px;
                        border: 2px dashed #ffffff;
                        color: #ffffff;
                        margin-top: 25px;
                        display: inline-block;

                        h2 {
                            color: #ffffff;
                            font-size: 34px;
                            text-transform: uppercase;
                            font-weight: 800 !important;
                            margin: 0;
                        }

                        p {
                            color: #ffffff;
                            font-size: 17px;
                            text-transform: uppercase;
                            margin: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}

.bioshop-sec-main {
    &.get-paid {
        background-color: #FFF;
        max-width: 1632px;
        margin: 0 auto;
        padding: 30px 0;

        .bioshop-container {

            // gap: 25px;
            .bioshop-box {
                &.flex-end {
                    justify-content: flex-end;
                }

                .bioshop-box-inner {
                    .bioshop-link {
                        a {
                            background-color: #010b40;
                            color: #ffffff;

                            &:hover {
                                background-color: #000;
                                color: #FFF;
                            }

                        }
                    }
                }
            }

        }

        &.space {
            padding: 100px 0;
        }
    }
}

.starter_page_header {
    width: 100%;
    box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
    position: relative;
    z-index: 10;
    display: none;

    .starter-header {
        width: 100%;
        padding: 16px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;

        a {
            .logo {
                width: 110px;
            }
        }

        .para {
            color: #737373;
            font-family: Nunito Sans, sans-serif !important;
            font-weight: 700;
            font-size: 18px;
            margin-bottom: 0;
        }

        .top-btn-sign-in {
            cursor: pointer;
            color: #252525;
            font-family: Nunito Sans, sans-serif !important;
            font-weight: 700;
            font-size: 14px;
        }
    }
}

.points_area {
    background-color: #E5E0FA;
    padding-top: 80px;
    padding-bottom: 80px;
    margin-bottom: 60px;
    width: 100%;

    .points-content-main {
        width: 100%;

        .points-content {
            text-align: center;
            width: 100%;

            h2 {
                color: #252525;
                font-weight: 800 !important;
                font-size: 30px;
                margin-bottom: 10px;
            }

            p {
                font-size: 16px;
            }
        }

        .points-main {
            width: 100%;
            margin-top: 40px;
            display: grid;
            grid-row-gap: 65px;
            grid-column-gap: 40px;
            grid-template-columns: repeat(3, 1fr);

            .points-box {
                text-align: center;

                .point-icon {
                    width: 65px;
                    height: 65px;
                    flex-basis: 65px;
                    position: relative;
                    overflow: hidden;
                    transition: box-shadow 0.25s;
                    padding: 4px;
                    flex-grow: 0;
                    flex-shrink: 0;
                    color: black;
                    margin: 0 auto 5px;

                    svg {
                        width: 100%;
                        height: 100%;
                        color: black;
                        display: block;
                    }
                }

                h3 {
                    font-weight: 800 !important;
                    font-size: 25px;
                    margin-bottom: 5px;
                    line-height: 30px;
                }

                p {
                    font-size: 16px;
                    font-weight: 700;
                    line-height: 20px;
                }
            }
        }
    }
}

.bnr-section-2 {
    &.starter-btm-cta {
        p {
            width: 50%;
            text-align: center;
            font-size: 16px;
            padding-top: 60px;
            margin: 0 auto 10px;
        }
    }
}


.brnd-sec-3 {
    &.how-works {
        // background-color: #e5e0fa;
        background-color: #efdfcb;
   

        &.grey-bg {
            background-color: #e5e0fa;
        }

        .boost-sale-main {
            .boost-sale-header {

                h3,
                h2 {
                    color: #513e43;
                }
            }

            .promote-brand-main {
                .promote-brand-box {
                    .promote-content {
                        h2 {
                            color: #513e43;
                        }

                        p {
                            color: #513e43;
                        }
                    }
                }
            }
        }
        &.new-works {
            background-color: #cfe8f9;

            .boost-sale-main {
                .boost-sale-header {
    
                    h3,
                    h2 {
                        color: #000080;
                        font-family: 'AvenirNextDemiBold'!important;
                    }
                }
    
                .promote-brand-main {
                    .promote-brand-box {
                        .promote-content {
                            h2 {
                                color: #000080;
                                font-family: 'AvenirNextDemiBold'!important;
                            }
    
                            p {
                                color: #000080;
                            }
                        }
                    }
                }
            }

        }
    }
}

.analaytics-sec-main {
    &.get-more {
        background-color: #07083e;
        padding: 0;

        .analytics-container {
            .analytics-box {

                // justify-content: center;
                img {
                    width: initial;
                }

                .analytics-box-inner {
                    max-width: initial;
                    min-width: 80%;
                    padding: 20px 0;

                    .play-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .featured-box {
                        display: flex;
                        margin-top: 15px;

                        .more-link {
                            font-family: Nunito Sans, sans-serif !important;
                            padding: 10px 35px;
                            background-color: #FFF;
                            font-weight: 700;
                            color: #000;
                            border-radius: 37px;
                            display: inline-block;
                            font-size: 16px;

                            &:hover {
                                background-color: #000;
                                color: #FFF;
                            }
                        }
                    }

                    h2 {
                        color: #ffffff;
                        font-size: 30px;
                    }

                    p {
                        color: #ffffff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
}


.divided-section {
    background-color: #737997;

    .banner4 {
        max-height: 1000px;
        width: 100%;
        object-fit: cover;
    }

    .banner4-content {
        padding: 50px 200px 50px 50px;

        h2 {
            font-size: 35px;
            font-family: 'AvenirHeavy'!important;
            font-weight: 800 !important;
            // color: #efdfcb;
            color: #000080;

            .sub-text {
                color: white;
            }
        }

        .sub-heading {
            color: white;
            font-size: 30px;
        }

        p {
            font-size: 17px;
            // color: white;
            color: #000080;
            font-family:'AvenirNext-Regular';

        }
    }

    &.section2 {
        background-color: #efdfcb;

        .banner4-content {
            padding: 50px 200px 50px 50px;

            h2 {
                font-size: 35px;
                font-weight: 800 !important;
                color: #000080;
                font-family: 'AvenirHeavy'!important;

                .color-update {
                    // color: #513e43;
                }
            }

            p {
                font-size: 17px;
                color: white;
            }
        }
    }
    &.color-second {
        background-color: #cfe8f9;    
    }
    &.color-third {
        background-color: #4cc9f2;    
    }
    &.color-four {
        background-color: #4cc9f2;   
    .banner4-content   { 
        h2 {
            .sub-text {
            color: #000080;
            }
        }
            .sub-heading {
                color: #000080;
                font-family: 'AvenirNextDemiBold'!important;
            } 
        } 
       
    }
    &.new-divided {
        background-color: #4cc9f2;
        background-repeat: no-repeat;
        background-position: left top;

        .banner4-content {
            h2 {
                color: #000080;
                font-family: 'AvenirHeavy' !important;
            }

            p {
                color: #000080;
                // font-family: 'AvenirNextDemiBold' !important;
                font-family:'AvenirNext-Regular';
                font-size: 20px;
            }

        }
    }
}


.app-section {
    // background-color: #efdfcb;
    background-color: #cfe8f9;

    .banner4 {
        max-height: 1000px;
        // width: 100%;
        // object-fit: cover;
    }

    .banner4-content {
        padding: 50px 200px 50px 50px;

        h2 {
            font-size: 35px;
            font-family: 'AvenirNextDemiBold'!important;
            font-weight: 800 !important;
            color: #000080;
            margin-bottom: 30px;

            .color-update {
                // color: #513e43;
                color: #000080;
            }
        }

        p {
            font-size: 17px;
            color: white;
        }
    }

}

.points-area-main {
    // font-family: Nunito Sans, sans-serif !important;
    font-family:'AvenirNext-Regular';

    .text-column {
        h4 {
            font-size: 15px;
            text-transform: uppercase;
            font-weight: 800 !important;
            // color: #513e43;
            color: #000080;
        }

        .points-para {
            font-size: 15px;
            font-weight: 600 !important;
            line-height: initial;
            // color: #7c7c7c;
            color: #000080;

        }
    }
}

.apps-button-cta {
    display: flex;
    gap: 25px;
    margin-bottom: 25px;

    .apps-button {
        max-width: 180px;
    }
}



.animated-text {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease-out;
    transition-delay: var(--animation-delay, 0s);
    /* Default delay is 0s */
}

.animate-on-scroll {
    opacity: 1;
    transform: translateY(0);
}

.loading-banner {
    // background-color: #e7cfb4;
    background-color: #cfe8f9;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 9999;
    transition: all 200ms ease-in;

    .loading-logo {
        max-width: 70%;
    }

    .loading-hd {
        font-size: 260px;
        line-height: 200px;
        font-weight: 700 !important;
        color: #000080;
        margin: 0px;
    }

    .loading-hd2 {
        font-size: 55px;
        font-weight: 500 !important;
        color: #000;
        margin: 0px;
    }
}

.fade-out {
    opacity: 0;
    transition: opacity 0.5s ease-out;
}

.new-theme-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
    padding-bottom: 15px;

    .theme-banner-content {
        display: flex;
        align-items: center;
        padding-inline-start: 144px;

        h1 {
            color: #000080;
            font-size: 70px;
            font-family: 'AvenirHeavy' !important;
            margin-bottom: 10px;

            span {
                // font-weight: 800;
            }
        }

        p {
            font-family: 'AvenirNextDemiBold' !important;
            color: #000080;
            font-size: 20px;
            margin-bottom: 0;
            margin-top: 0;
            max-width: 515px;
            padding-top: 10px;
        }

        a {
            padding: 10px 35px;
            background-color: #FFF;
            font-weight: 700;
            color: #000;
            border-radius: 37px;
            display: inline-block;
            margin-top: 35px;
            font-size: 16px;
            box-shadow: 0px 4px 8px #0a164626;

            &:hover {
                background-color: #000;
                color: #FFF;
            }
        }
    }

    .banner-video {
        max-height: 1000px;
        width: 100%;
        padding: 50px 0;
        padding-inline-end: 144px;
        display: flex;
        justify-content: center;
        align-items: center;

        video {
            // max-width: 100%;
            height: 100%;
            border-radius: 2rem;
        }
    }
}

@media screen and (min-width: 990px) {

    .divided-section {
        .banner4-content {
            &.discount-content {
                max-width: 500px;
                padding: 50px;
            }

            &.section2-content {
                max-width: 550px;
                padding: 50px 0px 50px 50px;

                h2 {
                    max-width: 500px;
                }

            }
        }

    }

    .app-section {
        .banner4-content {
            max-width: 500px;
            padding: 50px;
        }

    }



}


@media screen and (min-width: 1440px) {
    .max-1140 {
        max-width: 1140px !important;
    }
}

@media only screen and (max-width: 1640px) {
    .new-theme-banner .theme-banner-content {
        padding-inline-start: 70px;
    }

    .new-theme-banner .banner-video {
        max-height: 700px;
        padding: 50px 0;
        padding-inline-end: 70px;
    }

    .new-theme-banner .theme-banner-content h1 {
        font-size: 50px;
    }

    .new-theme-banner .theme-banner-content p {
        font-size: 18px;
        margin-bottom: 0;
    }
}

@media only screen and (max-width: 1270px) {

    .brand-sec-banner {
        &.starter-sec-banner {
            .brn-bnr-box-left {


                .bnr-content {

                    h1 {
                        font-size: 25px;

                        span {
                            font-size: 25px;
                            line-height: 30px;
                        }
                    }

                    h2 {
                        font-size: 25px;
                        line-height: 30px;
                        margin-top: 20px;
                    }

                    p {
                        max-width: 100%;
                        font-size: 14px;
                    }

                    a {
                        margin-top: 20px;
                    }

                    .cashback-box-main {
                        .cashback-box {
                            h2 {
                                font-size: 28px;
                            }

                            p {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }
        }
    }

    .analaytics-sec-main {
        &.get-more {
            .analytics-container {
                .analytics-box {
                    .analytics-box-inner {

                        padding: 20px;

                        .play-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .featured-box {
                            display: flex;
                            margin-top: 15px;
                        }

                        h2 {
                            font-size: 1.5rem;
                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .divided-section {
        .banner4-content {
            padding: 50px 50px 50px 50px;
        }
    }

    .app-section {
        .banner4-content {
            padding: 25px;
        }
    }

    .loading-banner {
        .loading-logo {
            max-width: 70%;
        }

        .loading-hd {
            font-size: 180px;
            line-height: 150px;

        }

        .loading-hd2 {
            font-size: 40px;
            font-weight: 500 !important;
        }
    }

}

@media only screen and (max-width: 990px) {

    .new-theme-banner .theme-banner-content {
        display: flex;
        align-items: center;
        padding-inline-start: 25px;

        h1 {
            font-size: 40px;
            margin-bottom: 5px;
        }

        p {
            font-size: 18px;
            padding-top: 0px;
        }
    }

    .new-theme-banner .banner-video {
        max-height: 400px;
        width: 100%;
        padding: 25px 25px;
        padding-inline-end: 30px;
        display: flex;
        justify-content: flex-start;
    }

    .new-theme-banner .banner-video video {
        height: 100%;
    }

    .divided-section {

        .banner4-content {
            padding: 25px;

            h2 {
                font-size: 25px;
                margin-bottom: 0.3rem;
            }

            p {
                font-size: 14px;
                line-height: initial;
                margin-bottom: 0;
            }

            .sub-heading {
                font-size: 20px;
                margin-bottom: 0;
            }

            &.section2-content {
                h2 {
                    font-size: 25px;
                }
            }
        }
    }

    .app-section {
        .banner4 {
            max-height: 500px;
        }

        .banner4-content {
            padding: 25px;
            width: 100%;
            text-align: center;

            h2 {
                font-size: 25px;
                margin-bottom: 10px;
            }

            .apps-button-cta {
                gap: 15px;
                margin-bottom: 10px;
                display: inline-flex;

                .apps-button {
                    max-width: 120px;
                }
            }
        }
    }

    .bioshop-sec-main .bioshop-container .bioshop-box .bioshop-box-inner p.mb-mobile-15 {
        margin-bottom: 15px !important;
    }

    .bnr-section-2.get-started.mobile-space-top-0 {
        margin-top: 0px;
    }

    .bnr-section-2 {
        &.get-started {
            &.mob-space {
                margin-top: 0;
            }

        }

    }

    .analaytics-sec-main {
        &.get-more {
            .analytics-container {
                .analytics-box {
                    .analytics-box-inner {
                        .app-links {
                            display: none;

                        }

                        p {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }

    .brand-sec-banner {
        &.starter-sec-banner {
            .brn-bnr-box-left {
                .bnr-content {
                    h1 {
                        span {
                            font-size: 24px;
                            line-height: initial;
                        }
                    }

                    .cashback-box-main {
                        display: none;
                    }
                }
            }
        }
    }


    .points_area {
        padding-top: 40px;
        padding-bottom: 40px;
        margin-bottom: 40px;

        .points-content-main {

            .points-content {

                h2 {
                    font-size: 1.5rem;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 16px;
                }
            }

            .points-main {
                margin-top: 20px;
                display: grid;
                grid-row-gap: 65px;
                grid-column-gap: 40px;
                grid-template-columns: repeat(3, 1fr);

                .points-box {
                    text-align: center;

                    .point-icon {
                        width: 55px;
                        height: 55px;
                        flex-basis: 55px;
                    }

                    h3 {
                        font-size: 18px;
                        line-height: 25px;
                    }

                    p {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .brand-sec-banner {
        &.starter-sec-banner {
            margin-top: 0;
        }
    }

    .starter_page_header {
        .starter-header {
            p {
                display: none;
            }
        }
    }
}


@media only screen and (max-width: 767px) {
    .new-theme-banner {
        .theme-banner-content {
            text-align: center;
            padding-inline-start: 15px;

            h1 {
                font-size: 25px;
            }

            p {
                font-size: 15px;
                max-width: initial;
            }

            a {
                margin-top: 15px;
            }

        }

        .banner-video {
            justify-content: center;
        }
    }

    .loading-banner {
        .loading-hd {
            font-size: 100px;
            line-height: 100px;
        }

        .loading-hd2 {
            font-size: 22px;
            font-weight: 500 !important;
        }
    }

    .divided-section {
        .banner4 {
            max-height: 500px;
        }

        .banner4-content {
            padding: 20px;

            h2 {
                // font-size: 20px;
            }

            p {
                font-size: 14px;
                margin-bottom: 0;
            }

            &.section2-content {
                padding: 20px;

                h2 {
                    // font-size: 20px;
                }
            }
        }
        &.new-divided {
            background-color: #4cc9f2;
            background-repeat: no-repeat;
            background-position: left top;
    
            .banner4-content {
                h2 {
                }
    
                p {
                    font-size: 15px;
                }
    
            }
        }

    }


    .analaytics-sec-main {
        &.get-more {
            .analytics-container {
                align-items: initial;

                .analytics-box {
                    .analytics-box-inner {
                        .app-links {
                            justify-content: initial;

                        }

                        max-width: initial;
                        min-width: 100%;
                        padding: 20px;

                        .play-box {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                        }

                        .featured-box {
                            display: table;
                            margin: 15px auto 0;

                            .more-link {
                                padding: 7px 25px;
                                font-size: 14px;
                                font-weight: 600;
                                display: table;
                                margin: auto;
                            }
                        }

                        h2 {
                            font-size: 30px;
                            line-height: 35px;
                        }

                        p {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
    }

    .bioshop-sec-main {

        .bioshop-container {
            &.not-reverse {
                flex-direction: column;
                align-items: center;
                flex-direction: column;
            }
        }
    }

    .brand-sec-banner {
        &.starter-sec-banner {
            .brn-bnr-box-left {
                .bnr-content {
                    h1 {
                        span {
                            font-size: 20px;
                            // line-height: 15px;

                        }
                    }
                }
            }
        }
    }
}




@media only screen and (max-width: 440px) {
    .loading-banner {
        .loading-hd {
            font-size: 70px;
            line-height: 70px;
        }

        .loading-hd2 {
            font-size: 15px;
            font-weight: 500 !important;
        }
    }
}

.no-scroll {
    overflow: hidden !important;
}
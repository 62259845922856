.insta-instructions {
	padding: 0 0 0 1rem;
	margin: 0;
	li {
		font-size: 14px;
		color: #252525;
		font-weight: 400 !important;
		margin-bottom: 10px;
		list-style-type: decimal;
	}
}
.remember-text {
	font-size: 13px;
	font-weight: bold;
}
.login-before-chat {
	display: flex;
	height: calc(100vh - 88px);
	width: 100%;
	.before-login-inner {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 0 20px;
		h1 {
			font-size: 1.15rem;
			font-weight: 800 !important;
			margin-bottom: 15px;
		}
		button {
			background-color: #000080;
			border-color: #000080;
			border-radius: 37px;
			color: #fff;
			cursor: pointer;
			display: inline-block;
			font-family: Nunito Sans, sans-serif !important;
			font-size: 16px;
			font-weight: 700;
			width: 100%;
			&:hover {
				background-color: #000;
			}
			&:focus {
				box-shadow: none;
			}
		}
	}
}
.load-container-main {
	display: flex;
	height: calc(100vh - 125px);
	width: 100%;
}
.load-container {
	display: flex;
	width: 100%;
	height: 100%;
	background-color: #fff;
	justify-content: center;
	align-items: center;
}
// #frame .content .messages ul li{
//   padding-right: 5px;
// }
// #frame .content .messages ul li img{
//   width: 30px;
//   height: 30px;
// }
// .replies{
//   .message-box-main{
//     float: right;
//   }
// }
// .sent{
//   .message-box-main{
//     float: left;
//   }
// }
// .msg-date-main{
//   .msg-date{
//     bottom: 0 !important;
//     left: 5px;
//   }
// }
.react-multiple-carousel__arrow {
	z-index: 2 !important;
}
.custom-backdrop {
	z-index: 1050;
}
.code-activated-popup {
	.modal-content {
		.modal-header {
			padding: 0;
			border: none;

			.close {
				margin: 0;
				position: absolute;
				right: 5px;
				top: 0;
				z-index: 9;
				font-size: 2rem;
				padding: 5px;
				opacity: 1;
			}
		}

		.act-brand-logo {
			display: flex;
			max-width: 100%;
			justify-content: center;
			margin-top: 50px;
			img {
				max-width: 120px;
				filter: invert(100%);
				-webkit-filter: invert(100%);
			}
		}
		.code-detail {
			display: flex;
			max-width: 100%;
			align-items: center;
			justify-content: center;
			margin-top: 20px;
			flex-direction: column;
			h1 {
				font-weight: 800 !important;
				font-size: 4rem;
				display: flex;
				flex-direction: column;
				align-items: center;
				span {
					font-weight: 700;
					font-size: 2rem;
					color: green;
				}
			}
			.code-button {
				background: #000080;
				border-color: #000080;
				width: 90%;
				margin-top: 20px;
				margin-bottom: 50px;
				padding: 10px 0;
				color: #fff;
				text-align: center;
				border-radius: 4px;
			}
		}
	}
}
.form-close-modal {
	.modal-content {
		.modal-header {
			padding: 0;
			border: none;

			.close {
				margin: 0;
				position: absolute;
				right: 5px;
				top: 0;
				z-index: 9;
				font-size: 2rem;
				padding: 5px;
				opacity: 1;
			}
		}
	}
}

.login-signup .join-now-btn  {
	 display:none;
}

.partner-page .join-now-btn  {
	display:block;
}

.partner-page .sign-in-btn {
	display:none;	
}

.login-signup {
	display: flex;
	align-items: center;

	.tp-frm-btns {
		background-color: #000080;
		color: #fff;
		font-family: Nunito Sans, sans-serif !important;
		font-weight: 700;
		padding: 8px 23px;
		border-radius: 37px;
		font-size: 14px;
		cursor: pointer;

		&:first-child {
			margin-right: 10px;
		}
		&.remove-space-right {
			margin-right: 0px;
		}
	}



	.country-select {
		margin-left: 15px;
		.ant-select-single {
			font-size: 14px;
			font-family: Nunito Sans, sans-serif !important;
			font-weight: 500;
			.ant-select-selector {
				border-color: #000;
				padding: 0 10px;
				min-width: 185px;
				.ant-select-selection-item {
					max-width: 185px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.ant-select-selection-placeholder {
					color: #000;
				}
			}
			.ant-select-arrow {
				color: #000;
			}
		}
	}
}
.signup-modal {
	.modal-content {
		border-radius: 8px;
	}
}
.login-signup-ift {
	.ant-form-item-control-input {
		.ant-form-item-feedback-icon-error {
			line-height: 0;
		}
	}

	h2 {
		text-align: center;
		font-weight: 800 !important;
		font-size: 24px;
		margin-bottom: 20px;
	}
	.ant-btn-primary {
		background-color: #000080;
		border-color: #000080;
		transition: none;
		&:hover {
			background-color: #000000;
			border-color: #000000;
			transition: none;
		}
		&:focus {
			background-color: #000080;
			border-color: #000080;
			transition: none;
		}
	}
	.frm-btns {
		.ant-form-item-control-input-content {
			display: flex;
			justify-content: space-between;
		}
		.ant-btn-primary {
			width: 48%;
			transition: none;
		}
	}
	.ant-input::placeholder {
		color: #000;
	}
	.ant-input-number-input::placeholder {
		color: #000;
	}
	.ant-select-lg {
		font-size: 14px;
	}
	.ant-input-lg {
		font-size: 14px;
		height: 45px;
	}
	.ant-input-affix-wrapper-lg {
		font-size: 14px;
	}
	.ant-select-selector {
		height: 45px !important;

		.ant-select-selection-search-input {
			height: 43px !important;
		}
		.ant-select-selection-placeholder {
			line-height: 43px;
			color: #000;
		}
	}
	.ant-input-affix-wrapper {
		height: 45px;
		.ant-input-lg {
			height: auto;
		}
	}
	.ant-input-number-lg {
		padding: 0;
		font-size: 14px;
		width: 100%;
		height: 45px;
		input {
			height: 45px;
		}
	}
	.account-choose-container {
		width: 100%;

		.ac_type_block {
			margin-bottom: 10px;
			background-color: #fff;
			position: relative;

			label {
				width: 100%;
				display: flex;
				cursor: pointer;
				margin-bottom: 0;
				padding: 10px 10px;
				// align-items: center;
				border: solid 1px #b3c4c6;
				border-radius: 8px;
				position: relative;
				background: #f4f9fa;

				.imp-brnd {
					// height: 26px;
					// width: 26px;
					// flex: 0 0 26px;
					// max-width: 26px;
					// background-repeat: no-repeat;
					// background-size: 34px 34px;
					// background-color: #FFF;
					// border-radius: 50%;
					// background-position: center center;
					// display: flex;
					// justify-content: center;
					// align-items: center;
					// font-size: 30px;
					// border: solid 1px #b3c4c6;
					align-items: center;
					background-color: #fff;
					background-position: 50%;
					background-repeat: no-repeat;
					background-size: 34px 34px;
					border-radius: 50%;
					// box-shadow: 0 4px 8px rgba(10,22,70,.15);
					border: solid 1px #b3c4c6;
					display: flex;
					flex: 0 0 64px;
					font-size: 30px;
					height: 64px;
					justify-content: center;
					max-width: 64px;
					width: 64px;
				}

				.brnd-right-content {
					padding-left: 10px;
					flex: 0 0 87%;
					max-width: 87%;
					margin-top: 4px;
					h4 {
						font-size: 16px;
						color: #000;
						font-weight: 800 !important;
						margin-bottom: 0 !important;
					}
					p {
						font-size: 0.9rem;
						font-weight: 400;
						color: #7b8994;
						margin-bottom: 0;
						position: relative;
					}
					.signup_detail {
						width: 100%;
						margin-top: 5px;
						ul {
							margin: 0;
							padding: 0;
							list-style-type: none;
							li {
								font-family: "Nunito Sans", sans-serif !important;
								font-size: 13px;
								padding: 1px 0;
								display: flex;
								align-items: center;
								font-weight: 600;
								i {
									font-size: 8px;
									padding-right: 5px;
									position: relative;
									top: -1px;
								}
							}
						}
					}
				}
			}
			.infchecked:checked + label:after {
				font-family: "FontAwesome";
				content: "\f058";
				position: absolute;
				right: 25px;
				top: 25px;
				font-size: 1.9rem;
				color: #000;
			}
		}
	}
	.enter-otp {
		display: flex;
		width: 100%;
		justify-content: center;

		.optp {
			margin-right: 10px;
			width: 60px;
			height: 60px;
		}
	}
	.otp-timer {
		.ant-statistic-content {
			text-align: center;
			margin-bottom: 24px;
			font-family: "Nunito Sans", sans-serif !important;
			font-weight: 800;
			font-size: 36px;
		}
	}
}
.mobile-style {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin: auto;
	// height: 100vh;
	.logo-mob {
		width: 120px;
		margin-bottom: 20px;
	}
	.mob-form {
		width: 100%;
		padding: 0 20px;
	}
	.login-page {
		width: 100%;
		padding: 0 15px;
	}
}
.already {
	// display: flex;
	width: 100%;
	span {
		cursor: pointer;
		color: #007bff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}

.inputStyle {
	width: 50px !important;
	height: 50px;
	margin: 0 0.8rem;
	font-size: 2rem;
	border-radius: 4px;
	border: 1px solid rgba(0, 0, 0, 0.3);
}
// .code-spin-hide {
//   input[type=number].inputStyle {
//     -moz-appearance: textfield;
//   }
// }

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
}

.coming_Soon_main {
	width: 100%;
	background: #fcfafb;

	.coming_soon_inr {
		max-width: 1632px;
		margin: 0 auto;
		height: calc(100vh - 235px);
		align-items: center;
		display: flex;
		justify-content: center;
		flex-direction: column;

		h1 {
			font-weight: 800 !important;
			margin-bottom: 0;
			font-size: 4rem;
		}

		p {
			font-family: Nunito Sans, sans-serif !important;
			font-size: 1.3rem;
			color: #7b8994;
			margin-bottom: 0;
			margin-top: 1rem;
			text-align: center;
		}
		.app_buttons {
			width: 100%;
			text-align: center;
			margin-top: 30px;

			a {
				&.apple-store {
					margin-right: 1rem;
				}
				img {
					width: 150px;
				}
			}

			h4 {
				font-weight: 800 !important;
				text-transform: uppercase;
				margin-bottom: 1rem;
			}
		}
	}
}

.bredcrumb_main {
	width: 100%;
	margin-top: 30px;

	.bredcurmb {
		width: 100%;

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
			display: flex;

			li {
				display: inline-flex;
				align-items: center;

				span {
					margin: 0 5px;
				}
				a {
					color: #7b8994;
					font-size: 1rem;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;

					&:hover,
					&.active {
						color: #000;
						text-decoration: underline;
					}
				}
			}
		}
	}
}

.pik-header {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
	align-items: center;

	h3 {
		font-weight: 800 !important;
	}
	a,
	span {
		font-weight: 700;
		font-family: "Nunito Sans", sans-serif !important;
		font-size: 16px;
		color: #000;
		cursor: pointer;
		&:hover {
			text-decoration: underline;
		}
	}
	.sort-filter {
		display: flex;
		.ant-select-lg {
			width: 250px;
			.ant-select-selection-item {
				font-size: 14px;
				&:hover {
					text-decoration: none;
				}
			}
		}
	}
}
.react-multiple-carousel__arrow {
	min-height: 100% !important;
	min-width: auto !important;
}
.react-multiple-carousel__arrow--right {
	right: calc(0% + 1px) !important;
	background: none !important;
}
.react-multiple-carousel__arrow--left {
	left: calc(0% + 1px) !important;
	background: none !important;
}
.cus-srh-mod {
	.modal-content {
		min-height: 70vh;

		.modal-body {
			background-color: #fff;

			.src-inupt input.form-control {
				border-color: #000;
			}

			.filter_brands {
				display: grid;
				grid-row-gap: 32px;
				grid-column-gap: 24px;
				grid-template-columns: repeat(4, 1fr);

				.ft_box {
					.ft_box_link {
						display: inline-flex;
						align-items: center;
						min-width: 100%;

						.ft_logo {
							width: 88px;
							height: 88px;
							flex-basis: 88px;
							background: #ffffff;
							position: relative;
							overflow: hidden;
							border-radius: 6px;
							box-shadow: 0px 4px 8px #0a164626;
							transition: box-shadow 0.25s;
							padding: 4px;
							flex-grow: 0;
							flex-shrink: 0;

							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
							}
						}
						.ft_content {
							display: flex;
							min-width: 0px;
							flex-direction: column;
							flex-grow: 1;
							margin-left: 12px;
							width: 100%;

							.ft-1,
							.ft-2 {
								display: flex;
								align-items: center;

								span.ft-name {
									font-size: 16px;
									font-weight: 800;
									font-family: Nunito Sans, sans-serif !important;
									line-height: 20px;
									color: #000;
								}
								span.ft-off {
									font-size: 16px;
									font-weight: 800;
									font-family: Nunito Sans, sans-serif !important;
									line-height: 20px;
									color: #ae272d;
								}
							}
						}
					}
				}
			}
		}

		.modal-header {
			position: relative;
			justify-content: center;
			border: none;
			box-shadow: 0px 4px 8px #0a164626;
			z-index: 1;
			margin-bottom: 20px;
			padding-top: 2rem;
			padding-bottom: 2rem;

			.h4 {
				font-weight: 800 !important;
				margin-bottom: 0;
			}

			.close {
				position: absolute;
				top: 8px;
				right: 15px;
				font-size: 2.5rem;
				opacity: 1;
			}
		}
	}
}
.ant-dropdown-arrow {
	box-shadow: 1px 1px #999 !important;
	top: 10.414214px !important;
}
.ant-dropdown-menu {
	border: solid 1px #ccc;
	padding: 0;
	&.notificaion-menu {
		// max-height: 400px;
		// overflow: auto;
		.ant-dropdown-menu-item {
			width: 300px;
			padding: 0px 0;
			.highlight-notification {
				padding: 0px 12px;
				display: block;
				font-size: 12px;
				span {
					padding: 5px 0;
				}
			}
			.read-notification {
				background-color: white;
			}
			.new-notification {
				background-color: #c0bfbf;
			}
		}
	}
}
.ant-dropdown-menu-item {
	border-bottom: solid 1px #ccc;
	padding-top: 0;
	padding-bottom: 0;
	&:last-child {
		border-bottom: none;
	}
}
.ant-dropdown-menu-title-content {
	width: 200px;
	span {
		width: 100%;
		padding: 10px 0;
		display: block;
		font-family: Nunito Sans, sans-serif !important;
		font-weight: 600;
		text-overflow: ellipsis;
		overflow: hidden;
		height: initial;
		white-space: nowrap;
		i {
			float: right;
			margin-top: 3px;
		}
	}
}
.settting {
	flex-direction: column;
	align-items: flex-start !important;
	.sub-menu-open {
		display: none;
		span {
			padding-top: 3px;
			padding-bottom: 3px;
			display: flex;
			align-items: center;
			color: rgba(0, 0, 0, 0.85);
			&:hover {
				text-decoration: underline;
			}
			i {
				float: left;
				font-size: 12px;
				margin-right: 5px;
			}
		}

		&.pro-ul {
			display: block;
			margin-left: 10px;
			margin-top: 5px;
		}
	}
}

.c-portal-header {
	width: 100%;

	.p-h-inner {
		width: 100%;
		padding: 16px 0;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.mobile-menu-ifti {
			display: none;
		}

		.p-h-left {
			a .logo {
				width: 110px;
			}
			.mobile-search-container {
				display: none;
			}
		}
		.p-h-middle {
			padding: 0;

			.form-control {
				border-color: #000;
			}
			.input-group-append {
				button {
					background: #000080;
					color: #fff;
					border-color: #000080;
				}
			}
		}
		.mobile-front-menu {
			display: none;
		}
		.p-h-user {
			font-family: Nunito Sans, sans-serif !important;
			font-weight: 700;
			font-size: 0.975rem;
			display: flex;
			align-items: center;

			.ant-space-item svg {
				margin-top: -3px;
			}

			a {
				color: #000;
			}

			.fa-2x {
				font-size: 1.4em;
			}
			.userNameTop {
				max-width: 85px;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			.country-select {
				margin-left: 15px;

				.ant-select-single {
					font-size: 14px;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 500;
					.ant-select-selector {
						border-color: #000;
						padding: 0 10px;
						min-width: 185px;
						.ant-select-selection-item {
							max-width: 185px;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						.ant-select-selection-placeholder {
							color: #000;
						}
					}
					.ant-select-arrow {
						color: #000;
					}
				}
			}
		}
	}
	.c-top-menu {
		width: 100%;
		border-top: solid 1px #dce4ec;
		border-bottom: solid 1px #dce4ec;

		.c-top-menu-inner {
			width: 100%;
			display: flex;
			position: relative;

			ul {
				list-style: none;
				margin: 0;
				padding: 0;
				display: flex;
				width: 100%;
				// justify-content: space-between;
				li {
					padding: 16px;
					display: flex;
					align-items: center;
					position: relative;
					&:first-child {
						padding-left: 0;
					}
					img {
						width: 18px;
						margin-right: 6px;
						float: left;
					}
					a {
						color: #7b8994;
						font-size: 1rem;
						font-family: Nunito Sans, sans-serif !important;
						font-weight: 600;
						text-decoration: none;
						&:hover {
							color: #000;
							text-decoration: none;
						}
						&.active {
							font-weight: 700;
							color: #000;
						}
					}
					.top-submenu {
						position: absolute;
						min-width: 220px;
						left: 0;
						top: 50px;
						padding-top: 5px;
						z-index: 9;
						display: none;
						ul {
							background-color: #fff;
							flex-direction: column;
							box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
							justify-content: flex-start;
							border-radius: 0 0 6px 6px;
							padding: 10px;
							li {
								padding: 0;
								width: 100%;
								border-bottom: solid 1px #dce4ec;
								a {
									width: 100%;
									padding: 12px 10px;
									color: #7b8994;
									&:hover {
										color: #000;
									}
								}

								&:last-child {
									border: none;
								}
							}
						}
					}
					&:hover {
						.top-submenu {
							display: block;
						}
					}
				}
				.kblive {
					display: flex;
					margin-left: auto;

					.aftr-mode {
						padding: 0;
						a {
							right: 0;
							color: #fff;
							background-color: #000080;
							border-radius: 37px;
							color: #fff;
							cursor: pointer;
							font-family: Nunito Sans, sans-serif !important;
							font-size: 14px;
							font-weight: 700;
							padding: 8px 15px;
							margin-right: 16px;
							&:hover {
								background-color: #000;
							}

							i {
								padding-right: 5px;
							}
						}
						&:last-child {
							a {
								margin-right: 0;
							}
						}
					}
				}
			}
		}
	}
}
.register-body-class .c-portal-header,
.register-body-class .brd_footer_main {
	display: none;
}
.mobile-front-menu {
	display: none;
}

.c-portal-banner-main {
	width: 100%;
	margin-top: 50px;

	.c-portal-banner {
		width: 100%;
		position: relative;

		.slick-list {
			margin-left: -10px;
			margin-right: -10px;

			&:after {
				content: "";
				background: linear-gradient(270deg, #ffffff 0%, #ffffff00 93.82%);
				width: 25%;
				position: absolute;
				right: -10px;
				top: 0;
				height: 100%;
			}

			.slick-slide {
				padding-left: 10px;
				padding-right: 10px;
			}
			.slick-slide > div {
				display: flex;
			}
		}

		.react-multi-carousel-item img {
			max-width: 100%;
		}

		&.remove-shadow {
			.slick-slider {
				.slick-list {
					&:after {
						display: none;
					}
				}
			}
		}
		.bnr-height {
			display: flex;
			width: 100%;
			height: 304px;
			position: relative;
			align-items: center;
			overflow: visible;

			.top-bnr-gap {
				width: 100%;
				padding: 0;
				position: absolute;
				height: 100%;
				object-fit: cover;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
				border-radius: 8px;
			}
			.dsk-bnr,
			.iph-bnr {
				display: none;
			}
		}
		.slick-prev {
			background: #fff;
			box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
			border-radius: 50%;
			width: 32px;
			height: 32px;
			z-index: 2;
			left: -15px;

			&.slick-disabled {
				display: none !important;
			}

			&:before {
				font-family: "FontAwesome";
				color: #000;
				font-size: 25px;
				content: "\f104";
			}
		}
		.slick-next {
			background: #fff;
			box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
			border-radius: 50%;
			width: 32px;
			height: 32px;
			z-index: 2;
			right: -15px;

			&.slick-disabled {
				display: none !important;
			}

			&:before {
				content: "\f105";
				font-family: "FontAwesome";
				color: #000;
				font-size: 25px;
				content: "\f105";
			}
		}

		.react-multiple-carousel__arrow::before {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: normal;
			box-shadow: 0px 4px 8px #0a164626;
			font-size: 16px;
			color: #000 !important;
		}
	}
}

.top_picks_main {
	width: 100%;
	margin-top: 70px;

	.top_picks {
		width: 100%;

		.brand-logos {
			width: 100%;
			// display: grid;
			// grid-row-gap: 32px;
			// grid-column-gap: 24px;
			// grid-template-columns: repeat(6, minmax(0, 1fr));

			.slick-slider {
				margin-left: -12px;
				margin-right: -12px;

				.slick-slide > div {
					&:last-child {
						.indv-brand {
							margin-bottom: 0;
						}
					}
				}

				.slick-list {
					padding: 10px 0;
					.slick-track {
						margin: 0;
						.slick-slide {
							padding: 0 12px;
						}
					}
				}
				.slick-next {
					background: #fff;
					box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
					border-radius: 50%;
					width: 32px;
					height: 32px;
					z-index: 2;
					right: -15px;
					top: 48%;

					&:before {
						font-family: "FontAwesome";
						color: #000;
						font-size: 25px;
						content: "\f105";
					}
				}
				.slick-prev {
					background: #fff;
					box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
					border-radius: 50%;
					width: 32px;
					height: 32px;
					z-index: 9;
					left: -15px;
					top: 48%;

					&:before {
						font-family: "FontAwesome";
						color: #000;
						font-size: 25px;
						content: "\f104";
					}
				}
			}

			.indv-brand {
				margin-bottom: 32px;

				a {
					display: inline-flex;
					flex-direction: column;
					width: 100%;
					cursor: pointer;

					.brand-boxes {
						background-color: #fff;
						border-radius: 6px;
						display: inline-flex;
						box-shadow: 0px 0px 8px rgba(10, 22, 70, 0.15);
						min-width: 100%;
						align-items: center;

						.brand-boxes-logos {
							width: 88px;
							height: 88px;
							flex-basis: 88px;
							position: relative;
							overflow: hidden;
							transition: box-shadow 0.25s;
							padding: 4px;
							flex-grow: 0;
							flex-shrink: 0;
							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
								border-radius: 6px;
							}
						}

						.brand-user {
							display: flex;
							flex-grow: 1;
							margin-left: 10px;
							min-width: 0px;
							flex-direction: column;
							width: 100%;

							.brnd-user-title {
								display: flex;
								align-items: center;

								span {
									font-family: "Nunito Sans", sans-serif !important;
									font-weight: 700;
									color: #000;
								}
							}
							.brand-user-off {
								display: flex;
								width: 100%;

								h4 {
									font-weight: 800 !important;
									font-size: 14px;
									margin-top: 0;
									margin-bottom: 0;
									color: #e80c5b;
									text-transform: uppercase;
									position: absolute;
								}
							}
						}

						&.warp {
							background-color: #05144b;
						}
						&.khols {
							background-color: #800033;
						}
					}
				}
			}
		}

		.popular_influencers {
			width: 100%;
			padding-top: 20px;

			.react-multiple-carousel__arrow::before {
				background: #fff;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				display: flex;
				justify-content: center;
				align-items: center;
				line-height: normal;
				box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
				font-size: 16px;
				color: #000 !important;
			}

			.popular-infl-deal {
				margin: 0 20px;
			}

			.ft_box_link_popular {
				display: inline-flex;
				align-items: center;
				min-width: 100%;
				flex-direction: column;

				.ft_logo_popular {
					width: 130px;
					height: 130px;
					flex-basis: 130px;
					background: #ffffff;
					position: relative;
					overflow: hidden;
					border-radius: 0;
					transition: box-shadow 0.25s;
					padding: 0;
					flex-grow: 0;
					flex-shrink: 0;

					img {
						border: 1px solid #ccc;
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: center;
						border-radius: 999px;
					}
				}
				.ft_content_popular {
					display: flex;
					min-width: 0px;
					flex-direction: column;
					flex-grow: 1;
					margin-left: 0;
					width: 100%;
					justify-content: center;
					align-items: center;
					margin-top: 20px;

					.ft-1_popular,
					.ft-2_popular {
						display: flex;
						align-items: center;

						span.ft-name_popular {
							font-size: 16px;
							font-weight: 800;
							font-family: Nunito Sans, sans-serif !important;
							line-height: 20px;
							color: #000;
						}
						span.ft-off_popular {
							font-size: 13px;
							font-weight: 600;
							font-family: Nunito Sans, sans-serif !important;
							line-height: 20px;
							color: #7b8994;
							margin-top: 3px;
						}
					}
				}
			}
		}
	}
}
.brand_you_love_main {
	width: 100%;
	margin-top: 70px;
	padding-top: 70px;
	padding-bottom: 70px;
	background: #29296e;

	.brand_you_love {
		width: 100%;

		.react-multiple-carousel__arrow::before {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: normal;
			box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
			font-size: 16px;
			color: #000 !important;
			margin-top: -57px;
		}

		.pik-header {
			h3 {
				color: #fff;
			}
		}
		.brand-item-lv {
			margin: 0 20px;

			.lv-item {
				position: relative;

				.lv-item-image {
					position: relative;
					width: 100%;
					box-shadow: 0px 4px 8px #0a1646cc;
					padding-bottom: 52.38%;
					background-size: cover;
					// background-image: url(https://assets.vogue.com/photos/5891a7068c64075803ad1ada/16:9/w_1280,c_limit/jessica-alba-jeans.jpg);
					border-radius: 8px;
					transition: box-shadow 0.25s ease 0s;
					overflow: hidden;
					background-position: center center;
					background-repeat: no-repeat;
				}
				.d-shop-now {
					position: absolute;
					background: #fff;
					color: #334251;
					padding: 6px 10px;
					bottom: -15px;
					right: 15px;
					border-radius: 9999px;
					box-shadow: 0px 4px 5px rgba(10, 22, 70, 0.1490196078);
					z-index: 1;
					font-family: "Nunito Sans", sans-serif !important;
					font-weight: 700;
				}
				.lv-logo {
					background-color: #fff;
					width: 48px;
					height: 48px;
					position: absolute;
					bottom: 12px;
					left: 12px;
					border: solid 2px #dddddd;
					border-radius: 50%;
					display: flex;
					align-items: center;
					justify-content: center;
					overflow: hidden;
					img {
						display: block;
						width: 100%;
						height: 100%;
						object-fit: contain;
						object-position: center;
					}
				}
			}
			.lv-detail {
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				width: 100%;
				margin-top: 2rem;

				.lv-d1 {
					display: flex;
					align-items: center;

					.lv-name {
						font-size: 16px;
						font-weight: 800;
						font-family: Nunito Sans, sans-serif !important;
						line-height: 20px;
						color: #fff;
					}
				}
				.lv-d2 {
					display: flex;
					align-items: center;

					.lv-off {
						font-size: 16px;
						font-weight: 800;
						font-family: Nunito Sans, sans-serif !important;
						line-height: 20px;
						color: #fff;
					}
				}
			}
		}
	}
}

.cash_back_main {
	width: 100%;
	margin-bottom: 70px;

	.cash_back {
		width: 100%;

		.cash_back_caursol {
			width: 100%;
			position: relative;

			.slick-list {
				margin-left: -10px;
				margin-right: -10px;

				.slick-slide {
					padding-left: 10px;
					padding-right: 10px;
				}
			}

			.slick-next {
				background: #fff;
				box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
				border-radius: 50%;
				width: 32px;
				height: 32px;
				z-index: 2;
				right: -14px;
				&:before {
					font-family: "FontAwesome";
					color: #000;
					font-size: 25px;
					content: "\f105";
				}
			}
			.slick-prev {
				background: #fff;
				box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.15);
				border-radius: 50%;
				width: 32px;
				height: 32px;
				z-index: 2;
				left: -14px;
				&:before {
					font-family: "FontAwesome";
					color: #000;
					font-size: 25px;
					content: "\f104";
				}
			}
		}

		.react-multiple-carousel__arrow::before {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: normal;
			box-shadow: 0px 4px 8px #0a164626;
			font-size: 16px;
			color: #000 !important;
		}
		.cash-item {
			display: flex;
			width: 100%;
			height: 240px;
			position: relative;
			align-items: center;
			overflow: visible;
			img {
				width: 100%;
				padding: 0;
				position: absolute;
				border-radius: 8px;
				height: 100%;
				object-fit: cover;
				top: 0px;
				left: 0px;
				right: 0px;
				bottom: 0px;
			}
		}
	}
}

.cat_brands_main {
	width: 100%;
	margin-top: 70px;
	margin-bottom: 70px;

	.cat_brands {
		width: 100%;

		.categ-button {
			padding: 3px 15px;
			background: #f2f2f2;
			margin: 0 8px;
			border-radius: 9999px;
			display: flex;
			border: solid 1px #333;
			cursor: pointer;
			justify-content: center;

			&:hover,
			&.active-tab {
				background-color: #00051a;
				border-color: #00051a;
				color: #fff;
			}
		}
		.slick-slider {
			padding: 12px 0;
			margin-bottom: 20px;
		}

		.slick-list {
			margin: 0 20px;
			.slick-track {
				.slick-slide > div {
					padding: 2px 0;
				}
			}
		}
		.slick-prev:before,
		.slick-next:before {
			font-family: "FontAwesome";
			color: #000;
			font-size: 25px;
		}
		.slick-prev:before {
			content: "\f104";
		}
		.slick-next:before {
			content: "\f105";
		}

		.slick-prev,
		.slick-next {
			background: #fff;
			box-shadow: 0px 4px 8px #0a164626;
			border-radius: 50%;
			width: 32px;
			height: 32px;
			z-index: 9;
		}
		.slick-next {
			right: -16px;
		}
		.slick-prev {
			left: -16px;
		}

		.filter_brands {
			display: grid;
			grid-row-gap: 32px;
			grid-column-gap: 24px;
			grid-template-columns: repeat(4, 1fr);

			.ft_box {
				.ft_box_link {
					display: inline-flex;
					align-items: center;
					min-width: 100%;

					.ft_logo {
						width: 88px;
						height: 88px;
						flex-basis: 88px;
						background: #ffffff;
						position: relative;
						overflow: hidden;
						border-radius: 6px;
						box-shadow: 0px 4px 8px #0a164626;
						transition: box-shadow 0.25s;
						padding: 4px;
						flex-grow: 0;
						flex-shrink: 0;

						img {
							display: block;
							width: 100%;
							height: 100%;
							border-radius: 6px;
							object-fit: contain;
							object-position: center;
						}
					}
					.ft_content {
						display: flex;
						min-width: 0px;
						flex-direction: column;
						flex-grow: 1;
						margin-left: 12px;
						width: 100%;

						.ft-1,
						.ft-2 {
							display: flex;
							align-items: center;

							span.ft-name {
								font-size: 14px;
								font-weight: 700;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #000;
							}
							span.ft-off {
								font-size: 14px;
								font-weight: 800;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #e80c5b;
							}
						}
					}
				}
			}
		}

		.filter_brands_influencer {
			display: grid;
			grid-row-gap: 32px;
			grid-column-gap: 24px;
			grid-template-columns: repeat(5, 1fr);
			padding-top: 20px;

			.ft_box_influencer {
				.ft_box_link_influencer {
					display: inline-flex;
					align-items: center;
					min-width: 100%;
					flex-direction: column;

					.ft_logo_influencer {
						width: 110px;
						height: 110px;
						flex-basis: 110px;
						background: #ffffff;
						position: relative;
						overflow: hidden;
						border-radius: 0;
						transition: box-shadow 0.25s;
						padding: 0;
						flex-grow: 0;
						flex-shrink: 0;

						img {
							border: 1px solid #ccc;
							display: block;
							width: 100%;
							height: 100%;
							object-fit: contain;
							object-position: center;
							border-radius: 999px;
						}
					}
					.ft_content_influencer {
						display: flex;
						min-width: 0px;
						flex-direction: column;
						flex-grow: 1;
						margin-left: 0;
						width: 100%;
						justify-content: center;
						align-items: center;
						margin-top: 20px;

						.ft-1_influencer,
						.ft-2_influencer {
							display: flex;
							align-items: center;

							span.ft-name_influencer {
								font-size: 16px;
								font-weight: 800;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #000;
							}
							span.ft-off_influencer {
								font-size: 13px;
								font-weight: 600;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #7b8994;
								margin-top: 3px;
							}
						}
					}
				}
			}
		}

		.all_categories_ift {
			display: grid;
			grid-row-gap: 32px;
			grid-column-gap: 24px;
			grid-template-columns: repeat(6, minmax(0, 1fr));

			.indv-categ {
				a {
					display: inline-flex;
					flex-direction: column;
					width: 100%;
					cursor: pointer;

					.categ-boxes {
						height: 88px;
						position: relative;
						overflow: hidden;
						width: 100%;
						transition: box-shadow 0.25s;
						// box-shadow: 0px 4px 8px rgba(10, 22, 70, 0.1490196078);
						border: solid 1px #ccc;
						border-radius: 8px;
						display: none;

						img {
							display: block;
							width: 100%;
							height: 100%;
							object-fit: cover;
							object-position: center;
						}
					}

					// h4{
					//     font-weight: 800 !important;
					//     font-size: 14px;
					//     margin-bottom: 0;
					//     margin-top: 10px;
					//     color: #000;
					//     display: none;
					// }
					h4 {
						font-weight: 800 !important;
						font-size: 14px;
						margin-bottom: 0;
						margin-top: 0;
						color: #000;
						height: 88px;
						position: relative;
						overflow: hidden;
						width: 100%;
						transition: box-shadow 0.25s;
						border: solid 1px #ccc;
						border-radius: 8px;
						display: flex;
						align-items: center;
						justify-content: center;
						text-transform: uppercase;
					}
				}
			}
		}

		@media only screen and (max-width: 1430px) {
			.slick-next {
				right: -22px;
			}
			.slick-prev {
				left: -22px;
			}
		}
	}
}

.daily_deals_main {
	width: 100%;
	margin-top: 70px;
	margin-bottom: 70px;
	background-color: #fff;

	.daily_deals {
		width: 100%;

		.react-multiple-carousel__arrow::before {
			background: #fff;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			line-height: normal;
			box-shadow: 0px 4px 8px #0a164626;
			font-size: 16px;
			color: #000 !important;
			margin-top: -57px;
		}

		.dail_deal_caursol {
			.deal-item {
				margin: 0 20px;

				.deal-box {
					.deal-box-outer {
						position: relative;

						.deal-box-image {
							position: relative;
							width: 100%;
							box-shadow: 0px 4px 8px rgb(10 22 70 / 15%);
							padding-bottom: 52.38%;
							background-size: cover;
							border-radius: 8px;
							transition: box-shadow 0.25s ease 0s;
							overflow: hidden;
							background-position: center center;
							background-repeat: no-repeat;
							background-color: #252525;

							img {
								display: block;
								width: 100%;
								height: 100%;
								object-fit: contain;
								object-position: center;
								display: none;
							}

							&.warp {
								background-color: #05144b;
							}
							&.kohls {
								background-color: #800033;
							}
						}
						.d-shop-now {
							position: absolute;
							background: #fff;
							color: #334251;
							padding: 6px 10px;
							bottom: -15px;
							right: 15px;
							border-radius: 9999px;
							box-shadow: 0px 4px 5px #0a164626;
							z-index: 1;
							font-family: "Nunito Sans", sans-serif !important;
							font-weight: 700;
						}
					}

					.deal-detail {
						display: flex;
						flex-direction: column;
						flex-grow: 1;
						width: 100%;
						margin-top: 2rem;

						.dl-1,
						.dl-2 {
							display: flex;
							align-items: center;

							span.dl-name {
								font-size: 16px;
								font-weight: 800;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #000;
								filter: drop-shadow(0px 0px 0px #fff);
							}
							span.dl-off {
								font-size: 16px;
								font-weight: 800;
								font-family: Nunito Sans, sans-serif !important;
								line-height: 20px;
								color: #e80c5b;
								filter: drop-shadow(0px 0px 0px #fff);
							}
						}
					}
				}
			}
		}
	}
}

.mobile_footer_ifti {
	display: none;
}

.brd_footer_main {
	width: 100%;
	background-color: #252525;
	padding-top: 64px;
	// min-height: 400px;

	.footer-new-design {
		display: flex;
		width: 100%;
		flex-direction: column;

		.brd-footer-top {
			display: flex;
			// justify-content: space-between;
			color: #fff;
			gap: 200px;

			.brd-footer-box {
				h4 {
					color: #fff;
					font-size: 12px;
					text-transform: uppercase;
					opacity: 0.7;
					margin-bottom: 10px;
				}
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					li {
						width: 100%;
						a {
							color: #fff;
							font-size: 14px;
							padding-bottom: 5px;
							display: block;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
	}

	.brd_footer {
		width: 100%;
		justify-content: space-between;
		display: flex;
		align-items: center;
		border-top: solid 1px rgba(255, 255, 255, 0.6);
		margin-top: 20px;
		padding: 20px 0;

		.brd-copyright {
			color: #fff;
			font-size: 1rem;
		}
		.f-right-temporary {
			display: flex;
			align-items: center;
			.terms-link-temporary {
				display: flex;
				margin-right: 15px;
				ul {
					list-style-type: none;
					margin: 0;
					padding: 0;
					display: flex;
					li {
						padding: 0 10px;
						a {
							color: #fff;
							font-size: 1rem;
							&:hover {
								text-decoration: underline;
							}
						}
					}
				}
			}
		}
		.brd-right {
			display: flex;
			align-items: center;

			.brd-links {
				margin-right: 3rem;
				a {
					color: #fff;
					font-size: 1rem;
					margin: 0 10px;
					&:hover {
						color: #000;
						text-decoration: underline;
					}
				}
			}

			.brd-social {
				.fa-stack {
					font-size: 1.5rem;
					.fa-stack-2x {
						color: #fff;
						transition: all 0.2s ease;
						opacity: 0.7;
					}
					.fa-stack-1x {
						color: #000;
						transition: all 0.2s ease;
						font-size: 1rem;
					}
				}
			}
		}
	}
}

.password_page {
	width: 100%;
	height: 100vh;
	.password-header {
		width: 100%;
		background-color: #252525;
		padding-top: 20px;
		padding-bottom: 20px;
		.container {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.p-h-left {
			a {
				color: #fff;
				font-size: 2rem;
				font-weight: bold;
				font-family: "SegoeUIBold";
				img {
					width: 110px;
				}
			}
		}
		.enter-using-ps {
			color: #fff;
			font-family: Nunito Sans, sans-serif !important;
			font-size: 16px;
			position: relative;
			span {
				display: flex;
				align-items: center;
				cursor: pointer;
				i {
					margin-right: 10px;
					margin-top: -2px;
				}
			}
			.enter-pass-box {
				position: absolute;
				background-color: #fff;
				min-width: 320px;
				padding: 24px;
				padding-bottom: 0;
				right: 0;
				top: 40px;
				z-index: 2;
				border-radius: 3px;
				box-shadow: 0 1px 5px 2px #0000001a;
				.arrow-up {
					position: absolute;
					top: 0;
					color: #fff;
					width: 100%;
					left: 0;
					display: flex;
					justify-content: center;
					top: -17px;
					font-size: 24px;
				}
				h3 {
					font-size: 18px;
					text-align: center;
					font-weight: 800 !important;
					margin-bottom: 24px;
				}
				.ant-form-item {
					width: 100%;
				}
				.enter-email {
					.ant-input {
						border-color: #000;
					}
				}
				.ant-btn-primary {
					width: 100%;
					background-color: #000080;
					border-color: #000080;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
					&:hover {
						background-color: #000;
					}
					span {
						justify-content: center;
						letter-spacing: 0.05rem;
					}
				}
			}
		}
	}
	.password-box {
		width: 100%;
		height: calc(100vh - 84px);
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #f5f5f5;
		.pswd-iner {
			h3 {
				text-align: center;
				text-transform: uppercase;
				margin-bottom: 0;
				font-weight: 800 !important;
			}
			p {
				font-family: Nunito Sans, sans-serif !important;
				font-size: 1.3rem;
				color: #7b8994;
				margin-bottom: 0;
				margin-bottom: 24px;
				text-align: center;
			}
			width: 630px;
			padding: 0 40px;
			.ant-form-horizontal {
				display: flex;
				.enter-email {
					width: 100%;
					margin-right: 10px;
					.ant-input {
						border-color: #000;
					}
				}
				.ant-btn-primary {
					background-color: #000080;
					border-color: #000080;
					font-family: Nunito Sans, sans-serif !important;
					font-weight: 700;
					&:hover {
						background-color: #000;
					}
				}
			}
		}
	}
}

.error_page_main {
	width: 100%;
	margin-top: 100px;
	margin-bottom: 100px;
	.error_content {
		width: 100%;
		.error_header {
			text-align: center;
			h2 {
				color: #000080;
				font-weight: 800 !important;
				font-size: 120px;
				margin-bottom: 40px;
			}
			.error-img {
				margin-bottom: 40px;
				max-width: 100%;
				height: auto;
			}
			h3 {
				color: #252525;
				font-weight: 800 !important;
				font-size: 30px;
				margin-bottom: 10px;
			}
			p {
				color: #575757;
				font-weight: 400 !important;
				font-size: 20px;
				margin-bottom: 0;
			}
			a {
				background-color: #000080;
				color: #fff;
				font-family: Nunito Sans, sans-serif !important;
				padding: 10px 35px;
				font-weight: 700;
				border-radius: 37px;
				display: inline-block;
				margin-top: 45px;
				font-size: 16px;
				&:hover {
					background-color: #000;
				}
			}
		}
	}
}

.privacy_page_main {
	width: 100%;
	margin-top: 70px;
	margin-bottom: 70px;
	.privacy_content {
		width: 100%;
		.side-hd {
			font-size: 20px;
			font-weight: 800 !important;
			margin-top: 48px;
			margin-bottom: 20px;
		}
		ul.side-list {
			list-style-type: none;
			margin: 0 0 20px 0;
			padding: 0;
			li {
				width: 100%;
				padding: 7px 0;
				a {
					color: #7b8994;
					padding-bottom: 0px;
					display: block;
					&:hover {
						color: #000000;
					}
					&.active {
						color: #000;
						font-weight: 800;
						font-family: "SegoeUIBold";
					}
				}
			}
		}
		.list-simple {
			padding-left: 20px;
			li {
				margin-bottom: 10px;
				color: #252525;
				text-align: justify;
				strong {
					font-family: "SegoeUIBold";
				}
			}
		}
		.privacy_header {
			// text-align: center;
			border-radius: 8px;
			box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
			-webkit-box-shadow: 0 4px 8px 0 rgb(10 22 70 / 15%);
			-moz-box-shadow: 0 4px 8px 0 rgba(10, 22, 70, 0.15);
			background-color: #fff;
			padding: 30px;
			h2 {
				color: #000080;
				font-weight: 800 !important;
				font-size: 120px;
				margin-bottom: 40px;
			}
			.privacy-img {
				margin-bottom: 40px;
				max-width: 100%;
				height: auto;
			}
			h3 {
				color: #252525;
				font-weight: 800 !important;
				font-size: 30px;
				margin-bottom: 10px;
			}
			h5 {
				color: #252525;
				font-weight: 800 !important;
				font-size: 22px;
				line-height: 2rem;
				margin-bottom: 10px;
				padding-top: 10px;
			}
			p {
				color: #252525;
				font-weight: 400 !important;
				text-align: justify;
				strong {
					font-family: "SegoeUIBold";
				}

				// line-height: initial;
			}
			// a {
			//     background-color: #000080;
			//     color: #FFF;
			//     font-family: Nunito Sans, sans-serif !important;
			//     padding: 10px 35px;
			//     font-weight: 700;
			//     border-radius: 37px;
			//     display: inline-block;
			//     margin-top: 45px;
			//     font-size: 16px;
			//     &:hover{
			//         background-color: #000;
			//     }
			// }

			.faq-container-ift {
				.ant-collapse {
					border: none;
					background-color: transparent;
					.ant-collapse-item {
						.ant-collapse-header {
							padding-left: 0;
							padding-top: 20px;
							padding-bottom: 20px;
							.ant-collapse-header-text {
								font-family: "Nunito Sans", sans-serif !important;
								font-size: 16px;
								color: #252525;
								font-weight: 700;
							}
							.ant-collapse-expand-icon {
								.ant-collapse-arrow {
									font-size: 20px;
								}
							}
						}
						.ant-collapse-content {
							border-top: none;
							.ant-collapse-content-box {
								padding: 5px 0;
							}
						}
					}
				}
			}
		}
	}
}

.contact_content {
	width: 100%;
	h4 {
		color: #252525;
		font-weight: 800 !important;
		font-size: 30px;
		margin-bottom: 10px;
	}
	p {
		color: #252525;
		padding-bottom: 20px;
	}
	.ant-btn-primary {
		background-color: #000080;
		border-color: #000080;
		color: #fff;
		font-family: Nunito Sans, sans-serif !important;
		padding: 10px 35px;
		font-weight: 700;
		border-radius: 37px;
		height: auto;
		font-size: 16px;
		&:hover {
			background-color: #000;
		}
	}
	.contact_header {
		// text-align: center;
		padding-bottom: 40px;
		h2 {
			color: #000080;
			font-weight: 800 !important;
			font-size: 120px;
			margin-bottom: 40px;
		}
		.privacy-img {
			margin-bottom: 40px;
			max-width: 100%;
			height: auto;
		}
		h3 {
			color: #252525;
			font-weight: 800 !important;
			font-size: 30px;
			margin-bottom: 10px;
		}

		p {
			color: #575757;
			font-weight: 400 !important;
			font-size: 16px;
			// line-height: initial;
		}
		a {
			background-color: #000080;
			color: #fff;
			font-family: Nunito Sans, sans-serif !important;
			padding: 10px 35px;
			font-weight: 700;
			border-radius: 37px;
			display: inline-block;
			margin-top: 45px;
			font-size: 16px;
			&:hover {
				background-color: #000;
			}
		}
	}
	.contact-us-form-kb {
		.cus-input-height {
			.ant-input-lg {
				font-size: 14px;
				border-color: #000;
				height: 40px;
			}
		}
		.cus-text-area {
			.ant-input-lg {
				border-color: #000;
				font-size: 14px;
			}
		}
	}
}

@media screen and (min-width: 100px) {
	.midd-width-ifti {
		padding-inline-end: 20px;
		padding-inline-start: 20px;
	}
}

@media screen and (min-width: 600px) {
	.midd-width-ifti {
		padding-inline-end: 24px;
		padding-inline-start: 24px;
	}
}
@media screen and (min-width: 1440px) {
	.midd-width-ifti {
		max-width: 100%;
		padding-inline-end: 24px;
		padding-inline-start: 24px;
		display: flex;
		justify-content: space-between;
		box-sizing: content-box;
		margin: 0 auto;
	}
	.max-1140 {
		max-width: 1140px !important;
	}
}

@media only screen and (max-width: 767px) {
	.contact_content .ant-btn-primary {
        padding: 7px 25px;
        font-size: 14px;
    }
	.cat_brands_main {
		.cat_brands {
			.filter_brands_influencer {
				grid-template-columns: repeat(2, 1fr);
			}
		}
	}
	.login-signup-ift {
		.account-choose-container {
			.ac_type_block label {
				.brnd-right-content {
					flex: 0 0 80%;
					max-width: 80%;

					.signup_detail {
						ul {
							li {
								align-items: flex-start;
								i {
									top: 4px;
								}
							}
						}
					}
				}
			}
		}
	}
}

.lv-cntr {
	display: flex;
	justify-content: center;
	padding: 50px 0;
}

.live-cam {
	display: flex;
	width: 100%;
	flex-direction: column;
	padding: 50px 0;
	justify-content: center;
	align-items: center;
	canvas {
		width: 700px;
	}
	.start-stop {
		width: 100%;
		display: flex;
		margin-top: 5px;
		padding: 10px 20px;
		justify-content: center;
		button {
			margin-right: 15px;
			right: 0;
			color: #fff;
			background-color: #000080;
			border-radius: 37px;
			color: #fff;
			cursor: pointer;
			font-family: Nunito Sans, sans-serif !important;
			font-size: 14px;
			font-weight: 700;
			padding: 8px 15px;
			margin-right: 16px;
			border: none;
			min-width: 90px;
			&:hover {
				background-color: #000;
			}
		}
	}
}

.notification-area-top {
	margin-right: 25px;
	.notification-area {
		position: relative;
		.fa-bell {
			font-size: 20px;
			color: #5c6067;
		}

		.notificatoin-count {
			position: absolute;
			right: -12px;
			top: -10px;
			background-color: red;
			color: white;
			border-radius: 100px;
			font-size: 10px;
			width: 22px;
			height: 22px;
			line-height: 20px;
			text-align: center;
			border: 2px solid white;
		}
	}
}

.abcde {
	color: blue;
}

.notification-scroll {
	// max-height: 350px;
	// overflow: auto;
}

.cart-area-top {
	margin-left: 25px;
	position: relative;
    top: 2px;
	.cart-area {
		position: relative;
		i {
			font-size: 22px;
			color: #5c6067;
		}

		.cart-count {
			position: absolute;
			right: -12px;
			top: -12px;
			background-color: red;
			color: white;
			border-radius: 100px;
			font-size: 10px;
			width: 22px;
			height: 22px;
			line-height: 20px;
			text-align: center;
			border: 2px solid white;
		}
	}
}



.notification-area-top {
  position: relative;
}
.drop-list-main {
	width: 300px;
	background-color: white;
	border: 1px solid rgb(227, 227, 227);
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 9999;
  max-height: 350px;
	overflow: auto;
}
.drop-list ul {
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.drop-list ul li {
	width: 100%;
	list-style-type: none;
	margin: 0;
	padding: 0;
}
.drop-list ul li a {
  display: block;
	margin: 0;
	padding: 0;
	border-bottom: 1px solid rgb(227, 227, 227);
  padding: 6px 10px;
  color: black;
  font-size: 12px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  height: initial;
  white-space: nowrap;
}
.drop-list ul li a b {
  font-weight: 700;
 }



.drop-list ul li a.highlight-notification {

}
.drop-list ul li a.read-notification {
  background-color: white;
}
.drop-list ul li a.new-notification {
  background-color: #c0bfbf;
}


.table-simple {
	padding: 20px 0;
	font-family: Nunito Sans, sans-serif !important;
	thead{
	  background: #000080;
	  color: #fff;
	tr {
	  th {
		width: 410px;
		vertical-align: middle;
		&:first-child {
		  width: 260px;
		}
	  }
	}
	}
  }